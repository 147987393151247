import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler, AppSidebarMinimizer } from '@coreui/react';
import logo from '../../assets/img/brand/89_25.png'
import sygnet from '../../assets/img/brand/favicon.ico'
import connect from "react-redux/es/connect/connect";
import {logOutUser} from "../../shared/helpers/GeneralHelpers";
import { withRouter } from 'react-router-dom';
import blank from '../../assets/img/blank.png'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  ref = React.createRef();
  state = {
    data: {}
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoggedInUser} = nextProps;
    let {data} = prevState;
    if (getLoggedInUser){
      let {success} = getLoggedInUser
      if (success) {
        data = getLoggedInUser.success.data;
      }
    }
    return{
      data
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    if (this.ref && !this.ref.current.contains(event.target)) {
      let parent = document.getElementById("profile-toggler");
      parent.classList.remove("show")
      parent.children[1].classList.remove("show")
    }
  }

  goToSettings(){
    this.props.history.push("/company-settings")
    this.toggleElement()
  }

  goToAppKeys(){
    this.props.history.push("/keys/app")
    this.toggleElement()
  }

  goToUserSettings(){
    this.props.history.push("/user-settings")
    this.toggleElement()
  }

  goToPaymentSettings(){
    this.props.history.push("/payment-settings")
    this.toggleElement()
  }

  goToPaymentGateway(){
    this.props.history.push("/payment-gateway")
    this.toggleElement()
  }

  goToRoles(){
    this.props.history.push("/roles")
    this.toggleElement()
  }

  goToPermissions(){
    this.props.history.push("/permissions")
    this.toggleElement()
  }

  toggleElement(e){
    let parent = document.getElementById("profile-toggler");
    parent.classList.toggle("show")
    parent.children[1].classList.toggle("show")
  }

  render() {
    let {data} = this.state
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarMinimizer className='sidebar-minimizer' display='lg'>
          <a className='icon-menu' style={{fontSize: 'larger'}}></a>
        </AppSidebarMinimizer>
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'GERP' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'GERP' }}
        />
        {/*<AppSidebarToggler className="d-md-down-none" display="lg" />*/}

        {/*<Nav className="ml-auto" navbar>*/}
        {/*  <AppHeaderDropdown direction="down">*/}
        {/*    <DropdownToggle nav>*/}
        {/*      <img src={data.image ? data.image +'?date=' +Date() : blank} className="img-avatar" alt="nettv" />*/}
        {/*    </DropdownToggle>*/}
        {/*    <DropdownMenu right>*/}
        {/*      <DropdownItem header tag="div" className="text-center"><strong>{'Welcome '+data.name + '!!'}</strong></DropdownItem>*/}
        {/*      <DropdownItem onClick={this.goToUserSettings.bind(this)}><i className="fa fa-cog"></i> User Settings</DropdownItem>*/}
        {/*      /!*<DropdownItem onClick={this.goToPaymentGateway.bind(this)}><i className="fa fa-google-wallet"></i> Payment Gateway</DropdownItem>*!/*/}
        {/*      <DropdownItem onClick={this.goToPaymentSettings.bind(this)}><i className="fa fa-balance-scale"></i> Payment Settings</DropdownItem>*/}
        {/*      <DropdownItem onClick={this.goToSettings.bind(this)}><i className="fa fa-cogs"></i> Company Settings</DropdownItem>*/}
        {/*      <DropdownItem onClick={this.goToRoles.bind(this)}><i className="fa fa-tasks"></i> Roles</DropdownItem>*/}
        {/*      <DropdownItem onClick={this.goToPermissions.bind(this)}><i className="fa fa-lock"></i> Permissions</DropdownItem>*/}
        {/*      <DropdownItem onClick={logOutUser}><i className="fa fa-sign-out"></i> Logout</DropdownItem>*/}
        {/*    </DropdownMenu>*/}
        {/*  </AppHeaderDropdown>*/}
        {/*</Nav>*/}

        <ul className="ml-auto navbar-nav fromLinkFiber12114">
          <li className="dropdown nav-item" id="profile-toggler"
              ref={this.ref}>
            <a aria-haspopup="true" className="nav-link fromLinkFiber12120" aria-expanded="true" onClick={(e) => this.toggleElement(e)}>
            <img src={data.image ? data.image +'?date=' +Date() : blank} className="img-avatar" alt="nettv" />
          </a>
            <div tabIndex="-1" role="menu" aria-hidden="false"
                 className="dropdown-menu dropdown-menu-right fromLinkFiber12123"
                 style={{position: "absolute", willChange: "transform", top: "0px", left: "0px", transform: "translate3d(-137px, 35px, 0px)"}}
                 x-placement="bottom-end">
              <div tabIndex="-1" className="text-center dropdown-header fromLinkFiber12124"><strong>
                {'Welcome '+data.name + '!!'}
              </strong></div>
              <button type="button"
                      onClick={this.goToUserSettings.bind(this)}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12125">
                <i className="fa fa-cog"></i> User Settings
              </button>
              <button type="button"
                      onClick={this.goToPaymentSettings.bind(this)}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12126">
                <i className="fa fa-balance-scale"></i> Payment Settings
              </button>
              <button type="button"
                      onClick={this.goToSettings.bind(this)}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12127">
                <i className="fa fa-cogs"></i> Company Settings
              </button>
              <button type="button"
                      onClick={this.goToRoles.bind(this)}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12128">
                <i className="fa fa-tasks"></i> Roles
              </button>
              <button type="button"
                      onClick={this.goToPermissions.bind(this)}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12129">
                <i className="fa fa-lock"></i> Permissions
              </button>
              <button type="button"
                      onClick={logOutUser}
                      tabIndex="0" role="menuitem" className="dropdown-item fromLinkFiber12130">
                <i className="fa fa-sign-out"></i> Logout
              </button>
            </div>
          </li>
        </ul>


        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    getLoggedInUser: state.getLoggedInUser
  }
}

export default connect(mapStateToProps)(withRouter(DefaultHeader));

