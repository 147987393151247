import React from 'react';
import {Button, ButtonGroup, Card, CardBody, Col, Form, FormGroup, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import ViewData from "../../shared/form-helpers/ViewData";
import SelectPermission from "./SelectPermission";
import {Tag, Tooltip} from "antd";

const RoleModal = (props) => {

  let {
    toggle,
    data,
    error,
    onChange,
    onSave,
    onReset,
    isView,
    isNew,
    permissions,
    permissionModal,
    searchChange,
    paginationButton,
    prevPageUrl,
    nextPageUrl,
    currentPage,
    from,
    to,
    total,
    lastPage,
    toggleModal,
    checkedPermissions,
    rowPressed,
    permissionAssigned,
  } = props

  let viewKeys = isView ? Object.keys(isView) : []

  let tempKeys = permissionAssigned.keys()

  tempKeys = Array.from(tempKeys)


  let showAssignedPermissions = tempKeys.map((single,index)=>{
    if (single){
      let tag = permissionAssigned.get(single)
      const isLongTag = tag.length > 20;
      const tagElem = (
        <Tag key={tag} closable={index !== -1} onClose={() => rowPressed(single,null)}>
          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
        </Tag>
      );
      return isLongTag ? (
        <Tooltip title={tag} key={tag}>
          {tagElem}
        </Tooltip>
      ) : (
        tagElem
      );
    }
    return null
  })

  let displayViewKeys = viewKeys.map((key) => {
    if (key === "type" || key === "id" || key === "created_at" || key === "updated_at") {
      return null;
    }
    else if (key === "perms"){
      let showPermissions = isView[key].map((single)=>{
        if (single){
            let tag = single.display_name
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={tag}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        }
        return null
      })
      return (
        <Row><Col>
          <span className="form-span-label">Permissions</span>
          <FormGroup>
            {showPermissions}
          </FormGroup>
        </Col></Row>
      )
    }
    else
      return (
        <Row>
          <Col>
            <ViewData
              label={key}
              data={isView[key]}
            />
          </Col>
        </Row>
      );
  })

  if (isView) {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="2"></Col>
              <Col sm="12" md="6">
                {displayViewKeys}
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  <Button outline color="success" onClick={toggle}>Okay</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div className="animated fadeIn">
        <SelectPermission
          isOpen={permissionModal}
          toggle={toggleModal}
          permissions={permissions}
          handleProducts={(e) => this.handleProducts(e)}
          checkedPermissions={checkedPermissions}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          lastPage={lastPage}
          paginationButton={paginationButton}
          rowPressed={rowPressed}
          searchChange={searchChange}
        />
        <Card>
          <CardBody>
            <Row>
              <Col md="2"/>
              <Col md="9" sm="12">
                <Form>
                  <HorizontalTextField
                    name="name"
                    value={data.name}
                    error={error.name}
                    onChange={onChange}
                    isRequired={true}
                  />
                  <HorizontalTextField
                    name="display_name"
                    value={data.display_name}
                    error={error.display_name}
                    onChange={onChange}
                    isRequired={true}
                  />
                  <HorizontalTextField
                    name="description"
                    error={error.description}
                    value={data.description}
                    onChange={onChange}
                  />
                  <Row><Col md="3"/><Col md="9"><Button outline color="success" onClick={toggleModal}>Assign Permission</Button></Col></Row>
                  <br/>
                  <Row><Col md="3"/><Col md="9">{showAssignedPermissions}</Col></Row>

                  {error.permission_id && error.permission_id.length > 0 &&
                  <Row><Col md="3"/><Col md="9"> <span className="custom-invalid-feedback">{error.permission_id}</span></Col></Row>}
                </Form>
              </Col>

            </Row>
            <Row>
              <Col>
                {isNew ? <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                  <Button outline color="primary" onClick={onReset}>Reset</Button>
                  <Button outline color="success" onClick={onSave}>Save</Button>
                </ButtonGroup> : <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                </ButtonGroup>}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}


export default RoleModal
