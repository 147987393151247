export default function downloadMessage(state = { message: "" }, action) {
  switch (action.type) {
    case "SET_DOWNLOAD_MESSAGE":
      return { ...state, ...{ message: action.message } };

    case "DELETE_DOWNLOAD_MESSAGE":
      return { ...state, ...{ message: "" } };

    default:
      return state;
  }
}
