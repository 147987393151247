import React from 'react';
import {Button, ButtonGroup, Col, Form, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import ViewData from "../../shared/form-helpers/ViewData";

const CurrencyModal = (props) => {

  let {
    toggle,
    data,
    error,
    onChange,
    onSave,
    onReset,
    viewModal,
  } = props

  if (viewModal) {
    return (
      <>
        <Row>
          <Col sm="12" md="2"></Col>
          <Col sm="12" md="6">
            <Row><Col><ViewData label="Code" data={data['code']}/></Col></Row>
            <Row><Col><ViewData label="Symbol" data={data['symbol']}/></Col></Row>
            {data['description'] && <Row><Col><ViewData label="Description" data={data['description']}/></Col></Row>}
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup className="float-right">
              <Button outline color="success" onClick={toggle}>Okay</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    )
  }
  else {
    return (
      <>
        <Row>
          <Col sm="2"/>
          <Col sm="6">
            <Form>
              <HorizontalTextField
                name="code"
                error={error['code']}
                onChange={onChange}
                value={data['code']}
                displayName="Code"
                isRequired={true}
              />
              <HorizontalTextField
                name="description"
                error={error['description']}
                onChange={onChange}
                value={data['description']}
                displayName="Description"
              />
              <HorizontalTextField
                name="symbol"
                error={error['symbol']}
                onChange={onChange}
                value={data['symbol']}
                displayName="Symbol"
                isRequired={true}
              />
            </Form>
          </Col>
          <Col sm="2">
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={toggle}>Cancel</Button>
              <Button outline color="primary" onClick={onReset}>Reset</Button>
              <Button outline color="success" onClick={onSave}>Save</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default CurrencyModal;
