import React from 'react';
import {
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

const IconTextField = (props) => {
  let {type,error,onChange,value,name,disabled,min,icon,onKeyDown} = props;
  return(
    <FormGroup row>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className={icon}></i></InputGroupText>
        </InputGroupAddon>
        <Input type={type} invalid={!!error} name={name} value={value} onChange={onChange} disabled={disabled} min={min} onKeyDown={onKeyDown}/>
        <FormFeedback>{error}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};

export default IconTextField;
