import React, {Component} from 'react'
import {Card, CardBody} from "reactstrap";

class ServerError extends Component {
  render() {
    return (
      <Card>
        <CardBody className="empty-div">
          <div className="text-center">
            <h1 className="status-code server-error">500 !!</h1>
            <h2>Internal Server Error</h2>
            <p>
              Either you are not connected to internet or something wrong went with server.
            </p>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default ServerError;
