import {Get} from "../actions/crud/get"

let prefix = "platforms"

export const getPlatforms = () =>{
  let reducers = {
    success: 'FETCH_PLATFORMS_SUCCESS',
    error: 'FETCH_PLATFORMS_ERROR'
  }
  let get = new Get(prefix,reducers)
  get.get()
}
