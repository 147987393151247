import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {Switch} from 'antd';
import {getCountries} from "../../apis/countries";
import {getVendor, updateResetVendor, updateVendor} from "../../apis/vendors";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {localValidation} from "../../shared/helpers/ValidationHelper";

class EditVendor extends Component {

  error = {}
  state = {
    restData: null,
    company: true,
    data: {},
    error: {...this.error},
    restError: null,
    countries: [],
    provinces: [],
    createSuccess: null
  }

  fetchData() {
    getCountries(1000)
    getVendor(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData();
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  changeStatus() {
    let data = {...this.state.data}
    data['status'] = data['status'] === 1 ? 0 : 1
    this.setState({
      data
    })
  }

  onCancel() {
    this.props.history.push("/purchases/vendors")
  }

  onReset() {
    this.setState({
      data: this.state.restData,
      error: {...this.error}
    })
  }

  onSubmit() {
    let validationRule = {
      last_name: ['required'],
      email: ['email'],
      website: ['url'],
      phone_no: ['phone'],
      mobile_no: ['mobile'],
      pan: ["pan"],
      address: ['required'],
      city: ['required', 'address'],
      country_id: ['required'],
      state: ['required'],
      zip_code: ['zip']
    }
    let data = {...this.state.data}
    data['type'] = "C"
    let localValidationStatus = false
    console.log(data)
    let check = localValidation(data, validationRule, this.state.error, localValidationStatus)

    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      updateVendor(this.props.match.params.id, data)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {updateVendor, getCountries, getVendor} = nextProps
    let {restError, countries, provinces, createSuccess, restData} = prevState
    if (updateVendor) {
      let {success, validation} = updateVendor
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        createSuccess = null
        restError = null
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      } else if (error) {
        console.log(error)
      }
    }

    if (getVendor) {
      let {success} = getVendor
      if (success) {
        restData = success.data
      }

    }
    return {
      restError, countries, provinces, createSuccess, restData
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
        error: this.state.restError
      }, () => {
        updateResetVendor()
      })
    }

    if (this.state.createSuccess !== null) {
      store.dispatch(addMessage(this.state.createSuccess, "success", "Update Vendor"));
      updateResetVendor()
      setFetch(true)
      this.props.history.push('/purchases/vendors')
    }

    if (this.state.restData !== prevState.restData) {
      this.setState({
        data: this.state.restData
      })
    }
  }

  render() {
    let {data, error, countries, provinces} = this.state

    let countryList = countries.map((country) => (
        <option value={country.id}>{country.name}</option>
      )
    )

    let provincesList = provinces[data.country_id] && provinces[data.country_id].map((province) => (
      <option value={province.id}>{province.name}</option>
    ))

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col md="2" sm="12"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col md="10" sm="12">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Vendor Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.last_name} className="form-control"
                             name="last_name" value={data.last_name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.last_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Email</span>
                    <FormGroup>
                      <Input invalid={!!error.email} className="form-control" name="email"
                             onChange={e => this.onChange(e)} value={data.email}/>
                      <FormFeedback>{error.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Website</span>
                    <FormGroup>
                      <Input invalid={!!error.website} className="form-control" name="website"
                             value={data.website}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.website}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Phone Number</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.phone_no} className="form-control"
                             name="phone_no" value={data.phone_no}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.phone_no}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Mobile Number</span>
                    <FormGroup>
                      <Input invalid={!!error.mobile_no} className="form-control"
                             name="mobile_no" value={data.mobile_no}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.mobile_no}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Status</span>
                    <FormGroup>
                      <Switch
                        name="status"
                        checked={data.status}
                        onChange={this.changeStatus.bind(this)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">PAN Number</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.pan} className="form-control"
                             name="pan" value={data.pan}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.pan}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col md="2" sm="12"><span className="form-left-span"><strong>Address</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Billing Address</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.address} className="form-control"
                             name="address" value={data.address}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.address}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">City</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.city} className="form-control" name="city"
                             value={data.city} onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.city}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Country</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.country_id} className="form-control" name="country_id"
                             onChange={e => this.onChange(e)} value={data.country_id}>
                        <option>Country</option>
                        {countryList}
                      </Input>
                      <FormFeedback>{error.country_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">State</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.state} className="form-control" name="state"
                             onChange={e => this.onChange(e)} value={data.state}>
                        <option>State</option>
                        {provincesList}
                      </Input>
                      <FormFeedback>{error.state}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <span className="form-span-label">Postal/Zip Code</span>
                    <FormGroup>
                      <Input invalid={!!error.zip_code} className="form-control"
                             name="zip_code" value={data.zip_code}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.zip_code}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {updateVendor, getCountries, getVendor} = state
  return {
    updateVendor, getCountries, getVendor
  }
}

export default connect(mapStateToProps)(EditVendor);
