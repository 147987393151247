import React, { Component } from 'react'
import {connect} from "react-redux";
import {Button, Col, Input, Row} from "reactstrap";
import logo from "../../assets/img/brand/89_25.png";
import {forgotPassword, forgotResetPassword} from "../../actions/password/forgot_password";
import store from '../../Store'

class ForgotPassword extends Component{

  state={
    data: {
      email: ''
    },
    restSuccess: null,
    restError: null,
    message: null
  }

  onSubmit(){
    store.dispatch(forgotPassword(this.state.data))
  }

  onChange(e){
    this.setState({
      data: {
        email: e.target.value
      }
    })
  }

  onDismiss() {
    this.setState({
      message: null,
    });
  }

  static getDerivedStateFromProps(nextProps,prevState){
    let {
      forgotPassword
    } = nextProps

    let {
      restSuccess,
      restError
    } = prevState

    if (forgotPassword){
      let { success,error } = forgotPassword
      if (success){
        restSuccess = success.data.message
      }
      else if (error){
        restError = error.data.message.email
      }
      else {
        restSuccess = null
        restError = null
      }
    }

    return {
      restSuccess,
      restError
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let {restSuccess,restError} = this.state

    if (restSuccess !== null){
      this.props.history.push('/forgot-password-success',{message: restSuccess})
    }

    if (restError !== null) {
      this.setState({
        message: restError
      },()=>{
        store.dispatch(forgotResetPassword())
      })
    }

  }

  render() {
    let {data,message} = this.state
    return(
      <div className="row justify-content-center" style={{background: '#ffffff', minHeight: '100vh'}}>
        <Col md="9" sm="12">
          <div className="right-side-div">
            <div>
              <img src={logo} className="right-side-payment-logo" alt="GERP"/>
            </div>
            <div>
              <p className="right-side-detail text-center">
                Enter the email below for password reset direction
              </p>
            </div>
            <Row className="justify-content-center">
              <Col md="4">
                <Input type="email" name="email" value={data.email} onChange={(e)=>this.onChange(e)}/>
              </Col>
            </Row>
            <br/>
            <Row className="justify-content-center">
              <Col md="1">
                <Button color="success" onClick={this.onSubmit.bind(this)}>Send</Button>
              </Col>
            </Row>
            <hr/>
            <div>
              <p className="form-span-label text-center confirmation-texttext-center">
                Go back to <span className="span-link">login</span> screen.
              </p>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    forgotPassword
  } = state

  return{
    forgotPassword
  }
}

export default connect(mapStateToProps)(ForgotPassword)
