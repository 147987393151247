import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import FormBuilder from "../../shared/form-helpers/FormBuilder";
import {FormFooter} from "../../shared/form-helpers/FormFooter";
import {inviteUser, resetInviteUser} from "../../apis/users";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {getPOSes} from "../../apis/pos";
import {getRoles} from "../../apis/roles";
import {localValidation} from "../../shared/helpers/ValidationHelper";

class InviteUser extends Component {

  data = {
    email: "",
    name: "",
    pos_id: "",
    role_id: ""
  }

  error = {
    email: "",
    name: "",
    pos_id: "",
    role_id: ""
  }

  state = {
    data: {...this.data},
    error: {...this.error},
    restError: null,
    restSuccess: null,
    poses: [],
    roles: []
  }

  componentDidMount() {
    getPOSes()
    getRoles()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      inviteUser,
      getPOSes,
      getRoles
    } = nextProps

    let {
      restError,
      restSuccess,
      poses,
      roles
    } = prevState

    if (inviteUser) {
      let {success, validation} = inviteUser
      if (success) {
        restSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        restError = null
        restSuccess = null
      }
    }

    if (getPOSes) {
      let { success} = getPOSes
      if (success){
        poses = success.data.data
      }
    }

    if (getRoles) {
      let {success} = getRoles
      if (success){
        roles = success.data.data
      }
    }

    return {
      restError,
      restSuccess,
      poses,
      roles
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {restSuccess, restError} = this.state
    if (restError !== null) {
      this.setState({
        error: restError
      }, () => {
        resetInviteUser()
      })
    }

    if (restSuccess !== null) {
      store.dispatch(addMessage(restSuccess,"success","Users"));
      resetInviteUser()
      setFetch(true)
      this.props.history.push('/company-users')
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push('/company-users')
  }

  onReset() {
    this.setState({
      data: {...this.data}
    })
  }

  onSubmit() {
    let {data} = this.state

    let validationRule = {
      name: ['required', "text"],
      pos_id: ['required'],
      email: ['required',"email"],
      role_id: ['required'],
    }
    let localValidationStatus = false
    let check = localValidation(data, validationRule, this.state.error, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      inviteUser(data)
    }
  }

  render() {
    let {data, error,poses,roles} = this.state

    let posList = poses.map((pos)=>{
      let temp = {
        label: pos.name,
        value: pos.id
      }
      return temp
    })

    let rolesList = roles.map((role)=>{
      let temp = {
        label: role.display_name,
        value: role.id
      }
      return temp
    })

    let itemKeys = [
      {
        title: "Invite User"
      },
      {
        name: "name",
        type: "text",
        is_required: true
      },
      {
        name: "email",
        type: "text",
        is_required: true
      },
      {
        name: "pos_id",
        type: "select",
        options: posList,
        is_required: true
      },
      {
        name: "role_id",
        type: "select",
        options: rolesList,
        is_required: true
      }
    ]

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row>
              <Col md="3" sm="12"></Col>
              <Col md="6" sm="12">
                <FormBuilder
                  onChange={(e) => this.onChange(e)}
                  keys={itemKeys}
                  data={data}
                  error={error}
                />
              </Col>
              <Col md="3" sm="12"></Col>
            </Row>
            <FormFooter
              onCancel={this.onCancel.bind(this)}
              onReset={this.onReset.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
            />
          </CardBody>
        </Card>
      </div>
    );
  }

}

function mapStateToProps(state) {
  let {inviteUser, getPOSes,getRoles} = state
  return {inviteUser, getPOSes, getRoles}
}

export default connect(mapStateToProps)(InviteUser)
