import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Col, FormGroup, Input, Label,
  Modal,
  ModalBody,
  ModalHeader, Row,
} from "reactstrap";
import {Document, Page, pdfjs} from "react-pdf";
import {downloadFile} from "../../shared/helpers/DownloadFile";
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import store from '../../Store'
import {disableInnerLoader} from "../../actions/loaders/loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  width: null
};


class PrintInvoiceModal extends React.Component {

  state = {
    width: null,
    showEmail: this.props.email,
    numPages: null
  }

  componentDidMount() {
    // store.dispatch(enableInnerLoader())
    window.addEventListener("resize", this.resizeWindow.bind(this))
  }

  componentDidUpdate() {
    this.resizeWindow();
  }

  resizeWindow() {
    let width = document.getElementById('pdf') && document.getElementById('pdf').clientWidth;
    if (this.state.width !== width) {
      this.setState({
        width
      })
    }
  }

  downloadPdf(method) {
    let url = this.props.url
    url = url.replace('q=preview', `q=${method}`)
    url = url + '&access_token=' + loginToken();
    let name = this.props.customer_code || "document"
    downloadFile(url, name);
  }

  onDocumentLoadSuccess = ({numPages}) => {
    store.dispatch(disableInnerLoader())
    this.setState({numPages});
  }

  printPdf(method) {
    let url = this.props.url
    url = url.replace('q=preview', `q=${method}`)
    url = url + '&access_token=' + loginToken();
    window.open(url)
  }

  onEmailClick() {
    this.setState({
      showEmail: true
    })
  }

  onToggle() {
    this.setState({
      showEmail: false
    }, () => {
      this.props.toggle();
    })
  }

  onSend() {
    this.onToggle()
  }

  render() {
    let {
      isOpen,
      toggle,
      title
    } = this.props;

    let {
      numPages
    } = this.state

    let url = this.props.url + '&access_token=' + loginToken();

    let {showEmail} = this.state;

    return (
      <Modal isOpen={isOpen} size="lg" toggle={toggle} className={'modal-lg ' + this.props.className}>
        <ModalHeader toggle={this.onToggle.bind(this)}>{title}</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody style={{minHeight: 600}}>
              {showEmail ?
                <div className="row">
                  <div className="col-sm-7" id="pdf">
                    <Document
                      file={url}
                      onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                      options={options}
                    >
                      {
                        Array.from(
                          new Array(numPages),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              width={this.state.width}
                            />
                          ),
                        )
                      }
                    </Document>
                  </div>
                  <div className="col-sm-5">
                    <FormGroup>
                      <Label>To : </Label>
                      <Input/>
                    </FormGroup>
                    <FormGroup>
                      <Label>Subject : </Label>
                      <Input/>
                    </FormGroup>
                    <FormGroup>
                      <Label>Message </Label>
                      <Input type="textarea" rows="5"/>
                    </FormGroup>
                    <FormGroup check>
                      <Label check><Input type="checkbox"/> {' '}Send me a copy</Label>
                    </FormGroup>
                    <br/>
                    <Button className="btn btn-block" color="primary" onClick={this.onSend.bind(this)}>Send</Button>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-sm-9" id="pdf">
                    <Document
                      file={url}
                    >
                      <Page pageNumber={1} width={this.state.width}/>
                    </Document>
                  </div>
                  <div className="col-sm-3">
                    <Row>
                      <Col>
                        <h6>Customer Copy</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button className="btn btn-block" color="success" outline
                                onClick={this.printPdf.bind(this, "print")}>Print</Button>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col>
                        <Button className="btn btn-block" color="success" outline
                                onClick={this.downloadPdf.bind(this, "print")}>Download</Button>
                      </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col>
                        <h6>Company Copy</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button className="btn btn-block" color="success" outline
                                onClick={this.printPdf.bind(this, "invoice")}>Print</Button>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col>
                        <Button className="btn btn-block" color="success" outline
                                onClick={this.downloadPdf.bind(this, "invoice")}>Download</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

export default PrintInvoiceModal;
