import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import SearchSelect from "../shared/form-helpers/SearchSelect";
import {connect} from "react-redux";
import {disableLoader} from "../actions/loaders/loader";
import store from '../Store'

class Dashboard extends Component {

  state = {
    data: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoggedInUser} = nextProps;
    let {data} = prevState;
    if (getLoggedInUser){
      let {success} = getLoggedInUser
      if (success) {
        data = getLoggedInUser.success.data;
      }
    }
    return{
      data
    }
  }

  componentDidMount() {
    if (this.props.location.state)
      if (this.props.location.state.isLogin)
        store.dispatch(disableLoader())

    var classname = document.getElementsByClassName("select-search-box__search")
    classname[0].classList.add("form-control")
    classname[0].classList.add("dashboard-search")
  }

  onChange(e){
    this.props.history.push(e.value)
  }

  onClickNavDiv(link){
    this.props.history.push(link)
  }

  render() {
    let wantOptions = [
      {name: "Invoices", value: '/sales/invoice'},
      {name: "New Invoice", value: '/sales/invoice/new'},
      {name: "Company Settings", value: '/company-settings'},
      {name: "New Product",value: '/sales/products_and_services/new'}
      ]

    let {data} = this.state

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="text-center"> Hello {data.name} !!</div>
              </Col>
            </Row>
            <Row>
              <Col md="3" sm="1"/>
              <Col md="6" sm="10">
                <div className="text-center">
                  <SearchSelect
                    options={wantOptions}
                    placeholder="What do you want to do??"
                    name="want"
                    nolabel={true}
                    onChange={(e)=>this.onChange(e)}
                  />
                </div>
              </Col>
              <Col md="3" sm="1"/>
            </Row>
            <Row>
              <Col sm="12" md="8">
                <span className="dashboard-apps-header">Apps</span>
                <Row className="dashboard-row">
                  <Col xs="4">
                    <div className="dashboard-div first-div" onClick={()=>this.onClickNavDiv('/sales/invoice')}>
                      <div className="main-content">
                        <span className="dashboard-app-icon"><i className="icon-basket-loaded das-ic"/></span>
                        <span className="dashboard-app-text">Sales</span>
                      </div>
                      <div className="overlay">
                        <div className="text">Email beautiful invoices and receive money in a snap</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="4">
                    <div className="dashboard-div second-div">
                      <div className="dashboard-div">
                        <div className="main-content">
                          <span className="dashboard-app-icon"><i className="fa fa-shopping-cart das-ic"/></span>
                          <span className="dashboard-app-text">Purchasing</span>
                        </div>
                        <div className="overlay">
                          <div className="text">Track your spending and justify expenses</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="4">
                    <div className="dashboard-div third-div" onClick={()=>this.onClickNavDiv('/inventory')}>
                      <div className="dashboard-div">
                        <div className="main-content">
                          <span className="dashboard-app-icon"><i className="icon-share-alt das-ic"/></span>
                          <span className="dashboard-app-text">Inventory</span>
                        </div>
                        <div className="overlay">
                          <div className="text">Prepare and ship customer orders keep tight control over your inventory levels.</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="dashboard-row">
                  <Col xs="4">
                    <div className="dashboard-div fourth-div">
                      <div className="dashboard-div">
                        <div className="main-content">
                          <span className="dashboard-app-icon"><i className="icon-pie-chart das-ic"/></span>
                          <span className="dashboard-app-text">Accounting</span>
                        </div>
                        <div className="overlay">
                          <div className="text">Sync your bank statements and see profit and cash flow.</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="4">
                    <div className="dashboard-div fifth-div">
                      <div className="dashboard-div">
                        <div className="main-content">
                          <span className="dashboard-app-icon"><i className="fa fa-book das-ic"/></span>
                          <span className="dashboard-app-text">Reports</span>
                        </div>
                        <div className="overlay">
                          <div className="text">Sync your bank statements and see profit and cash flow.</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="4">
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getLoggedInUser: state.getLoggedInUser
  }
}

export default connect(mapStateToProps)(Dashboard);
