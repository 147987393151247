import React, {Component} from 'react';
import {connect} from "react-redux";
import PaymentMethodModal from "./PaymentMethodModal";
import {
  createPaymentMethod,
  createResetPaymentMethod, deletePaymentMethod,
  deleteResetPaymentMethod, getPaymentMethod,
  getPaymentMethods, updatePaymentMethod,
  updateResetPaymentMethod
} from "../../apis/payment-methods";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";


class PaymentMethods extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    createSuccess: null,
    updateSuccess: null,
    restDelete: null,
    createError: null,
    updateError: null,
    restData: [],
    restUpdate: null,
    visible: false,
    delete: false,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    data: {},
    itemToDelete: "",
    formData: {},
    formError: {}
  }


  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getPaymentMethods(limit, page, searchArray)
  }

  componentDidMount() {
    if (!this.props.getPaymentMethods.success)
      this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    let {createSuccess, updateSuccess, updateError, createError, restUpdate,deleteSuccess} = this.state

    if (restUpdate !== prevState.restUpdate) {
      this.setState({
        formData: restUpdate
      })
    }

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Payment Method"));
      createResetPaymentMethod()
      this.toggleModal()
      this.fetchData();
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Update Payment Method"));
      this.fetchData();
      this.toggleModal()
      updateResetPaymentMethod()
    }

    if (deleteSuccess !== null) {
      store.dispatch(addMessage(deleteSuccess, "success", "Delete Payment Method"));
      this.fetchData();
      deleteResetPaymentMethod()
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetPaymentMethod()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        this.fetchData()
        updateResetPaymentMethod()
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getPaymentMethods,
      getPaymentMethod,
      createPaymentMethod,
      updatePaymentMethod,
      deletePaymentMethod
    } = nextProps
    let {
      restData,
      restUpdate,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      createSuccess,
      createError,
      updateSuccess,
      restDeleteSuccess,
      updateError,
      deleteSuccess
    } = prevState;
    if (getPaymentMethods) {
      let {success} = getPaymentMethods
      if (success) {
        restData = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (createPaymentMethod) {
      let {success, validation} = createPaymentMethod
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (updatePaymentMethod) {
      let {success, validation} = updatePaymentMethod
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null;
        updateError = null;
      }
    }

    if (getPaymentMethod) {
      let {success} = getPaymentMethod
      if (success) {
        restUpdate = success.data
      }
    }

    if (deletePaymentMethod) {
      let {success, error} = deletePaymentMethod
      if (success) {
        deleteSuccess = success.data.message
      } else if (error) {

      } else {
        deleteSuccess = null
      }
    }

    return {
      restData,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restDeleteSuccess,
      restUpdate,
      updateSuccess,
      createSuccess,
      deleteSuccess,
      createError,
      updateError
    }
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  onCreate() {
    createPaymentMethod(this.state.formData)
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deletePaymentMethod(this.state.itemToDelete)
  }

  onNewClick() {
    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: {
        status: 1,
        name: '',
        description: ''
      },
      formError: {}
    })
  }

  onUpdateClick(id) {
    getPaymentMethod(id)
    this.setState({
      id: id,
      openModal: true,
      newModal: false,
      formError: {}
    })
  }

  onReset() {
    if (this.state.id) {
      this.setState({
        formData: this.state.restUpdate
      })
    } else {
      this.setState({
        formData: {
          status: 1,
          name: '',
          description: ''
        }
      })
    }
  }

  onUpdate() {
    let {id, formData} = this.state
    updatePaymentMethod(id, formData)
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      restData,
      openModal,
      newModal,
      formData,
      formError,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    if (openModal){
      return (
        <PaymentMethodModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={newModal ? "Add New Payment Method" : "Edit Payment Method"}
          data={formData}
          error={formError}
          onChange={e => this.onChange(e)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
        />
      );
    }

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '60%'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '30%',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Disabled'
          }
        ]
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, name, status
      } = single

      tableData.push({
        id, name, status
      })
    })

    return (
      <>
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          filters={this.filters}
          newTitle="Create Payment Method"
          newAction={this.onNewClick.bind(this)}
          tabTable={true}
        />

        {/*<DeleteModal*/}
        {/*  open={deleteModal}*/}
        {/*  toggle={this.onDelete.bind(this)}*/}
        {/*  deleteItem={this.onDeleteConfirm.bind(this)}*/}
        {/*  title="Delete Payment Method"*/}
        {/*/>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {getCustomers, createPaymentMethod, getPaymentMethods, getPaymentMethod, updatePaymentMethod, deletePaymentMethod} = state;
  return {
    getCustomers, createPaymentMethod, getPaymentMethods, getPaymentMethod, updatePaymentMethod, deletePaymentMethod
  }
}

export default connect(mapStateToProps)(PaymentMethods);
