import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import {generateAuthKey, getAuthKeys, resetUpdateAuthKey, updateAuthKey} from "../../apis/keys";

class AppKeys extends Component {

  state = {
    data: [],
    processing: false,
    restSuccess: null,
    visible: false,
    success: '',
    itemToUpdate: '',
    showUpdateModal: false,
    generateSuccess: null
  }

  componentDidMount() {
    if (!this.props.getAuthKeys.success)
      this.fetchData();
  }

  fetchData() {
    getAuthKeys()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getAuthKeys, updateAuthKey, generateAuthKey} = nextProps
    let {data, processing, restSuccess, generateSuccess} = prevState
    if (getAuthKeys) {
      let {success, error} = getAuthKeys
      if (success) {
        data = success.data
      } else if (error) {
        console.log(error)
      }
      processing = getAuthKeys.processing
    }

    if (updateAuthKey) {
      let {success, error} = updateAuthKey
      restSuccess = null
      if (success) {
        restSuccess = success.data.message
      } else if (error) {
        console.log(error)
      }
    }

    if (generateAuthKey) {
      let {success, error} = generateAuthKey
      if (success) {
        generateSuccess = success.data.message
      } else if (error) {
        console.log(error)
      }
    }


    return {data, processing, restSuccess, generateSuccess}
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restSuccess !== prevState.restSuccess) {
      if (this.state.restSuccess) {
        this.setState({
          success: this.state.restSuccess,
          visible: true,
          showUpdateModal: false,
          itemToDelete: ''
        }, () => {
          resetUpdateAuthKey()
          this.fetchData()
        })
      }
    }

    if (this.state.generateSuccess !== prevState.generateSuccess) {
      this.setState({
        success: this.state.generateSuccess,
        visible: true,
        showUpdateModal: false,
        itemToDelete: ''
      }, () => {
        this.fetchData()
      })
    }
  }

  onUpdateConfirm() {
    updateAuthKey(this.state.itemToUpdate)
  }

  onUpdate(id) {
    this.state.showUpdateModal ?
      this.setState({showUpdateModal: false, itemToUpdate: ""}) :
      this.setState({showUpdateModal: true, itemToUpdate: id})
  }

  onDismiss() {
    this.setState({visible: false, success: ''});
  }

  onGenereate() {
    generateAuthKey()
  }

  render() {
    let {data} = this.state

    let sn = 0;

    let tablebody = !data.message && data.map((keys) => {
      let {app_name, client_id, client_secret} = keys
      sn++
      return (
        <tr key={sn}>
          <td>{sn}</td>
          <td>{app_name}</td>
          <td>{client_id}</td>
          <td>{client_secret}</td>
          <td><Button outline color="success" onClick={() => this.onUpdate(client_id)}>Update</Button></td>
        </tr>
      )
    })

    let tableheader = !data.message ? <Table responsive>
        <thead>
        <tr>
          <th width="50">S.N</th>
          <th width="200">App Name</th>
          <th width="80">Client Id</th>
          <th width="auto">Client Secret</th>
          <th width="80"></th>
        </tr>
        </thead>
        <tbody>
        {tablebody}
        </tbody>
      </Table> :
      <Row>
        <Col sm="9">{data.message} <span className="span-link" onClick={this.onGenereate.bind(this)}>Generate Key</span></Col>
      </Row>

    let cardbody = <CardBody>
      {tableheader}
    </CardBody>


    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <DeleteModal
                open={this.state.showUpdateModal}
                toggle={this.onUpdate.bind(this)}
                deleteItem={this.onUpdateConfirm.bind(this)}
                title="Update Key"
                isUpdate={true}
              />
              {cardbody}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getAuthKeys, updateAuthKey, generateAuthKey} = state
  return {getAuthKeys, updateAuthKey, generateAuthKey}
}

export default connect(mapStateToProps)(AppKeys)
