export default function updateCurrency(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {

    case 'UPDATE_CURRENCY_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'UPDATE_CURRENCY_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null}};
    case 'UPDATE_RESET_CURRENCY':
      return {...state, ...{success:null,validation:null}};
    default:
      return state;
  }
}
