import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import store from '../Store';
import {getLoggedInUser} from "../actions/login/get_logged_in_user";
import {DefaultLayout} from '../containers';
import {connect} from "react-redux";
import LandingPage from '../containers/Dashboard/LandingPage'
import SignUpSuccess from '../containers/Auth/SignUpSuccess'
import ForgotPassword from '../containers/Auth/ForgotPassword'
import SetPassword from '../containers/Auth/PasswordSet'
import '../assets/home.css'
import {endRefresh} from "../actions/login/refresh_token";
import NotFoundPage from '../views/NotFoundPage'
import {getCurrentPath} from "../shared/helpers/GeneralHelpers";
import {enableLoader} from "../actions/loaders/loader";
import {showNotification} from "../shared/helpers/Notification";
import {deleteMessage, deleteOuterMessage} from "../actions/messages/success_message";

class Routes extends React.Component {
  state = {
    user: {username: ""},
    window: {width: null},
    processing: false,
    isLoader: false,
    initial: true,
    loggedIn: true,
    expired: false,
    restData: null,
    initiate: null,
    message: {},
    restMessage: {message: '', type: ''},
    messageType: ''
  };

  componentDidMount() {
    if (window.location.pathname === "no-internet") {
      if (getCurrentPath())
        this.props.history.push(getCurrentPath())
    }
    if (window.location.pathname !== "/set-password")
      store.dispatch(getLoggedInUser());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoggedInUser, loader, refreshToken,successMessage} = nextProps
    let {user, isLoader, restData, initiate, loggedIn,restMessage,messageType} = prevState
    if (getLoggedInUser) {
      let {success, error} = getLoggedInUser
      if (success) {
        user = {
          username: success.data.name
        }
        // isLoader = false
      } else if (error) {
        // isLoader = false
      }
    }

    if (refreshToken) {
      let {expired, initial} = refreshToken
      if (expired === null && initial === null) {
        restData = null
      } else {
        restData = {
          expired,
          initial
        }
      }
      loggedIn = refreshToken.loggedIn
      initiate = refreshToken.initiate
    }

    isLoader = loader.isLoader

    restMessage = successMessage.outerMessage

    messageType = successMessage.outerMessage.type

    return {
      user,
      isLoader,
      initiate,
      restData,
      loggedIn,
      restMessage,
      messageType
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {initiate, loggedIn} = this.state

    if (initiate) {
      if (loggedIn === false) {
        store.dispatch(endRefresh())
        this.props.history.push('/')
      }
    }

    if (this.state.restMessage.message !== '') {

      let message = this.state.restMessage
      showNotification(message.message,message.type,message.title,true)
      store.dispatch(deleteOuterMessage(this.state.messageType))
    }
  }


  // componentWillReceiveProps(next) {
  //   if (this.props.getLoggedInUser !== next.getLoggedInUser) {
  //     let {success} = next.getLoggedInUser;
  //     if (success) {
  //       let user = {...this.state.user};
  //       // store.dispatch(getRole(success.data.payload.id));
  //       user.username = success.data.name;
  //       this.setState({user,processing:false});
  //     } else {
  //       //
  //     }
  //   }
  // }

  render() {
    let {user, isLoader} = this.state
    if (isLoader) {
      return (
        <div className="loading"></div>)
    } else {
      if (user.username) {
        return (
          <Switch>
            <Route path="/" component={DefaultLayout}/>
            <Route path="*" component={NotFoundPage}/>
          </Switch>
        )
      } else {
        return (
          <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path="/change-password-success" component={SignUpSuccess}/>
            <Route exact path="/forgot-password-success" component={SignUpSuccess}/>
            <Route exact path="/set-password" component={SetPassword}/>
          </Switch>
        );
      }
    }

  }
}

function mapStateToProps(state) {
  return {
    getLoggedInUser: state.getLoggedInUser,
    loader: state.loader,
    refreshToken: state.refreshToken,
    successMessage: state.successMessage
  }
}

export default withRouter(connect(mapStateToProps)(Routes));
