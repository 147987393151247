export default function createCustomer(
  state = { error: null, success: null, processing: "",validation: null },
  action
) {
  switch (action.type) {

    case 'CREATE_CUSTOMER_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_CUSTOMER_ERROR':
      return {...state, ...{error: action.error.response,validation: null}};
    case 'CREATE_RESET_CUSTOMER':
      return {...state, ...{success:null,error:null,validation: null}};
    case  'CREATE_CUSTOMER_VALIDATION_ERROR':
      return {...state, ...{success:null,error:null,validation: action.validation}};
    default:
      return state;
  }
}
