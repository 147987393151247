import React from 'react';
import {connect} from "react-redux";
import store from '../../Store'
import {Card, CardBody} from "reactstrap";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {getProformaInvoice} from "../../apis/proforma-invoice";

class ViewProformaInvoice extends React.Component {

  state = {
    data: {},
    error: {},
    processing: false,
    width: null,
    logo: '',
    company: {}
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    getProformaInvoice(this.props.match.params.id)
    store.dispatch(getLoggedInCompany())
  }

  componentDidUpdate() {
    this.resizeWindow();
  }

  resizeWindow() {
    let width = document.getElementById('pdf') && document.getElementById('pdf').clientWidth;
    if (this.state.width !== width) {
      this.setState({
        width
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getProformaInvoice, getLoggedInCompany} = nextProps;
    let {data, processing, company} = prevState
    if (getProformaInvoice) {
      let {success} = getProformaInvoice
      if (success) {
        data = success.data
      }
      processing = getProformaInvoice.processing
    }

    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        company = success.data
      }
    }

    return {data, processing, company}
  }

  render() {
    let {company, data} = this.state

    const tableData = data.details && data.details.map((detail, i) => (
      <tr>
        <td className="text-center">{i + 1}</td>
        <td className="text-center">{detail.product_name}</td>
        <td className="text-center">{detail.description}</td>
        <td className="text-right">{detail.qty}</td>
        <td className="text-right">{detail.amount}</td>
        <td className="text-right">{detail.total}</td>
      </tr>
    ))


    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <table style={{color: "#7270c5"}}>
                      <tr>
                        <td>{company.name}</td>
                      </tr>
                      <tr>
                        <td>{`${company.street},${company.city}`}</td>
                      </tr>
                      <tr>
                        <td>{`Tel: ${company.phone}`}</td>
                      </tr>
                      <tr>
                        <td>{`Email: ${company.email}`}</td>
                      </tr>
                      <tr>
                        <td>{`Pan no: ${company.pan}`}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-4"><h4 className="">Proforma Invoice</h4></div>
                  <div className="col-4"><img
                    style={{width: "110px", height: "40px"}} src={company.logo} alt="logo"/></div>
                </div>
                <hr style={{height: "3px", border: "none", backgroundColor: "black"}}/>
                <div className="row">
                  <div className="col-4">
                    <div>Invoice To</div>
                    <div>{data.customer_name}</div>
                    <div>{data.address}</div>
                  </div>
                  <div className="col-4">
                    <div>Invoice Date</div>
                    <div>Customer Id</div>
                    <div>Payment Id</div>
                    <div>Customer Pan</div>
                  </div>
                  <div className="col-4">
                    <div>{data.transaction_date}</div>
                    <div>{data.customer_id}</div>
                    <div>{data.payment_id}</div>
                    <div>{data.pan}</div>
                  </div>
                </div>
                <hr style={{height: "3px", border: "none", backgroundColor: "black"}}/>
                <div className="row">
                  <div className="col-12">
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th scope="col" width="50" className="text-center">S.N</th>
                        <th scope="col" width="150" className="text-center">Particulars</th>
                        <th scope="col" width="200" className="text-center">Description</th>
                        <th scope="col" width="50" className="text-center">Qty.</th>
                        <th scope="col" width="50" className="text-center">Rate</th>
                        <th scope="col" width="200" className="text-center">Total Amount (NPR)</th>
                      </tr>
                      </thead>
                      <tbody>
                      {tableData}
                      <tr>
                        <th scope="row" colSpan="4" className="text-right">SubTotal</th>
                        <td className="text-right" colSpan="2">{data.amount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-right">Discount %</th>
                        <td className="text-right" colSpan="2">{data.discount_rate}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-right">Taxable Amount</th>
                        <td className="text-right" colSpan="2">{data.taxable_amount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-right">VAT(13 %)</th>
                        <td className="text-right" colSpan="2">{data.vat_amount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-right">Total</th>
                        <td className="text-right" colSpan="2">{data.total}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state) {
  let {getProformaInvoice, getLoggedInCompany} = state
  return {
    getProformaInvoice, getLoggedInCompany
  }
}


export default connect(mapStateToProps)(ViewProformaInvoice);
