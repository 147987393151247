export default function getTscRateConfig(
  state = {error: null, success: null, processing: ""},
  action
) {
  switch (action.type) {

    case 'FETCH_TSC_RATE_CONFIG_SUCCESS':
      return {...state, ...{success: action.success, error: null}};
    case 'FETCH_TSC_RATE_CONFIG_ERROR':
      console.log(action.error.response)
      return {...state, ...{error: action.error.response, success: null}};
    case 'FETCH_TSC_RATE_CONFIG_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'RESET_TSC_RATE_CONFIG':
      return {...state, ...{success: null, error: null}}
    default:
      return state;
  }
}
