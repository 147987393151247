import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";

let prefix = "purchase-returns"

export const getPurchaseReturns = (limit, page = 1, filters = [],dateFilter = {}) => {
  let reducers = {
    success: 'FETCH_PURCHASE_RETURNS_SUCCESS',
    error: 'FETCH_PURCHASE_RETURNS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers, dateFilter)
  getGroup.getGroup()
}

export const downloadPurchaseReturns = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_PURCHASE_RETURNS_SUCCESS',
    error: 'DOWNLOAD_PURCHASE_RETURNS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const getPurchaseReturn = (id) => {
  let reducers = {
    success: 'FETCH_PURCHASE_RETURN_SUCCESS',
    error: 'FETCH_PURCHASE_RETURN_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createPurchaseReturn = (data) => {
  let reducers = {
    success: 'CREATE_PURCHASE_RETURN_SUCCESS',
    validation_error: 'CREATE_PURCHASE_RETURN_VALIDATION_ERROR',
    bad_gateway: 'CREATE_PURCHASE_RETURN_BAD_GATEWAY'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const createResetPurchaseReturn = () => {
  store.dispatch(resetData('CREATE_RESET_PURCHASE_RETURN'))
}
