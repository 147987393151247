import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";

function _success(success) {
  return {type: 'FETCH_TSC_RATE_CONFIG_SUCCESS', success}
}

function _error(error) {
  return {type: 'FETCH_TSC_RATE_CONFIG_ERROR', error}
}

export function resetTscRateConfig() {
  return dispatch => {
    dispatch({
      type: "RESET_TSC_RATE_CONFIG"
    });
  }
}


export function getTscRateConfig() {
  return dispatch => {
    store.dispatch(enableInnerLoader())
    let config = {
      url: Config.BillingUrl + `tsc-rate`,
      method: "GET",
      dataType: 'json',
      // headers: {
      //   'Authorization': 'Bearer ' + loginToken()
      // }
    };

    let request = axios(config)

      .then(res => {
        // dispatch(_processing(false));
        store.dispatch(disableInnerLoader())
        dispatch(_success(res));

      }).catch(error => {
        store.dispatch(disableInnerLoader())
        dispatch(_error(error));
        // dispatch(_processing(false));
      });

    return {type: 'FETCH_TSC_RATE_CONFIG_SUCCESS', payload: request}
  }
}

export default getTscRateConfig;
