import React from 'react';
import { Config } from './Config';

const WeekHeader = () => Config.days.map((d, idx) => (
  // eslint-disable-next-line
  <div
    key={idx}
    style={{
      width: `${100 / 7}%`,
      fontWeight:'bold',
      alignItems: 'center',
      display: 'inline-block'
    }}>
    {d}
  </div>
));

export default WeekHeader;