export default function setPassword(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {
    case "SET_PASSWORD_SUCCESS":
      return { ...state, ...{ success: action.success, error: null } };
    case "SET_PASSWORD_ERROR":
      console.log(action);
      return { ...state, ...{ success: null, error: action.error.response } };
    case "SET_RESET_PASSWORD":
      return { ...state, ...{ success: null, error: null,processing: null } };
    default:
      return state;
  }
}
