import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";

let prefix = "inventory"

export const createInventory = (data) => {
  let reducers = {
    success: 'CREATE_INVENTORY_SUCCESS',
    validation_error: 'CREATE_INVENTORY_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteInventory = (id) => {
  let reducers = {
    success: 'DELETE_INVENTORY_SUCCESS',
    error: 'DELETE_INVENTORY_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getInventory = (id) => {
  let reducers = {
    success: 'FETCH_INVENTORY_SUCCESS',
    error: 'FETCH_INVENTORY_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getInventories = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_INVENTORYS_SUCCESS',
    error: 'FETCH_INVENTORYS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const getInventoryHistory = (limit, page = 1, filters = [], extra_params={}) => {
  let reducers = {
    success: 'FETCH_INVENTORY_HISTORY_SUCCESS',
    error: 'FETCH_INVENTORY_HISTORY_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix + "/-/current-inventories", reducers,{},null, extra_params)
  getGroup.getGroup()
}

export const updateInventory = (id, data) => {
  let reducers = {
    success: 'UPDATE_INVENTORY_SUCCESS',
    validation_error: 'UPDATE_INVENTORY_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}
