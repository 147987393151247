import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody, CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {Switch} from "antd";
import {getProduct, updateProduct, updateResetProduct, uploadImageProduct} from "../../apis/products";
import {getUnits} from "../../apis/units";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {localValidation} from "../../shared/helpers/ValidationHelper";
import {getProductFamilies} from "../../apis/product-families";
import {Config} from "../../Config";
import UploadFile from "./UploadFile";
import {ucFirstWords} from "../../shared/helpers/GeneralHelpers";

class EditProductAndService extends Component {

  data = {
    type: "service",
    name: "",
    sales_price: "",
    purchase_price: "",
    unit_id: "",
    code: "",
    description: "",
    family: "",
    status: 1,
    product_family_id: "",
    hs_code: ""
  }

  error = {
    name: "",
    sales_price: "",
    purchase_price: "",
    unit_id: "",
    item_number: "",
    description: "",
    family: "",
    status: 1,
    product_family_id: "",
    hs_code: ""
  }

  state = {
    message: {type: ``, message: ``, title: ``},
    units: [],
    service: true,
    status: true,
    visible: false,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    countries: [],
    provinces: [],
    updateSuccess: null,
    restData: null,
    restSuccess: null,
    processing: false,
    families: [],
    fileUpload: {},
  }

  fetchData() {
    getUnits(1000)
    getProduct(this.props.match.params.id)
    getProductFamilies(1000)
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleSwitch() {
    let {data} = this.state
    data['status'] = !data.status
    this.setState({data})
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/products_and_services")
  }

  onReset() {
    let data = {...this.data}
    let service = true
    if (data.type === "p") {
      service = false
    }
    this.setState({
      data,
      error: {...this.error},
      service
    })
  }

  onSubmit() {
    let {data} = this.state

    let validationRule = {
      name: ['required'],
      unit_id: ['required'],
      sales_price: ['required', "decimal"],
      product_family_id: ['required'],
    }
    if (data['type'] !== "service")
      validationRule['purchase_price'] = ['required', "decimal"]
    else
      data['purchase_price'] = data['sales_price']
    data['created_by'] = this.state.username
    let localValidationStatus = false
    let check = localValidation(data, validationRule, this.state.error, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      updateProduct(this.props.match.params.id, data)
    }
  }

  onUploadImage(file) {
    uploadImageProduct(file)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getUnits, updateProduct, getProduct, successMessage, getProductsFamilies, getLoggedInUser, uploadImageProduct} = nextProps
    let {units, restError, updateSuccess, restData, restSuccess, processing, families, username, fileUpload} = prevState
    if (getUnits) {
      let {success, error} = getUnits
      if (success) {
        units = success.data.data
      } else if (error) {
        console.log(error)
      }
    }

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        username = success.data.name
      }
    }

    if (getProduct) {
      let {success, error} = getProduct
      if (success) {
        restData = success.data
      } else if (error) {
        console.log(error)
      }
      processing = getProduct.processing
    }

    if (updateProduct) {
      let {success, validation} = updateProduct
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        updateSuccess = null
        restError = null
      }
    }

    if (successMessage) {
      if (successMessage.message !== "") {
        restSuccess = successMessage.message
      }
    }

    if (getProductsFamilies) {
      let {success} = getProductsFamilies
      if (success) {
        if (success.status !== 204)
          families = success.data.data
      }
    }

    if (uploadImageProduct) {
      let {success, validation} = uploadImageProduct
      if (success) {
        fileUpload = {
          id: success.data.id,
          path: Config.BillingUrl + success.data.name
        }
      } else if (validation) {
        console.log(validation)
      }
    }

    return {
      units, restError, updateSuccess, restData, restSuccess, processing, families, username, fileUpload
    }
  }

  componentWillUnmount() {
    this.setState({
      updateSuccess: null
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
        error: this.state.restError
      }, () => {
        updateResetProduct()
      })
    }

    if (this.state.restSuccess !== prevState.restSuccess) {
      this.setState({
        message: {
          type: "success",
          message: this.state.restSuccess,
          title: ""
        },
        visible: true
      }, () => {
        updateResetProduct()
      })
    }

    if (this.state.updateSuccess !== null) {
      store.dispatch(addMessage(this.state.updateSuccess, "success", "Update Product"));
      updateResetProduct()
      setFetch(true)
      this.props.history.push('/products_and_services')
    }

    if (this.state.restData !== prevState.restData) {
      let data = {...this.state.restData}
      let service = this.state.service
      let fileUpload = this.state.fileUpload ? {...this.state.fileUpload}: {}
      if (data.type === "p") {
        service = false
      }
      if (data.product_attribute) {
        let attributes = ["standard_cost", "authorize_negative_stock",
          "minimum_stock_qty", "minimum_stock_qty", "private_note", "width", "height", "dimension_unit",]
        attributes.forEach((o) => {
          if (data.product_attribute[o])
            data[o] = data.product_attribute[o]
        })

        if(data.product_attribute.file){
          fileUpload = data.product_attribute.file
        }
      }
      this.data = data
      this.setState({
        data,
        service,
        fileUpload
      })
    }
  }

  onDismiss() {
    this.setState({visible: false});
  }

  render() {
    let {data, error, units, families} = this.state

    let familyList = families.map((family) => (
      <option value={family.id}>{family.name}</option>
    ))
    let unitList = units.map((unit) => (
      <option value={unit.id}>{unit.name}</option>
    ))

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="6">
                    <ButtonGroup>
                      <Button color="primary" outline={!this.state.service}>Service</Button>
                      <Button color="primary" outline={this.state.service}>Product</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.name} className="form-control"
                             name="name" value={data.name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Unit</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.unit_id} className="form-control" name="unit_id"
                             onChange={e => this.onChange(e)} value={data.unit_id}>
                        <option value=""></option>
                        {unitList}
                      </Input>
                      <FormFeedback>{error.unit_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Sales Price</span>
                    <FormGroup>
                      <Input invalid={!!error.sales_price} className="form-control text-right" placeholder="Sales Price"
                             name="sales_price" value={data.sales_price}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.sales_price}</FormFeedback>
                    </FormGroup>
                  </Col>
                  {data.type === "product" && <Col sm="12" md="6">
                    <span className="form-span-label">Purchase Price</span>
                    <FormGroup>
                      <Input invalid={!!error.purchase_price} className="form-control text-right"
                             placeholder="Purchase Price"
                             name="purchase_price" value={data.purchase_price}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.purchase_price}</FormFeedback>
                    </FormGroup>
                  </Col>}
                  <Col sm="12" md="6">
                    <span className="form-span-label">Standard Cost</span>
                    <FormGroup>
                      <Input invalid={!!error.standard_cost} className="form-control"
                             name="standard_cost" value={data.standard_cost}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.standard_cost}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">HS Code</span>
                    <FormGroup>
                      <Input invalid={!!error.hs_code} className="form-control"
                             name="hs_code" value={data.hs_code}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.hs_code}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Status</span>
                    <FormGroup>
                      <Switch
                        checked={data.status}
                        onChange={this.toggleSwitch.bind(this)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Details</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Code</span>
                    <FormGroup>
                      <Input invalid={!!error.code} className="form-control" placeholder="Code"
                             name="code" value={data.code}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.code}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Family</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.product_family_id} className="form-control"
                             name="product_family_id"
                             onChange={e => this.onChange(e)} value={data.product_family_id}>
                        <option value=""></option>
                        {familyList}
                      </Input>
                      <FormFeedback>{error.product_family_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Description</span>
                    <FormGroup>
                      <Input type="textarea" invalid={!!error.description} className="form-control"
                             placeholder="Description"
                             name="description" value={data.description}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Image</span>
                    <FormGroup>
                      <UploadFile img={this.state.fileUpload.path} onChange={(e) => this.onUploadImage(e)}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                </Row>
                <br/>
              </Col>
            </Row>
            <hr/>

            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Dimension</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  {["lenght", "height", "width"].map((o, i) =>
                    (<Col key={i}>
                      <span className="form-span-label">{ucFirstWords(o)}</span>
                      <FormGroup>
                        <Input type="number" invalid={!!error[o]} className="form-control"
                               min={0}
                               name={o} value={data[o]}
                               onChange={e => this.onChange(e)}/>
                        <FormFeedback>{error[o]}</FormFeedback>
                      </FormGroup>
                    </Col>)
                  )
                  }
                </Row>
                <Row>
                  {/*let attributes = ["name", "description", "status", "is_downloadable", "standard_cost", "authorize_negative_stock",*/}
                  {/*"minimum_stock_qty", "minimum_stock_qty", "private_note", "width", "height", "dimension_unit"]*/}
                  <Col sm="12" md="6">
                    <span className="form-span-label">Dimension Unit</span>
                    <FormGroup>
                      <Input type="text" invalid={!!error["dimension_unit"]} className="form-control"
                             name={"dimension_unit"} value={data["dimension_unit"]}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error["dimension_unit"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">New Weight</span>
                    <FormGroup>
                      <Input type="text" invalid={!!error["net_weight"]} className="form-control"
                             name={"net_weight"} value={data["net_weight"]}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error["net_weight"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>

            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Stock</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  {["minimum_stock_qty", "minimum_stock_qty"].map((o, i) =>
                    (<Col key={i}>
                      <span className="form-span-label">{ucFirstWords(o)}</span>
                      <FormGroup>
                        <Input type="number" invalid={!!error[o]} className="form-control"
                               min={0}
                               name={o} value={data[o]}
                               onChange={e => this.onChange(e)}/>
                        <FormFeedback>{error[o]}</FormFeedback>
                      </FormGroup>
                    </Col>)
                  )
                  }
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Authorize Negative Stock</span>
                    <FormGroup>
                      <Input type="text" invalid={!!error["authorize_negative_stock"]} className="form-control"
                             name={"authorize_negative_stock"} value={data["authorize_negative_stock"]}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error["authorize_negative_stock"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getUnits, updateProduct, getLoggedInUser, getProduct, successMessage, getProductsFamilies} = state
  return {
    getUnits, updateProduct, getLoggedInUser, getProduct, successMessage, getProductsFamilies
  }
}

export default connect(mapStateToProps)(EditProductAndService);
