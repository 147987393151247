import React from 'react';
import {Col, Row, Card, CardBody, ButtonGroup, Button, Table, CardFooter} from "reactstrap";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import {connect} from "react-redux";
import store from '../../Store'
import AddOrSelectProduct from "./AddOrSelectProduct";
import PrintInvoiceModal from "./PrintInvoiceModal";
import '../../assets/mac-search-input-style.css'
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import {Config} from "../../Config";
import HorizontalTextView from "../../shared/form-helpers/HorizontalTextView";
import {getActiveCustomers} from "../../apis/customers";
import {createInvoice, createResetInvoice} from "../../apis/invoices";
import {getProductFamilies} from "../../apis/product-families";
import {createProduct, getProducts} from "../../apis/products";
import {getTaxes} from "../../apis/taxes";
import {getActiveUnits, getUnits} from "../../apis/units";
import HorizontalToggle from "../../shared/form-helpers/HorizontalToggle";
import {createProformaInvoice, createResetProformaInvoice} from "../../apis/proforma-invoice";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {fixIfNecessary, isDecimal, isNumber, isPercentage} from "../../shared/helpers/GeneralHelpers";
import {getPaymentMethods} from "../../apis/payment-methods";
import HorizontalDateTimePicker from "../../shared/form-helpers/HorizontalDateTimePicker";
import moment from 'moment'
import {addMessage} from "../../actions/messages/success_message";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import {getTscRateConfig} from "../../actions/tscRate/get_tsc_rate_config";
import AlertModal from "../../shared/form-helpers/AlertModal";
import {resetError} from "../../actions/error";

class NewInvoice extends React.Component {

  constructor(props) {
    super(props)
    this.changeSubTotal = this.changeSubTotal.bind(this)
  }

  data = {
    customer_id: "",
    customer_name: "",
    customerData: {},
    phone_no: null,
    units: [],
    amount: 0,
    discount_rate: 0,
    discount_amount: 0,
    taxable_amount: 0,
    vat_amount: 0,
    vat_rate: 13,
    total: 0,
    reseller: "NETTV",
    note: "",
    created_by: "",
    address: "",
    discount_description: "",
    invoice_details: [],
    showProductModal: false,
    selectedOption: null,
    url: null,
    customer_code: null,
    payment_term: '',
    transaction_date: null,
    tsc_amount: 0,
    tsc_rate: null
  }

  product = {
    name: "",
    type: "",
    status: 1,
    unit_id: null,
    sales_price: null,
    purchase_price: null,
    family: ""
  }

  productError = {
    name: "",
    type: "",
    status: "",
    unit_id: "",
    sales_price: "",
    purchase_price: ""
  }

  error = {}


  state = {
    data: {...this.data},
    product: {...this.product},
    message: {type: ``, message: ``, title: ``},
    productRestError: null,
    productRestSuccess: false,
    productError: {...this.productError},
    error: {...this.error},
    localValidationError: false,
    restError: null,
    validationError: null,
    visible: true,
    unpaid: true,
    products: [],
    checkedProducts: new Map(),
    tempData: {},
    toggleForm: false,
    createInvoiceSuccess: null,
    createInvoiceSuccessStatus: false,
    username: "",
    paymentOption: [],
    vatOption: [],
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    searchValue: {},
    searchArray: [],
    searchIndex: [],
    discount_type: "rate",
    discount_value: 0,
    productFamilies: [],
    pos: "",
    proforma_invoice: false,
    transaction_error: null,
    id: '',
    showTscData: false,
    unauthorizedError: null,
  };

  componentDidMount() {
    this.fetchData();
    var classname = document.getElementsByClassName("select-search-box__search")
    // var classDate = document.getElementsByClassName("react-datetime-picker__inputGroup")
    classname[0].classList.add("form-control")
    classname[1].classList.add("form-control")
    // classDate[0].classList.add("form-control")
    if (window.location.pathname.includes('proforma')) {
      this.setState({
        proforma_invoice: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.productRestError !== prevState.productRestError) {
      let productError = this.state.productRestError
      console.log(productError)
      if (productError['details']) {
        console.log(productError['details'])
      }
      this.setState({
        productError
      })
    }

    if (this.state.productRestSuccess !== prevState.productRestSuccess) {
      this.setState({
        toggleForm: false,
        productRestSuccess: false,
        product: {...this.product}
      }, () => {
        this.fetchData()
      })
    }

    if (this.state.createInvoiceSuccess !== null) {
      setFetch(true)
      this.setState({
        data: {...this.data},
        product: {...this.product},
        createInvoiceSuccessStatus: true
      }, () => {
        if (this.state.proforma_invoice)
          createResetProformaInvoice()
        else
          createResetInvoice()
      })
    }

    if (this.state.restError !== null) {
      let error = {...this.state.restError}
      if (error['transaction_date']) {
        let classname = document.getElementsByClassName("ant-calendar-picker-input")
        let classelement = classname ? classname[0].classList['value'] : ""
        if (classelement.includes("custom-invalid-border")) {

        } else if (classelement.length > 0) {
          classname[0].classList.add("custom-invalid-border")
        }
      }
      if (error['customer_name']) {
        let classname = document.getElementsByClassName("select-search-box__search")
        let classelement = classname ? classname[0].classList['value'] : ""
        if (classelement.includes("custom-invalid-border")) {

        } else if (classelement.length > 0) {
          classname[0].classList.add("custom-invalid-border")
        }
      }
      if (error['payment_method']) {
        let classname = document.getElementsByClassName("select-search-box__search")
        let classelement = classname ? classname[1].classList['value'] : ""
        if (classelement.includes("custom-invalid-border")) {

        } else if (classelement.length > 0) {
          classname[1].classList.add("custom-invalid-border")
        }
      }
      if (error['taxable_amount']) {
        store.dispatch(addMessage("Taxable Amount is not valid", "error", "Invalid Taxable Amount"));
      }
      if (error['total']) {
        store.dispatch(addMessage("Total Amount is not valid", "error", "Invalid Total Amount"));
      }
      if (error['invoice_details']) {
        store.dispatch(addMessage("Please select products", "error", "Product Not Selected"));
      }
      this.setState({
        error
      }, () => {
        if (this.state.proforma_invoice)
          createResetProformaInvoice()
        else
          createResetInvoice()
      })
    }

    if (this.state.productRestError !== prevState.productRestError) {
      this.setState({
        productError: this.state.productRestError
      }, () => {
        // createResetProduct()
      })
    }
  }

  fetchData() {
    let activeMethods = [{key: 'status', value: 1}]
    getProducts(8, null, activeMethods)
    getPaymentMethods(1000, null, activeMethods)
    getActiveUnits()
    getTaxes()
    getProductFamilies(1000)
    store.dispatch(getTscRateConfig())

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getProducts,
      createInvoice,
      createProduct,
      getActiveCustomers,
      getLoggedInUser,
      getPaymentMethods,
      getActiveUnits,
      getTaxes,
      getProductsFamilies,
      createProformaInvoice,
      getTscRateConfig,
      errorHandling
    } = nextProps;
    let {
      products,
      productRestError,
      productRestSuccess,
      createInvoiceSuccess,
      customerData,
      units,
      username,
      url,
      customer_code,
      paymentOption,
      vatOption,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restError,
      productFamilies,
      pos,
      proforma_invoice,
      id,
      data,
      unauthorizedError
    } = prevState;

    if (proforma_invoice) {
      if (createProformaInvoice) {
        let {success, validation} = createProformaInvoice
        if (success) {
          createInvoiceSuccess = success.data
          url = Config.BillingUrl + 'proforma-invoice/' + success.data.id + '?q=print'
        } else if (validation) {
          restError = validation
        } else {
          restError = null
          createInvoiceSuccess = null
        }
      }
    } else {
      if (createInvoice) {
        let {success, validation} = createInvoice
        if (success) {
          createInvoiceSuccess = success.data
          customer_code = success.data.company_payment_id
          url = Config.BillingUrl + 'invoices/' + customer_code + '?q=preview'
        } else if (validation) {
          restError = validation
        } else {
          createInvoiceSuccess = null
          restError = null
        }
      }
    }

    if (getProducts) {
      let {success} = getProducts;
      if (success) {
        products = success.data.data
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (createProduct) {
      let {success, validation} = createProduct
      if (success) {
        if (success.status === 200) {
          productRestSuccess = true
        }
      } else if (validation) {
        productRestError = validation
      } else {
        productRestError = null
      }
    }

    if (getActiveCustomers) {
      let {success, error} = getActiveCustomers
      if (success) {
        customerData = success.data.data || []
      } else if (error) {
        console.log(error)
      }
    }

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        username = success.data.name
        pos = success.data.pos
        id = success.data.id
      }
    }

    if (getPaymentMethods) {
      let {success, error} = getPaymentMethods
      if (success) {
        paymentOption = success.data.data || []
      } else if (error) {
        console.log(error)
      }
    }

    if (getActiveUnits) {
      let {success, error} = getActiveUnits
      if (success) {
        if (success.data) {
          units = success.data || []
        }
      } else if (error) {
        console.log(error)
      }
    }

    if (getTaxes) {
      let {success, error} = getTaxes
      if (success) {
        vatOption = success.data.data
      } else if (error) {
        console.log(error)
      }
    }

    if (getProductsFamilies) {
      let {success} = getProductsFamilies
      if (success) {
        if (success.status !== 204)
          productFamilies = success.data.data
      }
    }
    if (getTscRateConfig) {
      let {success} = getTscRateConfig;
      if (success) {
        data['tsc_rate'] = success.data.tsc_rate
      }
    }
    unauthorizedError = errorHandling.unauthorized


    return {
      products,
      productRestError,
      productRestSuccess,
      createInvoiceSuccess,
      customerData,
      username,
      units,
      url,
      customer_code,
      paymentOption,
      vatOption,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restError,
      productFamilies,
      pos,
      id,
      data,
      unauthorizedError
    };
  }

  checkIfExists(id, datas) {
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].name === id) {
        return true
      }
    }
    return false
  }

  changeSubTotal() {
    let subTotal = 0.0;
    let vatAmount = 0.0;
    let data = {...this.state.data}
    if (this.state.showTscData) {
      data.invoice_details.forEach((single) => {
        if (this.state.checkedProducts.get("" + single.product_name)) {
          subTotal = parseFloat(single.total) + parseFloat('' + subTotal)
          vatAmount = parseFloat(single.vat_amount) + parseFloat('' + vatAmount)
        }
      })
      subTotal = parseFloat('' + subTotal).toFixed(2)
      let rate = parseFloat(data.tsc_rate).toFixed(2)
      let taxable_amount = parseFloat(subTotal) + parseFloat(data.tsc_amount)
      vatAmount = parseFloat('' + (taxable_amount * 0.13)).toFixed(2);
      let total = parseFloat(taxable_amount) + parseFloat(vatAmount)
      total = total.toFixed(2)
      data.amount = subTotal
      data.vat_amount = vatAmount
      data.total = total
      data.taxable_amount = taxable_amount
      this.setState({
        data
      })
    } else {
      data.invoice_details.forEach((single) => {
        if (this.state.checkedProducts.get("" + single.product_name)) {
          subTotal = parseFloat(single.total) + parseFloat('' + subTotal)
          vatAmount = parseFloat(single.vat_amount) + parseFloat('' + vatAmount)
        }
      })
      subTotal = parseFloat('' + subTotal).toFixed(2)
      let rate = parseFloat(data.discount_rate).toFixed(2)
      let discount = parseFloat(rate) >= 0 ? parseFloat(subTotal * rate / 100).toFixed(2) : data.discount_amount ? data.discount_amount : 0
      let taxable_amount = subTotal - discount
      vatAmount = parseFloat('' + (taxable_amount * 0.13)).toFixed(2)
      let total = parseFloat(subTotal) - parseFloat(discount) + parseFloat(vatAmount)
      total = total.toFixed(2)
      data.amount = subTotal
      data.vat_amount = vatAmount
      data.total = total
      data.discount_amount = discount
      data.taxable_amount = taxable_amount
      this.setState({
        data
      })
    }
    return null
  }

  handleProducts(e) {
    let {data} = this.state
    let error = {...this.state.error}
    const item = e.target.name
    const isChecked = e.target.checked
    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      let tempDetails = [...this.state.data.invoice_details];
      let subTotal = this.state.data.amount;
      let vatAmount = this.state.data.vat_amount;
      let total = this.state.data.total;
      this.state.products.forEach((product) => {
        if (this.state.checkedProducts.get("" + product.id)) {
          if (!this.checkIfExists(product.name, this.state.data.invoice_details)) {
            let vat_amount = 13 * product.sales_price / 100;
            let item_total = product.sales_price + 13 * product.sales_price / 100;
            let tempDetail = {
              id: product.id,
              product_name: product.name,
              qty: 1,
              unit: 'hr',
              amount: product.sales_price,
              discount_rate: '0',
              discount_amount: '0',
              taxable_amount: product.sales_price,
              vat_rate: "0",
              vat_amount: "0",
              total: item_total
            }
            subTotal += product.sales_price
            vatAmount += vat_amount
            total += item_total
            tempDetails.push(tempDetail)
          }
        }
      })
      data.amount = subTotal
      data.invoice_details = tempDetails
      data.vat_amount = vatAmount
      data.total = total
      error['invoice_details'] = []
      this.setState({
        data,
        error
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  toggleModal() {
    this.setState({
      showProductModal: !this.state.showProductModal,
      toggleForm: false
    })
  }

  togglePrintModal() {
    this.setState({
      createInvoiceSuccessStatus: !this.state.createInvoiceSuccessStatus
    }, () => {
      if (this.state.proforma_invoice)
        this.props.history.push('/sales/proforma-invoice')
      else
        this.props.history.push('/sales/invoice/')
    })
  }

  onChange(e) {
    if (e) {
      let {name} = e.target;
      let {value} = e.target;
      let data = {...this.state.data};
      let error = {...this.state.error};
      data[name] = value;
      error[name] = "";
      this.setState({data, error});
    }
  }

  onProductChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let product = {...this.state.product};
    let productError = {...this.state.productError};
    product[name] = value;
    productError[name] = "";
    this.setState({product, productError});
  }

  changeQuantity(e) {
    if (isNumber(e.target.value)) {
      let index = e.target.name.split("_")[1];
      let data = {...this.state.data}
      let amount = fixIfNecessary(this.state.data.invoice_details[index].amount)
      let taxableAmount = fixIfNecessary(parseFloat(amount) * parseFloat(e.target.value))
      data.invoice_details[index].qty = e.target.value;
      data.invoice_details[index].total = taxableAmount
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    }
  }

  changeAmount(e) {
    if (isDecimal(e.target.value)) {
      let index = e.target.name.split("_")[1]
      let data = {...this.state.data}
      let qty = fixIfNecessary(this.state.data.invoice_details[index].qty)
      let taxableAmount = fixIfNecessary(parseFloat(qty) * parseFloat(e.target.value))
      data.invoice_details[index].amount = fixIfNecessary(e.target.value)
      data.invoice_details[index].taxable_amount = fixIfNecessary(parseFloat(e.target.value))
      data.invoice_details[index].total = taxableAmount
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    }
  }

  changeDescription(e) {
    let index = e.target.name.split("_")[1];
    let data = {...this.state.data}
    data.invoice_details[index].description = e.target.value;
    this.setState({
      data
    })
  }

  changeTotalDiscount(e) {
    let data = {...this.state.data}
    if (isDecimal(e.target.value) || e.target.value === "") {
      if (e.target.value > 100 || e.target.value < 0) {
        store.dispatch(addMessage("Discount rate is not valid", "error", "Create Invoice"));
      } else {
        data.discount_rate = e.target.value ? e.target.value : 0
        if (e.target.value === "") {
          data.discount_rate = 0
        }
        this.setState({
          data,
          discount_value: e.target.value
        }, () => {
          this.changeSubTotal()
        })
      }
    }
  }

  changeTsc(e) {
    let {data} = this.state
    console.log('@product', this.state.checkedProducts)

    let {name, value} = e.target
    data[name] = value
    this.setState({
      data,
    }, () => {
      this.changeSubTotal()
    })
  }


  onToggleForm() {
    this.setState({
      toggleForm: !this.state.toggleForm
    })
  }


  changeDiscount(e) {
    if (isPercentage(e.target.value)) {
      let index = e.target.name.split("_")[1];
      let data = {...this.state.data}
      let value = fixIfNecessary(e.target.value)
      let amount = fixIfNecessary(this.state.data.invoice_details[index].amount)
      let discount = fixIfNecessary(parseFloat(data.invoice_details[index].qty) * (parseFloat(value) * parseFloat(amount) / 100))
      let taxable_amount = fixIfNecessary(amount * data.invoice_details[index].qty - discount)
      let vat_amount = fixIfNecessary(13 / 100 * parseFloat(taxable_amount))
      let total = fixIfNecessary(parseFloat(taxable_amount) + parseFloat(vat_amount))
      data.invoice_details[index].discount_rate = value;
      data.invoice_details[index].discount_amount = discount
      data.invoice_details[index].taxable_amount = taxable_amount
      data.invoice_details[index].vat_amount = vat_amount
      data.invoice_details[index].total = total
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    }
  }

  changeVatrate(e) {
    let index = e.target.name.split("_")[2];
    let data = {...this.state.data}
    let vat_rate = fixIfNecessary(e.target.value)
    let vat_amount = fixIfNecessary(parseFloat(vat_rate) / 100 * parseFloat(data.invoice_details[index].taxable_amount))
    let total = fixIfNecessary(parseFloat(data.invoice_details[index].taxable_amount) + parseFloat(vat_amount))
    data.invoice_details[index].vat_amount = vat_amount
    data.invoice_details[index].total = total
    data.invoice_details[index].vat_rate = vat_rate
    this.setState({
      data
    }, () => {
      this.changeSubTotal()
    })
  }

  onSubmit() {
    let data = {...this.state.data}
    data['created_by'] = this.state.username + ' (' + this.state.id + ')'
    data['pos'] = this.state.pos ? this.state.pos.name : ''
    data['payment_gateway'] = data['pos']
    let startDate = data['transaction_date'] ? moment(data['transaction_date'], 'YYYY/MM/DD') : null
    let startTime = moment(data['transaction_date'], 'HH:mm:ss')
    data['transaction_date'] = startDate ? `${startDate.format('YYYY')}-${startDate.format('MM')}-${startDate.format('DD')} ${startTime.format('HH')}:${startTime.format('mm')}:${startTime.format('ss')}` : null
    // if (data['total'] > 0) {
    if (this.state.showTscData) {
      data['taxable_amount'] = fixIfNecessary(parseFloat(data.amount) + parseFloat(data.tsc_amount))

    } else {
      data['taxable_amount'] = fixIfNecessary(parseFloat(data.amount) - parseFloat(data.discount_amount))
      delete data['tsc_rate'];
      delete data['tsc_amount'];
    }
    console.log(data)
    if (!this.state.proforma_invoice) {
      data['customer_name'] = data['full_name']
      delete data['full_name']
      createInvoice(data)
    } else {
      data['details'] = data['invoice_details']
      data['discount_amount'] = this.state.discount_value
      data['customer_name'] = data['full_name']
      delete data['full_name']
      delete data['invoice_details']
      createProformaInvoice(data)
    }
    // } else {
    //   store.dispatch(addMessage("Total must be greater than 0 ", "error", "Create Invoice"));
    // }
  }

  onReset() {
    let proforma = true;
    if (window.location.pathname.includes('proforma')) {
      proforma = true
    } else {
      proforma = false
    }
    this.setState({
      data: {...this.data},
      product: {...this.product},
      error: {...this.error},
      productError: {...this.productError},
      proforma_invoice: proforma,
      checkedProducts: new Map(),
      discount_value: 0,
    })
  }

  onCancel() {
    this.props.history.push("/sales/invoice")
  }

  onCreateProduct() {
    let product = {...this.state.product}
    product['created_by'] = this.state.username
    createProduct(product)
  }

  onCustomerChange(e) {
    let data = {...this.state.data}
    let error = {...this.state.error}
    if (e.name) {
      data['customer_name'] = e.value
      data['customer_id'] = e.cus_code
      if (e.pan)
        data['pan'] = e.pan
      data['address'] = e.address
      data['phone_no'] = e.phone
      data['full_name'] = e.full_name
      error['customer_name'] = ""
      let classname = document.getElementsByClassName("select-search-box__search")
      let classelement = classname ? classname[0].classList['value'] : ""
      if (classelement.includes("custom-invalid-border")) {
        classname[0].classList.remove("custom-invalid-border")
      }
      this.setState({data, error})
    }
  }

  onCustomerFetch(e) {
    let filters = [
      {
        key: 'customer_code',
        value: e
      },
      {
        key: 'status',
        value: 1
      }
    ]
    getActiveCustomers(20, 1, filters)
  }

  onPaymentTermChange(e) {
    let data = {...this.state.data}
    let error = {...this.state.error}
    if (e.name) {
      data['payment_method'] = e.value
      error['payment_method'] = ""
      let classname = document.getElementsByClassName("select-search-box__search")
      let classelement = classname ? classname[1].classList['value'] : ""
      if (classelement.includes("custom-invalid-border")) {
        classname[1].classList.remove("custom-invalid-border")
      }
      this.setState({data, error})
    }
  }

  handleDateChange = date => {
    let data = {...this.state.data};
    let error = {...this.state.error}
    let temp = moment()
    if (date) {
      if (temp.valueOf() > date.valueOf()) {
        data['transaction_date'] = date
      } else {
        store.dispatch(addMessage("Current timestamp is allowed maximum", "warning", "Invalid date"));
      }
    } else {
      data['transaction_date'] = date
    }
    let classname = document.getElementsByClassName("ant-calendar-picker-input")
    let classelement = classname ? classname[0].classList['value'] : ""
    if (classelement.includes("custom-invalid-border")) {
      classname[0].classList.remove("custom-invalid-border")
    }
    error['transaction_date'] = ""
    this.setState({data, error});
  };

  paginationButton(page) {
    let filters = [
      {
        key: 'status',
        value: 1
      }
    ]
    this.setState({page}, () => {
      getProducts(8, page, filters);
    });
  }

  searchChange(e) {
    let data = e.target.value
    let key = "name"
    let searchArray = [...this.state.searchArray]
    let searchValue = {...this.state.searchValue}
    let a = {
      key: key,
      value: data
    }

    let searchIndex = this.state.searchIndex
    let position = searchIndex.indexOf(key)
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push(key)
    }

    a = {
      key: 'status',
      value: 1
    }
    searchArray.push(a)

    searchValue[key] = data
    this.setState({
      searchArray,
      searchValue,
      searchIndex
    }, () => {
      let {limit, page, searchArray} = this.state
      getProducts(limit, page, searchArray)
    })
  }

  removeProduct(id, o) {
    let {data} = this.state
    let error = {...this.state.error}
    const item = "" + id
    const isChecked = false

    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      let tempDetails = [...this.state.data.invoice_details];
      tempDetails.splice(o, 1)

      data.invoice_details = tempDetails
      error['invoice_details'] = []
      this.setState({
        data,
        error
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  rowPressed(id, product) {
    let {data} = this.state
    let error = {...this.state.error}
    let checkProductFamily = product.product_family
    if (checkProductFamily && checkProductFamily.name.toLowerCase() === "internet") {
      this.setState({
        showTscData: true
      })
    }
    const item = "" + id
    const isChecked = !this.state.checkedProducts.get("" + id)
    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      // let tempDetails = [];
      let tempDetails = [...this.state.data.invoice_details];
      if (!isChecked) {
        let order = -1
        tempDetails.forEach((detail, o) => {
          if (detail.product_name === id)
            order = o
        })
        if (order !== -1)
          tempDetails.splice(order, 1)
      }
      let subTotal = this.state.data.amount;
      let vatAmount = this.state.data.vat_amount;
      let total = this.state.data.total;
      if (this.state.checkedProducts.get("" + product.name)) {
        if (!this.checkIfExists(product.name, this.state.data.invoice_details)) {
          let vat_amount = 13 * product.sales_price / 100;
          let item_total = product.sales_price + 13 * product.sales_price / 100;
          let tempDetail = {
            id: product.id,
            product_name: product.name,
            qty: 1,
            unit: product.units.name,
            amount: product.sales_price,
            taxable_amount: product.sales_price,
            total: product.sales_price, discount_rate: '0',
            discount_amount: '0',
            vat_rate: "0",
            vat_amount: "0",
            hs_code: product.hs_code ? product.hs_code : null
          }
          subTotal += product.sales_price
          vatAmount += vat_amount
          total += item_total
          tempDetails.push(tempDetail)
        }
      }
      data.amount = subTotal
      data.invoice_details = tempDetails
      data.vat_amount = vatAmount
      data.total = total
      error['invoice_details'] = []
      this.setState({
        data,
        error
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  changeDiscountType(e) {
    let data = {...this.state.data}
    data.discount_amount = 0
    data.discount_rate = 0
    this.setState({
      discount_type: e.target.value,
      discount_value: 0,
      data
    }, () => {
      this.changeSubTotal()
    })
  }

  changeFlatDiscount(e) {
    let data = {...this.state.data}
    if (!isNaN(e.target.value)) {
      if (e.target.value < 0) {
        store.dispatch(addMessage("Discount cannot be negative.", "error", "Flat Discount"));
      } else {
        data.discount_amount = e.target.value ? e.target.value : 0
        this.setState({
          data,
          discount_value: e.target.value
        }, () => {
          this.changeSubTotal()
        })
      }
    }
  }

  toggleSwitch() {
    this.setState({
      proforma_invoice: !this.state.proforma_invoice
    })
  }

  toggleAlert() {
    store.dispatch(resetError())
  }



  render() {
    let {
      data,
      error,
      products,
      showProductModal,
      checkedProducts,
      productError,
      product,
      createInvoiceSuccessStatus,
      customerData,
      units,
      url,
      customer_code,
      paymentOption,
      vatOption,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      discount_type,
      discount_value,
      productFamilies,
      proforma_invoice,
      unauthorizedError
    } = this.state;

    let paymentTerms = [];

    paymentTerms.push({
      name: "",
      value: ""
    })


    paymentOption.forEach((payment) => {
      paymentTerms.push({
        name: payment.name,
        value: payment.name
      })
    })

    let settledOptions = [
      {
        label: "Deposit Later",
        value: "later"
      }
    ];

    let showProducts = data.invoice_details.map((product, o) => {
      if (checkedProducts.get("" + product.product_name)) {
        return (
          <tr>
            <td>{product.hs_code ? product.hs_code : "-"}</td>
            <td>{product.product_name}</td>
            <td>
              <textarea className="form-control" name={'description_' + o}
                        onChange={(e) => this.changeDescription(e)} value={product.description}/>
            </td>
            <td><input className="form-control text-right" name={'qty_' + o} value={product.qty}
                       onChange={e => this.changeQuantity(e)}/></td>
            <td><input className="form-control text-right" name={'amount_' + o} value={product.amount}
                       onChange={e => this.changeAmount(e)}/></td>
            <td className="text-right">{product.total}</td>
            <td>
              <Button className="btn-sm" color="danger" outline title="Remove"
                      onClick={() => this.removeProduct(product.product_name, o)}>
                <i className="fa fa-times"></i>
              </Button>
            </td>
          </tr>
        );
      } else {
        return null
      }
    })

    let serviceList = [
      {
        label: "Service",
        value: "service"
      },
      {
        label: "Product",
        value: "product"
      }
    ];

    let unitList = units && units.map((unit) => {
      return {
        label: unit.name,
        value: unit.id
      }
    })

    let customerOption = [];

    customerOption.push({
      name: "",
      value: "",
      address: "",
      pan: "",
      phone: "",
      full_name: ""
    })

    customerData && customerData.forEach((customer) => {
      customerOption.push({
        name: customer.first_name + ' ' + customer.last_name + '  (' + customer.customer_code + ')',
        value: customer.customer_code,
        address: customer.address + ', ' + customer.city,
        pan: customer.pan,
        phone: customer.mobile_no,
        cus_code: customer.customer_code,
        full_name: customer.first_name + ' ' + customer.last_name + '  (' + customer.customer_code + ')'
      })
    })


    return (
      <div className="animated fadeIn">
        <PrintInvoiceModal
          isOpen={createInvoiceSuccessStatus}
          toggle={this.togglePrintModal.bind(this)}
          url={url}
          customer_code={customer_code}
          email={false}
        />
        <AddOrSelectProduct
          isOpen={showProductModal}
          toggle={this.toggleModal.bind(this)}
          products={products}
          handleProducts={(e) => this.handleProducts(e)}
          checkedProducts={checkedProducts}
          toggleForm={this.state.toggleForm}
          onToggleForm={this.onToggleForm.bind(this)}
          serviceList={serviceList}
          unitList={unitList}
          onCreateProduct={this.onCreateProduct.bind(this)}
          productError={productError}
          onProductChange={(e) => this.onProductChange(e)}
          product={product}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          lastPage={lastPage}
          paginationButton={this.paginationButton.bind(this)}
          rowPressed={this.rowPressed.bind(this)}
          searchChange={(e) => this.searchChange(e)}
          productFamilies={productFamilies}
        />
        <AlertModal
          open={!!unauthorizedError}
          status={403}
          statusTitle="Unauthorized"
          statusMessage={unauthorizedError}
          toggle={this.toggleAlert.bind(this)}
        />
        <Row className="top-page-content">
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" sm="6" md="6">
                    {/*<HorizontalTextField*/}
                    {/*type="text"*/}
                    {/*name="customer_name"*/}
                    {/*value={data.customer_name}*/}
                    {/*disabled={true}*/}
                    {/*/>*/}
                    <HorizontalSelectSearch
                      options={customerOption}
                      value={data.customer_id}
                      name="customer_name"
                      onChange={(e) => this.onCustomerChange(e)}
                      placeholder="Search customer by Customer code"
                      error={error.customer_name}
                      isRequired={true}
                      onFetch={this.onCustomerFetch.bind(this)}
                    />

                    {/*<SelectSearch*/}
                    {/*options={customerOption}*/}
                    {/*placeholder="Search"*/}
                    {/*/>*/}

                    <HorizontalTextView
                      name="address"
                      value={data.address}
                    />
                  </Col>
                  <Col xs="12" sm="6" md="6">
                    <HorizontalSelectSearch
                      name="payment_method"
                      value={data.payment_method}
                      onChange={e => this.onPaymentTermChange(e)}
                      error={error.payment_method}
                      options={paymentTerms}
                      placeholder="Select Payment Method"
                    />
                    <HorizontalToggle
                      name="proforma_invoice"
                      status={proforma_invoice}
                      onChange={this.toggleSwitch.bind(this)}
                    />
                    {/*<HorizontalDatePicker*/}
                    {/*  name="date"*/}
                    {/*  value={data.date}*/}
                    {/*  onChange={this.handleDateChange.bind(this)}*/}
                    {/*  error={error.date}*/}
                    {/*/>*/}
                  </Col>
                </Row>
                <Row>
                  {/*<Col xs="12" sm="6" md="6">*/}
                  {/*<Button outline={!this.state.unpaid} color="primary"*/}
                  {/*onClick={e => this.togglePaid()} disabled={true}>Unpaid</Button>*/}
                  {/*&nbsp;*/}
                  {/*<Button outline={this.state.unpaid} color="primary"*/}
                  {/*onClick={e => this.togglePaid()} disabled={true}>Settled</Button>*/}
                  {/*</Col>*/}
                  <Col xs="12" sm="6" md="6">
                    {!this.state.unpaid &&
                    <HorizontalSelect
                      name="deposit_to"
                      value={data.deposit_to}
                      onChange={e => this.onChange(e)}
                      error={error.deposit_to}
                      options={settledOptions}
                    />}
                  </Col>
                </Row>
                {!proforma_invoice &&
                <Row>
                  <Col sm="12" md="6">
                    <HorizontalDateTimePicker
                      onChange={this.handleDateChange.bind(this)}
                      value={data.transaction_date}
                      name="transaction_date"
                      error={error.transaction_date}
                    />
                  </Col>
                </Row>
                }
                <br/>
                <Row>
                  <Col xs="12">
                    <Table responsive>
                      <thead>
                      <tr>
                        <th width="80" className="">HS Code</th>
                        <th width="160" className="">Name</th>
                        <th width="250" className="">Description</th>
                        <th width="150" className="text-center">Qty</th>
                        <th width="150" className="text-center">Price</th>
                        <th width="150" className="text-center">Total</th>
                        <th width="10"></th>
                      </tr>
                      </thead>
                      <tbody>
                      {data.invoice_details.length > 0 ? showProducts : <tr>
                        <td className="text-center" colSpan="8">No Items To Show</td>
                      </tr>}
                      </tbody>
                      <tfoot>
                      <br/>
                      <Button color="success" className="btn-square" onClick={this.toggleModal.bind(this)}>
                        Add Product or service
                      </Button>
                      </tfoot>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6">
                    {/*<HorizontalTextArea*/}
                    {/*rows="3"*/}
                    {/*name="document_note"*/}
                    {/*value={data.document_note}*/}
                    {/*error={data.document_note}*/}
                    {/*onChange={e => this.onChange(e)}*/}
                    {/*/>*/}
                  </Col>
                  <Col xs="12" sm="6" md="6">
                    <HorizontalTextView
                      name="subtotal"
                      isNumber={true}
                      value={data.amount ? data.amount : 0}
                    />
                    {this.state.showTscData &&
                    <React.Fragment>
                      <HorizontalTextView
                        name="tsc_rate"
                        isNumber={true}
                        value={data.tsc_rate}
                      />
                      {/*<HorizontalTextField*/}
                      {/*  name="tsc_rate"*/}
                      {/*  value={data.tsc_rate}*/}
                      {/*  onChange={e => this.changeTsc(e)}*/}
                      {/*/>*/}
                      <HorizontalTextField
                        name="tsc_amount"
                        value={data.tsc_amount}
                        onChange={e => this.changeTsc(e)}
                      />
                    </React.Fragment>
                    }
                    <HorizontalTextField
                      name="discount_rate"
                      value={discount_value}
                      onChange={e => this.changeTotalDiscount(e)}
                    />
                    {/*<FormGroup row>*/}
                    {/*  <Col md="5">*/}
                    {/*    <Input type="select" name="discount_type" onChange={(e) => this.changeDiscountType(e)}>*/}
                    {/*      <option value="rate">Discount Rate</option>*/}
                    {/*      <option value="flat">Flat Discount</option>*/}
                    {/*    </Input>*/}
                    {/*  </Col>*/}
                    {/*  <Col md="7">*/}
                    {/*    <Input name="discount_rate"*/}
                    {/*           onChange={discount_type === "rate" ? e => this.changeTotalDiscount(e) : e => this.changeFlatDiscount(e)}*/}
                    {/*           value={discount_value}/>*/}
                    {/*  </Col>*/}
                    {/*</FormGroup>*/}
                    {/*<HorizontalTextField*/}
                    {/*  type="number"*/}
                    {/*  name="discount_rate"*/}
                    {/*  value={data.discount_rate}*/}
                    {/*  onChange={e => this.changeTotalDiscount(e)}*/}
                    {/*  error={error.discount_rate}*/}
                    {/*  min={0}*/}
                    {/*/>*/}
                    <HorizontalTextView
                      name="discount_amount"
                      isNumber={true}
                      value={data.discount_amount ? data.discount_amount : 0}
                    />
                    <HorizontalTextView
                      name="VAT"
                      isNumber={true}
                      value={data.vat_amount ? data.vat_amount : 0}
                    />
                    <hr/>
                    <HorizontalTextView
                      name="total"
                      isNumber={true}
                      value={data.total ? data.total : 0}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
                  <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
                  <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );

  }
}

function mapStateToProps(state) {
  let {
    getProducts,
    createInvoice,
    createProduct,
    getActiveCustomers,
    getLoggedInUser,
    getPaymentMethods,
    getUnits,
    getTaxes,
    getProductsFamilies,
    createProformaInvoice,
    getActiveUnits,
    getTscRateConfig,
    errorHandling
  } = state

  return {
    getProducts,
    createInvoice,
    createProduct,
    getActiveCustomers,
    getLoggedInUser,
    getPaymentMethods,
    getUnits,
    getTaxes,
    getProductsFamilies,
    createProformaInvoice,
    getActiveUnits,
    getTscRateConfig,
    errorHandling

  }
}

export default connect(mapStateToProps)(NewInvoice);

