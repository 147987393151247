import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from "../../Store";
import {deleteDownloadMessage, setDownloadMessage} from "./download_message";
import moment from "moment";
import {disableInnerLoader} from "../loaders/loader";
import {_bad_gateway_error, _bad_request, _taken_error, _validation_error} from "../connect_reducer";
import {noInternet, serverError, unauthorizedError} from "../error";

export function downloadFile(dateFilter, type, name, page, reportType, docUrl, filters = []) {
  return dispatch => {

    let message = `Downloading ${page} ${type} file`
    let pos = "";

    store.dispatch(setDownloadMessage(message))

    let config = {
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      },
      params: {
        start_date: dateFilter.start_date,
        end_date: dateFilter.end_date,
        export_type: type
      },
      responseType: 'blob'
    };
    if (name.toLowerCase() === "xlsx") {
      config.params['copy'] = true
    }

    for (let i = 0; i < filters.length; i++) {
      config.params["filter[" + filters[i].key + "]"] = filters[i].value;
      if (filters[i].key === "pos") {
        pos = filters[i].value + "-"
      }
    }

    let url = ""

    if (reportType === "purchase") {
      url = Config.BillingUrl + (`purchases-registers/export`)
    } else if (reportType === "sales") {
      url = Config.BillingUrl + (`sales-registers/export`)
    } else if (reportType === "materialized") {
      url = Config.BillingUrl + (`material-view/export`)
    }

    if (docUrl) {
      url = docUrl
    }

    axios.get(url, config)
      .then((response) => {
        const fileurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileurl;
        // link.setAttribute('download', name+'-'+ moment().format() + `.${type}`);
        link.setAttribute('download', `${pos}${moment().format()}.${type}`);
        document.body.appendChild(link);
        link.click();
        store.dispatch(deleteDownloadMessage())
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403)
            store.dispatch(unauthorizedError("You don't have permissions to download this file."))
          if (error.response.status === 401) {
            store.dispatch(_taken_error(error.response.data.message, this.reducers.taken_error))
          }
          if (error.response.status >= 500)
            store.dispatch(serverError())
        } else if (error.request) {
          store.dispatch(noInternet())
        } else {
          store.dispatch(serverError())
        }
        store.dispatch(deleteDownloadMessage())
      })
  }
}

export default downloadFile;
