import axios from "axios";
import { Config } from "../../Config";

function _success(success) {
  return { type: "FETCH_LOGIN_SUCCESS", success };
}
function _error(error) {
  return { type: "FETCH_LOGIN_ERROR", error };
}

export function resetLoginRequest() {
  return dispatch => {
    dispatch({
      type: "RESET_FETCH_LOGIN"
    });
  }
}

export function getLoginRequest(data) {
  return dispatch => {
    // store.dispatch(enableLoader())
    let base_url = Config.BillingUrl;

    axios({
      url: base_url + `login`,
      method: "post",
      dataType: "json",
      data: data,
      headers: {
        // 'Authorization': 'Bearer ' + loginToken
      }
    })
      .then(res => {
        console.log(res)
        // store.dispatch(disableLoader())
        dispatch(_success(res));
      })
      .catch(error => {
        console.log(error)
        dispatch(_error(error))
        // console.log(error)
        // store.dispatch(disableLoader())
        // dispatch(_processing(false));
      });
  };
}

export default getLoginRequest;
