import React, {Component} from 'react';
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import {connect} from "react-redux";
import {
  createPermission,
  createResetPermission, deletePermission, deleteResetPermission,
  getPermission,
  getPermissions,
  updatePermission, updateResetPermission
} from "../../apis/permissions";
import PermissionModal from "./PermissionModal";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {localValidation} from "../../shared/helpers/ValidationHelper";

class Permissions extends Component {

  state = {
    restData: [],
    restView: null,
    inactiveData: null,
    createSuccess: null,
    createError: null,
    updateSuccess: null,
    updateError: null,
    openModal: false,
    newModal: true,
    formData: {},
    formError: {},
    deleteModal: false,
    message: {
      type: '',
      message: ''
    },
    slug: '',
    keys: {},
    isNew: true,
    isSave: false,
    isView: null,
    statistics: {},
    status: {
      all: true,
      active: false,
      inactive: false
    },
    isEdit: false,
    showDeleteModal: false,
    itemToDelete: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: 1,
    restDelete: null
  }

  tempData = {
    name: '',
    display_name: '',
    description: ''
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {page} = this.state
    getPermissions(null, page)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getPermissions, createPermission, getPermission, updatePermission, deletePermission} = nextProps
    let {
      restData,
      updateSuccess,
      updateError,
      createSuccess,
      createError,
      restView,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restDelete
    } = prevState
    if (getPermissions) {
      let {success} = getPermissions
      if (success) {
        restData = success.data.data || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (deletePermission) {
      let {success} = deletePermission
      if (success) {
        restDelete = success.data.message
      } else {
        restDelete = null
      }
    }

    if (createPermission) {
      let {success, validation} = createPermission
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (updatePermission) {
      let {success, validation} = updatePermission
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null
        updateError = null
      }
    }

    if (getPermission) {
      let {success, error} = getPermission
      if (success) {
        restView = success.data
      } else if (error) {
        console.log(error)
      }
    }

    return {
      restData,
      createSuccess,
      createError,
      restView,
      updateSuccess,
      updateError,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restDelete
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {createSuccess, createError, keys, restView, updateSuccess, updateError, restDelete} = this.state
    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success","Create Permission"))
      this.setState({
        formData: {},
        keys: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        createResetPermission()
      })
    }

    if (restDelete !== null) {
      store.dispatch(addMessage(this.state.restDelete, "error","Permissions"))
      this.setState({
          showDeleteModal: false,
          itemToDelete: ''
        },
        () => {
          deleteResetPermission()
          this.fetchData()
        })
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success","Update Permission"))
      this.setState({
        formData: {},
        keys: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        updateResetPermission()
      })
    }

    if (restView !== prevState.restView) {
      if (!this.state.isEdit)
        this.setState({
          isView: restView
        })
      else {
        this.tempData = restView
        this.setState({formData: restView})
      }
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetPermission()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        updateResetPermission()
      })
    }

    if (keys !== prevState.keys) {
      if (keys !== null) {
        let tempData = this.tempData
        keys.required_field && keys.required_field.forEach((field) => {
          let {name} = field
          tempData[name] = ''
        })
        this.tempData = tempData
      }
    }
  }

  onUpdateClick(slug) {
    getPermission(slug)

    this.setState({
      openModal: true,
      newModal: true,
      isEdit: false,
      slug: slug
    })
  }

  onEditClick(slug) {
    getPermission(slug)

    this.setState({
      openModal: true,
      newModal: true,
      isEdit: true,
      isView: null,
      slug: slug
    })
  }

  onNewClick() {
    let tempData = {
      name: '',
      display_name: '',
      description: ''
    }

    this.tempData = tempData

    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: tempData,
      formError: {},
      isView: null,
      isEdit: null
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  onCreate() {
    let formData = {...this.state.formData}

    let validationRule = {
      name: ['required', 'text'],
      display_name: ['required','text'],
    }

    let localValidationStatus = false
    let check = localValidation(formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      createPermission(this.state.formData)
    }
  }

  onUpdate() {
    updatePermission(this.state.slug, this.state.formData)
  }

  onReset() {
    this.setState({formData: this.tempData})
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deletePermission(this.state.itemToDelete)
    this.setState({
      deleteModal: false,
      itemToDelete: ''
    })
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  paginationButton(page) {
    this.setState({page}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      restData,
      inactiveData,
      openModal,
      newModal,
      formData,
      formError,
      deleteModal,
      keys,
      isSave,
      isView,
      isEdit,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Display Name',
        dataIndex: 'display_name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'button',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            onClick: this.onUpdateClick.bind(this)
          },
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onEditClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, name, display_name, description
      } = single

      tableData.push({
        id, name, display_name, description
      })
    })

    let gatewayOptions = inactiveData ? inactiveData.map((data) => {
      return {
        label: data.name,
        value: data.id
      }
    }) : null

    let buttonClick = !isEdit ? this.onCreate.bind(this) : this.onUpdate.bind(this)

    if (openModal) {
      return (
        <PermissionModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          data={formData}
          error={formError}
          onChange={e => this.onChange(e)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={buttonClick}
          onReset={this.onReset.bind(this)}
          gatewayOptions={gatewayOptions}
          isView={isView}
          keys={keys}
          isNew={newModal}
          isSave={isSave}
          isEdit={isEdit}
        />
      );
    } else {
      return (
        <div className="animated fadeIn">
          <DeleteModal
            open={deleteModal}
            toggle={this.onDelete.bind(this)}
            deleteItem={this.onDeleteConfirm.bind(this)}
            title="Delete Permission"
          />

          <TableHelpers
            columns={columns}
            tableData={tableData}
            newTitle="Create Permissions"
            hideDownload={true}
            newAction={this.onNewClick.bind(this)}
            to={to}
            from={from}
            total={total}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            currentPage={currentPage}
            paginationButton={this.paginationButton.bind(this)}
            lastPage={lastPage}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let {getPermissions, updatePermission, createPermission, getPermission, deletePermission} = state
  return {getPermissions, updatePermission, createPermission, getPermission, deletePermission}
}

export default connect(mapStateToProps)(Permissions)
