import React, {Component} from 'react';
import {connect} from "react-redux";
import store from '../../Store'
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import ViewData from "../../shared/form-helpers/ViewData";
import {NavLink} from "react-router-dom";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {getInvoice} from "../../apis/invoices";


class CreditNoteList extends Component {
  state = {
    data: {},
    currency: ""
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    getInvoice(this.props.match.params.id)
    store.dispatch(getLoggedInCompany())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getInvoice,
      getLoggedInCompany
    } = nextProps

    let {
      data,
      currency
    } = prevState

    if (getInvoice) {
      let {success, error} = getInvoice
      if (success) {
        if (success.status === 200)
          data = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (getLoggedInCompany){
      let {success} = getLoggedInCompany
      if (success){
        currency = success.data.currency.symbol
      }
    }


    return {
      data,
      currency
    }
  }

  render() {
    let {data, currency} = this.state

    let sn = 0;

    let tableBody =data.credit_note && data.credit_note.map((single)=>{
      let { id,company_payment_id,remarks,created_at,created_by,total} = single
      sn++;
      return(
        <tr key={id}>
          <td>{sn}</td>
          <td>{company_payment_id}</td>
          <td>{remarks}</td>
          <td>{total}</td>
          <td>{created_at}</td>
          <td>{created_by}</td>
          <td>
            <NavLink to={'/sales/credit_notes/' + company_payment_id}>
              <Button className="btn-sm" color="success" outline title="View">
                <i className="fa fa-eye"></i>
              </Button>
            </NavLink>
          </td>
        </tr>
      )
    })

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row><Col className="text-center">
              <span className="report-page-title">{this.props.match.params.id}</span>
            </Col></Row>
            <br/>
            <Row>
              <Col className="col-lg-4 col-md-6 col-sm-12">
                <ViewData
                  label="Customer Name"
                  data={data.customer_name}
                />
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-12">
                <ViewData
                  label="Total Credit Amount"
                  data={`${currency} ${data['sum-credit-note']}`}
                />
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-12">
                <ViewData
                  label="Remaining Amount"
                  data={`${currency} ${data['remaining-amount']}`}
                />
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-12">
                <ViewData
                  label="Invoice Number"
                  data={`${data['invoice_no']}`}
                />
              </Col>
              <Col className="col-lg-4 col-md-6 col-sm-12">
                <ViewData
                  label="Fiscal Year"
                  data={`${data['fiscal_year']}`}
                />
              </Col>
            </Row>
            <hr/>
            <Table responsive>
              <thead>
                <tr>
                  <th width="10">SN</th>
                  <th width="100">Invoice</th>
                  <th width="150">Remarks</th>
                  <th width="100">Amount</th>
                  <th width="100">Date</th>
                  <th width="100">Created By</th>
                  <th width="100"></th>
                </tr>
              </thead>
              <tbody>
              {tableBody}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getInvoice,
    getLoggedInCompany
  } = state

  return {
    getInvoice,
    getLoggedInCompany
  }
}

export default connect(mapStateToProps)(CreditNoteList)
