import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "invoices"

export const getInvoices = (limit, page = 1, filters = [],dateFilter = {}) => {
  let reducers = {
    success: '',
    error: ''
  }
  reducers['success'] = 'FETCH_INVOICES_SUCCESS'
  reducers['error'] = 'FETCH_INVOICES_ERROR'
  let group = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}

export const getInvoice = (id) => {
  let reducers = {
    success: 'FETCH_INVOICE_SUCCESS',
    error: 'FETCH_INVOICE_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const downloadInvoice = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_INVOICES_SUCCESS',
    error: 'DOWNLOAD_INVOICES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const createInvoice = (data) => {
  let reducers = {
    success: 'CREATE_INVOICES_SUCCESS',
    validation_error: 'CREATE_INVOICES_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const createResetInvoice = () => {
  store.dispatch(resetData('CREATE_RESET_INVOICES'))
}
