export default function createProduct(
  state = { validation: null, success: null},
  action
) {
  switch (action.type) {

    case 'CREATE_PRODUCT_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_PRODUCT_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null}};
    case 'CREATE_RESET_PRODUCT':
      return {...state, ...{success:null,validation:null}};
    default:
      return state;
  }
}
