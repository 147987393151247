import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "credit-notes"

export const getCreditNotes = (limit, page = 1, filters = [],dateFilter={}) => {
  let reducers = {
    success: 'FETCH_CREDIT_NOTES_SUCCESS',
    error: 'FETCH_CREDIT_NOTES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}

export const getCreditNote = (id) => {
  let reducers = {
    success: 'FETCH_CREDIT_NOTE_SUCCESS',
    error: 'FETCH_CREDIT_NOTE_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createCreditNote = (data) => {
  let reducers = {
    success: 'CREATE_CREDIT_NOTE_SUCCESS',
    validation_error: 'CREATE_CREDIT_NOTE_VALIDATION_ERROR',
    bad_gateway: 'CREATE_CREDIT_NOTE_BAD_GATEWAY',
    bad_request: 'CREATE_CREDIT_NOTE_BAD_REQUEST'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const downloadCreditNotes = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_CREDIT_NOTES_SUCCESS',
    error: 'DOWNLOAD_CREDIT_NOTES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const createResetCreditNote = () => {
  store.dispatch(resetData('CREATE_RESET_CREDIT_NOTE'))
}
