import store from '../Store'
import {Create} from "../actions/crud/create";
import {Get} from "../actions/crud/get";
import {resetData} from "../actions/reset";
import {Update} from "../actions/crud/update";

let prefix = 'users'

export const uploadImage = (id,data) => {
  let reducers = {
    success: 'UPLOAD_IMAGE_SUCCESS',
    error: 'UPLOAD_IMAGE_ERROR',
    validation_error: 'UPLOAD_IMAGE_VAIDATION_ERROR'
  }
  let url = `${prefix}/${id}/images`
  let create = new Create(url, reducers,data)
  create.create()
}

export const uploadResetImage = () => {
  store.dispatch(resetData('UPLOAD_RESET_IMAGE'))
}

export const inviteUser = (data) => {
  let reducers = {
    success: 'INVITE_USER_SUCCESS',
    error: 'INVITE_USER_ERROR',
    validation_error: 'INVITE_USER_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const getUser = (id) => {
  let reducers = {
    success: 'FETCH_USER_SUCCESS',
    error: 'FETCH_USER_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const updateUser = (id, data) => {
  let reducers = {
    success: 'UPDATE_USER_SUCCESS',
    error: 'UPDATE_USER_ERROR',
    validation_error: 'UPDATE_USER_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const resetInviteUser = () => {
  store.dispatch(resetData('RESET_INVITE_USER'))
}

export const updateResetUser = () => {
  store.dispatch(resetData('UPDATE_RESET_USER'))
}
