export default function getResellers(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'GET_RESELLERS_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'GET_RESELLERS_ERROR':
      return {...state, ...{error: action.error}};
    default:
      return state;
  }
}
