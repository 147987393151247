import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap"
import {getUnits} from "../../apis/units";
import {NavLink} from "react-router-dom";
import ViewData from "../../shared/form-helpers/ViewData";
import {ArrayValue} from "../../shared/helpers/ArrayKey";
import {getProduct} from "../../apis/products";

class ViewProductAndService extends Component {

  data = {}

  error = {}
  state = {
    message: {type: ``, message: ``, title: ``},
    units: [],
    service: true,
    status: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    countries: [],
    provinces: [],
    createSuccess: false,
    username: null,
    restSuccess: null,
    visible: false,
    processing: false
  }

  fetchData() {
    getUnits(1000)
    getProduct(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData()
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getUnits, getProduct} = nextProps
    let {units, data, service, restSuccess, processing} = prevState

    if (getProduct) {
      let {success, error} = getProduct
      if (success) {
        data = success.data
        if (success.data.service === "p")
          service = false
      } else if (error) {
        console.log(error)
      }

      processing = getProduct.processing
    }

    if (getUnits) {
      let {success, error} = getUnits
      if (success) {
        units = success.data.data
      } else if (error) {
        console.log(error)
      }
    }

    return {
      units, data, service, restSuccess, processing
    }
  }


  onDismiss() {
    this.setState({visible: false});
  }


  render() {
    let {data, units} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardHeader>
                <span>
                  <strong>Products and Services</strong>
                </span>
            <ButtonGroup className="float-right">
              <NavLink to={'/products_and_services/' + data.id + '/edit'}>
                <Button outline color="primary">Edit</Button></NavLink>
            </ButtonGroup>
          </CardHeader>

          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Type"
                      data={data.type === "s" ? "Service" : "Product"}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Name"
                      data={data.name}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Unit"
                      data={ArrayValue(units, data.unit_id, 'id', 'name')}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Sales Price"
                      data={data.sales_price}
                    />
                  </Col>
                  {data.type === "product" &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Purchase Price"
                      data={data.purchase_price}
                    />
                  </Col>
                  }
                  <Col sm="12" md="6">
                    <ViewData
                      label="Status"
                      data={data.status ? "Active" : "Inactive"}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="HS Code"
                      data={data.hs_code ? data.hs_code : "-"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Details</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Code"
                      data={data.code}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ViewData
                      label="Description"
                      data={data.description}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getUnits, createProduct, getLoggedInUser, getProduct} = state
  return {
    getUnits, createProduct, getLoggedInUser, getProduct
  }
}

export default connect(mapStateToProps)(ViewProductAndService);
