import React from 'react';
import {Button, ButtonGroup, Card, CardBody, Col, Form, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import ViewData from "../../shared/form-helpers/ViewData";

const PermissionModal = (props) => {

  let {
    toggle,
    data,
    error,
    onChange,
    onSave,
    onReset,
    isView,
    isNew
  } = props

  let viewKeys = isView ? Object.keys(isView) : []

  let displayViewKeys = viewKeys.map((key) => {
    if (key === "type" || key === "id" || key === "created_at" || key === "updated_at") {
      return null;
    } else
      return (
        <Row>
          <Col>
            <ViewData
              label={key}
              data={isView[key]}
            />
          </Col>
        </Row>
      );
  })

  if (isView) {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="2"></Col>
              <Col sm="12" md="6">
                {displayViewKeys}
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  <Button outline color="success" onClick={toggle}>Okay</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="2"/>
              <Col sm="6">
                <Form>
                  <HorizontalTextField
                    name="name"
                    value={data.name}
                    error={error.name}
                    onChange={onChange}
                    isRequired={true}
                  />
                  <HorizontalTextField
                    name="display_name"
                    value={data.display_name}
                    error={error.display_name}
                    onChange={onChange}
                    isRequired={true}
                  />
                  <HorizontalTextField
                    name="description"
                    error={error.description}
                    value={data.description}
                    onChange={onChange}
                  />
                </Form>
              </Col>
              <Col sm="2">
              </Col>
            </Row>
            <Row>
              <Col>
                {isNew ? <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                  <Button outline color="primary" onClick={onReset}>Reset</Button>
                  <Button outline color="success" onClick={onSave}>Save</Button>
                </ButtonGroup> : <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                </ButtonGroup>}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PermissionModal;
