import {ucFirst} from "../helpers/GeneralHelpers";

export const localValidation = (
  data,
  validationRule,
  error,
  localvalidationerror
) => {
  if (validationRule) {
    Object.keys(validationRule).forEach((item, key) => {
      for (let i = 0; i < validationRule[item].length; i++) {
        let validate = validation(
          item,
          data[item],
          validationRule[item][i],
          data
        );
        if (validate) {
          error[item] = validate;
          localvalidationerror = true;
          break;
        }
      }
    });
  }
  return {
    localvalidationerror,
    error
  };

  function validation(name, value, rule, data) {
    let r = rule.split(":");
    if (r[0] !== "required") {
      if (value) {
        if (value.length < 1) {
          return null
        }
      } else
        return null
    }

    if (r[0] === "zip")
      value = value + ""

    switch (r[0]) {
      case "required": {
        return value ? null : "This field is required.";
      }
      case "email": {

        let re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return re.test(value)
          ? null
          : "This is not a valid email.";
      }
      case "numeric": {
        return !/\D/.test(value)
          ? null
          : "This field must be positive number.";
      }
      case "array": {
        return Array.isArray(value)
          ? null
          : "This field must be array.";
      }
      case "unique": {
        let index = data[r[1]].indexOf(value);
        return index < 0 ? null : [capitalString(name) + " already esists."];
      }
      case "url": {
        let re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        if (value) {
          return re.test(value)
            ? null
            : "This is not a valid Url.";
        }
        return null;
      }
      case "pan": {
        let temp = value + ""
        return temp.match("^\\d{9}$") !== null ?
          null :
          "This is not a valid pan number"
      }
      case "zip": {
        return value.match("^[0-9]{5}(?:-[0-9]{4})?$") !== null ?
          null :
          "This is not a valid zip code"
      }
      case "address": {
        return value.match("^[a-zA-Z\\,]{2,}$") !== null ?
          null :
          "This is not a valid address"
      }
      case "phone": {
        return value.match("^\\d{7}$") !== null ?
          null :
          "This is not a valid phone number"
      }
      case "mobile": {
        return value.match("^\\d{10}$") !== null ?
          null :
          "This is not a valid mobile number"
      }
      case "text": {
        return value.match("^[a-zA-Z ]*$") !== null ?
          null :
          "Only alphabets and space are allowed"
      }
      case "decimal": {
        value = value+""
        return value.match("^[+]?([0-9]+(?:[\\.][0-9]*)?|\\.[0-9]+)$") !== null ?
          null :
          "Only positive decimal value is allowed"
      }
      case "image_dimensions": {
        let dmsn = r[1].split("=");
        dmsn[0] = dmsn[0].trim().toLowerCase();
        switch (dmsn[0]) {
          case "fixed": {
            let vals = dmsn[1].split(",");
            return parseInt(vals[0]) === parseInt(vals[2]) &&
            parseInt(vals[1]) === parseInt(vals[3])
              ? null
              : [
                "Image height, width must be " +
                vals[0] +
                ", " +
                vals[1] +
                " ."
              ];
          }
        }
      }
      default:
        return null;
    }
  }

  function capitalString(string) {
    let array = string.split("_");
    array = array.map(item => {
      return ucFirst(item);
    });
    return array.join(" ");
  }
};

/**
 * Documentation
 *
 * image_dimensions===>
 * image_dimensions:fixed=required_height,required_width,height,width
 * image_dimensions:max=required_height,required_width,height,width
 * image_dimensions:min=required_height,required_width,height,width
 *
 */
