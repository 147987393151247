import React, {Component} from 'react';
import {connect} from "react-redux";
import {Card, CardBody, Col, Input, Row, Table} from "reactstrap";
import ReportHeader from "./ReportHeader";
import ReportFilter from "./ReportFilter";
import ReportColor from "./ReportColor";
import TablePagination from "../../shared/table-helpers/TablePagination";
import store from '../../Store'
import CurrencyAdder from "../CurrencyAdder";
import {getSalesRegisters} from "../../apis/sales-registers";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {setFilter} from "../../shared/helpers/FilterHelper";
import nepaliCalendar from '../../shared/nepali-calendar-dates'
import {getEquivalentNepaliMonth} from "../../shared/helpers/GeneralHelpers";
import {getWorldlinkPos} from "../../apis/pos";
import {getResellers} from "../../apis/resellers";


class Sales extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    customer_name: '',
    dateFilter: {
      start_date: '',
      end_date: ''
    },
    startDate: null,
    endDate: null,
    nepaliDate: {
      month: 9,
      year: 2075
    }
  }

  currentPath = window.location.pathname

  state = {
    filters: {...this.filters},
    userDetail: {},
    focusedInput: null,
    data: null,
    sumData: null,
    creditData: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    currency: "",
    companyDetail: {},
    poses: [],
    resellerList: []
  }

  componentDidMount() {
    let tempDate = new Date();
    let georgianDate = nepaliCalendar.toNepali(tempDate.getFullYear(), tempDate.getMonth() + 1, tempDate.getDate())
    let filters = {...this.state.filters}
    filters['nepaliDate']['month'] = georgianDate.nm
    filters['nepaliDate']['year'] = georgianDate.ny
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
    if (!this.props.getLoggedInCompany.success) {
      store.dispatch(getLoggedInCompany())
    }
    getWorldlinkPos()
    getResellers()
  }

  fetchData() {
    let {limit, page, searchArray, dateFilter} = this.state.filters
    getSalesRegisters(limit, page, searchArray, dateFilter)
  }

  setGeorgianDate() {
    console.log("here");
    let filters = {...this.state.filters}
    if(filters.nepaliDate.month) {
      let monthLength = nepaliCalendar.nepaliMonthLength(filters.nepaliDate.year, filters.nepaliDate.month)
      console.log(filters.nepaliDate)
      console.log("monthLength", monthLength)
      let tempDate = nepaliCalendar.toGregorian(filters.nepaliDate.year, filters.nepaliDate.month, 1)
      console.log("tempDate", tempDate)
      let endDate = new Date(`${tempDate.gy}-${tempDate.gm}-${tempDate.gd}`)
      console.log("endDate", endDate)
      console.log("month lenght", monthLength)
      endDate.setDate(endDate.getDate() + monthLength - 1)
      console.log("endDate", endDate)
      setTimeout(() => {
        let start_date = `${tempDate.gy}-${tempDate.gm}-${tempDate.gd} 00:00:00`
        let end_date = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} 23:59:59`
        filters['dateFilter']['start_date'] = start_date
        filters['dateFilter']['end_date'] = end_date
        this.setState({
          filters
        }, () => {
          this.fetchData()
        })
      }, 10)
    }else{
      let monthLength = nepaliCalendar.nepaliMonthLength(filters.nepaliDate.year * 1 + 1, 3)
      let startDateGeorgian = nepaliCalendar.toGregorian(filters.nepaliDate.year, 4, 1)
      let endDateGeorgian = nepaliCalendar.toGregorian(filters.nepaliDate.year * 1 + 1, 3, 1)
      let start_date = `${startDateGeorgian.gy}-${startDateGeorgian.gm}-${startDateGeorgian.gd} 00:00:00`
      let endDate = new Date(`${endDateGeorgian.gy}-${endDateGeorgian.gm}-${endDateGeorgian.gd}`)
      endDate.setDate(endDate.getDate() + monthLength - 1)
      let end_date = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} 23:59:59`
      setTimeout(() => {
        filters['dateFilter']['start_date'] = start_date
        filters['dateFilter']['end_date'] = end_date
        this.setState({
          filters
        }, () => {
          this.fetchData()
        })
      }, 10)
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getLoggedInUser,
      getSalesRegisters,
      getLoggedInCompany,
      getWorldlinkPos,
      getResellers
    } = nextProps

    let {
      userDetail,
      data,
      sumData,
      creditData,
      prevPageUrl,
      nextPageUrl,
      from,
      to,
      total,
      lastPage,
      firstPage,
      currentPage,
      companyDetail,
      poses,
      resellerList
    } = prevState

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        userDetail = success.data
      }
    }

    if (getSalesRegisters) {
      let {success} = getSalesRegisters
      if (success) {
        if (success.status === 200) {
          data = success.data.invoices.data || []
          sumData = success.data.sum_data
          creditData = success.data.credit_notes.data
          prevPageUrl = success.data.invoices.prev_page_url;
          nextPageUrl = success.data.invoices.next_page_url;
          currentPage = success.data.invoices.current_page;
          from = success.data.invoices.from;
          to = success.data.invoices.to;
          total = success.data.invoices.total;
          lastPage = success.data.invoices.last_page;
          firstPage = 1;
        }
      }
    }

    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        companyDetail = success.data
      }
    }

    if (getWorldlinkPos) {
      let {success, error} = getWorldlinkPos
      if (success) {
        poses = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (getResellers) {
      let {success, error} = getResellers
      if(success){
        if (success.data){
          let temp = []
          success.data.forEach((single)=>{
            temp.push({
              label: single,
              value: single
            })
          })
          resellerList = temp
        }
      }
      else if (error){

      }
    }

    return {
      userDetail,
      data,
      sumData,
      prevPageUrl,
      nextPageUrl,
      from,
      to,
      total,
      lastPage,
      firstPage,
      currentPage,
      creditData,
      companyDetail,
      poses,
      resellerList
    }
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    });
  }

  limitChange(e) {
    let filters = {...this.state.filters}
    filters['limit'] = e.target.value
    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }


  searchChange(e) {
    let name = e.target.name
    let data = e.target.value
    let filters = {...this.state.filters}
    let searchArray = [...this.state.filters.searchArray]
    let a = {
      key: name,
      value: data
    }

    let searchIndex = this.state.filters.searchIndex
    let position = searchIndex.indexOf(name)
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push(name)
    }

    if (name === 'customer_name')
      filters['customer_name'] = data
    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex

    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }

  changeDate(data) {
    let filters = {...this.state.filters}
    filters.nepaliDate.year = data.year
    filters.nepaliDate.month = data.month
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
  }

  changeRange(data) {
    console.log(data);
    let filters = {...this.state.filters}
    let from = data.from.split("-")
    let to = data.to.split("-")
    let startDate = nepaliCalendar.toGregorian(from[0] *1, from[1] *1, from[2] *1)
    let endDate = nepaliCalendar.toGregorian(to[0] * 1, to[1] * 1, to[2] * 1)
    console.log("startDate", startDate)
    console.log("endDate", endDate)
    setTimeout(() => {
      let start_date = `${startDate.gy}-${startDate.gm}-${startDate.gd} 00:00:00`
      let end_date = `${endDate.gy}-${endDate.gm}-${endDate.gd} 23:59:59`
      filters['dateFilter']['start_date'] = start_date
      filters['dateFilter']['end_date'] = end_date
      this.setState({
        filters
      }, () => {
        this.fetchData()
      })
    }, 10)
  }

  render() {
    let {
      data,
      sumData,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      limit,
      creditData,
      companyDetail,
      filters,
      resellerList
    } = this.state


    let salesData = data && data.map((sales) => {
      let {id, created_at, invoice_no, customer_name, pan, total, taxable_amount, vat_amount, vat_rated_sale, exempted_sale,discount_amount} = sales

      return (
        <tr key={id} className={sales['sum-credit-note'] !== 0 ? "report-return-indicator" : "report-data-success"}>
          <td><span>{created_at}</span></td>
          <td><span>{`${invoice_no}/-`}</span></td>
          <td><span>{customer_name}</span></td>
          <td><span>{pan ? pan : "-"}</span></td>
          <td className="text-right"><CurrencyAdder amount={total}/></td>
          <td className="text-right"><CurrencyAdder amount={exempted_sale}/></td>
          <td className="text-right">-</td>
          <td className="text-right"><CurrencyAdder amount={discount_amount}/></td>
          <td className="text-right"><CurrencyAdder amount={taxable_amount}/></td>
          <td className="text-right"><CurrencyAdder amount={vat_amount}/></td>
        </tr>
      );
    })

    let purchaseTotal = sumData &&
      <tr className="report-data-success">
        <td className="text-right" colSpan="4">Sales Total</td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.total}/></td>
        <td className="text-right"><span className="">0.00</span></td>
        <td>-</td>
        <td className="text-right"><span className="">{sumData.total_sales.discount_amount}</span></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.vat_amount}/></td>
      </tr>

    let creditTotal = sumData &&
      <tr className="report-data-return">
        <td className="text-right" colSpan="4">Credit Note Total</td>
        <td className="text-right"><CurrencyAdder amount={"" + sumData.return_sales.total}/></td>
        <td className="text-right"><span className="">0.00</span></td>
        <td>-</td>
        <td className="text-right"><span className="">{sumData.return_sales.discount_amount}</span></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_sales.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_sales.vat_amount}/></td>
      </tr>

    let summaryData = sumData && <>
      <tr className="report-data-success">
        <td className="text-right" colSpan="4">Sales Total</td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.total}/></td>
        <td className="text-right"><span className="">0.00</span></td>
        <td>-</td>
        <td className="text-right"><span className="">{sumData.total_sales.discount_amount}</span></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_sales.vat_amount}/></td>
      </tr>
      <tr className="report-data-return">
        <td className="text-right" colSpan="4">Credit Note Total</td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_sales.total}/></td>
        <td className="text-right"><span className="">0.00</span></td>
        <td>-</td>
        <td className="text-right"><span className="">{sumData.return_sales.discount_amount}</span></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_sales.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_sales.vat_amount}/></td>
      </tr>
      <tr>
        <td className="text-right" colSpan="4">Grand Total</td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_sales.total}/></td>
        <td className="text-right"><span className="">0.00</span></td>
        <td>-</td>
        <td className="text-right"><span className="">{sumData.active_sales.discount_amount}</span></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_sales.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_sales.vat_amount}/></td>
      </tr>
    </>

    let creditRow = creditData && creditData.map((credit) => {
      let {id, created_at, credit_note_no, customer_name, pan, total, taxable_amount, vat_amount, invoice,discount_amount} = credit
      return (
        <tr className="report-data-return" key={id}>
          <td><span>{created_at}</span></td>
          <td><span>{`${invoice.invoice_no}/${credit_note_no}`}</span></td>
          <td><span>{customer_name}</span></td>
          <td><span>{pan ? pan : "-"}</span></td>
          <td className="text-right"><CurrencyAdder amount={total}/></td>
          <td className="text-right"><CurrencyAdder amount="0"/></td>
          <td>-</td>
          <td className="text-right"><CurrencyAdder amount={discount_amount || 0}/></td>
          <td className="text-right"><CurrencyAdder amount={taxable_amount}/></td>
          <td className="text-right"><CurrencyAdder amount={vat_amount}/></td>
        </tr>
      );
    })

    let salesTable = <>
      <Table responsive bordered className="small-font">
        <tbody>
        <tr>
          <td colSpan="4" className="text-center text-bold">Invoice</td>
          <td width="100" rowSpan="2" className="text-center text-bold">Total Sales</td>
          <td width="100" rowSpan="2" className="text-center text-bold">Non Taxable Sales</td>
          <td width="100" rowSpan="2" className="text-center text-bold">Export Sales</td>
          <td width="100" rowSpan="2" className="text-center text-bold">Discount</td>
          <td width="100" colSpan="2" className="text-center text-bold">Taxable Sales</td>
        </tr>
        <tr>
          <td width="100" className="text-center text-bold">Date</td>
          <td width="100" className="text-center text-bold">Bill No/ CNN</td>
          <td width="200" className="text-center text-bold">Buyer's Name</td>
          <td width="100" className="text-center text-bold">Buyer's PAN</td>
          <td width="100" className="text-center text-bold">Amount</td>
          <td width="100" className="text-center text-bold">Tax</td>
        </tr>
        {salesData}
        {purchaseTotal}
        <tr>
          <td colSpan="9"></td>
        </tr>
        {creditRow}
        {creditTotal}
        <tr>
          <td colSpan="12" className="text-center">
            <span className="text-bold report-table-title">Summary</span>
          </td>
        </tr>
        {summaryData}
        </tbody>
      </Table>
    </>

    let posesList = []

    this.state.poses.forEach((single) => {
      posesList.push({
        label: single,
        value: single
      })
    })

    return (
      <div className="animated fadeIn">
        <ReportFilter
          startDate={filters.startDate}
          endDate={filters.endDate}
          searchChange={(e) => this.searchChange(e)}
          searchValue={filters.customer_name}
          reportType="sales"
          changeDate={this.changeDate.bind(this)}
          changeRange={this.changeRange.bind(this)}
          poses={posesList}
          reseller={resellerList}
        />

        <Card>
          <CardBody>
            <ReportHeader
              page="Sales Register"
              dateFilter={this.state.filters.dateFilter}
              userDetail={companyDetail}
              reportType="sales"
              filters={this.state.filters.searchArray}
            />
            <hr/>
            <Row><Col className="text-center">
              <span className="report-page-title">Sales Register</span>
            </Col></Row>
            <ReportColor/>
            <Row>
              <Col md="6" sm="12">
                <div className="dataTables_wrapper form-inline float-left">
                  <label>
                    Show &nbsp;
                    <Input type="select" onChange={(e) => this.limitChange(e)} value={limit}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </Input> &nbsp;
                    entries
                  </label>
                </div>
              </Col>
              <Col md="6" sm="12" className="text-right">
                <span
                  className="text-bold">{`Duration of Sales : ${filters.nepaliDate.month ? getEquivalentNepaliMonth(filters.nepaliDate.month - 1) : ""},${filters.nepaliDate.year}`}</span>
              </Col>
            </Row>
            <br/>
            {salesTable}
            <TablePagination
              to={to}
              from={from}
              total={total}
              prevPageUrl={prevPageUrl}
              nextPageUrl={nextPageUrl}
              currentPage={currentPage}
              paginationButton={this.paginationButton.bind(this)}
              lastPage={lastPage}
              name="records"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getLoggedInUser,
    getSalesRegisters,
    getLoggedInCompany,
    getWorldlinkPos,
    getResellers
  } = state
  return {
    getLoggedInUser,
    getSalesRegisters,
    getLoggedInCompany,
    getWorldlinkPos,
    getResellers
  }
}

export default connect(mapStateToProps)(Sales)
