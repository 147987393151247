import {Get} from "../actions/crud/get"

let prefix = "countries"

export const getCountries = () =>{
  let reducers = {
    success: 'FETCH_COUNTRIES_SUCCESS',
    error: 'FETCH_COUNTRIES_ERROR'
  }
  let get = new Get(prefix,reducers)
  get.get()
}
