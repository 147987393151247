import React, {Component} from 'react';
import {connect} from "react-redux";
import PaymentTermModal from "./PaymentTermModal";
import {
  createPaymentTerm,
  createResetPaymentTerm, deletePaymentTerm,
  deleteResetPaymentTerm, getPaymentTerm,
  getPaymentTerms, updatePaymentTerm,
  updateResetPaymentTerm
} from "../../apis/payment-terms";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {localValidation} from "../../shared/helpers/ValidationHelper";


class PaymentTerms extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    createSuccess: null,
    updateSuccess: null,
    restDelete: null,
    createError: null,
    updateError: null,
    restData: [],
    restUpdate: null,
    visible: false,
    delete: false,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    itemToDelete: "",
    formData: {},
    formError: {},
  }


  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getPaymentTerms(limit, page, searchArray)
  }

  componentDidMount() {
    if (!this.props.getPaymentTerms.success)
      this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    let {createSuccess, updateSuccess, updateError, createError, restUpdate, deleteSuccess} = this.state

    if (restUpdate !== prevState.restUpdate) {
      this.setState({
        formData: restUpdate
      })
    }

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Payment Term"));
      createResetPaymentTerm()
      this.fetchData();
      this.toggleModal()
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Update Payment Term"));
      this.fetchData();
      updateResetPaymentTerm()
      this.toggleModal()
    }

    if (deleteSuccess !== null) {
      store.dispatch(addMessage(deleteSuccess, "success", "Delete Payment Term"));
      this.fetchData();
      deleteResetPaymentTerm()
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetPaymentTerm()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        this.fetchData()
        updateResetPaymentTerm()
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getPaymentTerms,
      getPaymentTerm,
      createPaymentTerm,
      updatePaymentTerm,
      deletePaymentTerm
    } = nextProps
    let {
      restData,
      restUpdate,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      createSuccess,
      createError,
      updateSuccess,
      restDeleteSuccess,
      processing,
      getProcessing,
      initial,
      updateError,
      deleteSuccess
    } = prevState;
    if (getPaymentTerms) {
      let {success, error} = getPaymentTerms
      if (success) {
        restData = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
        initial = false
      } else if (error) {
        console.log(error)
      }
      processing = getPaymentTerms.processing
    }

    if (createPaymentTerm) {
      let {success, validation} = createPaymentTerm
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (updatePaymentTerm) {
      let {success, validation} = updatePaymentTerm
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null;
        updateError = null;
      }
    }

    if (getPaymentTerm) {
      let {success} = getPaymentTerm
      if (success) {
        restUpdate = success.data
      }
      getProcessing = getPaymentTerm.processing
    }

    if (deletePaymentTerm) {
      let {success, error} = deletePaymentTerm
      if (success) {
        deleteSuccess = success.data.message
      } else if (error) {

      } else {
        deleteSuccess = null
      }
    }

    return {
      restData,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      restDeleteSuccess,
      processing,
      getProcessing,
      initial,
      restUpdate,
      updateSuccess,
      createSuccess,
      deleteSuccess,
      createError,
      updateError
    }
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  onCreate() {
    let {formData} = this.state
    let validationRule = {
      days: ['required', 'numeric'],
      description: ['required']
    }
    let localValidationStatus = false
    let check = localValidation(formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      createPaymentTerm(this.state.formData)
    }
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deletePaymentTerm(this.state.itemToDelete)
  }

  onNewClick() {
    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: {
        status: 1,
        name: '',
        description: ''
      },
      formError: {}
    })
  }

  onUpdateClick(id) {
    getPaymentTerm(id)
    this.setState({
      id: id,
      openModal: true,
      newModal: false,
      formError: {}
    })
  }

  onReset() {
    if (this.state.id) {
      this.setState({
        formData: this.state.restUpdate
      })
    } else {
      this.setState({
        formData: {
          status: 1,
          days: '',
          description: ''
        }
      })
    }
  }

  onUpdate() {
    let {id, formData} = this.state
    let validationRule = {
      days: ['required', 'numeric'],
      description: ['required']
    }
    let localValidationStatus = false
    let check = localValidation(formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      updatePaymentTerm(id, formData)
    }
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      restData,
      openModal,
      newModal,
      formData,
      formError,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Days',
        dataIndex: 'days'
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '30%',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Disabled'
          }
        ]
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, status, days, description
      } = single

      tableData.push({
        id, status, days, description
      })
    })

    if (openModal) {
      return (
        <PaymentTermModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={newModal ? "Add New Payment Term" : "Edit Payment Term"}
          data={formData}
          error={formError}
          onChange={e => this.onChange(e)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
        />
      );
    } else {
      return (
        <>
          <TableHelpers
            columns={columns}
            tableData={tableData}
            to={to}
            from={from}
            total={total}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            currentPage={currentPage}
            paginationButton={this.paginationButton.bind(this)}
            lastPage={lastPage}
            filters={this.filters}
            newTitle="Create Payment Term"
            newAction={this.onNewClick.bind(this)}
            tabTable={true}
          />
          {/*<DeleteModal*/}
          {/*  open={deleteModal}*/}
          {/*  toggle={this.onDelete.bind(this)}*/}
          {/*  deleteItem={this.onDeleteConfirm.bind(this)}*/}
          {/*  title="Delete Payment Term"*/}
          {/*/>*/}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  let {getCustomers, createPaymentTerm, getPaymentTerms, getPaymentTerm, updatePaymentTerm, deletePaymentTerm} = state;
  return {
    getCustomers, createPaymentTerm, getPaymentTerms, getPaymentTerm, updatePaymentTerm, deletePaymentTerm
  }
}

export default connect(mapStateToProps)(PaymentTerms);
