import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {getCustomer, getCustomers, updateCustomer, updateResetCustomer} from "../../apis/customers";
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class Customers extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showDeleteModal: false,
    itemToDelete: '',
    singleCustomer: null,
    disableCustomer: null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.singleCustomer !== prevState.singleCustomer) {
      this.setState({
          showDeleteModal: false
        },
        () => {
          let temp = this.state.singleCustomer
          if (!temp['pan']) {
            delete temp['pan']
          }
          temp['status'] = 0
          updateCustomer(this.state.itemToDelete, temp)
        })
    }

    if (this.state.disableCustomer !== null) {
      store.dispatch(addMessage(this.state.disableCustomer, "success", "Disable Customer"))
      updateResetCustomer()
      this.fetchData()
    }
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getCustomers(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getCustomers,
      getCustomer,
      updateCustomer
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      singleCustomer,
      disableCustomer
    } = prevState;

    if (getCustomers) {
      let {success, error} = getCustomers;
      if (success) {
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    if (getCustomer) {
      let {success} = getCustomer
      if (success) {
        singleCustomer = success.data
      }
    }

    if (updateCustomer) {
      let {success} = updateCustomer
      if (success) {
        disableCustomer = success.data.message
      } else {
        disableCustomer = null
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      singleCustomer,
      disableCustomer
    };
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  togglePrintModal() {
    this.setState({
      showPrintModal: !this.state.showPrintModal
    })
  }

  onDelete(id) {
    this.state.showDeleteModal ?
      this.setState({showDeleteModal: false, itemToDelete: ""}) :
      this.setState({showDeleteModal: true, itemToDelete: id})
  }

  onDeleteConfirm() {
    getCustomer(this.state.itemToDelete)
    // deleteCustomer(this.state.itemToDelete)
  }

  newAction() {
    this.props.history.push('/sales/customers/new')
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        searchText: 'last_name'
      },
      {
        title: 'Customer Code',
        dataIndex: 'customer_code',
        searchText: 'customer_code'
      },
      {
        title: 'Customer Pan',
        dataIndex: 'pan'
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        contact: true
      },
      {
        title: 'Created By',
        dataIndex: 'created_by',
        searchText: 'created_by'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        variant_text: [
          {
            key: 'I',
            value: 'blue',
            label: 'Individual'
          },
          {
            key: 'C',
            value: 'green',
            label: 'Corporate'
          }
        ]
      },
      {
        title: 'Status',
        dataIndex: 'status',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Disabled'
          }
        ]
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            url: ['/sales/customers/']
          },
          {
            type: 'navlink',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            url: ['/sales/customers/', '/edit']
          },
          {
            type: 'button',
            icon: 'delete',
            twoToneColor: "#f86c6b",
            title: 'Disable Customer',
            onClick: this.onDelete.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    data.map((single) => {
      let {
        id,
        first_name,
        last_name,
        phone_no,
        mobile_no,
        email,
        address,
        city,
        country,
        customer_code,
        created_at,
        created_by,
        remarks,
        ird_sync,
        pan,
        type,
        status
      } = single

      tableData.push({
        id,
        customer_name: `${first_name} ${last_name}`,
        customer_code,
        pan: pan || '-',
        remarks,
        created_at,
        ird_sync,
        created_by,
        contact: {
          phone: `${phone_no}${phone_no && ','}${mobile_no}`,
          email: email || '',
          address: `${address}${address && ','}${city}${city && ','}${country.name}`
        },
        type,
        status
      })
      return null
    })

    return (
      <div className="animated fadeIn">
        <DeleteModal
          open={this.state.showDeleteModal}
          toggle={this.onDelete.bind(this)}
          deleteItem={this.onDeleteConfirm.bind(this)}
          title="Disable Customer"
          disable={true}
        />
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          onDateConfirm={this.onDateConfirm.bind(this)}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Create Customer"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getCustomers,
    getCustomer,
    updateCustomer
  } = state;
  return {
    getCustomers,
    getCustomer,
    updateCustomer
  };
}

export default connect(mapStateToProps)(Customers)
