import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";
import {_success, _validation_error, _bad_gateway_error, _bad_request, _taken_error} from '../connect_reducer'
import {noInternet, serverError, unauthorizedError} from "../error";


export class Create {
  constructor(url, reducers, data, isRedirect = false) {
    this.url = url
    this.reducers = reducers
    this.data = data
    this.isRedirect = isRedirect
  }

  create() {
    store.dispatch(enableInnerLoader())
    let config = {
      url: Config.BillingUrl + this.url,
      method: "post",
      dataType: 'json',
      data: this.data,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    };

    axios(config)

      .then(res => {
        store.dispatch(disableInnerLoader())
        if (this.isRedirect)
          window.location = res.request.responseURL
        else
          store.dispatch(_success(res, this.reducers.success))

      })
      .catch(error => {
        store.dispatch(disableInnerLoader())
        if (error.response) {
          if (error.response.status === 422)
            if (error.response.data.message)
              store.dispatch(_validation_error(error.response.data.message, this.reducers.validation_error))
            else
              store.dispatch(_validation_error(error.response.data, this.reducers.validation_error))
          else if (error.response.status === 403)
            store.dispatch(unauthorizedError(error.response.data.message))
          else if (error.response.status === 400) {
            if (error.response.data.message.product) {
              store.dispatch(_bad_request(
                {
                  title: Object.keys(error.response.data.message.product)[0],
                  error: error.response.data.message.error
                }, this.reducers.bad_request
              ))
            } else {
              store.dispatch(_bad_gateway_error(error.response.data.message, this.reducers.bad_gateway_error))
            }
          }
          if (error.response.status === 401) {
            store.dispatch(_taken_error(error.response.data.message, this.reducers.taken_error))
            console.log(error.response.data.message)
          }
          if (error.response.status >= 500)
            store.dispatch(serverError())
        } else if (error.request) {
          store.dispatch(noInternet())
        } else {
          store.dispatch(serverError())
        }
      });
  }
}

