import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Col, Input, Row, Table} from "reactstrap";
import DownloadGroup from "../../shared/helpers/DownloadGroup";
import {getActivityLog} from "../../apis/activity-log";
import {Config} from "../../Config";
import {downloadFile} from "../../shared/helpers/DownloadFile";
import TablePagination from "../../shared/table-helpers/TablePagination";
import SearchCard from "../../shared/dashboard-helpers/SearchCard";

class ActivityLog extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    username: '',
    startDate: null,
    endDate: null,
    dateFilter: {
      start_date: '',
      end_date: ''
    },
  }

  state = {
    filters: {...this.filters},
    startDate: null,
    endDate: null,
    focusedInput: null,
    search_value: '',
    data: [],
    page: 1,
    from: 1,
    to: null,
    total: null,
    firstPage: 1,
    lastPage: null,
    currentPage: 1,
    prevPageUrl: null,
    nextPageUrl: null,
    limit: "10"
  }

  componentDidMount() {
    this.fetchData()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getActivityLog} = nextProps
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState
    if (getActivityLog) {
      let {success} = getActivityLog
      if (success) {
        data = success.data.data || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }
    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    }
  }

  fetchData() {
    let {limit, page, searchArray} = this.state.filters
    getActivityLog(limit, page, searchArray)
    // getActivityLog(limit, page, searchArray, dateFilter)
  }

  searchChange(e) {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  onDateChange(date, dateString) {
    let filters = {...this.state.filters}
    filters['startDate'] = date[0]
    filters['endDate'] = date[1]
    filters['dateFilter'] = {
      start_date: dateString[0],
      end_date: dateString[1]
    }

    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  onFocusChange(focusedInput) {
    this.setState({focusedInput})
  }

  onDownloadClick(type) {
    let url = Config.BillingUrl + 'logs/export?export_type=' + type
    downloadFile(url, "activity-log", true, true)
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    });
  }

  limitChange(e) {
    let filters = {...this.state.filters}
    filters['limit'] = e.target.value
    this.setState({
      filters
    }, () => {
      this.fetchData()
    });
  }

  searchCustomerChange(e) {
    let data = e.target.value
    let filters = {...this.state.filters}
    let searchArray = [...filters.searchArray]
    let a = {
      key: 'username',
      value: data
    }

    let searchIndex = filters.searchIndex
    let position = searchIndex.indexOf('username')
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push('username')
    }

    filters['username'] = data
    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex

    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  render() {
    let {data, from, to, total, prevPageUrl, nextPageUrl, lastPage, currentPage, filters} = this.state

    let sn = 0;

    let tablebody = data.map((single) => {
      let {id, action, created, field_name, group_id, new_value, old_value, table_name, table_pk, table_pk_value, username} = single
      sn++;
      return (
        <tr key={id}>
          <td>{sn}</td>
          <td>{id}</td>
          <td>{group_id}</td>
          <td>{username}</td>
          <td>{table_name}</td>
          <td>{table_pk}</td>
          <td>{table_pk_value}</td>
          <td>{old_value}</td>
          <td>{new_value}</td>
          <td>{field_name}</td>
          <td>{action}</td>
          <td>{created}</td>
        </tr>
      );
    })

    return (
      <div className="animated fadeIn">

        <SearchCard
          searchPlaceholder="Search by User Name"
          searchValue={filters.username}
          searchChange={(e) => this.searchCustomerChange(e)}
          startDate={filters.startDate}
          endDate={filters.endDate}
          focusedInput={this.state.focusedInput}
          onDateChange={this.onDateChange.bind(this)}
          isDate={true}
          onFocusChange={this.onFocusChange.bind(this)}
          isDownload={false}
        />

        <Card>
          <CardBody>
            <Row>
              <Col md="6" className="text-center">
                <div className="dataTables_wrapper form-inline">
                  <label>
                    Show &nbsp;
                    <Input type="select" onChange={(e) => this.limitChange(e)}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </Input> &nbsp;
                    entries
                  </label>
                </div>
              </Col>
              <Col md="6" className="text-center">
                <DownloadGroup
                  onCSV={() => this.onDownloadClick("csv")}
                  onXML={() => this.onDownloadClick("xml")}
                  onPDF={() => this.onDownloadClick("pdf")}
                  onTXT={() => this.onDownloadClick("txt")}
                  onXLS={() => this.onDownloadClick("xls")}
                />
              </Col>
            </Row>
            <br/>
            <Table bordered responsive>
              <tbody>
              <tr>
                <td width="50" rowSpan="2">S.N</td>
                <td width="80" rowSpan="2">Id</td>
                <td width="50" rowSpan="2">Group Id</td>
                <td width="120" rowSpan="2">Username</td>
                <td colSpan="3" className="text-center">Table</td>
                <td colSpan="2" className="text-center">Value</td>
                <td width="90" rowSpan="2">Field Name</td>
                <td width="90" rowSpan="2">Action</td>
                <td width="90" rowSpan="2">Created At</td>
              </tr>
              <tr>
                <td width="80">Name</td>
                <td width="80">Pk</td>
                <td width="80">Pk Value</td>
                <td width="120">Old</td>
                <td width="120">New</td>
              </tr>
              </tbody>
              <tbody>
              {tablebody}
              </tbody>
            </Table>
            <TablePagination
              to={to}
              from={from}
              total={total}
              prevPageUrl={prevPageUrl}
              nextPageUrl={nextPageUrl}
              currentPage={currentPage}
              paginationButton={this.paginationButton.bind(this)}
              lastPage={lastPage}
              name="activity log"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {getActivityLog: state.getActivityLog}
}

export default connect(mapStateToProps)(ActivityLog)
