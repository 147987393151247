export default function getProductFamily(
  state = { error: null, success: null},
  action
) {
  switch (action.type) {

    case 'FETCH_PRODUCT_FAMILY_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'FETCH_PRODUCT_FAMILY_ERROR':
      return {...state, ...{error: action.error}};
    default:
      return state;
  }
}
