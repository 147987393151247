import React, {Component} from 'react';
import {connect} from "react-redux";
import store from '../../Store'
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import blank from '../../assets/img/blank.png'
import TextField from "../../shared/form-helpers/TextField";
import {changePassword, changeResetPassword} from "../../actions/password/change_password";
import {getCountries} from "../../apis/countries";
import {getCurrencies} from "../../apis/currencies";
import {uploadImage, uploadResetImage} from "../../apis/users";
import {getLoggedInUser} from "../../actions/login/get_logged_in_user";
import {addMessage} from "../../actions/messages/success_message";

class UserSettings extends Component {
  state = {
    restData: null,
    data: null,
    new: true,
    countries: [],
    restError: {},
    restSuccess: null,
    error: {},
    message: '',
    provinces: [],
    currencies: [],
    formData: {},
    passwordSuccess: null,
    passwordError: null,
    type: 'success',
    uploadImageSuccess: null,
    uploadImageError: null
  }

  componentDidMount() {
    if (!this.props.getCountries.success)
      getCountries()

    if (!this.props.getCurrencies.success)
      getCurrencies()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoggedInUser, getCountries, getCurrencies, changePassword, uploadImage} = nextProps
    let {restData, countries, restError, restSuccess, provinces, currencies, passwordSuccess, passwordError, uploadImageSuccess,uploadImageError} = prevState
    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        restData = success.data
      }
    }

    if (uploadImage) {
      let {success, validation} = uploadImage
      if (success)
        uploadImageSuccess = true
      else if (validation)
        uploadImageError = validation.images
      else {
        uploadImageSuccess = null
        uploadImageError = null
      }
    }

    if (changePassword) {
      let {success, error} = changePassword
      if (success) {
        restSuccess = success.data.message

      } else if (error) {
        if (error.status === 422) {
          passwordError = error.data.message
        }
        if (error.status === 400) {
          restError = error.data.message
        }
      } else {
        restSuccess = null
        restError = null
        passwordError = null
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      } else if (error) {
        console.log(error)
      }
    }

    if (getCurrencies) {
      let {success, error} = getCurrencies
      if (success) {
        currencies = success.data.data
      } else if (error) {
        console.log(error)
      }
    }

    return {
      restData,
      countries,
      restError,
      restSuccess,
      provinces,
      currencies,
      passwordSuccess,
      passwordError,
      uploadImageSuccess,
      uploadImageError
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restData !== null && (this.state.data === null || this.state.uploadImageSuccess !== prevState.uploadImageSuccess)) {
      this.setState({
        data: this.state.restData
      })
    }

    if (this.state.uploadImageSuccess !== null) {
      store.dispatch(addMessage("Image updated successfully.", "success", "Update User"));
      uploadResetImage()
      store.dispatch(getLoggedInUser())
    }


    if (this.state.passwordError !== null) {
      this.setState({
        error: this.state.passwordError
      }, () => {
        store.dispatch(changeResetPassword())
      })
    }

    if (this.state.restError !== null) {
      store.dispatch(addMessage(this.state.restError, "error", "Change Password"));
      store.dispatch(changeResetPassword())
    }

    if (this.state.restSuccess !== null) {
      if (this.state.restSuccess) {
        store.dispatch(addMessage(this.state.restSuccess, "success", "Change Password"));
        store.dispatch(changeResetPassword())
      }
    }

    if (this.state.uploadImageError !== null){
      this.state.uploadImageError.forEach((single)=>{
        store.dispatch(addMessage(single, "error", "Upload Image"));
      })
      uploadResetImage()
    }
  }

  imageChange(e) {
    let data = {...this.state.data}
    if (e.target.files[0]) {
      data['logo'] = e.target.files[0]
      this.setState({
        new: false,
        data
      }, () => {
        const formData = new FormData();
        formData.append("images", data.logo)
        uploadImage(this.state.data['id'], formData)
      })
    }
  }

  onUpdate() {
    store.dispatch(changePassword(this.state.data['id'], this.state.formData))
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let error = {...this.state.error};
    formData[name] = value;
    error[name] = "";
    this.setState({formData, error});
  }

  onDismiss() {
    this.setState({
      message: ''
    });
  }

  render() {

    let {data, error, formData} = this.state
    console.log(data && data.image)
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="3">
                <span className="float-right span-label">
                  Profile Image
                </span>
              </Col>
              <Col sm="2">
                <div className="custom-image-container">
                  <div className="custom-image-inner-container">
                    {this.state.new ? data && data.image ? <img
                        className="custom-image"
                        src={data.image + '?date=' + Date()} alt="logo"/> : <img
                        className="custom-image"
                        src={blank} alt="logo"/> :
                      data && data.image &&
                      <img
                        className="custom-image"
                        src={window.URL.createObjectURL(data.logo)}
                        alt="logo"
                      />}
                  </div>
                  <div className="topLayerBtnContainer">
                    <div className="upload-btn-wrapper">
                      <button className="custom-img-up-btn">Change</button>
                      <input type="file" accept="image/x-png,image/gif,image/jpeg"
                             onChange={(e) => this.imageChange(e)}/>
                    </div>
                  </div>
                </div>
                {/*<input className="file-upload" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.imageChange(e)}/>*/}
              </Col>
              <Col sm="7">
                <div className="image-info">
                  <p className="image-text">You can upload/change company logo here</p>
                  <p className="image-text">Required file types: .GIF, .PNG, or .JPG</p>
                </div>
              </Col>

            </Row>
            <Row className="custom-row">
              <Col sm="3">
                <span className="float-right span-label">
                  Change Password
                </span>
              </Col>
              <Col sm="9">
                <Row>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField type="password" name="current_password" value={formData.current_password}
                                 onChange={(e) => this.onChange(e)}
                                 error={error.current_password} isRequired={true}/>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField type="password" name="password" value={formData.password}
                                 onChange={(e) => this.onChange(e)}
                                 error={error.password} isRequired={true}/>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="password_confirmation" value={formData.password_confirmation}
                                 onChange={(e) => this.onChange(e)}
                                 type="password" error={error.password_confirmation} isRequired={true}/>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="float-right" color="success" outline
                        onClick={this.onUpdate.bind(this)}>Update</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getLoggedInUser, getCountries, updateRootCustomer, getCurrencies, changePassword, uploadImage} = state
  return {
    getLoggedInUser, getCountries, updateRootCustomer, getCurrencies, changePassword, uploadImage
  }
}

export default connect(mapStateToProps)(UserSettings);
