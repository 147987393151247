import React from 'react';
import {Button, ButtonGroup, Card, CardBody, Col, Form, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import ViewData from "../../shared/form-helpers/ViewData";

const PaymentGatewayModal = (props) => {

  let {
    toggle,
    data,
    error,
    onChange,
    onSave,
    onReset,
    gatewayOptions,
    isView,
    keys,
    isNew,
    isSave,
    isEdit
  } = props

  let viewKeys = isView ? Object.keys(isView) : []

  let displayKeys = keys.required_field && keys.required_field.map((field) => {
    let {name, display_name} = field
    return (
      <HorizontalTextField
        name={name}
        error={error[name]}
        onChange={onChange}
        value={data[name]}
        displayName={display_name}
        isRequired={true}
      />
    );
  })

  let editKeys = viewKeys.map((key) => {
    if (key === "type" || key === "id" || key === "created_at" || key === "updated_at") {
      return null;
    } else
      return (
        <Row>
          <Col>
            <HorizontalTextField
              name={key}
              error={error[key]}
              onChange={onChange}
              value={data[key]}
              isRequired={true}
            />
          </Col>
        </Row>
      );
  })

  let displayViewKeys = viewKeys.map((key) => {
    if (key === "type" || key === "id" || key === "created_at" || key === "updated_at") {
      return null;
    } else
      return (
        <Row>
          <Col>
            <ViewData
              label={key}
              data={isView[key]}
            />
          </Col>
        </Row>
      );
  })

  if (isEdit){
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="2"></Col>
              <Col sm="12" md="8">
                {editKeys}
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                  <Button outline color="success" onClick={onSave}>Update</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
  else if (isView) {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="2"></Col>
              <Col sm="12" md="6">
                {displayViewKeys}
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  <Button outline color="success" onClick={toggle}>Okay</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col sm="2"/>
              <Col sm="6">
                { gatewayOptions && gatewayOptions.length > 0 ?
                  <Form>
                    <HorizontalSelect
                      name="name"
                      options={gatewayOptions}
                      error={null}
                      onChange={onChange}
                      value={data.name}
                    />
                    {!isNew && !isSave && keys && <span className="payment-span">{keys.description}</span>}
                    {!isNew && !isSave && displayKeys}
                  </Form>
                  :
                  <span>Every config has been configured.</span>
                }
              </Col>
              <Col sm="2">
              </Col>
            </Row>
            <Row>
              <Col>
                {!isNew ? !isSave ? !!keys.required_field ?
                  <ButtonGroup className="float-right">
                    <Button outline color="danger" onClick={toggle}>Cancel</Button>
                    <Button outline color="primary" onClick={onReset}>Reset</Button>
                    <Button outline color="success" onClick={onSave}>Save</Button>
                  </ButtonGroup> : <ButtonGroup className="float-right">
                    <Button outline color="danger" onClick={toggle}>Cancel</Button>
                  </ButtonGroup> : <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                </ButtonGroup> : <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={toggle}>Cancel</Button>
                </ButtonGroup>}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PaymentGatewayModal;
