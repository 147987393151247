export function updateResetPaymentTerm() {
  return dispatch => {
    dispatch({
      type: "UPDATE_RESET_PAYMENT_TERM"
    });
  };
}

export function newResetPaymentTerm() {
  return dispatch => {
    dispatch({
      type: "CREATE_RESET_PAYMENT_TERM"
    });
  };
}

export function resetData(reducer) {
  return dispatch => {
    dispatch({
      type: reducer
    });
  };
}
