import React, {Component} from 'react';
import {connect} from "react-redux";
import TaxModal from "../UserSettings/TaxModal";
import {
  createTax,
  deleteTax,
  getTax,
  getTaxes,
  resetCreateTax,
  resetDeleteTax,
  resetUpdateTax,
  updateTax
} from "../../apis/taxes";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {localValidation} from "../../shared/helpers/ValidationHelper";

class Tax extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    message: {
      type: '',
      message: ''
    },
    formData: {
      status: 1
    },
    formError: {},
    restData: [],
    data: [],
    error: [],
    keys: [],
    updateSuccess: null,
    createSuccess: null,
    deleteSuccess: null,
    createError: null,
    updateError: null,
    openModal: false,
    newModal: true,
    id: null,
    deleteModal: false,
    itemToDelete: '',
    restUpdate: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getTaxes(limit, page, searchArray)
  }

  componentDidMount() {
    this.fetchData()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getTaxes,
      updateTax,
      createTax,
      getTax,
      deleteTax
    } = nextProps
    let {
      restData,
      updateSuccess,
      updateError,
      createSuccess,
      createError,
      restUpdate,
      deleteSuccess,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState

    if (getTaxes) {
      let {success} = getTaxes
      if (success) {
        restData = success.data.data || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (getTax) {
      let {success, error} = getTax
      if (success) {
        restUpdate = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (updateTax) {
      let {success, validation} = updateTax
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null
        updateError = null
      }
    }

    if (createTax) {
      let {success, validation} = createTax
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (deleteTax) {
      let {success, error} = deleteTax
      if (success) {
        deleteSuccess = success.data.message
      } else if (error) {
        console.log(error)
      } else {
        deleteSuccess = null
      }
    }

    return {
      restData, updateSuccess, createSuccess, createError, restUpdate, updateError, deleteSuccess,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      updateSuccess,
      createSuccess,
      createError,
      restUpdate,
      updateError,
      deleteSuccess
    } = this.state

    if (deleteSuccess !== null) {
      store.dispatch(addMessage(deleteSuccess, "success", "Delete Tax"));
      this.fetchData()
      resetDeleteTax()
    }

    if (restUpdate !== prevState.restUpdate) {
      this.setState({
        formData: restUpdate
      })
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Create Payment Term"));
      this.fetchData();
      resetUpdateTax()
      this.setState({
        formData: {
          status: 1,
          name: '',
          rate: '',
          description: ''
        },
        openModal: false,
      })
    }

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Payment Term"));
      this.fetchData()
      resetCreateTax()
      this.setState({
        formData: {
          status: 1,
          name: '',
          rate: '',
          description: ''
        },
        openModal: false,
      })
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        resetCreateTax()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        resetUpdateTax()
      })
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  onUpdate() {
    let validationRule = {
      name: ['required'],
      rate: ['required', 'decimal']
    }

    let localValidationStatus = false
    let check = localValidation(this.state.formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      let {formData, id} = this.state
      updateTax(id, formData)
    }
  }

  onCreate() {
    let validationRule = {
      name: ['required'],
      rate: ['required', 'decimal']
    }

    let localValidationStatus = false
    let check = localValidation(this.state.formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      createTax(this.state.formData)
    }
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  onNewClick() {
    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: {
        status: 1,
      },
      formError: {}
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  onReset() {
    if (this.state.id) {
      this.setState({
        formData: this.state.restUpdate
      })
    } else {
      this.setState({
        formData: {
          status: 1,
          name: '',
          rate: '',
          description: ''
        }
      })
    }
  }

  onUpdateClick(id) {
    getTax(id)
    this.setState({
      id: id,
      openModal: true,
      newModal: false,
      formError: {}
    })
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deleteTax(this.state.itemToDelete)
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      restData, openModal, newModal, formData, formError, prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
    } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Rate',
        dataIndex: 'rate'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '30%',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Disabled'
          }
        ]
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, name, rate, status, description
      } = single

      tableData.push({
        id, name, rate, status, description
      })
    })

    if (openModal) {
      return (
        <TaxModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={newModal ? "Add New TAX" : "Edit TAX"}
          data={formData}
          error={formError}
          onChange={this.onChange.bind(this)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
        />
      );
    }

    return (
      <>
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          filters={this.filters}
          newTitle="Create Tax"
          newAction={this.onNewClick.bind(this)}
          tabTable={true}
        />
        {/*<DeleteModal*/}
        {/*  open={deleteModal}*/}
        {/*  toggle={this.onDelete.bind(this)}*/}
        {/*  deleteItem={this.onDeleteConfirm.bind(this)}*/}
        {/*  title="Delete TAX"*/}
        {/*/>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {getTaxes, updateTax, createTax, getTax, deleteTax} = state
  return {
    getTaxes, updateTax, createTax, getTax, deleteTax
  }
}

export default connect(mapStateToProps)(Tax);
