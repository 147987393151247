import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {deleteInventory, getInventoryHistory} from "../../apis/inventory";
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import SearchCard from "../../shared/dashboard-helpers/SearchCard";
import DownloadModal from "../../shared/dashboard-helpers/DownloadModal";
import {Button, ButtonGroup, Card, CardBody} from "reactstrap";
import {DatePicker} from 'antd';
import SelectSearch from "../../shared/react-select-search/src/SelectSearch";
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import {getProducts} from "../../apis/products";

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class InventoryHistory extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    customer_name: '',
    status: {
      all: true,
      active: false,
      inactive: false
    },
    startDate: null,
    endDate: null,
    dateFilter: {
      start_date: '',
      end_date: ''
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    product_id:"",
    data: [],
    showPrintModal: false,
    deleteSuccess: null,
    extra_params: {},
    dateFilter: {
      from: '',
      to: ''
    },
    products: []
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters, extra_params} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getInventoryHistory(limit, page, searchArray, extra_params)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getInventoryHistory,
      deleteInventory,
      getProducts
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      deleteSuccess,
      products
    } = prevState;

    if (getInventoryHistory) {
      let {success, error} = getInventoryHistory;
      if (success) {
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    if (getProducts) {
      let {success, error} = getProducts;
      if (success) {
        if (success.data)
          products = success.data.data || [];
      } else if (error) {
      }
    }

    if (deleteInventory) {
      let {success, error} = deleteInventory;
      if (success) {
        deleteSuccess = success.data || [];
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      deleteSuccess,
      products
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.deleteInventory && this.props.deleteInventory !== prevProps.deleteInventory) {
      let {success, error} = this.props.deleteInventory
      if (success && success.data) {
        this.fetchData()
        store.dispatch(addMessage(success.data.message, "success", "Delete Inventory"))
      }
    }
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  onDateChange(date, dateString) {
    let extra_params = {...this.state.extra_params}
    let dateFilter = {...this.state.dateFilter}
    extra_params['from'] = dateString[0]
    extra_params['to'] = dateString[1]
    dateFilter = {
      from: date[0],
      to: date[1]
    }

    this.setState({
      extra_params, dateFilter
    }, () => {
      this.fetchData()
    })
  }

  onFocusChange(focusedInput) {
    this.setState({focusedInput})
  }

  onProductChange(e) {
    if (e.name) {
      let extra_params = {...this.state.extra_params}
      extra_params['product_id'] = e.value
      this.setState({
        extra_params
      }, () => this.fetchData())
    }else{
      let extra_params = {...this.state.extra_params}
      delete extra_params['product_id']
      this.setState({
        extra_params
      }, () => this.fetchData())
    }
  }

  onProductFetch(e) {
    let filters = [
      {
        key: 'name',
        value: e
      },
      {
        key: 'status',
        value: 1
      }
    ]
    getProducts(20, 1, filters)
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      filters,
      products,
      extra_params,
      dateFilter
    } = this.state

    const columns = [
      {
        title: 'ID',
        dataIndex: 'product_id'
      },
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Stock Quantity',
        dataIndex: 'stock',
      },
      {
        title: 'Added Date',
        dataIndex: 'stock_date'
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        product_id,
        name,
        stock,
        stock_date
      } = single

      tableData.push({
        product_id,
        name,
        stock,
        stock_date
      })

    })

    let productOption = [];

    productOption.push({
      name: "",
      value: "",
      address: "",
      pan: "",
      phone: ""
    })

    products && products.forEach((product) => {
      productOption.push({
        name: product.name,
        value: product.id,
      })
    })

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="search">
                  <SelectSearch
                    options={productOption}
                    value={extra_params.product_id}
                    name="product_id"
                    onChange={(e) => this.onProductChange(e)}
                    placeholder="Search product"
                    onOptionsRefresh={this.onProductFetch.bind(this)}
                    internalSearch={true}
                  />
                  <span className="text-muted search-icon"><i className="fa fa-search"></i></span>
                  {extra_params.product_id &&
                  <span className="text-muted delete-icon" onClick={() => this.onProductChange({})}><i className="fa fa-times-circle"></i></span>
                  }
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <RangePicker
                  format={dateFormat}
                  onChange={(date,dateString)=>{this.onDateChange(date, dateString)}}
                  value={[dateFilter.from,dateFilter.to]}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={{}}
          filters={this.filters}
          confirmSearch={() => {}}
          newTitle="Create Inventory"
          newAction={() => {}}
          url=""
          hideNewCard={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getInventoryHistory,
    deleteInventory,
    getProducts
  } = state;
  return {
    getInventoryHistory,
    deleteInventory,
    getProducts
  };
}

export default connect(mapStateToProps)(InventoryHistory)
