import React from 'react';
import {Col, Row} from "reactstrap";

const PaginationHelper = (props) => {
  let {to, from, total, prevPageUrl, nextPageUrl, currentPage, paginationButton, lastPage, name, hideInfo} = props;

  // let paginationInfo = to - from + 1 <= total
  //   &&
  //   <span>
  //     Showing {from} to {to} of {total} {name}
  //   </span>;

  let pages = [];
  if (from) {
    let pageFront = currentPage;
    let pageLast = lastPage;
    let end = pageFront + 2;
    let start = pageFront - 2;
    if (pageLast - pageFront === 0) start -= 2;
    else if (pageLast - pageFront === 1) start -= 1;
    for (let i = start; i <= end; i++) {
      if (i < 1) {
        end++;
        continue;
      }
      if (i > pageLast) break;
      pages.push(i);
    }
  }


  let pagination = pages.map((page) => (
    <li title={page}
        className={`ant-pagination-item ant-pagination-item-${page} ${page === currentPage ? 'ant-pagination-item-active' : ''}`}
        tabIndex="0"
        onClick={() => {
          paginationButton(page)
        }}><a>{page}</a></li>
  ));

  return (
    <>
      <br/>
      <Row>
        <Col className="text-center">
          <ul className="ant-pagination mini" unselectable="unselectable">
            <li title="Previous Page" className={`ant-pagination-prev ${prevPageUrl ? '' : 'ant-pagination-disabled'}`}
                aria-disabled="false" tabIndex="0" onClick={() => paginationButton(currentPage - 1)}>
              <a className="ant-pagination-item-link">
                <i aria-label="icon: left" className="anticon anticon-left">
                  <svg viewBox="64 64 896 896" className="" data-icon="left" width="1em" height="1em"
                       fill="currentColor"
                       aria-hidden="true" focusable="false">
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z">
                    </path>
                  </svg>
                </i>
              </a>
            </li>
            {pagination}
            <li title="Next Page" className={`ant-pagination-next ${nextPageUrl ? '' : 'ant-pagination-disabled'}`}
                aria-disabled="false" tabIndex="0" onClick={() => paginationButton(currentPage + 1)}>
              <a className="ant-pagination-item-link">
                <i aria-label="icon: right" className="anticon anticon-right">
                  <svg viewBox="64 64 896 896" className="" data-icon="right" width="1em" height="1em"
                       fill="currentColor"
                       aria-hidden="true" focusable="false">
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z">
                    </path>
                  </svg>
                </i>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

export default PaginationHelper;
