import React from 'react';
import {Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

const MessageModal = (props) => {
  let {
    message,
    isOpen,
    toggle,
    title,
    onConfirm
  } = props

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} className={'modal-lg ' + props.className}>
      {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
      <ModalBody>
        {message}
      </ModalBody>
      {title && <ModalFooter>
        <Row>
          <Col>
            <ButtonGroup className="float-right">
              <Button outline color='danger' onClick={toggle}>Cancel</Button>
              <Button outline color='success' onClick={onConfirm}>Confirm</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ModalFooter>}
    </Modal>
  );
}

export default MessageModal
