export default function downloadCreditNotes
  (state = {error: null, success: null, processing: ''}, action) {

  switch (action.type) {

    case 'DOWNLOAD_CREDIT_NOTES_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DOWNLOAD_CREDIT_NOTES_ERROR':
      return {...state, ...{error: action.error}};
    case 'DOWNLOAD_CREDIT_NOTES_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
