import React from 'react'
import {connect} from "react-redux";
import TablePagination from "../../shared/table-helpers/TablePagination";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import {getTransactions} from "../../apis/transactions";

class CustomerTransactions extends React.Component {

  state = {
    limit: "10",
    data: [],
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    searchArray: []
  }


  componentDidMount() {
    let a = {
      key: 'customer_code',
      value: this.props.match.params.id
    }
    let searchArray = [...this.state.searchArray]
    searchArray.push(a)
    this.setState({
      searchArray
    },()=>{
      this.fetchData()
    })
  }

  fetchData() {
    let {limit, page,searchArray} = this.state
    getTransactions(limit, page,searchArray)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getTransactions} = nextProps
    let {data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage, firstPage} = prevState;
    if (getTransactions) {
      let {success, error} = getTransactions
      if (success) {
        data = success.data.data;
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
      else if (error){
        console.log(error)
      }
    }
    return {
      data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage, firstPage
    }
  }


  paginationButton(page) {
    this.setState({page}, () => {
      this.fetchData()
    });
  }

  limitChange(e) {
    this.setState({
      limit: e.target.value,
      page: 1
    }, () => {
      this.fetchData();
    });
  }

  onView(id){
    this.props.history.push(`/sales/customers/${this.props.match.params.id}/transactions/${id}`)
  }

  render() {
    let {data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage} = this.state;

    let name = "";
    let sn=0;

    let tableBody = data && data.map((transaction)=>{
      let {id,customer,transaction_id,type,recharged_type,product,amount,created_at} = transaction
      name = customer.last_name + ' ' +customer.first_name
      sn++
      return (
        <tr key={id}>
          <td>{sn}</td>
          <td><span className="span-link" onClick={()=>this.onView(id)}>{transaction_id}</span></td>
          <td>{created_at}</td>
          <td>{product}</td>
          <td>{amount}</td>
          <td>{type}</td>
          <td>{recharged_type}</td>
        </tr>
      );
    })

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg="12">
            <Card>
              <CardBody>
                <Row><Col className="text-center">
                  <span className="report-page-title">{`Transaction history of ${name}`}</span>
                  <span className="confirmation-text">{`(${this.props.match.params.id})`}</span>
                </Col></Row>
                <hr/>
                <Table responsive hover>
                  <thead>
                  <tr>
                    <th width="10">S.N</th>
                    <th width="240">Transaction Id</th>
                    <th width="100">Date</th>
                    <th width="100">Product</th>
                    <th width="40">Amount</th>
                    <th width="100">Type</th>
                    <th width="80">Channel</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tableBody}
                  </tbody>
                </Table>
                <TablePagination
                  to={to}
                  from={from}
                  total={total}
                  prevPageUrl={prevPageUrl}
                  nextPageUrl={nextPageUrl}
                  currentPage={currentPage}
                  paginationButton={this.paginationButton.bind(this)}
                  lastPage={lastPage}
                  name="transactions"
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getTransactions} = state;
  return {
    getTransactions
  }
}

export default connect(mapStateToProps)(CustomerTransactions)
