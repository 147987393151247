import React from 'react';
import {
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap';
import { stringUpperCase } from '../helpers/GeneralHelpers';

const TextField = (props) => {
  let {type,error,onChange,value,name,isRequired} = props;
  let label = stringUpperCase(name);
  return(
    <FormGroup>
      <Label className="custom-label">{label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      <Input type={type} invalid={!!error} name={name} value={value} onChange={onChange}/>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default TextField;
