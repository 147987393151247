import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "product-families"

export const createProductFamily = (data) => {
  let reducers = {
    success: 'CREATE_PRODUCT_FAMILY_SUCCESS',
    validation_error: 'CREATE_PRODUCT_FAMILY_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteProductFamily = (id) => {
  let reducers = {
    success: 'DELETE_PRODUCT_FAMILY_SUCCESS',
    error: 'DELETE_PRODUCT_FAMILY_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getProductFamily = (id) => {
  let reducers = {
    success: 'FETCH_PRODUCT_FAMILY_SUCCESS',
    error: 'FETCH_PRODUCT_FAMILY_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getProductFamilies = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_PRODUCTS_FAMILIES_SUCCESS',
    error: 'FETCH_PRODUCTS_FAMILIES_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const updateProductFamily = (id, data) => {
  let reducers = {
    success: 'UPDATE_PRODUCT_FAMILY_SUCCESS',
    validation_error: 'UPDATE_PRODUCT_FAMILY_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const updateResetProductFamily = () => {
  store.dispatch(resetData('UPDATE_RESET_PRODUCT_FAMILY'))
}

export const createResetProductFamily = () => {
  store.dispatch(resetData('CREATE_RESET_PRODUCT_FAMILY'))
}

export const deleteResetProductFamily = () => {
  store.dispatch(resetData('DELETE_RESET_PRODUCT_FAMILY'))
}
