import React from 'react';
import {Col, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";

const TablePagination = (props) => {
  let {to, from, total, prevPageUrl, nextPageUrl, currentPage, paginationButton, lastPage, name, hideInfo} = props;

  let paginationInfo = to - from + 1 <= total
    &&
    <span>
      Showing {from} to {to} of {total} {name}
    </span>;

  let pages = [];
  if (from) {
    let pageFront = currentPage;
    let pageLast = lastPage;
    let end = pageFront + 2;
    let start = pageFront - 2;
    if (pageLast - pageFront === 0) start -= 2;
    else if (pageLast - pageFront === 1) start -= 1;
    for (let i = start; i <= end; i++) {
      if (i < 1) {
        end++;
        continue;
      }
      if (i > pageLast) break;
      pages.push(i);
    }
  }


  let pagination = pages.map((page) => (
    <PaginationItem active={page === currentPage} key={page}>
      <PaginationLink tag="button" onClick={() => {
        paginationButton(page)
      }}>{page}</PaginationLink>
    </PaginationItem>
  ));

  return (
    <>
      <hr/>
      <Row className="small-font">
        <Col sm="6">
          {!hideInfo && paginationInfo}
        </Col>
        <Col sm="6">
          <Pagination className="float-right">
            {
              prevPageUrl
              &&
              <PaginationItem>
                <PaginationLink previous tag="button" onClick={(e) => paginationButton(currentPage - 1)}/>
              </PaginationItem>
            }
            {pagination}
            {
              nextPageUrl
              &&
              <PaginationItem>
                <PaginationLink next tag="button" onClick={(e) => paginationButton(currentPage + 1)}/>
              </PaginationItem>
            }
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default TablePagination;
