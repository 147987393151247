export default function getProformaInvoice(
  state = { error: null, success: null},
  action
) {
  switch (action.type) {

    case 'FETCH_PROFORMA_INVOICE_SUCCESS':
      return {...state, ...{success: action.success,error: null}};
    case 'FETCH_RESET_PROFORMA_INVOICE':
      return {...state, ...{success: null,error: null}};
    case 'FETCH_PROFORMA_INVOICE_ERROR':
      return {...state, ...{error: action.error,success: null}};
    default:
      return state;
  }
}
