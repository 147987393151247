/* Get the login Token */

import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import moment from 'moment'
import {useEffect, useRef} from "react";

export const loginToken = () => {
  return localStorage.getItem("ERP_Bearer");
};

export const refreshToken = () => {
  return localStorage.getItem("ERP_rt");
};

/* Find out if anyone is logged in | @return boolean */
export const loggedIn = () => {
  return !!localStorage.getItem("ERP_Bearer");
};

export const setCurrentPath = () => {
  localStorage.setItem("ERP_Path", window.location.pathname)
}

export const getCurrentPath = () => {
  return localStorage.getItem("ERP_Path")
}

export const setUserToLocalStorage = data => {
  localStorage.setItem("ERP_Bearer", data.access_token);
  localStorage.setItem("ERP_refresh_token", data.refresh_token);
  localStorage.setItem("ERP_expires_in", data.expires_in);
  localStorage.setItem("ERP_token_type", data.token_type);
};

export const logOutUser = () => {
  localStorage.clear()
  window.location.href = `/`;
};

export const objectIsEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
};

export const isValidEmail = string => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
};

export const hasOnlyDigits = string => {
  return /^\d+$/.test(string);
};

export const isInvalidMobileNumber = number => {
  return number < 1111111111 || number > 9999999999;
};

export const isInvalidPhoneNumber = number => {
  return number < 11111 || number > 9999999999;
};

export const alternativeNumber = string => {
  return /^\+?([0-9]{5,})$/.test(string);
};

export const ucFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export function ucFirstWords(str) {
  var frags = str.split('_');
  for (let i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const containsOnlyLetter = string => {
  return /[a-zA-Z]+$/.test(string);
};

export const containsNumbers = string => {
  return /\d/.test(string);
};

export const enoughPassword = string => {
  let enoughRegex = new RegExp("(?=.{6,}).*", "g");
  return enoughRegex.test(string);
};

export const mediumPassword = string => {
  let mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  return mediumRegex.test(string);
};

export const strongPassword = string => {
  let strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return strongRegex.test(string);
};

export const passwordStrengthClass = string => {
  if (!string) return ``;
  else if (strongPassword(string)) {
    return `strong`;
  } else if (mediumPassword(string)) {
    return `medium`;
  } else return `weak`;
};

export const dateDifference = year => {
  let currentDateYear = new Date().getFullYear();
  return currentDateYear - year >= 16;
};

export const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const if_ = (condition, result) => {
  if (condition) return result;
};

export const generateServerValidationErrors = error => {
  let status = error && error.response && error.response.status;
  if (status === 422) {
    let {message} = error.response.data;
    if (message) return message;
  } else return false;
};

export const currentPage = document.URL.split(`/`)[3];

export const isAnActionItem = item => {
  return (
    is("view") ||
    is("delete") ||
    is("create") ||
    is("import") ||
    is("update") ||
    is("update") ||
    is(`upload`)
  );

  function is(_item) {
    return item === _item;
  }
};

export const unslugify = (text, symbol) => {
  if (text) {
    let string = ``;
    let part = text.split(symbol);
    if (!part.length) return part;
    if (part.length)
      part.forEach(item => {
        string += ucFirst(item) + " ";
      });
    return string.trim();
  }
};

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

const stringBreak = (word) => {
  let wordBreak = [];
  for (let i = 0; i < word.length; i++) {
    if (word.charAt(i) === "_")
      wordBreak.push(i)
  }
  let newWord = word;
  for (let i = 0; i < wordBreak.length; i++) {
    newWord = setCharAt(newWord, wordBreak[i], ' ');
  }
  return newWord;
}


export const stringUpperCase = (word) => {
  if (word.includes("_id")){
    word = word.split("_id")[0]
  }
  let str = stringBreak(word);
  str = str.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function ($1) {
      return $1.toUpperCase();
    });
}

export const isNumber = (number) => {
  if (/^\d+$/.test(number))
    return true
  else if (number !== "")
    store.dispatch(addMessage("Only numeric values are allowed", "error","Not Allowed"))
}

export const isDecimal = (decimal) => {
  if (!isNaN(decimal))
    return true
  else if (decimal !== "")
    store.dispatch(addMessage("Only decimal values are allowed", "error","Not Allowed"))
}

export const isPercentage = (rate) => {
  if (parseFloat(rate) > 100) {
    store.dispatch(addMessage("Maximum allowed rate is 100.", "error","Not Allowed"))
  } else if (!isNaN(rate))
    return true
  else
    store.dispatch(addMessage("Must be valid percentage", "error","Not Allowed"))
}

export const fixIfNecessary = (value) => {
  let temp = value + ''
  if (temp.includes('.')) {
    let position = temp.indexOf('.')
    if (!temp.charAt(position + 1))
      return value
  }
  return +parseFloat(value).toFixed(2)
}

export const formatDate = (date) => {
  let monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export const getEquivalentNepaliMonth = (month) => {
  let months =  ['Baisakh','Jestha','Asadh','Shrawan','Bhadra','Ashwin','Kartik','Mangsir','Poush','Magh','Falgun','Chaitra']
  return months[month]
}

export const isFiscalYear = (startDate,endDate) => {
  let years = moment.duration(endDate.diff(startDate)).asYears()
  if (years < 1)
    return "Invalid date range"
  return true
}

export const getYears = (year) => {
  let years = []
  for(var i = 2073; i<= year; i++){
     years.unshift({label: `${i}`, value: `${i}`})
  }
  return years
}

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

export const checkURL = (url) => {
  try {
    return (url && url.match(/^(http|https):\/\//) != null);
  }catch (e) {
    return false
  }
}
