import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "vendors"

export const getVendors = (limit, page = 1, filters = [],dateFilter = {}) => {
  let reducers = {
    success: 'FETCH_VENDORS_SUCCESS',
    error: 'FETCH_VENDORS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  getGroup.getGroup()
}

export const downloadVendors = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_VENDORS_SUCCESS',
    error: 'DOWNLOAD_VENDORS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const getVendor = (id) => {
  let reducers = {
    success: 'FETCH_VENDOR_SUCCESS',
    error: 'FETCH_VENDOR_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createVendor = (data) => {
  let reducers = {
    success: 'CREATE_VENDOR_SUCCESS',
    validation_error: 'CREATE_VENDOR_VALIDATION_ERROR',
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteVendor = (id) => {
  let reducers = {
    success: 'DELETE_VENDOR_SUCCESS',
    error: 'DELETE_VENDOR_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const updateVendor = (id, data) => {
  let reducers = {
    success: 'UPDATE_VENDOR_SUCCESS',
    validation_error: 'UPDATE_VENDOR_VALIDATION_ERROR',
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const createResetVendor = () => {
  store.dispatch(resetData('CREATE_RESET_VENDOR'))
}

export const deleteResetVendor = () => {
  store.dispatch(resetData('DELETE_RESET_VENDOR'))
}

export const updateResetVendor = () => {
  store.dispatch(resetData('UPDATE_RESET_VENDOR'))
}
