import React, {Component} from 'react';
import {Badge, Button, Col, FormFeedback, FormGroup, Input, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import {DatePicker} from 'antd';
import moment from 'moment';
import {createFiscalYear, getFiscalYears, resetCreateFiscalYear} from "../../apis/fiscal-years";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {isFiscalYear} from "../../shared/helpers/GeneralHelpers";

const {RangePicker} = DatePicker;
const dateFormat = 'DD/MM/YYYY';

class FiscalYear extends Component {

  state = {
    message: {type: `success`, message: ``},
    restData: [],
    data: {},
    error: {},
    restError: {},
    createSuccess: null,
    createError: null,
    startDate: null,
    endDate: null,
    isMessage: false,
    fiscalMessage: null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getFiscalYears, createFiscalYear} = nextProps
    let {restData, restError, createSuccess, createError, fiscalMessage} = prevState
    if (getFiscalYears) {
      let {success, error} = getFiscalYears
      if (success) {
        restData = success.data || []
      } else if (error) {
        console.log(error)
      }
    }

    if (createFiscalYear) {
      let {success, validation, gateway,taken} = createFiscalYear
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else if (gateway) {
        restError = gateway
      }
      else if (taken){
        fiscalMessage = taken
      }
      else {
        createSuccess = null
        createError = null
        restError = null
        fiscalMessage = null
      }
    }

    return {
      restData, restError, createSuccess, createError, fiscalMessage
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    getFiscalYears()
  }

  componentDidUpdate(prevProps, prevState) {
    let {restError, createSuccess, createError, fiscalMessage} = this.state
    if (restError !== null) {
      this.setState({
        error: restError
      }, () => {
        resetCreateFiscalYear()
      })
    }

    if (fiscalMessage !== null){
      store.dispatch(addMessage(fiscalMessage, "error", "Create Fiscal Year"))
      resetCreateFiscalYear()
    }

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Fiscal Year"))
      resetCreateFiscalYear()
      getFiscalYears()
    }

    if (createError !== null) {
      this.setState({
        error: createError
      }, () => {
        resetCreateFiscalYear()
      })
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCreate() {
    let data = {...this.state.data}
    data['start_date'] = this.state.startDate
    data['end_date'] = this.state.endDate
    if(this.state.startDate && this.state.endDate) {
      let check = isFiscalYear(this.state.startDate,this.state.endDate)
      if (check === true) {
        let startDate = moment(this.state.startDate, 'YYYY/MM/DD')
        let endDate = moment(this.state.endDate, 'YYYY/MM/DD')
        data['start_date'] = `${startDate.format('YYYY')}-${startDate.format('MM')}-${startDate.format('DD')}`
        data['end_date'] = `${endDate.format('YYYY')}-${endDate.format('MM')}-${endDate.format('DD')}`
        createFiscalYear(data)
      } else {
        store.dispatch(addMessage(check, "error", "Create Fiscal Year"));
      }
    }
    else {
      store.dispatch(addMessage("Start Date and End Date is required.", "error", "Date range required"));
    }
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
      isMessage: false
    });
  }

  render() {
    let {restData, data, error} = this.state
    let tableBody = restData.map((single) => {
      let {start_date, end_date, name, current_fiscal_year} = single
      return (
        <tr>
          <td>
            {current_fiscal_year ?
              <Badge color="success" pill className="status-badge">Current</Badge> :
              <Badge color="warning" pill className="status-badge">Not Current</Badge>
            }
          </td>
          <td>
            {name}
          </td>
          <td>
            <RangePicker
              format={dateFormat}
              value={[moment(start_date), moment(end_date)]}
            />
          </td>
        </tr>
      )
    })
    return (
      <>
        <Row>
          <Col>
            <Table>
              <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Range</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <Badge color="primary" pill className="status-badge">New</Badge>
                </td>
                <td>
                  <FormGroup>
                    <Input invalid={!!error.name} name="name" className="form-control custom-form-input"
                           value={data.name}
                           placeholder="Name" onChange={e => this.onChange(e)}/>
                    <FormFeedback>{error.name}</FormFeedback>
                  </FormGroup>
                </td>
                <td>
                  <FormGroup>
                    <RangePicker
                      format={dateFormat}
                      onChange={(date) => {
                        this.setState({startDate: date[0], endDate: date[1]})
                      }}
                      value={[this.state.startDate, this.state.endDate]}
                    />
                    <span
                      className="invalid-static-feedback">{`${error.start_date || ""} ${error.end_date || ""}`}</span>
                  </FormGroup>
                </td>
                <td>
                  <Button outline color="success" className="float-right"
                          onClick={this.onCreate.bind(this)}>Add</Button>
                </td>
              </tr>
              {tableBody}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {getFiscalYears, createFiscalYear} = state
  return {
    getFiscalYears, createFiscalYear
  }
}

export default connect(mapStateToProps)(FiscalYear)
