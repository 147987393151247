import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {Config} from "../../Config";
import {listDb} from "../../apis/backup";
import {downloadFile} from "../../shared/helpers/DownloadFile";

class Database extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showPrintModal: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    listDb(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      listDb
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    } = prevState;

    if (listDb) {
      let {success, error} = listDb;
      if (success) {
        data = success.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    };
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  newAction() {
    let url = Config.BillingUrl+'backup/download'
    downloadFile(url,'db',true)
  }

  onDownload(id){
    let url = Config.BillingUrl+'backup/'+id
    downloadFile(url,id,true)
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Database Name',
        dataIndex: 'db_name'
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Size',
        dataIndex: 'size'
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'button',
            icon: 'download',
            simple: true,
            title: 'Download',
            onClick: this.onDownload.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        db_name, created_at, size
      } = single

      tableData.push({
        id: db_name, db_name, created_at, size
      })

    })

    return (
      <div className="animated fadeIn">
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Backup Now"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    listDb
  } = state;
  return {
    listDb
  };
}

export default connect(mapStateToProps)(Database)
