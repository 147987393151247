import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "units"

export const createUnit = (data) => {
  let reducers = {
    success: 'CREATE_UNIT_SUCCESS',
    validation_error: 'CREATE_UNIT_VALIDATION_ERROR',
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteUnit = (id) => {
  let reducers = {
    success: 'DELETE_UNIT_SUCCESS',
    error: 'DELETE_UNIT_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getUnit = (id) => {
  let reducers = {
    success: 'FETCH_UNIT_SUCCESS',
    error: 'FETCH_UNIT_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getUnits = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_UNITS_SUCCESS',
    error: 'FETCH_UNITS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const getActiveUnits = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_ACTIVE_UNITS_SUCCESS',
    error: 'FETCH_ACTIVE_UNITS_ERROR'
  }
  let url = `api/${prefix}`
  let getGroup = new GetGroup(limit, page, filters, url, reducers)
  getGroup.getGroup()
}

export const updateUnit = (id, data) => {
  let reducers = {
    success: 'UPDATE_UNIT_SUCCESS',
    validation_error: 'UPDATE_UNIT_VALIDATION_ERROR',
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const resetUpdateUnit = () => {
  store.dispatch(resetData('RESET_UPDATE_UNIT'))
}

export const resetCreateUnit = () => {
  store.dispatch(resetData('RESET_CREATE_UNIT'))
}

export const resetDeleteUnit = () => {
  store.dispatch(resetData('DELETE_RESET_UNIT'))
}
