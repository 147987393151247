export default function deletePOS(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'DELETE_POS_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DELETE_POS_ERROR':
      return {...state, ...{error: action.error}};
    case 'DELETE_POS_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'DELETE_RESET_POS':
      return {...state, ...{success:null,error:null}};
    default:
      return state;
  }
}
