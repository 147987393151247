export default function resetPaymentTerm(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'RESET_PAYMENT_TERM':
      return {...state, ...{success: null,error: null}};
    default:
      return state;
  }
}
