export const Config = {
  monthName : ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  monthNameAD : ['January', 'February', 'March', 'April', 'May','June','July','August','September','October','November','December'],
  monthNameBS : ["बैशाख", "जेठ", "असार", "साउन", "भदौ", "असोज", "कार्तिक", "मंसिर", "पौष", "माघ", "फागुन", "चैत"],
  monthNameBS_en: ['Baisakh', 'Jestha', 'Ashar', 'Shrawan', 'Bhadra', 'Ashoj', 'Kartik', 'Mangsir', 'Poush', 'Magh', 'Falgun', 'Chaitra'],
  monthNamesNS: ['कछलाथ्व', 'कछलागा', 'थिंलाथ्व', 'थिंलागा', 'पोहेलाथ्व', 'पोहेलागा', 'सिल्लाथ्व', 'सिल्लागा', 'चिल्लाथ्व', 'चिल्लागा', 'चौलाथ्व', 'चौलागा', 'बछलाथ्व', 'बछलागा', 'तछलाथ्व', 'तछलागा', 'दिल्लाथ्व', 'दिल्लागा', 'गुँलाथ्व', 'गुँलागा', 'ञलाथ्व', 'ञलगा', 'कौलाथ्व', 'कौलागा'],
  tithiNames: [ 'प्रतिपदा', 'द्वितीया', 'तृतीया', 'चतुर्थी', 'पञ्चमी', 'षष्ठी', 'सप्तमी', 'अष्टमी', 'नवमी', 'दशमी', 'एकादशी', 'द्वादशी', 'त्रयोदशी', 'चतुर्दशी', 'पूर्णिमा', 'औंसी'],
  tithiNames_en: ['Pratipada','Dwitiya','Tritiya','Chaturthi','Panchami','Sasthi','Saptami','Astami','Nawami','Dashami','Ekadashi','Dwadashi','Trayodashi','Chaturdashi','Purnima','Aunsi'],
  chandraNames: [ 'कार्तिक शुक्लपक्ष', 'मार्ग कृष्णपक्ष', 'मार्ग शुक्लपक्ष', 'पौष कृष्णपक्ष', 'पौष शुक्लपक्ष', 'माघ कृष्णपक्ष', 'माघ शुक्लपक्ष', 'फाल्गुण कृष्णपक्ष', 'फाल्गुण शुक्लपक्ष', 'चैत्र कृष्णपक्ष', 'चैत्र शुक्लपक्ष','वैशाख कृष्णपक्ष', 'वैशाख शुक्लपक्ष', 'ज्येष्ठ कृष्णपक्ष', 'ज्येष्ठ शुक्लपक्ष', 'आषाढ कृष्णपक्ष', 'आषाढ शुक्लपक्ष', 'श्रावण कृष्णपक्ष', 'श्रावण शुक्लपक्ष', 'भाद्र कृष्णपक्ष', 'भाद्र शुक्लपक्ष', 'आश्विन कृष्णपक्ष', 'आश्विन शुक्लपक्ष', 'कार्तिक कृष्णपक्ष' ],
  chandraNames_en: [ 'Kartik Shuklapaksha', 'Marga Krishnapaksha', 'Marga Shuklapaksha', 'Poush Krishnapaksha', 'Poush Shuklapaksha', 'Magh Krishnapaksha', 'Magh Shuklapaksha', 'Falgun Krishnapaksha', 'Falgun Shuklapaksha', 'Chaitra Krishnapaksha','Chaitra Shuklapaksha','Baisakh Krishnapaksha', 'Baisakh Shuklapaksha', 'Jestha Krishnapaksha','Jestha Shuklapaksha', 'Asadh Krishnapaksha','Asadh Shuklapaksha','Shrawan Krishnapaksha','Shrawan Shuklapaksha', 'Bhadra Krishnapaksha', 'Bhadra Shuklapaksha', 'Ashwin Krishnapaksha', 'Ashwin Shuklapaksha','Kartik Krishnapaksha'],
  monthNameNS: {1: 'कछलाथ्व', 2:'कछलागा', 3:'थिंलाथ्व', 4:'थिंलागा', 5:'पोहेलाथ्व', 6:'पोहेलागा', 7:'सिल्लाथ्व',8: 'सिल्लागा', 9:'चिल्लाथ्व', 10:'चिल्लागा', 11:'चौलाथ्व', 12: 'चौलागा', 13: 'बछलाथ्व', 14: 'बछलागा', 15: 'तछलाथ्व',16: 'तछलागा',17: 'दिल्लाथ्व',18: 'दिल्लागा',19: 'गुँलाथ्व',20: 'गुँलागा',21: 'ञलाथ्व',22: 'ञलगा',23: 'कौलाथ्व',24: 'कौलागा'},
  tithiName: {1: 'प्रतिपदा',2: 'द्वितीया',3: 'तृतीया',4: 'चतुर्थी', 5: 'पञ्चमी', 6:'षष्ठी',7: 'सप्तमी',8: 'अष्टमी',9: 'नवमी',10: 'दशमी',11: 'एकादशी',12: 'द्वादशी',13: 'त्रयोदशी',14: 'चतुर्दशी',15: 'पूर्णिमा',30: 'औंसी'},
  tithiName_en: {1: 'Pratipada',2: 'Dwitiya',3: 'Tritiya',4: 'Chaturthi', 5: 'Panchami', 6:'Sasthi',7: 'Saptami',8: 'Astami',9: 'Nawami',10: 'Dashami',11: 'Ekadashi',12: 'Dwadashi',13: 'Trayodashi',14: 'Chaturdashi',15: 'Purnima',30: 'Aunsi'},
  chandraName: {12: 'वैशाख कृष्णपक्ष', 13: 'वैशाख शुक्लपक्ष', 14:'ज्येष्ठ कृष्णपक्ष',15: 'ज्येष्ठ शुक्लपक्ष', 16:'आषाढ कृष्णपक्ष',17: 'आषाढ शुक्लपक्ष',18: 'श्रावण कृष्णपक्ष',19: 'श्रावण शुक्लपक्ष', 20:'भाद्र कृष्णपक्ष',21: 'भाद्र शुक्लपक्ष', 22:'आश्विन कृष्णपक्ष',23: 'आश्विन शुक्लपक्ष',24: 'कार्तिक कृष्णपक्ष',1: 'कार्तिक शुक्लपक्ष', 2:'मार्ग कृष्णपक्ष', 3:'मार्ग शुक्लपक्ष',4: 'पौष कृष्णपक्ष', 5:'पौष शुक्लपक्ष',6: 'माघ कृष्णपक्ष', 7:'माघ शुक्लपक्ष',8: 'फाल्गुण कृष्णपक्ष',9: 'फाल्गुण शुक्लपक्ष', 10:'चैत्र कृष्णपक्ष',11: 'चैत्र शुक्लपक्ष'},
  chandraName_en: {12: 'Baisakh Krishnapaksha', 13: 'Baisakh Shuklapaksha', 14:'Jestha Krishnapaksha',15: 'Jestha Shuklapaksha', 16:'Asadh Krishnapaksha',17: 'Asadh Shuklapaksha',18: 'Shrawan Krishnapaksha',19: 'Shrawan Shuklapaksha', 20:'Bhadra Krishnapaksha',21: 'Bhadra Shuklapaksha', 22:'Ashwin Krishnapaksha',23: 'Ashwin Shuklapaksha',24: 'Kartik Krishnapaksha',1: 'Kartik Shuklapaksha', 2:'Marga Krishnapaksha', 3:'Marga Shuklapaksha',4: 'Poush Krishnapaksha', 5:'Poush Shuklapaksha',6: 'Magh Krishnapaksha', 7:'Magh Shuklapaksha',8: 'Falgun Krishnapaksha',9: 'Falgun Shuklapaksha', 10:'Chaitra Krishnapaksha',11: 'Chaitra Shuklapaksha'},
  chandraInitial: {12: 'वै. कृ.', 13: 'वै. शु.', 14:'जे. कृ.',15: 'जे. शु.', 16:'आ. कृ.',17: 'आ. शु.',18: 'श्रा. कृ.',19: 'श्रा. शु.', 20:'भा. कृ.',21: 'भा. शु.', 22:'आ. कृ.',23: 'आ. शु.',24: 'का. कृ.',1: 'का. शु.', 2:'मा. कृ.', 3:'मा. शु.',4: 'पौ. कृ.', 5:'पौ. शु.',6: 'मा. कृ.', 7:'मा. शु.',8: 'फा. कृ.',9: 'फा. शु.', 10:'चै. कृ.',11: 'चै. शु.'},
  chandraInitial_en: {12: 'B. K.', 13: 'B. S.', 14:'J. K.',15: 'J. S.', 16:'A. K.',17: 'A. S.',18: 'S. K.',19: 'S. S.', 20:'B. K.',21: 'B. S.', 22:'A. K.',23: 'A. S.',24: 'K. K.',1: 'K. S.', 2:'M. K.', 3:'M. S.',4: 'P. K.', 5:'P. S.',6: 'M. K.', 7:'M. S.',8: 'F. K.',9: 'F. S.', 10:'C. K.',11: 'C. S.'},
  days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  days_np: ["आ", "सो", "मं", "बु", "बि", "शु", "श"],
  dayName : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
  daySuffix : ['day','day','sday','nesday','rsday','day','urday'],
  dayNameNepali : ["आइत", "सोम", "मंगल", "बुध", "बिही", "शुक्र", "शनि"],
  day_en: ['Sunday', 'Monday','Tuesday', 'Wednesday','Thursday','Friday','Saturday'],
  day_np:["आइतवार", "सोमवार", "मंगलवार", "बुधवार", "बिहीवार", "शुक्रवार", "शनिवार"],
  base: ['AD','BS', 'NS'],
  formatDefault : 'YYYY-MM-DD',
  NUM_OF_WEEKS: 5,  
  monthAD : {"1":'January', "2":'February', "3":'March', "4":'April', "5":'May',"6":'June',"7":'July',"8":'August',"9":'September',"10":'October',"11":'November',"12":'December'},
  monthBS : {"1":"Baishakh","2": "Jestha","3": "Ashar","4":"Shrawan","5":"Bhadra","6":"Ashoj", "7":"Kartik","8":"Mangsir","9": "Poush", "10":"Magh", "11":"Falgun", "12":"Chaitra"},


}
