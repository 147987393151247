import {GetGroup} from "../actions/crud/get_group"

let prefix = "query-log"

export const getQueryLog = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_QUERY_LOG_SUCCESS',
    error: 'FETCH_QUERY_LOG_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}
