import React, {Component} from 'react';
import {connect} from "react-redux";
import ProductFamilyModal from "./ProductFamilyModal";
import {
  createProductFamily,
  createResetProductFamily, deleteProductFamily,
  deleteResetProductFamily,
  getProductFamilies, getProductFamily, updateProductFamily,
  updateResetProductFamily
} from "../../apis/product-families";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class ProductsFamilies extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    formData: {
      status: 1
    },
    formError: {},
    restData: [],
    data: {},
    error: [],
    keys: [],
    updateSuccess: null,
    createSuccess: null,
    deleteSuccess: null,
    createError: null,
    updateError: null,
    openModal: false,
    newModal: true,
    id: null,
    deleteModal: false,
    itemToDelete: '',
    restUpdate: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getProductFamilies(limit, page, searchArray)
  }

  componentDidMount() {
    this.fetchData()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getProductsFamilies,
      updateProductFamily,
      createProductFamily,
      getProductFamily,
      deleteProductFamily
    } = nextProps
    let {
      restData,
      updateSuccess,
      updateError,
      createSuccess,
      createError,
      restUpdate,
      deleteSuccess,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState

    if (getProductsFamilies) {
      let {success, error} = getProductsFamilies
      if (success) {
        restData = success.data.data  || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      } else if (error) {
        console.log(error)
      }
    }

    if (getProductFamily) {
      let {success, error} = getProductFamily
      if (success) {
        restUpdate = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (updateProductFamily) {
      let {success, validation} = updateProductFamily
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null
        updateError = null
      }
    }

    if (createProductFamily) {
      let {success, validation} = createProductFamily
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (deleteProductFamily) {
      let {success, error} = deleteProductFamily
      if (success) {
        deleteSuccess = success.data.message
      } else if (error) {
        console.log(error)
      } else {
        deleteSuccess = null
      }
    }

    return {
      restData, updateSuccess, createSuccess, createError, restUpdate, updateError, deleteSuccess,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      updateSuccess,
      createSuccess,
      createError,
      restUpdate,
      updateError,
      deleteSuccess
    } = this.state

    if (deleteSuccess !== null) {
      store.dispatch(addMessage(deleteSuccess, "success", "Delete Product Family"));
      this.fetchData()
      deleteResetProductFamily()
    }

    if (restUpdate !== prevState.restUpdate) {
      this.setState({
        formData: restUpdate
      })
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Update Product Family"));
      this.fetchData();
      updateResetProductFamily()
      this.setState({
        openModal: false
      })
    }

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Product Family"));
      this.fetchData()
      createResetProductFamily()
      this.setState({
        openModal: false
      })
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetProductFamily()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        updateResetProductFamily()
      })
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  onUpdate() {
    let {formData, id} = this.state
    updateProductFamily(id, formData)
  }

  onCreate() {
    createProductFamily(this.state.formData)
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  onNewClick() {
    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: {
        status: 1,
      },
      formError: {}
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  onReset() {
    if (this.state.id) {
      this.setState({
        formData: this.state.restUpdate
      })
    } else {
      this.setState({
        formData: {
          status: 1,
          name: '',
          rate: '',
          description: ''
        }
      })
    }
  }

  onUpdateClick(id) {
    getProductFamily(id)
    this.setState({
      id: id,
      openModal: true,
      newModal: false,
      formError: {}
    })
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deleteProductFamily(this.state.itemToDelete)
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      openModal,
      newModal,
      formData,
      formError,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      restData
    } = this.state


    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '30%'
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, name, description
      } = single

      tableData.push({
        id, name, description
      })
    })

    if (openModal) {
      return (
        <ProductFamilyModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={newModal ? "Add New TAX" : "Edit TAX"}
          data={formData}
          error={formError}
          onChange={this.onChange.bind(this)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
        />
      );
    }

    return (
      <>
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          filters={this.filters}
          newTitle="Create Product Family"
          newAction={this.onNewClick.bind(this)}
          tabTable={true}
        />
        {/*<DeleteModal*/}
        {/*  open={deleteModal}*/}
        {/*  toggle={this.onDelete.bind(this)}*/}
        {/*  deleteItem={this.onDeleteConfirm.bind(this)}*/}
        {/*  title="Delete Product Family"*/}
        {/*/>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {getProductsFamilies, updateProductFamily, createProductFamily, getProductFamily, deleteProductFamily} = state
  return {
    getProductsFamilies, updateProductFamily, createProductFamily, getProductFamily, deleteProductFamily
  }
}

export default connect(mapStateToProps)(ProductsFamilies);
