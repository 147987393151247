import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {Config} from "../../Config";
import {downloadFile} from "../../shared/helpers/DownloadFile";
import {getQueryLog} from "../../apis/query-log";

class QueryLog extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    data: [],
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getQueryLog(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getQueryLog
    } = nextProps;
    let {
      data,
    } = prevState;

    if (getQueryLog) {
      let {success, error} = getQueryLog;
      if (success) {
        data = success.data || [];
      } else if (error) {
      }
    }

    return {
      data,
    };
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  onDownload(id){
    let url = Config.BillingUrl+'query-log/'+id
    downloadFile(url,id,true)
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Query Log Name',
        dataIndex: 'name'
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Size',
        dataIndex: 'size'
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'button',
            icon: 'download',
            simple: true,
            title: 'Download',
            onClick: this.onDownload.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      console.log(single)
      let {
        name, created_at, size
      } = single

      tableData.push({
        id: name, name, created_at, size
      })

    })

    return (
      <div className="animated fadeIn">
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          confirmSearch={this.confirmSearch.bind(this)}
          hideNewCard={true}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getQueryLog
  } = state;
  return {
    getQueryLog
  };
}

export default connect(mapStateToProps)(QueryLog)
