import React, {Component} from 'react';
import {connect} from "react-redux";
import {Card, CardBody, Col, Input, Row, Table} from "reactstrap";
import ReportHeader from "./ReportHeader";
import ReportFilter from "./ReportFilter";
import ReportColor from "./ReportColor";
import TablePagination from "../../shared/table-helpers/TablePagination";
import store from '../../Store'
import CurrencyAdder from "../CurrencyAdder";
import {getPurchaseRegisters} from "../../apis/purchase-registers";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {setFilter} from "../../shared/helpers/FilterHelper";
import nepaliCalendar from "nepali-calendar-js";
import {getEquivalentNepaliMonth} from "../../shared/helpers/GeneralHelpers";

class PurchaseRegister extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    customer_name: '',
    dateFilter: {
      start_date: '',
      end_date: ''
    },
    startDate: null,
    endDate: null,
    nepaliDate: {
      month: 9,
      year: 2075
    }
  }

  currentPath = window.location.pathname

  state = {
    filters: {...this.filters},
    userDetail: {},
    focusedInput: null,
    data: null,
    sumData: null,
    debitData: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    currency: "",
    companyDetail: {}
  }

  componentDidMount() {
    let tempDate = new Date();
    let georgianDate = nepaliCalendar.toNepali(tempDate.getFullYear(), tempDate.getMonth() + 1, tempDate.getDate())
    let filters = {...this.state.filters}
    filters['nepaliDate']['month'] = georgianDate.nm
    filters['nepaliDate']['year'] = georgianDate.ny
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
    if (!this.props.getLoggedInCompany.success) {
      store.dispatch(getLoggedInCompany())
    }
  }

  fetchData() {
    let {limit, page, searchArray, dateFilter} = this.state.filters
    getPurchaseRegisters(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getLoggedInUser,
      getPurchaseRegisters,
      getLoggedInCompany
    } = nextProps

    let {
      userDetail,
      data,
      sumData,
      debitData,
      prevPageUrl,
      nextPageUrl,
      from,
      to,
      total,
      lastPage,
      firstPage,
      currentPage,
      companyDetail
    } = prevState

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        userDetail = success.data
      }
    }

    if (getPurchaseRegisters) {
      let {success} = getPurchaseRegisters
      if (success) {
        if (success.status === 200) {
          data = success.data.billing.data
          sumData = success.data.sum_data
          debitData = success.data.debit_note.data
          prevPageUrl = success.data.billing.prev_page_url;
          nextPageUrl = success.data.billing.next_page_url;
          currentPage = success.data.billing.current_page;
          from = success.data.billing.from;
          to = success.data.billing.to;
          total = success.data.billing.total;
          lastPage = success.data.billing.last_page;
          firstPage = 1;
        }
      }
    }

    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        companyDetail = success.data
      }
    }

    return {
      userDetail,
      data,
      sumData,
      prevPageUrl,
      nextPageUrl,
      from,
      to,
      total,
      lastPage,
      firstPage,
      currentPage,
      debitData,
      companyDetail
    }
  }

  onDateChange(startDate, endDate) {
    let filters = {...this.state.filters}
    filters['startDate'] = startDate
    filters['endDate'] = endDate
    filters['dateFilter'] = {
      start_date: startDate ? startDate.format("YYYY-MM-DD") : null,
      end_date: endDate ? endDate.format("YYYY-MM-DD") : null
    }

    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }

  onFocusChange(focusedInput) {
    this.setState({focusedInput})
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    });
  }

  limitChange(e) {
    let filters = {...this.state.filters}
    filters['limit'] = e.target.value
    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }


  searchChange(e) {
    let data = e.target.value
    let filters = {...this.state.filters}
    let searchArray = [...this.state.filters.searchArray]
    let a = {
      key: 'vendor_name',
      value: data
    }

    let searchIndex = this.state.filters.searchIndex
    let position = searchIndex.indexOf('customer_name')
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push('customer_name')
    }

    filters['customer_name'] = data
    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex

    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }

  setGeorgianDate() {
    let filters = {...this.state.filters}
    let monthLength = nepaliCalendar.nepaliMonthLength(filters.nepaliDate.year, filters.nepaliDate.month)
    let tempDate = nepaliCalendar.toGregorian(filters.nepaliDate.year, filters.nepaliDate.month, 1)
    let endDate = new Date(`${tempDate.gy}-${tempDate.gm}-${tempDate.gd}`)
    endDate.setDate(endDate.getDate() + monthLength - 1)
    setTimeout(() => {
      let start_date = `${tempDate.gy}-${tempDate.gm}-${tempDate.gd} 00:00:00`
      let end_date = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} 23:59:59`
      filters['dateFilter']['start_date'] = start_date
      filters['dateFilter']['end_date'] = end_date
      this.setState({
        filters
      }, () => {
        this.fetchData()
      })
    }, 10)
  }

  changeDate(data) {
    let filters = {...this.state.filters}
    filters.nepaliDate.year = data.year
    filters.nepaliDate.month = data.month
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
  }

  render() {
    let {
      focusedInput,
      data,
      sumData,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      limit,
      debitData,
      companyDetail,
      filters
    } = this.state


    let purchaseData = data && data.map((sales) => {
      let {id, created_at, billing_no, vendor_name, pan, total, taxable_amount, vat_amount, discount_amount, type} = sales
      return (
        <tr key={id} className={sales['sum-debit-note'] !== 0 ? "report-return-indicator" : "report-data-success"}>
          <td><span>{created_at}</span></td>
          <td><span>{`${billing_no}/-`}</span></td>
          <td><span>{vendor_name}</span></td>
          <td><span>{pan ? pan : "-"}</span></td>
          <td className="text-right"><span><CurrencyAdder amount={total}/></span></td>
          <td className="text-right"><span><CurrencyAdder amount={discount_amount}/></span></td>
          <td className="text-right"><span>{type === "normal" ? <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "normal" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "import" ? <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "import" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "capital" ?
            <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "capital" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
        </tr>
      );
    })

    let purchaseTotal = sumData && <tr>
      <td colSpan="5" className="text-right"><CurrencyAdder amount={sumData.active_purchase.total}/></td>
      <td className="text-right">0</td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.purchase.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.purchase.vat_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.import.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.import.vat_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.capital.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.capital.vat_amount}/></td>
    </tr>

    let returnTotal = debitData && debitData.length > 0 && sumData && <tr>
      <td colSpan="5" className="text-right"><CurrencyAdder amount={sumData.return_purchase.total}/></td>
      <td className="text-right">0</td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.purchase.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.purchase.vat_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.import.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.import.vat_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.capital.taxable_amount}/></td>
      <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.capital.vat_amount}/></td>
    </tr>

    let summaryData = sumData && <>
      <tr className="report-data-success">
        <td colSpan="5" className="text-right"><CurrencyAdder amount={sumData.active_purchase.total}/></td>
        <td className="text-right">0</td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.purchase.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.purchase.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.import.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.import.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.capital.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.active_purchase.capital.vat_amount}/></td>
      </tr>
      <tr className="report-data-return">
        <td colSpan="5" className="text-right"><CurrencyAdder amount={sumData.return_purchase.total}/></td>
        <td className="text-right">0</td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.purchase.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.purchase.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.import.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.import.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.capital.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.return_purchase.capital.vat_amount}/></td>
      </tr>
      <tr>
        <td colSpan="5" className="text-right"><CurrencyAdder amount={sumData.total_purchase.total}/></td>
        <td className="text-right">0</td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.purchase.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.purchase.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.import.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.import.vat_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.capital.taxable_amount}/></td>
        <td className="text-right"><CurrencyAdder amount={sumData.total_purchase.capital.vat_amount}/></td>
      </tr>
    </>

    let debitRow = debitData && debitData.map((debit) => {
      let {id, created_at, billing_no, vendor_name, pan, total, taxable_amount, vat_amount, discount_amount, type, payment_id, billing} = debit
      return (
        <tr className="report-data-return" key={id}>
          <td><span>{`${billing.created_at}/${created_at}`}</span></td>
          <td><span>{`${billing_no}/${payment_id}`}</span></td>
          <td><span>{vendor_name}</span></td>
          <td><span>{pan ? pan : ""}</span></td>
          <td className="text-right"><span><CurrencyAdder amount={total}/> </span></td>
          <td className="text-right"><span><CurrencyAdder amount={discount_amount}/> </span></td>
          <td className="text-right"><span>{type === "normal" ? <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "normal" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "import" ? <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "import" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "capital" ?
            <CurrencyAdder amount={taxable_amount}/> : "-"}</span>
          </td>
          <td className="text-right"><span>{type === "capital" ? <CurrencyAdder amount={vat_amount}/> : "-"}</span>
          </td>
        </tr>
      );
    })

    let salesTable = <>
      <Table responsive bordered className="small-font">
        <tbody>
        <tr>
          <td colSpan="4" className="text-bold">Bill</td>
          <td rowSpan="2" className="text-bold">Total Purchase Price</td>
          <td rowSpan="2" className="text-bold">Discount</td>
          <td colSpan="2" className="text-bold">Taxable Purchase</td>
          <td colSpan="2" className="text-bold">Taxable Import</td>
          <td colSpan="2" className="text-bold">Capital Taxable Purchase / Import</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>Bill No/DNN</td>
          <td>Vendor Name</td>
          <td>Vendor PAN</td>
          <td>Price</td>
          <td>VAT</td>
          <td>Price</td>
          <td>VAT</td>
          <td>Price</td>
          <td>VAT</td>
        </tr>
        {purchaseData}
        {purchaseTotal}
        <tr>
          <td colSpan="12"></td>
        </tr>
        {debitRow}
        {returnTotal}
        <tr>
          <td colSpan="12" className="text-center">
            <span className="text-bold report-table-title">Summary</span>
          </td>
        </tr>
        {summaryData}
        </tbody>
      </Table>
    </>

    return (
      <div className="animated fadeIn">
        <ReportFilter
          startDate={filters.startDate}
          endDate={filters.endDate}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange.bind(this)}
          onDateChange={this.onDateChange.bind(this)}
          searchChange={(e) => this.searchChange(e)}
          searchValue={filters.customer_name}
          reportType="purchase"
          changeDate={this.changeDate.bind(this)}
        />
        <Card>
          <CardBody>
            <ReportHeader
              page="Purchase Register"
              dateFilter={this.state.filters.dateFilter}
              isPurchase={true}
              userDetail={companyDetail}
              reportType="purchase"
            />
            <hr/>
            <Row><Col className="text-center">
              <span className="report-page-title">Purchase Register</span>
            </Col></Row>
            <ReportColor
              isPurchase={true}
            />
            <Row>
              <Col md="6">
                <div className="dataTables_wrapper form-inline float-left">
                  <label>
                    Show &nbsp;
                    <Input type="select" onChange={(e) => this.limitChange(e)} value={limit}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </Input> &nbsp;
                    entries
                  </label>
                </div>
              </Col>
              <Col md="6" sm="12" className="text-right">
                <span className="text-bold">{`Duration of Purchase : ${getEquivalentNepaliMonth(filters.nepaliDate.month-1)},${filters.nepaliDate.year}`}</span>
              </Col>
            </Row>
            <br/>
            {salesTable}
            <TablePagination
              to={to}
              from={from}
              total={total}
              prevPageUrl={prevPageUrl}
              nextPageUrl={nextPageUrl}
              currentPage={currentPage}
              paginationButton={this.paginationButton.bind(this)}
              lastPage={lastPage}
              name="records"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function

mapStateToProps(state) {
  let {
    getLoggedInUser,
    getLoggedInCompany,
    getPurchaseRegisters
  } = state
  return {
    getLoggedInUser,
    getLoggedInCompany,
    getPurchaseRegisters
  }
}

export default connect(mapStateToProps)(PurchaseRegister)
