export default function getRole(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'FETCH_ROLE_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'FETCH_ROLE_ERROR':
      return {...state, ...{error: action.error}};
    case 'FETCH_ROLE_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
