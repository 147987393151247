import React, {Component} from 'react';
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import {connect} from "react-redux";
import {createResetRole, createRole, getRole, getRoles, updateResetRole, updateRole} from "../../apis/roles";
import RoleModal from "./RoleModal";
import {getPermissions} from "../../apis/permissions";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {localValidation} from "../../shared/helpers/ValidationHelper";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class Roles extends Component {

  state = {
    restData: [],
    restView: null,
    inactiveData: null,
    createSuccess: null,
    createError: null,
    updateSuccess: null,
    updateError: null,
    openModal: false,
    newModal: true,
    formData: {},
    formError: {},
    deleteModal: false,
    message: {
      type: '',
      message: ''
    },
    slug: '',
    keys: {},
    isNew: true,
    isSave: false,
    isView: null,
    statistics: {},
    status: {
      all: true,
      active: false,
      inactive: false
    },
    isEdit: false,
    showDeleteModal: false,
    itemToDelete: null,
    permissionPage: 1,
    searchValue: {},
    searchArray: [],
    searchIndex: [],
    permissions: [],
    permissionModal: false,
    permissionPrevPageUrl: "",
    permissionNextPageUrl: "",
    permissionCurrentPage: "",
    permissionFrom: "",
    permissionTo: "",
    permissionTotal: "",
    permissionLastPage: "",
    permissionFirstPage: "",
    checkedPermissions: new Map(),
    permissionAssigned: new Map()
  }

  tempData = {
    name: '',
    display_name: '',
    description: '',
    permissions_id: []
  }


  checkedPermissions= new Map()
  permissionAssigned= new Map()

  componentDidMount() {
    this.fetchData()
    this.fetchPermission()
  }

  fetchData() {
    getRoles()
  }

  fetchPermission() {
    let {permissionPage, searchArray} = this.state
    getPermissions(null, permissionPage, searchArray)
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    let {getRoles, createRole, getRole, updateRole, getPermissions} = nextProps
    let {
      restData,
      updateSuccess,
      updateError,
      createSuccess,
      createError,
      restView,
      permissions,
      permissionPrevPageUrl,
      permissionNextPageUrl,
      permissionCurrentPage,
      permissionFrom,
      permissionTo,
      permissionTotal,
      permissionLastPage,
      permissionFirstPage,
    } = prevState
    if (getRoles) {
      let {success, validation} = getRoles
      if (success) {
        restData = success.data.data || []
      } else if (validation) {
        console.log(validation)
      }
    }

    if (createRole) {
      let {success, validation} = createRole
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (updateRole) {
      let {success, validation} = updateRole
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null
        updateError = null
      }
    }

    if (getRole) {
      let {success, error} = getRole
      if (success) {
        restView = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (getPermissions) {
      let {success} = getPermissions
      if (success) {
        permissions = success.data.data
        permissionPrevPageUrl = success.data.prev_page_url;
        permissionNextPageUrl = success.data.next_page_url;
        permissionCurrentPage = success.data.current_page;
        permissionFrom = success.data.from;
        permissionTo = success.data.to;
        permissionTotal = success.data.total;
        permissionLastPage = success.data.last_page;
        permissionFirstPage = 1
      }
    }

    return {
      restData,
      createSuccess,
      createError,
      restView,
      updateSuccess,
      updateError,
      permissions,
      permissionPrevPageUrl,
      permissionNextPageUrl,
      permissionCurrentPage,
      permissionFrom,
      permissionTo,
      permissionTotal,
      permissionLastPage,
      permissionFirstPage,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {createSuccess, createError, keys, restView, updateSuccess, updateError} = this.state
    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Role"));
      this.setState({
        formData: {},
        keys: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        createResetRole()
      })
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Update Role"));
      this.setState({
        formData: {},
        keys: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        updateResetRole()
      })
    }

    if (restView !== prevState.restView) {
      if (!this.state.isEdit)
        this.setState({
          isView: restView
        })
      else {
        let permissionAssigned = new Map()
        let checkedPermissions = new Map()

        restView['perms'].forEach((single)=>{
          let item = single.id + ""
          let temp = single.display_name
          permissionAssigned = permissionAssigned.set(item, temp)
          checkedPermissions = checkedPermissions.set(item,true)
        })

        let temp = restView
        temp['permission_id'] = temp

        this.tempData = temp
        this.checkedPermissions = new Map(checkedPermissions)
        this.permissionAssigned = new Map(permissionAssigned)

        this.setState({formData: temp,permissionAssigned : new Map(permissionAssigned),checkedPermissions: new Map(checkedPermissions)})
      }
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetRole()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        updateResetRole()
      })
    }

    if (keys !== prevState.keys) {
      if (keys !== null) {
        let tempData = this.tempData
        keys.required_field && keys.required_field.forEach((field) => {
          let {name} = field
          tempData[name] = ''
        })
        this.tempData = tempData
      }
    }
  }

  onUpdateClick(slug) {
    getRole(slug)

    this.setState({
      openModal: true,
      newModal: true,
      isEdit: false,
      slug: slug
    })
  }

  onEditClick(slug) {
    getRole(slug)

    this.setState({
      openModal: true,
      newModal: true,
      isEdit: true,
      isView: null,
      slug: slug
    })
  }

  onNewClick() {
    let tempData = {
      name: '',
      display_name: '',
      description: '',
      permission_id: []
    }

    this.tempData = tempData
    this.permissionAssigned = new Map()
    this.checkedPermissions = new Map()

    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: tempData,
      formError: {},
      isView: null,
      isEdit: null,
      permissionAssigned: this.permissionAssigned,
      checkedPermissions: this.checkedPermissions
    })
  }

  toggleRoleModal() {
    this.setState({
      openModal: false
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    formData[name] = value;
    formError[name] = "";
    this.setState({formData, formError});
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  onCreate() {
    let formData = {...this.state.formData}
    let {permissionAssigned} = this.state
    let tempKeys = permissionAssigned.keys()
    tempKeys = Array.from(tempKeys)
    formData['permission_id'] = tempKeys

    let validationRule = {
      name: ['required', 'text'],
      description: ['required'],
      permission_id: ['required']
    }

    let localValidationStatus = false
    let check = localValidation(formData, validationRule, this.state.formError, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        formError: check.error
      })
    } else {
      createRole(formData)
    }
  }

  onUpdate() {
    let formData = {...this.state.formData}
    let {permissionAssigned} = this.state
    let tempKeys = permissionAssigned.keys()
    tempKeys = Array.from(tempKeys)
    formData['permission_id'] = tempKeys
    delete formData['perms']
    updateRole(this.state.slug, formData)
  }

  onReset() {
    this.setState({
      formData: {...this.tempData},
      checkedPermissions: new Map(this.checkedPermissions),
      permissionAssigned: new Map(this.permissionAssigned)
    })
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    this.setState({
      deleteModal: false,
      itemToDelete: ''
    })
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  searchPermissionChange(e) {
    let data = e.target.value
    let key = "display_name"
    let searchArray = [...this.state.searchArray]
    let searchValue = {...this.state.searchValue}
    let a = {
      key: key,
      value: data
    }

    let searchIndex = this.state.searchIndex
    let position = searchIndex.indexOf(key)
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push(key)
    }

    searchValue[key] = data
    this.setState({
      searchArray,
      searchValue,
      searchIndex,
      permissionPage: 1
    }, () => {
      this.fetchPermission()
    })
  }

  paginationPermissionButton(permissionPage) {
    this.setState({permissionPage}, () => {
      this.fetchPermission()
    });
  }

  toggleModal() {
    this.setState({
      permissionModal: !this.state.permissionModal
    })
  }

  rowPressed(id, name) {

    let permissionAssigned = this.state.permissionAssigned

    let temp = null
    const item = "" + id
    const isChecked = !this.state.checkedPermissions.get("" + id)
    if (isChecked) {
      temp = name
      permissionAssigned = permissionAssigned.set(item, temp)
    }
    if (!name) {
      permissionAssigned.delete(item)
    }

    this.setState(prevState => (
      {
        checkedPermissions: prevState.checkedPermissions.set(item, isChecked),
        permissionAssigned
      }), () => {
    })
  }

  removeData() {

  }

  render() {
    let {
      restData,
      inactiveData,
      openModal,
      newModal,
      formData,
      formError,
      deleteModal,
      keys,
      isSave,
      isView,
      isEdit,
      permissions,
      permissionModal,
      permissionPrevPageUrl,
      permissionNextPageUrl,
      permissionCurrentPage,
      permissionFrom,
      permissionTo,
      permissionTotal,
      permissionLastPage,
      checkedPermissions,
      permissionAssigned
    } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Display Name',
        dataIndex: 'display_name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'button',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            onClick: this.onUpdateClick.bind(this)
          },
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onEditClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, name, display_name, description
      } = single

      tableData.push({
        id, name, display_name, description
      })
    })

    let gatewayOptions = inactiveData ? inactiveData.map((data) => {
      return {
        label: data.name,
        value: data.id
      }
    }) : null


    let buttonClick = !isEdit ? this.onCreate.bind(this) : this.onUpdate.bind(this)

    if (openModal) {
      return (
        <RoleModal
          isOpen={openModal}
          toggle={this.toggleRoleModal.bind(this)}
          data={formData}
          error={formError}
          onChange={e => this.onChange(e)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={buttonClick}
          onReset={this.onReset.bind(this)}
          gatewayOptions={gatewayOptions}
          isView={isView}
          keys={keys}
          isNew={newModal}
          isSave={isSave}
          isEdit={isEdit}
          permissions={permissions}
          permissionModal={permissionModal}
          searchChange={(e) => this.searchPermissionChange(e)}
          paginationButton={this.paginationPermissionButton.bind(this)}
          prevPageUrl={permissionPrevPageUrl}
          nextPageUrl={permissionNextPageUrl}
          currentPage={permissionCurrentPage}
          from={permissionFrom}
          to={permissionTo}
          total={permissionTotal}
          lastPage={permissionLastPage}
          toggleModal={this.toggleModal.bind(this)}
          checkedPermissions={checkedPermissions}
          rowPressed={this.rowPressed.bind(this)}
          permissionAssigned={permissionAssigned}
          removeData={this.removeData.bind(this)}
        />
      );
    } else {
      return (
        <div className="animated fadeIn">
          <DeleteModal
            open={deleteModal}
            toggle={this.onDelete.bind(this)}
            deleteItem={this.onDeleteConfirm.bind(this)}
            title="Delete Role"
          />
          <TableHelpers
            columns={columns}
            tableData={tableData}
            newTitle="Create Role"
            hideDownload={true}
            newAction={this.onNewClick.bind(this)}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let {getRoles, updateRole, createRole, getRole, getPermissions} = state
  return {getRoles, updateRole, createRole, getRole, getPermissions}
}

export default connect(mapStateToProps)(Roles)
