import React from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  InputGroup,
  InputGroupAddon, InputGroupText
} from "reactstrap";
import TablePagination from "../../shared/table-helpers/TablePagination";

const SelectPermission = (props) => {
  let {
    isOpen,
    toggle,
    to,
    from,
    total,
    prevPageUrl,
    nextPageUrl,
    currentPage,
    paginationButton,
    lastPage,
    searchChange,
    rowPressed,
    permissions,
    checkedPermissions
  } = props;

  let tableBody = permissions && permissions.length > 0 ? permissions.map((permission) => {
      let {id, name, display_name, description} = permission;

      return (
        <tr key={id} onClick={() => rowPressed(id, display_name)}
            className={`table-row-checkbox ${checkedPermissions.get("" + id) && 'table-row-checkbox-selected'}`}>
          <td>
            {name}
          </td>
          <td>
            {display_name}
          </td>
          <td>{description}</td>
        </tr>
      )
    }
  ) : (<tr>
    <td colSpan="10" align="center"><b>Empty Records</b></td>
  </tr>);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} className={'modal-lg ' + props.className}>
      <ModalHeader>Assign Permission</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12" md="6">
          </Col>
          <Col sm="12" md="6">
            <InputGroup>
              <Input
                placeholder="Search by display name"
                onChange={(e) => searchChange(e)}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-search"></i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="12">
            <Table responsive>
              <thead>
              <tr>
                <th width="100">Name</th>
                <th width="100">Display Name</th>
                <th width="120">Description</th>
              </tr>
              </thead>
              <tbody>
              {tableBody}
              </tbody>
            </Table>
            <TablePagination
              to={to}
              from={from}
              total={total}
              prevPageUrl={prevPageUrl}
              nextPageUrl={nextPageUrl}
              currentPage={currentPage}
              paginationButton={paginationButton}
              lastPage={lastPage}
              hideInfo={true}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button color="success" onClick={toggle}>Done</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default SelectPermission;
