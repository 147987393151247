import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, FormFeedback, FormGroup, Input, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import {connect} from "react-redux";
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import HorizontalToggle from "../../shared/form-helpers/HorizontalToggle";
import {createMenu, deleteMenu, getAllMenus, getMenu, updateMenu} from "../../apis/menus";
import {useDidMountEffect} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import HorizontalCheckbox from "../../shared/form-helpers/HorizontalCheckbox";
import {getRoles} from "../../apis/roles";
import "./custom-menu.css"
import {NavLink} from "react-router-dom";
import {Divider, Icon} from "antd";
import DeleteModal from "../../shared/form-helpers/DeleteModal";

const resetData = {
  name: "",
  filename: "",
  status: 1,
  type: ""
}

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function NewMenu(props) {
  let id = props.match.params.id
  const [data, setData] = useState({status: 1})
  const [error, setError] = useState({})
  const [parents, setParents] = useState([])
  const [roles, setRoles] = useState([])
  const [itemToDelete, setItemToDelete] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  let query = useQuery();
  useEffect(() => {
    fetchData()
    getRoles()
    getAllMenus();
  }, [])

  useDidMountEffect(() => {
    fetchData()
  }, [id])

  useDidMountEffect(() => {
    let {success, validation} = props.createMenu
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Menu Created"))
        onCancel()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.createMenu])

  useDidMountEffect(() => {
    let {success, validation} = props.updateMenu
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Menu Updated"))
        onCancel()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.updateMenu])

  useDidMountEffect(() => {
    let {success, validation} = props.getRoles
    if (success) {
      if (success.data) {
        setRoles(success.data.data)
      }
    }
  }, [props.getRoles])

  useEffect(() => {
    let {success, validation} = props.getAllMenus
    if (success) {
      if (success.data) {
        setParents(success.data.data)
      }
    }
  }, [props.getAllMenus])

  useDidMountEffect(() => {
    let {success, validation} = props.getMenu
    if (success) {
      if (success.data) {
        let _data = success.data
        if (_data) {
          let role_id = _data.roles.map(o => o.id)
          _data["role_id"] = role_id
        }
        setData(success.data)
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.getMenu])

  useDidMountEffect(() => {
    let {success, validation} = props.deleteMenu
    if (success) {
      if (success.data) {
        fetchData()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.deleteMenu])

  function fetchData() {
    if (id) {
      getMenu(id)
    }
  }

  const onCancel = () => {
    if (query.get("child") === "true")
      props.history.goBack()
    else
      props.history.push("/menus")
  }
  const onReset = () => {
    if (id)
      fetchData()
    else
      setData({...resetData})
  }
  const onSubmit = () => {
    if (id) {
      if (data.parent_id === 0) delete data.parent_id
      updateMenu(id, data)
    } else
      createMenu(data)
  }

  function onChange(e) {
    let {name, value} = e.target
    setData({...data, [name]: value})
    setError({...error, [name]: ""})
  }

  function onChangeRole(e) {
    let {value} = e.target
    let _data = data
    let role_id = data.role_id ? [...data.role_id] : []
    let index = role_id.indexOf(value * 1)
    if (index > -1) role_id.splice(index, 1)
    else role_id.push(value * 1)
    setData({...data, role_id: role_id})
    setError({...error, role_id: ""})
  }

  const toggleSwitch = () => {
    setData({
      ...data, status: !data["status"]
    })
  }

  function onDelete(id) {
    if (showDeleteModal) {
      setShowDeleteModal(false)
      setItemToDelete("false")
    } else {
      setShowDeleteModal(true)
      setItemToDelete(id)
    }
  }

  function onDeleteConfirm() {
    deleteMenu(itemToDelete)
    onDelete()
  }


  return (
    <div className="animated fadeIn">
      <DeleteModal
        open={showDeleteModal}
        toggle={onDelete.bind(this)}
        deleteItem={onDeleteConfirm.bind(this)}
        title="Delete Menu"
      />
      <Card className="top-page-content">
        <CardBody>
          <Row>
            {
              (!data.children || !data.children.length > 0) && <Col sm="3"></Col>
            }
            <Col sm="7">
              <Row>
                <Col xs="12">
                  <HorizontalTextField
                    name="name"
                    value={data.name}
                    onChange={(e) => onChange(e)}
                    error={error.name}
                  />
                </Col>
                {(!data.children || !data.children.length > 0) &&
                <Col xs="12">
                  <HorizontalSelect
                    name="parent_id"
                    value={data.parent_id}
                    onChange={(e) => onChange(e)}
                    error={error.parent_id}
                    keyval={["name", "id"]}
                    options={parents}
                  />
                </Col>
                }
                <Col xs="12">
                  <HorizontalTextField
                    name="url"
                    value={data.url}
                    onChange={(e) => onChange(e)}
                    error={error.url}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalTextField
                    name="icon"
                    value={data.icon}
                    onChange={(e) => onChange(e)}
                    error={error.icon}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalCheckbox
                    name="role_id"
                    value={data.role_id ? data.role_id : []}
                    onChange={(e) => onChangeRole(e)}
                    error={error.role_id}
                    options={roles ? roles : []}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalTextField
                    label="Priority"
                    name="order"
                    type="number"
                    value={data.order}
                    onChange={(e) => onChange(e)}
                    error={error.order}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalToggle
                    name="status"
                    status={data["status"]}
                    onChange={toggleSwitch}
                  />
                </Col>
              </Row>
            </Col>
            {
              (data.children && data.children.length > 0) &&
              <Col sm="5" className="b-l-1">
                <h4>Menu Hierarchy</h4>

                <ul className="custom-nav">
                  <li className="custom-nav-item custom-nav-dropdown open"><p
                    className="custom-nav-link custom-nav-dropdown-toggle" href="#"><i
                    className={`custom-nav-icon ${data.icon}`}></i>{data.name}</p>
                    <ul className="custom-nav-dropdown-items">
                      {data.children && data.children.map((o, i) => (
                        <li className="custom-nav-item child"><p className="custom-nav-link"><i
                          className={`custom-nav-icon ${o.icon}`}></i>{o.name}<small
                          className="text-muted ml-3">{o.url}</small></p>
                          <div className="actions">
                            <NavLink to={`/menus/${o.id}/edit?child=true`}>
                              <Icon type="edit" theme="twoTone" twoToneColor="#4dbd74" title="Edit"/>
                            </NavLink>
                            <Divider type="vertical"/>
                            <Icon type="delete" title="Delete" theme="twoTone" twoToneColor="#f86c6b"
                                  onClick={() => onDelete(o.id)}/>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </Col>
            }
          </Row>

        </CardBody>
        <CardFooter>
          <ButtonGroup className="float-right">
            <Button outline color="danger" onClick={onCancel}>Cancel</Button>
            <Button outline color="primary" onClick={onReset}>Reset</Button>
            <Button outline color="success" onClick={onSubmit}>Save</Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  );
}

function mapStateToProps(state) {
  let {
    createMenu,
    getMenu,
    updateMenu,
    getRoles,
    getAllMenus,
    deleteMenu
  } = state

  return {
    createMenu,
    getMenu,
    updateMenu,
    getRoles,
    getAllMenus,
    deleteMenu
  }
}

export default connect(mapStateToProps)(NewMenu);

