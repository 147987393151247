import {notification} from "antd";

export const showNotification = (message, variant, title,persistent) => {
  if (variant)
    notification[variant]({
      message: title,
      description: message,
      placement: 'bottomRight',
      duration: persistent ? 0 : 3,
    });
}
