export default function errorHandling(
  state = { no_internet: null, server_error: null, unauthorized: null},
  action
) {
  switch (action.type) {

    case 'NO_INTERNET':
      return {...state, ...{no_internet: true,server_error: null, unauthorized: null}};
    case 'SERVER_ERROR':
      return {...state, ...{server_error: true,no_internet: null, unauthorized: null}};
    case 'RESET_ERROR':
      return {...state, ...{server_error: null,no_internet: null, unauthorized: null}};
    case 'UNAUTHORIZED_ERROR': {
      return {...state, ...{server_error: null, no_internet: null, unauthorized: action.message}};
    }
    default:
      return state;
  }
}
