import React from 'react';
import {
  FormGroup,
  FormFeedback,
  Label
} from 'reactstrap';
import {stringUpperCase} from '../helpers/GeneralHelpers';
import SelectSearch from "../../shared/react-select-search/src/SelectSearch";

const SearchSelect = (props) => {
  let {error, onChange, value, name, options, placeholder,nolabel} = props;
  let label = stringUpperCase(name);
  return (
    <FormGroup>
      {nolabel ? "" : <Label>{label}</Label>}
      <SelectSearch
        options={options}
        value={parseInt(value)}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default SearchSelect;
