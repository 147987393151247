import React, {Component} from 'react'
import {connect} from "react-redux";
import {Button, Col, Input, Row} from "reactstrap";
import logo from "../../assets/img/brand/89_25.png";
import queryString from "query-string";
import {isEmptyObject} from "../../shared/helpers/ArrayKey";
import {setPassword, setResetPassword} from "../../actions/password/set_password";
import store from '../../Store'

class PasswordSet extends Component {
  state = {
    data: {
      password: ""
    },
    token: '',
    restSuccess: null,
    restError: null,
    message: null
  }

  componentDidMount() {
    const response = queryString.parse(this.props.location.search);
    if (isEmptyObject(response))
      this.props.history.push('/')
    if (response['token'])
      this.setState({token: response['token']})
    else
      this.props.history.push('/')
  }

  onChange(e) {
    this.setState({
      data: {
        password: e.target.value
      }
    })
  }

  onSubmit() {
    let data = {...this.state.data}
    data['token'] = this.state.token
    store.dispatch(setPassword(data))
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      setPassword
    } = nextProps

    let {
      restSuccess,
      restError
    } = prevState

    if (setPassword) {
      let {success, error} = setPassword
      if (success) {
        restSuccess = success.data.message
      } else if (error) {
        restError = error.data.message
      } else {
        restSuccess = null
        restError = null
      }
    }

    return {
      restSuccess,
      restError
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      restError,
      restSuccess
    } = this.state

    if (restError !== null) {
      let keys = Object.keys(restError)
      this.setState({
          message: restError[keys[0]]
        },
        () => {
          store.dispatch(setResetPassword())
        })
    }

    if (restSuccess !== null){
      this.props.history.push('/change-password-success',{message: restSuccess})
    }
  }

  onDismiss() {
    this.setState({
      message: null,
    });
  }

  render() {
    let {data} = this.state
    return (
      <div className="row justify-content-center" style={{background: '#ffffff', minHeight: '100vh'}}>
        <Col md="9" sm="12">
          <div className="right-side-div">
            <div>
              <img src={logo} className="right-side-payment-logo" alt="GERP"/>
            </div>
            <div>
              <p className="right-side-detail text-center">
                Enter the new password below
              </p>
            </div>
            <Row className="justify-content-center">
              <Col md="4">
                <Input type="password" name="password" value={data.password} onChange={(e) => this.onChange(e)}/>
              </Col>
            </Row>
            <br/>
            <Row className="justify-content-center">
              <Col md="1">
                <Button color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
              </Col>
            </Row>
            <hr/>
            <div>
              <p className="form-span-label text-center confirmation-texttext-center">
                Go back to <span className="span-link">landing</span> screen.
              </p>
            </div>
          </div>
        </Col>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let {
    setPassword
  } = state

  return {
    setPassword
  }
}

export default connect(mapStateToProps)(PasswordSet)
