import React, {Component} from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import nepaliCalendar from '../../shared/nepali-calendar-dates'
import {getYears} from "../../shared/helpers/GeneralHelpers";
import DateInput from "./DateInput";
import Label from "reactstrap/es/Label";

let months = [
  {label: "Baisakh", value: 1},
  {label: "Jestha", value: 2},
  {label: "Asadhh", value: 3},
  {label: "Shrawan", value: 4},
  {label: "Bhadra", value: 5},
  {label: "Ashwin", value: 6},
  {label: "Kartik", value: 7},
  {label: "Mangsir", value: 8},
  {label: "Poush", value: 9},
  {label: "Magh", value: 10},
  {label: "Falgun", value: 11},
  {label: "Chaitra", value: 12}
]

class ReportFilter extends Component {
  componentDidMount() {
    let currentDate = new Date()
    let nepali_date = nepaliCalendar.toNepali(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
    let years = getYears(nepali_date.ny)
    this.setState({
      data: {
        month: nepali_date.nm,
        year: nepali_date.ny
      },
      years
    })
  }

  state = {
    data: {
      month: 0,
      year: 0
    },
    years: []
  }

  onChange(e) {
    let {name, value} = e.target;
    let data = {...this.state.data};
    if(name !== "year" || value){
      data[name] = value;
    }
    this.setState({data}, () => {
      if (name !== "from" && name !== "to")
        this.props.changeDate(data)
      else{
        if(data["from"] && data["to"]){
          this.props.changeRange({from:data.from, to: data.to})
        }
      }
    });
  }


  render() {
    let {
      searchValue,
      searchChange,
      isPurchase,
      poses,
      reseller,
      reportType
    } = this.props

    let {data} = this.state

    return (
      <Card>
        <CardBody>
          <Row>
            <div className="col-auto">
              <Input placeholder={`Search by ${reportType === "purchase" ? "Vendor" : "Customer"} name`}
                     value={searchValue}
                     onChange={searchChange} name="customer_name"/>
            </div>
            <div className="col-auto">
              <HorizontalSelect name="month" options={months} value={data.month} onChange={(e) => this.onChange(e)}/>
            </div>
            <div className="col-auto">
              <HorizontalSelect name="year" options={this.state.years} value={data.year}
                                onChange={(e) => this.onChange(e)}/>
            </div>
            {this.props.reportType === "sales" && <React.Fragment>
              <div className="col-auto">
                <HorizontalSelect name="pos" options={poses} value={data.pos} onChange={searchChange}/>
              </div>
              <div className="col-auto">
                <HorizontalSelect name="reseller" options={reseller} value={data.reseller} onChange={searchChange}/>
              </div>
              <div className="col-auto">
                <Row>
                  <Col md="3"><Label>Range</Label></Col>
                  <Col xs="12" md="9">
                    <DateInput name="from" value={data.from} onChange={(e) => this.onChange(e)}/>
                  </Col>
                </Row>
              </div>
              <div className="col-auto">
                <DateInput name="to" value={data.to} onChange={(e) => this.onChange(e)}/>
              </div>
            </React.Fragment>}
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default ReportFilter
