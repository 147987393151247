import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "taxes"

export const createTax = (data) => {
  let reducers = {
    success: 'CREATE_TAX_SUCCESS',
    validation_error: 'CREATE_TAX_VALIDATION_ERROR',
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteTax = (id) => {
  let reducers = {
    success: 'DELETE_TAX_SUCCESS',
    error: 'DELETE_TAX_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getTax = (id) => {
  let reducers = {
    success: 'FETCH_TAX_SUCCESS',
    error: 'FETCH_TAX_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getTaxes = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_TAXES_SUCCESS',
    error: 'FETCH_TAXES_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const updateTax = (id, data) => {
  let reducers = {
    success: 'UPDATE_TAX_SUCCESS',
    validation_error: 'UPDATE_TAX_VALIDATION_ERROR',
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const resetUpdateTax = () => {
  store.dispatch(resetData('RESET_UPDATE_TAX'))
}

export const resetCreateTax = () => {
  store.dispatch(resetData('RESET_CREATE_TAX'))
}

export const resetDeleteTax = () => {
  store.dispatch(resetData('DELETE_RESET_TAX'))
}
