import "../actions/crud/get_group";
import {Create} from "../actions/crud/create";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "backup"

export function listDb(limit = null, page = 1, filters = []) {
  let reducers = {
    success: 'LIST_DB_SUCCESS',
    error: 'LIST_DB_ERROR'
  }
  let group =new GetGroup(limit, page, filters,prefix,reducers)
  group.getGroup()
}

export function recoveryDb(data){
  let reducers = {
    success: 'RECOVER_DB_SUCCESS',
    error: 'RECOVER_DB_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}
