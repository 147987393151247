export default function createPaymentGateway(
  state = { validation: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'CREATE_PAYMENT_GATEWAY_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_PAYMENT_GATEWAY_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null}};
    case 'CREATE_RESET_PAYMENT_GATEWAY':
      return {...state, ...{success: null,validation: null}};
    default:
      return state;
  }
}
