export default function forgotPassword(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {
    case "FORGOT_PASSWORD_SUCCESS":
      return { ...state, ...{ success: action.success, error: null } };
    case "FORGOT_PASSWORD_ERROR":
      console.log(action);
      return { ...state, ...{ success: null, error: action.error.response } };
    case "FORGOT_PASSWORD_PROCESSING":
      return { ...state, ...{ processing: action.processing } };
    case "FORGOT_RESET_PASSWORD":
      return { ...state, ...{ success: null, error: null,processing: null } };
    default:
      return state;
  }
}
