
export const setFilter = (path, data) => {
  let temp = JSON.stringify(data)
  localStorage.setItem(path, temp)
  // setFetch(path)
}

export const getFilter = (path) => {
  let temp = localStorage.getItem(path)
  if (!!temp)
    return JSON.parse(temp)
  return null
}

export const setFetch = (data) => {
  localStorage.setItem("fetch", data)
}

export const getFetch = () => {
  return localStorage.getItem("fetch")
}
