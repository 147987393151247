import {GetGroup} from "../actions/crud/get_group"

let prefix = "bin-log"

export const getBinaryLog = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_BINARY_LOG_SUCCESS',
    error: 'FETCH_BINARY_LOG_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}
