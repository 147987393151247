import {GetGroup} from "../actions/crud/get_group";

let prefix = "sales-registers"
let reducers = {
  success: '',
  error: ''
}

export const getSalesRegisters = (limit, page = 1, filters = [],dateFilter = {}) => {
  reducers['success'] = 'FETCH_SALES_REGISTERS_SUCCESS'
  reducers['error'] = 'FETCH_SALES_REGISTERS_ERROR'
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers, dateFilter)
  getGroup.getGroup()
}
