import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";
import {Update} from "../actions/crud/update";
import {Remove} from "../actions/crud/remove";

const prefix = "payment-gateways"

export const createPaymentGateway = (data,id) => {
  let reducers = {
    success: 'CREATE_PAYMENT_GATEWAY_SUCCESS',
    validation_error: 'CREATE_PAYMENT_GATEWAY_VALIDATION_ERROR'
  }
  let url = prefix
  if(id) url = prefix + `/${id}`
    let create = new Create(url, reducers, data)
    create.create()
}

export const getInactivePaymentGateways = () => {
  let reducers = {
    success: 'FETCH_INACTIVE_PAYMENT_GATEWAYS_SUCCESS',
    error: 'FETCH_INACTIVE_PAYMENT_GATEWAYS_ERROR'
  }
  let temp = [{key: 'status',value: 0}]
  let group = new GetGroup(null, null, temp, prefix, reducers,{},true)
  group.getGroup()
}

export const getKeys = (id) => {
  let reducers = {
    success: 'FETCH_KEYS_SUCCESS',
    error: 'FETCH_KEYS_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getPaymentGateway = (id) => {
  let reducers = {
    success: 'FETCH_PAYMENT_GATEWAY_SUCCESS',
    error: 'FETCH_PAYMENT_GATEWAY_ERROR'
  }
  let url = prefix + `/${id}/config`
  let get = new Get(url, reducers)
  get.get()
}

export const getPaymentGateways = () => {
  let reducers = {
    success: 'FETCH_PAYMENT_GATEWAYS_SUCCESS',
    error: 'FETCH_PAYMENT_GATEWAYS_ERROR'
  }
  let temp = []
  let group = new GetGroup(null, null, temp, prefix, reducers)
  group.getGroup()
}

export const getAdminPaymentGateways = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_ADMIN_PAYMENT_GATEWAYS_SUCCESS',
    error: 'FETCH_ADMIN_PAYMENT_GATEWAYS_ERROR'
  }
  let temp = []
  let url = prefix + `/all`
  let group = new GetGroup(limit, page, filters, url, reducers)
  group.getGroup()
}

export const getAdminPaymentGateway = (id) => {
  let reducers = {
    success: 'FETCH_ADMIN_PAYMENT_GATEWAY_SUCCESS',
    error: 'FETCH_ADMIN_PAYMENT_GATEWAY_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const deleteAdminPaymentGateway = (id) => {
  let reducers = {
    success: 'DELETE_ADMIN_PAYMENT_GATEWAY_SUCCESS',
    error: 'DELETE_ADMIN_PAYMENT_GATEWAY_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const createResetPaymentGateway = () => {
  store.dispatch(resetData('CREATE_RESET_PAYMENT_GATEWAY'))
}
