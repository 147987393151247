import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {getCustomer, updateCustomer, updateResetCustomer} from "../../apis/customers";
import { getCountries} from "../../apis/countries";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {localValidation} from "../../shared/helpers/ValidationHelper";
import {Switch} from 'antd'

class EditCustomer extends Component {

  data = {
    type: "C",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    website: "",
    address: "",
    mobile_no: "",
    zip_code: "",
    state: "",
    country_id: "",
    city: ""
  }

  error = {}
  state = {
    company: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    restData: null,
    countries: [],
    provinces: [],
    isSuccess: null,
    processing: false
  }

  fetchData() {
    getCountries(1000)
    getCustomer(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData();
  }

  changeCompany() {
    let {data} = this.state
    data['type'] = "C"
    console.log(data)
    this.setState({
      company: true,
      data
    })
  }

  changePerson() {
    let {data} = this.state
    data['type'] = "I"
    this.setState({
      company: false,
      data
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/sales/customers")
  }

  onReset() {
    this.setState({
      data: {...this.data},
      error: {...this.error}
    })
  }

  onSubmit() {
    let validationRule = {
      last_name: ['required','alphabet'],
      first_name: ['alphabet'],
      email: ['email'],
      website: ['url'],
      phone_no: ['phone'],
      mobile_no: ['mobile'],
      pan: ["pan"],
      address: ['required'],
      city: ['required'],
      country_id: ['required'],
      state: ['required'],
      zip_code: ['zip']
    }

    let data = {...this.state.data}

    let localValidationStatus = false
    let check = localValidation(data,validationRule,this.state.error,localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    }
    else {
      if (data['zip_code'] === 0){
        delete data['zip_code']
      }
      updateCustomer(this.props.match.params.id, data)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {updateCustomer, getCountries, getCustomer} = nextProps
    let {restError, countries, provinces, restData, isSuccess,processing} = prevState
    if (updateCustomer) {
      let {success,validation} = updateCustomer
      if (success) {
        isSuccess = success.data.message
      }
      else if (validation)
        restError = validation
      else {
        restError = null
        isSuccess = null
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      }
      else if (error) {
        console.log(error)
      }
    }

    if (getCustomer) {
      let {success, error} = getCustomer
      if (success) {
        restData = success.data
      }
      else if (error) {
        console.log(error)
      }
      processing = getCustomer.processing
    }
    return {
      restError, countries, provinces, restData, isSuccess,processing
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
        error: this.state.restError
      },()=>{
        updateResetCustomer()
      })
    }

    if (this.state.restData !== prevState.restData) {
      this.data = this.state.restData
      let temp = false;
      if (this.data.type === "C")
        temp = true
      this.setState({
        data: this.data,
        company: temp
      })
    }

    if (this.state.isSuccess !== null) {
      store.dispatch(addMessage(this.state.isSuccess,"success", "Update Customer"))
      updateResetCustomer()
      setFetch(true)
      this.props.history.push('/sales/customers');
    }
  }

  changeSwitch(e){
    let data = {...this.state.data}
    data['status'] = e
    this.setState({data})
  }

  render() {
    let {data, error, countries, provinces} = this.state

    let countryList = countries.map((country) => (
        <option value={country.id}>{country.name}</option>
      )
    )

    let provincesList = provinces[data.country_id] && provinces[data.country_id].map((province) => (
      <option value={province.id}>{province.name}</option>
    ))

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col>
                    <ButtonGroup>
                      <Button color="primary" outline={!this.state.company}
                              onClick={this.changeCompany.bind(this)}>Company</Button>
                      <Button color="primary" outline={this.state.company}
                              onClick={this.changePerson.bind(this)}>Individual</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <br/>
                {this.state.company ? <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Company Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.last_name} className="form-control"
                             name="last_name" value={data.last_name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.last_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row> : <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">First Name</span>
                    <FormGroup>
                      <Input invalid={!!error.first_name} className="form-control"
                             name="first_name" value={data.first_name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.first_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Last Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.last_name} className="form-control"
                             name="last_name" value={data.last_name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.last_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Email</span>
                    <FormGroup>
                      <Input invalid={!!error.email} className="form-control" name="email"
                             onChange={e => this.onChange(e)} value={data.email}/>
                      <FormFeedback>{error.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Website</span>
                    <FormGroup>
                      <Input invalid={!!error.website} className="form-control" name="website"
                             value={data.website}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.website}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Phone Number</span>
                    <FormGroup>
                      <Input invalid={!!error.phone_no} className="form-control"
                             name="phone_no" value={data.phone_no}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.phone_no}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Mobile Number</span>
                    <FormGroup>
                      <Input invalid={!!error.mobile_no} className="form-control"
                             name="mobile_no" value={data.mobile_no}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.mobile_no}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">PAN number</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.pan} className="form-control"
                             name="pan" value={data.pan}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.pan}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Status</span>
                    <FormGroup>
                      <Switch checked={data.status} name="status" onChange={this.changeSwitch.bind(this)}/>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <span className="text-danger confirmation-text">Any one of the Mobile Number  or Phone Number is required</span>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Address</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Billing Address</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.address} className="form-control" placeholder="Billing Address"
                             name="address" value={data.address}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.address}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">City</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.city} className="form-control" placeholder="City" name="city"
                             onChange={e => this.onChange(e)} value={data.city}/>
                      <FormFeedback>{error.city}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Country</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.country_id} className="form-control" name="country_id"
                             onChange={e => this.onChange(e)} value={data.country_id}>
                        <option>Country</option>
                        {countryList}
                      </Input>
                      <FormFeedback>{error.country_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">State</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.state} className="form-control" name="state"
                             onChange={e => this.onChange(e)} value={data.state}>
                        <option>State</option>
                        {provincesList}
                      </Input>
                      <FormFeedback>{error.state}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Postal/Zip Code</span>
                    <FormGroup>
                      <Input invalid={!!error.zip_code} className="form-control"
                             name="zip_code" value={data.zip_code}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.zip_code}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {updateCustomer, getCountries, getCustomer} = state
  return {
    updateCustomer, getCountries, getCustomer
  }
}

export default connect(mapStateToProps)(EditCustomer);
