import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Label
} from 'reactstrap';
import {stringUpperCase} from '../helpers/GeneralHelpers';
import SelectSearch from "../react-select-search/src/SelectSearch";

const HorizontalSelectSearch = (props) => {
  let {error, onChange, value, name, options,placeholder,isRequired,onFetch} = props;
  let label = stringUpperCase(name);

  return (
    <FormGroup row>
      <Col md="3">
        <Label>{label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <SelectSearch
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          onOptionsRefresh={onFetch}
          internalSearch={true}
        />
        <span className="custom-invalid-feedback">{error}</span>
      </Col>
    </FormGroup>
  );
};

export default HorizontalSelectSearch;
