import React, {Component} from 'react'
import {connect} from "react-redux";
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, Input, Row, Table} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import SelectBox from "../../shared/form-helpers/SelectBox";
import HorizontalTextArea from "../../shared/form-helpers/HorizontalTextArea";
import {Config} from "../../Config";
import PrintInvoiceModal from "../Invoice/PrintInvoiceModal";
import ViewData from "../../shared/form-helpers/ViewData";
import {getBilling} from "../../apis/billings";
import {createPurchaseReturn, createResetPurchaseReturn} from "../../apis/purchase-returns";
import {getTaxes} from "../../apis/taxes";
import {getUnits} from "../../apis/units";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {isNumber} from "../../shared/helpers/GeneralHelpers";
import {setFetch} from "../../shared/helpers/FilterHelper";

class NewBilling extends Component {

  state = {
    data: {
      details: []
    },
    details: [],
    vatOption: [],
    unitsOptions: [],
    datas: {},
    createPurchaseReturnSuccess: null,
    createPurchaseReturnStatus: false,
    vendor_code: null,
    url: null,
    error: {},
    username: "",
    purchaseRestError: null,
    restErrorMessage: null,
    errorMessage: "",
    returnItem: {},
    index: -1,
    debitDetails: [],
    pos: "",
    selected_array: [],
    current_index: null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.datas !== prevState.datas) {
      this.setState({
        data: this.state.datas,
        details: this.state.datas.details
      })
    }

    if (this.state.createPurchaseReturnSuccess !== null) {
      this.setState({
        createPurchaseReturnStatus: true
      }, () => {
        setFetch(true)
        createResetPurchaseReturn()
      })
    }

    if (this.state.purchaseRestError !== null) {
      let error = this.state.purchaseRestError
      this.setState({
        error
      }, () => {
        createResetPurchaseReturn()
      })
    }

    if (this.state.restErrorMessage !== null) {
      this.setState({
        errorMessage: this.state.restErrorMessage
      }, () => {
        createResetPurchaseReturn()
      })
    }
  }

  fetchData() {
    getBilling(this.props.match.params.id)
    getUnits(1000)
    getTaxes()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getTaxes,
      getUnits,
      getBilling,
      createPurchaseReturn,
      getLoggedInUser
    } = nextProps
    let {
      datas,
      vatOption,
      unitsOptions,
      createPurchaseReturnSuccess,
      vendor_code,
      url,
      username,
      purchaseRestError,
      restErrorMessage,
      pos
    } = prevState

    if (getBilling) {
      let {success} = getBilling
      if (success) {
        datas = success.data
      }
    }

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        username = success.data.name
        pos = success.data.pos
      }
    }

    if (getTaxes) {
      let {success} = getTaxes
      if (success) {
        vatOption = success.data.data
      }
    }

    if (getUnits) {
      let {success} = getUnits
      if (success) {
        unitsOptions = success.data.data
      }
    }

    if (createPurchaseReturn) {
      let {success, validation, bad_gateway} = createPurchaseReturn
      if (success) {
        if (success.status === 200) {
          createPurchaseReturnSuccess = success.data
          vendor_code = success.data.payment_id
          url = Config.BillingUrl + 'purchase-returns/' + success.data.id + '?q=print'
        }
      } else if (validation) {
        purchaseRestError = validation
      } else if (bad_gateway) {
        restErrorMessage = bad_gateway
      } else {
        createPurchaseReturnSuccess = null
        purchaseRestError = null
        restErrorMessage = null
      }
    }

    return {
      datas,
      vatOption,
      unitsOptions,
      createPurchaseReturnSuccess,
      vendor_code,
      url,
      username,
      purchaseRestError,
      restErrorMessage,
      pos
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/purchases/billings")
  }

  onReset() {
    let {returnItem} = this.state
    returnItem['product_name'] = ""
    returnItem['quantity'] = ""
    returnItem['return_rate'] = ""
    returnItem['vat_rate'] = ""
    returnItem['total'] = ""
    this.setState({
      // data: {...this.data},
      product: {...this.product},
      returnItem: returnItem,
      error: {...this.error},
      productError: {...this.productError}
    })
  }

  onSubmit() {
    let data = {...this.state.data}
    data['billing_id'] = data['id']
    data['created_by'] = this.state.username
    data['pos'] = this.state.pos.name
    data['details'] = this.state.debitDetails
    createPurchaseReturn(data)
  }

  togglePrintModal() {
    this.setState({
      createPurchaseReturnStatus: !this.state.createPurchaseReturnStatus
    }, () => {
      this.props.history.push('/purchases/billings/')
    })
  }

  productChange(e) {
    let returnItem = {...this.state.returnItem}
    let value = e.target.value
    let position = value.split("_")
    let current_index = null
    if (position[1])
      current_index = position[1]
    let index = 0;
    if (value === "0") {
      index = -1
      returnItem['product_name'] = value
      returnItem['quantity'] = 0
      returnItem['return_rate'] = 0
      returnItem['vat_rate'] = 13
      returnItem['total'] = 0
    } else {
      let details = [...this.state.details]
      index = value.split('_')[1]
      returnItem['product_name'] = value
      returnItem['quantity'] = details[index].qty
      returnItem['return_rate'] = 0
      returnItem['vat_rate'] = 13
      returnItem['total'] = 0
    }
    this.setState({
      returnItem,
      index,
      current_index
    })
  }

  changeQuantity(e, maxQty) {
    if (isNumber(e.target.value)) {
      if (e.target.value <= maxQty && e.target.value > 0) {
        let returnItem = {...this.state.returnItem}
        returnItem['quantity'] = e.target.value
        returnItem['total'] = parseFloat(returnItem['quantity']) * parseFloat(returnItem['return_rate']) + (parseFloat(returnItem['vat_rate']) * (parseFloat(returnItem['quantity'] * parseFloat(returnItem['return_rate'])) / 100))
        this.setState({
          returnItem
        })
      } else if (e.target.value < 1) {
        store.dispatch(addMessage("Minimum 1 quantity must be returned.", "error", "Debit Note"))
      } else {
        store.dispatch(addMessage("Maximum " + maxQty + " quantity can be returned.", "error", "Debit Note"))
      }
    }
  }


  changeAmount(e, maxAmount) {
    if (isNumber(e.target.value) || e.target.value === "") {
      if (e.target.value <= maxAmount) {
        let returnItem = {...this.state.returnItem}
        returnItem['return_rate'] = e.target.value.replace(/^0+/, '') | 0
        returnItem['total'] = (parseFloat(returnItem['quantity']) * parseFloat(returnItem['return_rate']) + (parseFloat(returnItem['vat_rate']) * (parseFloat(returnItem['quantity'] * parseFloat(returnItem['return_rate'])) / 100))).toFixed(2)
        this.setState({
          returnItem
        })
      } else {
        store.dispatch(addMessage("Maximum return rate is " + maxAmount, "error", "Debit Note"))
      }
    }
  }

  onAddDebit() {
    let debitDetails = [...this.state.debitDetails]
    let selected_array = [...this.state.selected_array]
    let returnItem = {...this.state.returnItem}
    let {details, index} = this.state
    if (returnItem.return_rate <= 0) {
      store.dispatch(addMessage("Return rate cannot be 0.", "error", "Debit Note"))
    } else {
      if (index !== -1) {
        let temp = {...details[index]}
        temp['qty'] = returnItem.quantity
        temp['amount'] = returnItem.return_rate
        temp['vat_rate'] = returnItem.vat_rate
        temp['total'] = returnItem.total
        temp['taxable_amount'] = returnItem.return_rate
        temp['vat_amount'] = parseFloat(temp['vat_rate']) * parseFloat(temp['taxable_amount']) / 100

        debitDetails.push(temp)

        returnItem['product_name'] = 0
        returnItem['quantity'] = 0
        returnItem['return_rate'] = 0
        returnItem['vat_rate'] = 13
        returnItem['total'] = 0

        selected_array.push("" + this.state.current_index)

        this.setState({
          debitDetails,
          returnItem,
          index: -1,
          selected_array
        }, () => {
          this.changeSubTotal()
        })
      }
    }
  }

  changeSubTotal() {
    let subTotal = 0.0;
    let vatAmount = 0.0;
    let data = {...this.state.data}
    this.state.debitDetails.forEach((single) => {
      subTotal = parseFloat(single.taxable_amount) + parseFloat('' + subTotal)
      vatAmount = parseFloat(single.vat_amount) + parseFloat('' + vatAmount)
    })
    subTotal = parseFloat('' + subTotal).toFixed(2)
    vatAmount = parseFloat('' + vatAmount).toFixed(2)
    let rate = parseFloat(data.discount_rate).toFixed(2)
    let discount = parseFloat(subTotal * rate / 100).toFixed(2)
    let taxable_amount = subTotal - discount
    let total = parseFloat(subTotal) - parseFloat(discount) + parseFloat(vatAmount)
    total = total.toFixed(2)
    data.amount = subTotal
    data.vat_amount = vatAmount
    data.total = total
    data.discount_amount = discount
    data.taxable_amount = taxable_amount
    this.setState({
      data
    })
  }

  onRemoveDebit(id) {
    id--
    let debitDetails = [...this.state.debitDetails]
    debitDetails.splice(id, 1)
    this.setState({
      debitDetails
    })
  }


  render() {
    let {
      data,
      vatOption,
      unitsOptions,
      createPurchaseReturnStatus,
      url,
      vendor_code,
      error,
      returnItem,
      debitDetails
    } = this.state

    let vatRate = vatOption ? vatOption.map((vat) => {
      return {
        label: vat.name,
        value: parseFloat(vat.rate).toFixed(2)
      }
    }) : []

    let unitList = unitsOptions.map((unit) => {
      return {
        label: unit.name,
        value: unit.name
      }
    })

    let showProducts = data.details && data.details.map((product, o) => {
      return (
        <tr>
          <td><input className="form-control" type="text" value={product.product_name}/></td>
          <td><input className="form-control" name={'qty_' + o} type="number" value={product.qty}/></td>
          <td><SelectBox name={'unit_' + o} options={unitList} noLabel={true}/></td>
          <td><input className="form-control" type="text" value={product.amount}/></td>
          <td><input className="form-control" type="text" value={product.amount}/></td>
          <td><SelectBox name={'vat_rate_' + o} value={product.vat_rate}
                         options={vatRate} noLabel={true}/></td>
          <td><input className="form-control" value={product.vat_amount}/></td>
          <td><input className="form-control" value={product.total}/></td>
        </tr>
      );
    })

    let typeOptions = [
      {
        label: 'Capital',
        value: 'capital'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Import',
        value: 'import'
      }
    ]

    let count = 0;
    let allowedRate = []
    let allowedQuantity = []

    let tablebody = data.details && data.details.map((product, o) => {
      allowedRate[count] = product.taxable_amount / product.qty
      allowedQuantity[count] = product.qty
      return (
        <tr key={product.id}>
          <td>{product.product_name}</td>
          <td>{product.qty}</td>
          <td>{product.taxable_amount / product.qty}</td>
          <td>{product.vat_rate}</td>
          <td>{product.total}</td>
        </tr>
      );
    })

    let returnItemOption = data.details ? data.details.map((detail, o) => {
      let {product_name} = detail
      let {selected_array} = this.state
      if (selected_array.includes("" + o)) {
        return null
      }
      return (
        <option value={product_name + '_' + o}>{product_name}</option>
      );
    }) : []

    let debitDataRows = debitDetails.map((credit) => {
      let {id, product_name, qty, amount, vat_rate, total} = credit
      temp++
      return (
        <tr key={id}>
          <td>{product_name}</td>
          <td>{qty}</td>
          <td>{amount}</td>
          <td>{vat_rate}</td>
          <td>{total}</td>
          <td>
            <Button className="btn-sm" color="danger" title="Add Debit Note"
                    onClick={() => this.onRemoveDebit(temp)} outline>
              <i className="fa fa-times"></i>
            </Button>
          </td>
        </tr>
      )
    })

    let temp = <Row className="top-page-content">
      <Col xs="12" sm="12">
        <Card>
          <CardBody>
            <Row>
              <Col xs="12" sm="6" md="6">
                <HorizontalTextField
                  name={"vendor_name"}
                  value={data.vendor_name}
                />
                <HorizontalTextField
                  name="fiscal_year"
                  value={data.fiscal_year}
                />
              </Col>
              <Col xs="12" sm="6" md="6">
                <HorizontalSelect
                  name="type"
                  value={data.type}
                  options={typeOptions}
                />
                <HorizontalTextField
                  name="billing_no"
                  value={data.billing_no}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                  <tr>
                    <th width="60" className="text-center">Name</th>
                    <th width="50" className="text-center">Qty</th>
                    <th width="80" className="text-center">Unit</th>
                    <th width="80" className="text-center">Amount</th>
                    <th width="80" className="text-center">Taxable Amount</th>
                    <th width="100" className="text-center">VAT Rate</th>
                    <th width="50" className="text-center">VAT Amount</th>
                    <th width="50" className="text-center">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  {data.details ? data.details.length > 0 ? showProducts : <tr>
                    <td className="text-center" colSpan="8">No Items To Show</td>
                  </tr> : <tr>
                    <td className="text-center" colSpan="8">No Items To Show</td>
                  </tr>}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6" md="6">
                <HorizontalTextArea
                  rows="3"
                  name="remarks"
                  value={data.remarks}
                  error={error.remarks}
                  onChange={e => this.onChange(e)}
                  isRequired={true}
                />
              </Col>
              <Col xs="12" sm="6" md="6">
                <HorizontalTextField
                  name="subtotal"
                  value={data.amount}
                />
                <hr/>
                <HorizontalTextField
                  name="VAT"
                  value={data.vat_amount}
                />
                <hr/>
                <HorizontalTextField
                  name="total"
                  value={data.total}
                />
                <hr/>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </Col>
    </Row>

    return (
      <div className="animated fadeIn">

        <PrintInvoiceModal
          isOpen={createPurchaseReturnStatus}
          toggle={this.togglePrintModal.bind(this)}
          url={url}
          customer_code={vendor_code}
          email={false}
        />
        <Card className="top-page-content">
          <CardBody>
            <Row>
              <Col className="text-center">
                <span className="report-page-title">Billing Details</span>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Reference Bill Number"
                  data={data.billing_no}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Venodr Name"
                  data={data.vendor_name}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Payment Id"
                  data={data.payment_id}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Fiscal Year"
                  data={data.fiscal_year}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Type"
                  data={data.type}
                />
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm="12">
                <Table responsive>
                  <thead>
                  <tr>
                    <th width="120">Items</th>
                    <th width="50">Quantity</th>
                    <th width="50">Rate</th>
                    <th width="50">VAT(%)</th>
                    <th width="50">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tablebody}
                  </tbody>
                </Table>
                <hr/>
              </Col>
            </Row>
            <Row><Col>
              <span className="notes-data-return">
                {`Note: Purchase total return amount should not exceed the above limit. Total Allowed Amount Rs. ${data['remaining-amount']}`}
              </span>
            </Col></Row>
            <Row>
              <Col className="text-center">
                <span className="report-page-title">Debit Note</span>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                  <tr>
                    <th width="120">Items&nbsp;<span className="text-danger">*</span></th>
                    <th width="50">Quantity&nbsp;<span className="text-danger">*</span></th>
                    <th width="50">Return Rate&nbsp;<span className="text-danger">*</span></th>
                    <th width="50">VAT(%)&nbsp;<span className="text-danger">*</span></th>
                    <th width="50">Total&nbsp;<span className="text-danger">*</span></th>
                    <th width="50"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <Input type="select" name="product_name" onChange={(e) => this.productChange(e)}
                             value={returnItem.product_name} invalid={!!error.details}>
                        <option value="0"> -- Select Items --</option>
                        {returnItemOption}
                      </Input>
                    </td>
                    <td>
                      <Input value={returnItem.quantity}
                             onChange={(e) => this.changeQuantity(e, returnItem.product_name ? allowedQuantity[returnItem.product_name.split("_")[1]] : null)}
                             invalid={!!error.details} className="text-right"/>
                    </td>
                    <td>
                      <Input value={returnItem.return_rate}
                             onChange={(e) => this.changeAmount(e, returnItem.product_name ? allowedRate[returnItem.product_name.split("_")[1]] : null)}
                             invalid={!!error.details} className="text-right"/>
                    </td>
                    <td>{returnItem.vat_rate || 0}</td>
                    <td>{returnItem.total || 0}</td>
                    <td>
                      <Button className="btn-sm" color="success" title="Add Debit Note"
                              onClick={this.onAddDebit.bind(this)}>
                        Add
                      </Button>
                    </td>
                  </tr>
                  {debitDataRows}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6">
                <HorizontalTextArea
                  name="remarks"
                  value={data.remarks}
                  error={error.remarks}
                  onChange={e => this.onChange(e)}
                  isRequired={true}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    createPurchaseReturn,
    getUnits,
    getTaxes,
    getBilling,
    getLoggedInUser
  } = state

  return {
    createPurchaseReturn,
    getUnits,
    getTaxes,
    getBilling,
    getLoggedInUser
  }
}

export default connect(mapStateToProps)(NewBilling)
