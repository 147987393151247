export default function uploadImage(
  state = { error: null, success: null, validation: null},
  action
) {
  switch (action.type) {

    case 'UPLOAD_IMAGE_SUCCESS':
      return {...state, ...{success: action.success, validation: null, error: null}};
    case 'UPLOAD_IMAGE_ERROR':
      return {...state, ...{error: action.error.response, validation: null, success: null}};
    case 'UPLOAD_RESET_IMAGE':
      return {...state, ...{error:null,success:null, validation: null}};
    case 'UPLOAD_IMAGE_VAIDATION_ERROR':
      return {...state, ...{error:null,success:null, validation: action.validation}};
    default:
      return state;
  }
}
