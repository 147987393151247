import {GetGroup} from "../actions/crud/get_group"

let prefix = "logs"

export const getActivityLog = (limit, page = 1, filters = [], dateFilter={}) => {
  let reducers = {
    success: 'FETCH_ACTIVITY_LOG_SUCCESS',
    error: 'FETCH_ACTIVITY_LOG_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}
