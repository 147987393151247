import React, {Component} from 'react'
import {connect} from "react-redux";
import store from '../../Store'
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {addMessage} from "../../actions/messages/success_message";
import MessageModal from "../../shared/modals/MessageModal";
import {recoveryDb} from "../../apis/backup";

class Recovery extends Component {

  state = {
    showConfirmModal: false,
    data: {
      recover_database: null
    },
    filename: null,
    message: null
  }

  static getDerivedStateFromProps(nextProps,prevState){
    let {
      recoveryDb
    } = nextProps

    let {
      message
    } = prevState

    if (recoveryDb){
      let {success} = recoveryDb
      if (success){
        message = success.data.message
      }
    }

    return {
      message
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.message !== null){
      store.dispatch(addMessage(this.state.message, "success", "Recover Database"));
      this.setState({
        showConfirmModal: false,
        message: null
      })
    }
  }

  onRecover() {
    if (this.state.data.recover_database !== null)
      this.onToggle()
    else
      store.dispatch(addMessage("Please upload sql file.", "error","Recovery"));
  }

  onToggle() {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal
    })
  }

  onConfirm() {
    let {data} = this.state
    const formData = new FormData();
    formData.append("recovery_file", data.recover_database)
    recoveryDb(formData)
  }

  onUpload(e) {
    let data = {...this.state.data}
    let  filename = this.state.filename
    if (e.target.files[0]) {
      data['recover_database'] = e.target.files[0]
      filename = e.target.files[0].name
      this.setState({data,filename})
    }
  }

  render() {
    let {showConfirmModal,filename} = this.state

    return (
      <div className="animated fadeIn">
        <MessageModal
          isOpen={showConfirmModal}
          title="Recover Database"
          message="All the contents in database will be lost. Are you sure you want to recover ?"
          toggle={this.onToggle.bind(this)}
          onConfirm={this.onConfirm.bind(this)}
        />
        <Card className="empty-div">
          <CardBody>
            <Row><Col className="text-center">
              <span className="report-page-title">Recover Database</span>
            </Col></Row>
            <Row>
              <Col>
                <div className="upload-btn-wrapper file-input">
                  <Button className="btn" outline color="success">Upload sql file</Button>
                  <input type="file" name="myfile" accept=".sql" onChange={(e)=>this.onUpload(e)}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <span className="confirmation-text">{filename}</span>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <span className="text-danger confirmation-text">Please be careful. All the contents in database will be lost.</span>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col className="text-center">
                <Button color="success" onClick={this.onRecover.bind(this)}>Recover</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { recoveryDb }  = state
  return {recoveryDb}
}

export default connect(mapStateToProps)(Recovery)
