import {Config} from "../../Config";
import axios from "axios";

function not_logged_in() {
  return {type: 'NOT_LOGGED_IN'}
}

function initiate_refresh() {
  return {type: 'START_REFRESH'}
}

export function endRefresh() {
  return dispatch => {
    dispatch({
      type: "END_REFRESH"
    });
  };
}

export function resetRefresh() {
  return dispatch => {
    dispatch({
      type: "RESET_REFRESH"
    });
  };
}

export function refreshToken(){
  return dispatch => {
    dispatch(initiate_refresh())
    let config = {
      url: Config.BillingUrl + `refresh-token`,
      method: "post",
      dataType: 'json',
      data: {
        refresh_token: localStorage.getItem("ERP_rt")
      },
      headers: {}
    };
    axios(config).then(
      res => {
        localStorage.setItem('ERP_Bearer', res.data.access_token);
        localStorage.setItem('ERP_rt', res.data.refresh_token);
        window.location.reload();
      })
      .catch(error => {
        dispatch(not_logged_in())
        // if (error.response.status === 422) {
        //   dispatch(not_logged_in())
        // }
      });
  }
}
