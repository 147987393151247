import React, {Component} from 'react'
import {connect} from "react-redux";
import store from '../../Store'
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, Row, Table} from "reactstrap";
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import AddOrSelectProduct from "../Invoice/AddOrSelectProduct";
import SelectBox from "../../shared/form-helpers/SelectBox";
import PrintInvoiceModal from "../Invoice/PrintInvoiceModal";
import {Config} from "../../Config";
import HorizontalTextView from "../../shared/form-helpers/HorizontalTextView";
import {createBilling, createResetBilling, uploadImageBilling} from "../../apis/billings";
import {createProduct, getProducts} from "../../apis/products";
import {getProductFamilies} from "../../apis/product-families";
import {getTaxes} from "../../apis/taxes";
import {getUnits} from "../../apis/units";
import {getVendors} from "../../apis/vendors";
import {getFiscalYears} from "../../apis/fiscal-years";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {fixIfNecessary, isNumber} from "../../shared/helpers/GeneralHelpers";
import moment from "moment";
import {addMessage} from "../../actions/messages/success_message";
import HorizontalDateTimePicker from "../../shared/form-helpers/HorizontalDateTimePicker";

class NewBilling extends Component {

  product = {
    name: "",
    type: "",
    status: 0,
    unit_id: null,
    created_by: "jherry",
    sales_price: null,
    purchase_price: null,
    family: ""
  }

  productError = {
    name: "",
    type: "",
    status: "",
    unit_id: "",
    sales_price: "",
    purchase_price: ""
  }


  data = {
    vendor_id: "",
    vendor_name: "",
    billing_no: "",
    pan: "",
    fiscal_year: "",
    amount: "",
    taxable_amount: "",
    vat_amount: "",
    vat_rate: "",
    total: "",
    type: "",
    details: [],
    date: null
  }


  state = {
    searchArray: [],
    searchIndex: [],
    vendors: [],
    data: {...this.data},
    error: {},
    products: [],
    fiscalYears: [],
    checkedProducts: new Map(),
    toggleForm: false,
    product: {...this.product},
    productError: {...this.productError},
    productRestError: null,
    productRestSuccess: false,
    showProductModal: false,
    vatOption: [],
    unitsOptions: [],
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    searchValue: {},
    createBillingSuccess: null,
    createBillingSuccessStatus: false,
    createBillingError: null,
    productFamilies: [],
    fileUpload: {
      id: null,
      path: null
    },
    images: null,
    username: "",
    pos: ""
  }

  componentDidMount() {
    if (!this.props.getFiscalYears.success)
      getFiscalYears()
    this.fetchData()
    var classname = document.getElementsByClassName("select-search-box__search")
    classname[0].classList.add("form-control")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.createBillingSuccess !== null) {
      this.setState({
        data: {...this.data},
        product: {...this.product},
        createBillingSuccessStatus: true
      }, () => {
        setFetch(true)
        createResetBilling()
      })
    }

    if (this.state.createBillingError !== null) {
      let error = {...this.state.createBillingError}
      if (error['transaction_date']) {
        let classname = document.getElementsByClassName("ant-calendar-picker-input")
        let classelement = classname ? classname[0].classList['value'] : ""
        if (classelement.includes("custom-invalid-border")) {

        } else if (classelement.length > 0) {
          classname[0].classList.add("custom-invalid-border")
        }
      }
      if (error['vendor_name']) {
        let classname = document.getElementsByClassName("select-search-box__search")
        let classelement = classname ? classname[0].classList['value'] : ""
        if (classelement.includes("custom-invalid-border")) {

        } else if (classelement.length > 0) {
          classname[0].classList.add("custom-invalid-border")
        }
      }
      if (error.details && error.details[0].includes("required")) {
        store.dispatch(addMessage("Please select product", "error", "Product not selected"));
      }
      if (error.total && error.total[0].includes("1")) {
        store.dispatch(addMessage("Total must be greater than 1", "error", "Invalid total"));
      }
      this.setState({
        error
      }, () => {
        createResetBilling()
      })
    }

    if (this.state.productRestError !== prevState.productRestError) {
      let productError = this.state.productRestError
      this.setState({
        productError
      })
    }

    if (this.state.productRestSuccess !== prevState.productRestSuccess) {
      this.setState({
        toggleForm: false,
        productRestSuccess: false,
        product: {...this.product}
      }, () => {
        this.fetchData()
      })
    }
  }

  fetchData() {
    let {searchArray} = this.state
    let temp = [{key: 'status', value: 1}]
    getVendors(null, null, searchArray)
    getProducts(8)
    getUnits(1000, null, temp)
    getTaxes(1000)
    getProductFamilies(1000)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getVendors,
      getProducts,
      getTaxes,
      getUnits,
      createBilling,
      getProductsFamilies,
      createProduct,
      uploadImageBilling,
      getFiscalYears,
      getLoggedInUser
    } = nextProps
    let {
      vendors,
      products,
      vatOption,
      unitsOptions,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      createBillingSuccess,
      createBillingError,
      vendor_code,
      url,
      productFamilies,
      productRestError,
      productRestSuccess,
      fileUpload,
      fiscalYears,
      username,
      pos
    } = prevState

    if (getVendors) {
      let {success} = getVendors
      if (success) {
        vendors = success.data.data
      }
    }

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        username = success.data.name
        pos = success.data.pos
      }
    }

    if (uploadImageBilling) {
      let {success, validation} = uploadImageBilling
      if (success) {
        fileUpload = {
          id: success.data.id,
          path: Config.BillingUrl + success.data.name
        }
      } else if (validation) {
        console.log(validation)
      }
    }

    if (getProducts) {
      let {success} = getProducts;
      if (success) {
        products = success.data.data
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (createProduct) {
      let {success, validation} = createProduct
      if (success) {
        if (success.status === 200) {
          productRestSuccess = true
        }
      } else if (validation) {
        productRestError = validation
      }
    }

    if (getTaxes) {
      let {success} = getTaxes
      if (success) {
        vatOption = success.data.data
      }
    }

    if (getUnits) {
      let {success} = getUnits
      if (success) {
        unitsOptions = success.data.data
      }
    }

    if (createBilling) {
      let {success, validation} = createBilling
      if (success) {
        createBillingSuccess = success.data
        vendor_code = success.data.vendor_id
        url = Config.BillingUrl + 'billings/' + success.data.id + '?q=print'
      } else if (validation) {
        createBillingError = validation
      } else {
        createBillingSuccess = null
        createBillingError = null
      }
    }

    if (getProductsFamilies) {
      let {success} = getProductsFamilies
      if (success) {
        if (success.status !== 204)
          productFamilies = success.data.data
      }
    }

    if (getFiscalYears) {
      let {success} = getFiscalYears
      if (success) {
        fiscalYears = success.data
      }
    }

    return {
      vendors,
      products,
      vatOption,
      unitsOptions,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      createBillingSuccess,
      createBillingError,
      vendor_code,
      url,
      productFamilies,
      productRestSuccess,
      productRestError,
      fileUpload,
      fiscalYears,
      username,
      pos
    }
  }

  onVendorChange(e) {
    let data = {...this.state.data}
    let error = {...this.state.error}
    if (e.name) {
      error['vendor_name'] = ""
      data['vendor_name'] = e.name
      data['vendor_id'] = e.value
      data['pan'] = e.pan
      let classname = document.getElementsByClassName("select-search-box__search")
      let classelement = classname ? classname[0].classList['value'] : ""
      if (classelement.includes("custom-invalid-border")) {
        classname[0].classList.remove("custom-invalid-border")
      }
      this.setState({data, error})
    }
  }

  imageChange(e) {
    if (e.target.files[0]) {
      this.setState({
        images: e.target.files[0]
      })
      const formData = new FormData();
      formData.append("file", e.target.files[0])
      uploadImageBilling(formData)
    }
  }

  handleDateChange = date => {
    let data = {...this.state.data};
    let error = {...this.state.error}
    let temp = moment()
    if (date) {
      if (temp.valueOf() > date.valueOf()) {
        data['transaction_date'] = date
      } else {
        store.dispatch(addMessage("Current timestamp is allowed maximum", "warning", "Invalid date"));
      }
    } else {
      data['transaction_date'] = date
    }
    let classname = document.getElementsByClassName("ant-calendar-picker-input")
    let classelement = classname ? classname[0].classList['value'] : ""
    if (classelement.includes("custom-invalid-border")) {
      classname[0].classList.remove("custom-invalid-border")
    }
    error['transaction_date'] = ""
    this.setState({data, error});
  };

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  toggleModal() {
    this.setState({
      showProductModal: !this.state.showProductModal,
      toggleForm: false
    })
  }

  onToggleForm() {
    this.setState({
      toggleForm: !this.state.toggleForm
    })
  }

  checkIfExists(id, datas) {
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].id === id) {
        return true
      }
    }
    return false
  }

  handleProducts(e) {
    let {data} = this.state
    const item = e.target.name
    const isChecked = e.target.checked
    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      let tempDetails = [...this.state.data.details];
      let subTotal = this.state.data.amount;
      let vatAmount = this.state.data.vat_amount;
      let total = this.state.data.total;
      this.state.products.forEach((product) => {
        if (this.state.checkedProducts.get("" + product.id)) {
          if (!this.checkIfExists(product.id, this.state.data.details)) {
            let vat_amount = fixIfNecessary((parseFloat(product.purchase_price)) * 13 / 100)

            let item_total = fixIfNecessary(parseFloat(product.purchase_price + 13 * product.purchase_price / 100))
            let tempDetail = {
              id: product.id,
              product_name: product.name,
              qty: 1,
              unit: 'hr',
              amount: product.purchase_price,
              taxable_amount: product.purchase_price,
              vat_rate: "13.00",
              vat_amount: vat_amount,
              total: item_total
            }
            subTotal += product.prchase_price
            vatAmount += vat_amount
            total += item_total
            tempDetails.push(tempDetail)
          }
        }
      })
      data.amount = subTotal
      data.details = tempDetails
      data.vat_amount = vatAmount
      data.total = total
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  onCreateProduct() {
    createProduct(this.state.product)
  }

  onProductChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let product = {...this.state.product};
    let productError = {...this.state.productError};
    product[name] = value;
    productError[name] = "";
    this.setState({product, productError}, () => {
      console.log(this.state.productError)
    });
  }

  changeQuantity(e) {
    if (isNumber(e.target.value)) {
      let index = e.target.name.split("_")[1];
      let data = {...this.state.data}
      let amount = fixIfNecessary(this.state.data.details[index].amount)
      let taxableAmount = fixIfNecessary((parseFloat(amount)) * parseFloat(e.target.value))
      let vat_amount = fixIfNecessary(13 / 100 * taxableAmount)
      let total = fixIfNecessary(parseFloat(taxableAmount) + parseFloat(vat_amount))
      data.details[index].qty = e.target.value;
      data.details[index].taxable_amount = taxableAmount
      data.details[index].total = total
      data.details[index].vat_amount = vat_amount
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    }
  }

  changeVatrate(e) {
    let index = e.target.name.split("_")[2];
    let data = {...this.state.data}
    let vat_rate = fixIfNecessary(e.target.value)
    let vat_amount = fixIfNecessary(parseFloat(vat_rate) / 100 * parseFloat(data.details[index].taxable_amount))
    let total = fixIfNecessary(parseFloat(data.details[index].taxable_amount) + parseFloat(vat_amount))
    data.details[index].vat_amount = vat_amount
    data.details[index].total = total
    data.details[index].vat_rate = e.target.value
    this.setState({
      data
    }, () => {
      this.changeSubTotal()
    })
  }

  changeSubTotal() {
    let subTotal = 0.0;
    let vatAmount = 0.0;
    let data = {...this.state.data}
    data.details.forEach((single) => {
      if (this.state.checkedProducts.get("" + single.product_name)) {
        subTotal = fixIfNecessary(parseFloat(single.taxable_amount) + parseFloat('' + subTotal))
        vatAmount = fixIfNecessary(parseFloat(single.vat_amount) + parseFloat('' + vatAmount))
      }
    })
    subTotal = fixIfNecessary(subTotal)
    vatAmount = fixIfNecessary(vatAmount)
    let taxable_amount = subTotal
    let total = fixIfNecessary(parseFloat(subTotal) + parseFloat(vatAmount))
    total = fixIfNecessary(total)
    data.amount = subTotal
    data.vat_amount = vatAmount
    data.vat_rate = 13
    data.total = total
    data.taxable_amount = taxable_amount
    this.setState({
      data
    })
    return null
  }

  onCancel() {
    this.props.history.push("/purchases/billings")
  }

  onReset() {
    this.setState({
      data: {...this.data},
      product: {...this.product},
      error: {...this.error},
      productError: {...this.productError}
    })
  }

  onSubmit() {
    let data = {...this.state.data}
    let {fileUpload} = this.state
    if (fileUpload.id)
      data['file_id'] = fileUpload.id
    data['created_by'] = this.state.username
    data['pos'] = this.state.pos.name
    let startDate = data['transaction_date'] ? moment(data['transaction_date'], 'YYYY/MM/DD') : null
    let startTime = moment(data['transaction_date'], 'HH:mm:ss')
    data['transaction_date'] = startDate ? `${startDate.format('YYYY')}-${startDate.format('MM')}-${startDate.format('DD')} ${startTime.format('HH')}:${startTime.format('mm')}:${startTime.format('ss')}` : null
    createBilling(data)
  }

  paginationButton(page) {
    let {searchArray} = this.state
    this.setState({page}, () => {
      getProducts(8, page, searchArray);
    });
  }

  searchChange(e) {
    let data = e.target.value
    let key = "name"
    let searchArray = [...this.state.searchArray]
    let searchValue = {...this.state.searchValue}
    let a = {
      key: key,
      value: data
    }

    let searchIndex = this.state.searchIndex
    let position = searchIndex.indexOf(key)
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push(key)
    }

    searchValue[key] = data
    this.setState({
      searchArray,
      searchValue,
      searchIndex
    }, () => {
      let {limit, page, searchArray} = this.state
      getProducts(limit, page, searchArray)
    })
  }

  togglePrintModal() {
    this.setState({
      createBillingSuccessStatus: !this.state.createBillingSuccessStatus
    }, () => {
      this.props.history.push('/purchases/billings/')
    })
  }

  rowPressed(id, product) {
    let {data} = this.state
    const item = "" + id
    const isChecked = !this.state.checkedProducts.get("" + id)
    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      let tempDetails = [...this.state.data.details];
      if (!isChecked) {
        let order = -1
        tempDetails.forEach((detail, o) => {
          if (detail.id === id)
            order = o
        })
        if (order !== -1)
          tempDetails.splice(order, 1)
      }
      let subTotal = this.state.data.amount;
      let vatAmount = this.state.data.vat_amount;
      let total = this.state.data.total;
      if (this.state.checkedProducts.get("" + product.name)) {
        if (!this.checkIfExists(product.id, this.state.data.details)) {
          let vat_amount = fixIfNecessary(parseFloat((13 * product.purchase_price / 100)+""))
          let item_total = fixIfNecessary(parseFloat((product.purchase_price + 13 * product.purchase_price / 100)+""))
          let tempDetail = {
            id: product.id,
            product_name: product.name,
            qty: 1,
            unit: 'hr',
            amount: product.purchase_price,
            taxable_amount: product.purchase_price,
            vat_rate: "13.00",
            vat_amount: vat_amount,
            total: item_total
          }
          subTotal += product.prchase_price
          vatAmount += vat_amount
          total += item_total
          tempDetails.push(tempDetail)
        }
      }
      data.amount = subTotal
      data.details = tempDetails
      data.vat_amount = vatAmount
      data.total = total
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  removeProduct(id, o) {
    let {data} = this.state
    const item = "" + id
    const isChecked = false

    this.setState(prevState => ({checkedProducts: prevState.checkedProducts.set(item, isChecked)}), () => {
      let tempDetails = [...this.state.data.details];
      tempDetails.splice(o, 1)
      data.details = tempDetails
      this.setState({
        data
      }, () => {
        this.changeSubTotal()
      })
    });
  }

  render() {
    let {
      vendors,
      data,
      error,
      products,
      checkedProducts,
      productError,
      product,
      showProductModal,
      vatOption,
      unitsOptions,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      createBillingSuccessStatus,
      url,
      vendor_code,
      productFamilies,
      images,
      fiscalYears
    } = this.state

    let vatRate = vatOption ? vatOption.map((vat) => {
      return {
        label: `${vat.name} (${vat.rate})`,
        value: parseFloat(vat.rate).toFixed(2)
      }
    }) : []

    let serviceList = [
      {
        label: "Service",
        value: "service"
      },
      {
        label: "Product",
        value: "product"
      }
    ];

    // let unitList = units && units.map((unit) => {
    //   return {
    //     label: unit.name,
    //     value: unit.id
    //   }
    // })

    let unitList = unitsOptions.map((unit) => {
      return {
        label: unit.name,
        value: unit.name
      }
    })

    let newUnitList = unitsOptions.map((unit) => {
      return {
        label: unit.name,
        value: unit.id
      }
    })

    let showProducts = data.details && data.details.map((product, o) => {
      if (checkedProducts.get("" + product.product_name)) {
        return (
          <tr>
            <td>{product.product_name}</td>
            <td><input className="form-control text-right" name={'qty_' + o} value={product.qty+""}
                       onChange={e => this.changeQuantity(e)} min={1}/></td>
            <td><SelectBox name={'unit_' + o} options={unitList} noLabel={true}/></td>
            <td className="text-right">{product.amount}</td>
            <td><SelectBox name={'vat_rate_' + o} value={product.vat_rate} onChange={e => this.changeVatrate(e)}
                           options={vatRate} noLabel={true}/></td>
            <td><input className="form-control" value={product.vat_amount}/></td>
            <td>{product.total}</td>
            <td>
              <Button className="btn-sm" color="danger" outline title="Remove"
                      onClick={() => this.removeProduct(product.product_name,o)}>
                <i className="fa fa-times"></i>
              </Button>
            </td>
          </tr>
        );
      } else {
        return (<tr>
          <td></td>
        </tr>)
      }
    })


    let familyList = [
      {
        label: "Miscellanous",
        value: "miscellanous"
      }
    ]

    let vendorOption = vendors ? vendors.map((vendor) => {
      return {
        name: vendor.first_name + ' ' + vendor.last_name + '  (' + vendor.vendor_code + ')',
        value: vendor.id,
        pan: vendor.pan,
      }
    }): []

    let fiscalYearOptions = fiscalYears.map((single) => {
      let {name, start_date, end_date} = single
      return {
        label: `${name} (${start_date} - ${end_date})`,
        value: name
      }
    })

    let typeOptions = [
      {
        label: 'Capital',
        value: 'capital'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Import',
        value: 'import'
      }
    ]

    return (
      <div className="animated fadeIn">

        <PrintInvoiceModal
          isOpen={createBillingSuccessStatus}
          toggle={this.togglePrintModal.bind(this)}
          url={url}
          customer_code={vendor_code}
          email={false}
        />

        <AddOrSelectProduct
          isOpen={showProductModal}
          toggle={this.toggleModal.bind(this)}
          products={products}
          handleProducts={(e) => this.handleProducts(e)}
          checkedProducts={checkedProducts}
          toggleForm={this.state.toggleForm}
          onToggleForm={this.onToggleForm.bind(this)}
          serviceList={serviceList}
          unitList={newUnitList}
          familyList={familyList}
          onCreateProduct={this.onCreateProduct.bind(this)}
          productError={productError}
          onProductChange={(e) => this.onProductChange(e)}
          product={product}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          searchChange={(e) => this.searchChange(e)}
          lastPage={lastPage}
          rowPressed={this.rowPressed.bind(this)}
          productFamilies={productFamilies}
        />

        <Row className="top-page-content">
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" sm="6" md="6">
                    <HorizontalSelectSearch
                      options={vendorOption}
                      value={data.vendor_id}
                      name="vendor_name"
                      onChange={(e) => this.onVendorChange(e)}
                      placeholder="Search By Vendor Name"
                      error={error.vendor_name}
                    />
                    <HorizontalSelect
                      name="fiscal_year"
                      value={data.fiscal_year}
                      options={fiscalYearOptions}
                      onChange={(e) => this.onChange(e)}
                      error={error.fiscal_year}
                      isRequired={true}
                    />
                  </Col>
                  <Col xs="12" sm="6" md="6">
                    <HorizontalDateTimePicker
                      name="transaction_date"
                      value={data.transaction_date}
                      onChange={this.handleDateChange.bind(this)}
                      error={error.transaction_date}
                    />
                    <HorizontalSelect
                      name="type"
                      value={data.type}
                      options={typeOptions}
                      onChange={(e) => this.onChange(e)}
                      error={error.type}
                      isRequired={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    <HorizontalTextField
                      name="billing_no"
                      value={data.billing_no}
                      onChange={(e) => this.onChange(e)}
                      error={error.billing_no}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table responsive>
                      <thead>
                      <tr>
                        <th width="60" className="text-center">Name</th>
                        <th width="50" className="text-center">Qty</th>
                        <th width="80" className="text-center">Unit</th>
                        <th width="80" className="text-center">Amount</th>
                        <th width="100" className="text-center">VAT Rate</th>
                        <th width="50" className="text-center">VAT Amount</th>
                        <th width="50" className="text-center">Total</th>
                        <th width="20"></th>
                      </tr>
                      </thead>
                      <tbody>
                      {data.details ? data.details.length > 0 ? showProducts : <tr>
                        <td className="text-center" colSpan="8">No Items To Show</td>
                      </tr> : <tr>
                        <td className="text-center" colSpan="8">No Items To Show</td>
                      </tr>}
                      </tbody>
                      <tfoot>
                      <Button color="success" className="btn-square" onClick={this.toggleModal.bind(this)}>Add Product
                        or service
                      </Button>
                      </tfoot>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6">
                    {/*<HorizontalTextArea*/}
                    {/*rows="3"*/}
                    {/*name="document_note"*/}
                    {/*value={data.document_note}*/}
                    {/*error={data.document_note}*/}
                    {/*onChange={e => this.onChange(e)}*/}
                    {/*/>*/}
                  </Col>
                  <Col xs="12" sm="6" md="6">
                    <HorizontalTextView
                      name="subtotal"
                      value={data.amount ? data.amount : 0}
                      isRequired={true}
                      isNumber={true}
                    />
                    <HorizontalTextView
                      name="VAT"
                      value={data.vat_amount ? data.vat_amount : 0}
                      isRequired={true}
                      isNumber={true}
                    />
                    <hr/>
                    <HorizontalTextView
                      name="total"
                      value={data.total ? data.total : 0}
                      isRequired={true}
                      isNumber={true}
                    />
                  </Col>
                </Row>
                <div className=" file-upload">
                  <Row>
                    {images ? <Col></Col> :
                      <Col>
                        <div className="upload-btn-wrapper file-input">
                          <Button className="btn" outline color="success">Upload a file</Button>
                          <input type="file" name="myfile" onChange={(e) => this.imageChange(e)}/>
                        </div>
                      </Col>
                    }
                  </Row>
                  <Row>
                    <Col>
                      {images && <img src={URL.createObjectURL(images)} className="img-preview" alt="logo"/>}
                    </Col>
                  </Row>
                </div>
              </CardBody>
              <CardFooter>
                <ButtonGroup className="float-right">
                  <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
                  <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
                  <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    createBilling,
    getVendors,
    getUnits,
    getTaxes,
    getProducts,
    getProductsFamilies,
    createProduct,
    uploadImageBilling,
    getFiscalYears,
    getLoggedInUser
  } = state

  return {
    createBilling,
    getVendors,
    getUnits,
    getTaxes,
    getProducts,
    getProductsFamilies,
    createProduct,
    uploadImageBilling,
    getFiscalYears,
    getLoggedInUser
  }
}

export default connect(mapStateToProps)(NewBilling)
