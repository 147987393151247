export default function createInventory(
  state = { validation: null, success: null},
  action
) {
  switch (action.type) {

    case 'CREATE_INVENTORY_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_INVENTORY_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null}};
    case 'CREATE_RESET_INVENTORY':
      return {...state, ...{success: null,validation: null}};
    default:
      return state;
  }
}
