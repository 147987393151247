import React, {Component} from 'react'
import {Document, Page, pdfjs} from "react-pdf";
import {Card, CardBody, Col, Row} from "reactstrap";
import store from '../Store'
import {Config} from "../Config";
import {disableInnerLoader, enableInnerLoader} from "../actions/loaders/loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  width: null
};

class UserManual extends Component {
  state = {
    url: Config.BillingUrl + '/user-manual',
    numPages: null
  }

  componentDidMount() {
    store.dispatch(enableInnerLoader())
    this.setDivSize()
    window.addEventListener("resize", this.setDivSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDivSize)
  }

  onDocumentLoadSuccess = ({numPages}) => {
    store.dispatch(disableInnerLoader())
    this.setState({numPages});
  }

  setDivSize = () => {
    this.setState({width: this.pdfWrapper.getBoundingClientRect().width})
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <div id="pdfWrapper" style={{width: "80vw"}} ref={(ref) => this.pdfWrapper = ref}>
                <Document
                  file={this.state.url}
                  onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                  options={options}
                >
                  {
                    Array.from(
                      new Array(this.state.numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          width={this.state.width}
                        />
                      ),
                    )
                  }
                </Document>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UserManual
