import React, {Component} from 'react';
import {connect} from "react-redux";
import {Card, CardBody, Col, Input, Row, Table} from "reactstrap";
import ReportHeader from "./ReportHeader";
import ReportFilter from "./ReportFilter";
import TablePagination from "../../shared/table-helpers/TablePagination";
import store from '../../Store'
import CurrencyAdder from "../CurrencyAdder";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {getMaterialView} from "../../apis/material-view";
import nepaliCalendar from "../../shared/nepali-calendar-dates";
import {setFilter} from "../../shared/helpers/FilterHelper";
import {getEquivalentNepaliMonth} from "../../shared/helpers/GeneralHelpers";

class MaterializedView extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    customer_name: '',
    dateFilter: {
      start_date: '',
      end_date: ''
    },
    startDate: null,
    endDate: null,
    nepaliDate: {
      month: 9,
      year: 2075
    }
  }

  state = {
    filters: {...this.filters},
    userDetail: {},
    focusedInput: null,
    data: [],
    sumData: null,
    creditData: null,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    customer_name: "",
    currency: "",
    companyDetail:{}
  }

  componentDidMount() {
    let tempDate = new Date();
    let georgianDate = nepaliCalendar.toNepali(tempDate.getFullYear(), tempDate.getMonth() + 1, tempDate.getDate())
    let filters = {...this.state.filters}
    filters['nepaliDate']['month'] = georgianDate.nm
    filters['nepaliDate']['year'] = georgianDate.ny
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
    if (!this.props.getLoggedInCompany.success) {
      store.dispatch(getLoggedInCompany())
    }
  }

  fetchData() {
    let {limit, page, searchArray, dateFilter} = this.state.filters
    getMaterialView(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getLoggedInUser,
      getLoggedInCompany,
      getMaterialView
    } = nextProps

    let {
      userDetail,
      companyDetail,
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        userDetail = success.data
      }
    }

    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        companyDetail = success.data
      }
    }

    if (getMaterialView){
      let {success} = getMaterialView
      if (success){
        data = success.data.data || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    return {
      userDetail,
      companyDetail,
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    }
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    });
  }

  limitChange(e) {
    let filters = {...this.state.filters}
    filters['limit'] = e.target.value
    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }


  searchChange(e) {
    let data = e.target.value
    let filters = {...this.state.filters}
    let searchArray = [...this.state.filters.searchArray]
    let a = {
      key: 'customer_name',
      value: data
    }

    let searchIndex = this.state.filters.searchIndex
    let position = searchIndex.indexOf('customer_name')
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push('customer_name')
    }

    filters['customer_name'] = data
    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex

    this.setState({
      filters
    }, () => {
      this.fetchData()
      setFilter(this.currentPath, filters)
    })
  }

  setGeorgianDate() {
    let filters = {...this.state.filters}
    let monthLength = nepaliCalendar.nepaliMonthLength(filters.nepaliDate.year, filters.nepaliDate.month)
    let tempDate = nepaliCalendar.toGregorian(filters.nepaliDate.year, filters.nepaliDate.month, 1)
    let endDate = new Date(`${tempDate.gy}-${tempDate.gm}-${tempDate.gd}`)
    endDate.setDate(endDate.getDate() + monthLength - 1)
    setTimeout(() => {
      let start_date = `${tempDate.gy}-${tempDate.gm}-${tempDate.gd} 00:00:00`
      let end_date = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} 23:59:59`
      filters['dateFilter']['start_date'] = start_date
      filters['dateFilter']['end_date'] = end_date
      this.setState({
        filters
      }, () => {
        this.fetchData()
      })
    }, 10)
  }

  changeDate(data) {
    let filters = {...this.state.filters}
    filters.nepaliDate.year = data.year
    filters.nepaliDate.month = data.month
    this.setState({
      filters
    }, () => {
      this.setGeorgianDate()
    })
  }

  render() {
    let {
      startDate,
      endDate,
      limit,
      companyDetail,
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      filters
    } = this.state

    let tablebody = data.map((keys) => {
      let {
        fiscal_year,
        bill_no,
        transaction_id,
        customer_name,
        customer_pan,
        bill_date,
        amount,
        discount,
        taxable_amount,
        tax_amount
        ,total_amount,
        vat_refund_amount,
        payment_method,
        is_printed,
        printed_time,
        ird_sync,
        is_bill_active,
        entered_by,
        is_realtime,
        printed_by
      } = keys
      return (
        <tr>
          <td>{fiscal_year}</td>
          <td className="text-right">{bill_no}</td>
          <td>{transaction_id}</td>
          <td>{customer_name}</td>
          <td>{customer_pan || '-'}</td>
          <td>{bill_date}</td>
          <td className="text-right"><CurrencyAdder amount={amount}/></td>
          <td className="text-right"><CurrencyAdder amount={discount}/></td>
          <td className="text-right"><CurrencyAdder amount={taxable_amount}/></td>
          <td className="text-right"><CurrencyAdder amount={tax_amount}/></td>
          <td className="text-right"><CurrencyAdder amount={total_amount}/></td>
          <td>{ird_sync === 1 ? "Synced" : "Not Synced"}</td>
          <td>{is_printed}</td>
          <td>{is_bill_active}</td>
          <td>{printed_time}</td>
          <td>{entered_by}</td>
          <td>{printed_by}</td>
          <td>{is_realtime}</td>
          <td className="text-right"><CurrencyAdder amount={vat_refund_amount}/></td>
          <td>{payment_method}</td>
        </tr>
      )
    })

    let salesTable = <>
      <Table bordered className="report-table materialized-table small-font">
        <tbody>
        <tr className="text-bold">
          <td width="120" rowSpan="2">Fiscal Year</td>
          <td width="70" rowSpan="2">Bill No</td>
          <td width="100" rowSpan="2">Transaction ID</td>
          <td colSpan="2" className="text-center">Customer</td>
          <td rowSpan="2" width="120">Bill Date</td>
          <td rowSpan="2" width="100">Amount</td>
          <td rowSpan="2" width="100">Discount</td>
          <td rowSpan="2" width="100">Taxable Amount</td>
          <td rowSpan="2" width="100">Tax Amount</td>
          <td rowSpan="2" width="100">Total Amount</td>
          <td rowSpan="2" width="80">IRD Sync</td>
          <td rowSpan="2" width="80">Is Printed</td>
          <td rowSpan="2" width="80">Is Active</td>
          <td rowSpan="2" width="120">Printed Time</td>
          <td rowSpan="2" width="120">Entered By</td>
          <td rowSpan="2" width="120">Printed By</td>
          <td rowSpan="2" width="120">Is Real Time</td>
          <td rowSpan="2" width="100">VAT Refund Amount</td>
          <td rowSpan="2" width="100">Payment Method</td>
        </tr>
        <tr className="text-bold">
          <td width="120">Name</td>
          <td width="120">PAN</td>
        </tr>
        {tablebody}
        </tbody>
      </Table>
      <TablePagination
        to={to}
        from={from}
        total={total}
        prevPageUrl={prevPageUrl}
        nextPageUrl={nextPageUrl}
        currentPage={currentPage}
        paginationButton={this.paginationButton.bind(this)}
        lastPage={lastPage}
        name="records"
      />
    </>

    return (
      <div className="animated fadeIn">
        <ReportFilter
          startDate={startDate}
          endDate={endDate}
          searchChange={(e) => this.searchChange(e)}
          searchValue={filters.customer_name}
          reportType="materialized"
          changeDate={this.changeDate.bind(this)} className="text-right"
        />

        <Card>
          <CardBody>
            <ReportHeader
              page="Sales Register"
              dateFilter={this.state.filters.dateFilter}
              userDetail={companyDetail}
              reportType="materialized"
            />
            <hr/>
            <Row><Col className="text-center">
              <span className="report-page-title">Materialized View</span>
            </Col></Row>
            <Row>
              <Col md="6">
                <div className="dataTables_wrapper form-inline float-left">
                  <label>
                    Show &nbsp;
                    <Input type="select" onChange={(e) => this.limitChange(e)} value={limit}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </Input> &nbsp;
                    entries
                  </label>
                </div>
              </Col>
              <Col md="6" sm="12" className="text-right">
                <span className="text-bold">{`Duration of Sales : ${getEquivalentNepaliMonth(filters.nepaliDate.month-1)},${filters.nepaliDate.year}`}</span>
              </Col>
            </Row>
            <br/>
            <div className="overflow-scroll">
            {salesTable}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getLoggedInUser,
    getMaterialView,
    getLoggedInCompany
  } = state
  return {
    getLoggedInUser,
    getMaterialView,
    getLoggedInCompany
  }
}

export default connect(mapStateToProps)(MaterializedView)
