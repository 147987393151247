import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap';
import { stringUpperCase } from '../helpers/GeneralHelpers';

const HorizontalTextArea = (props) => {
  let {rows,error,onChange,value,name,disabled,isRequired} = props;
  let label = stringUpperCase(name);
  return(
    <FormGroup row>
      <Col md="3">
        <Label>{label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <Input type="textarea" rows={rows} invalid={!!error} name={name} value={value} onChange={onChange} disabled={disabled}/>
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};

export default HorizontalTextArea;
