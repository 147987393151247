import Dashboard from './views/Dashboard'

import CompanySettings from './views/CompanySettings/CompanySettings'
import UserSettings from './views/UserSettings/UserSettings'

import Invoice from './views/Invoice/Invoice'
import NewInvoice from './views/Invoice/NewInvoice'
import ViewInvoice from './views/Invoice/ViewInvoice'
import PrintInvoice from './views/Invoice/PrintInvoice'

import NewCreditNote from './views/CreditNotes/NewCreditNote'

import Transactions from './views/Transactions/Transactions'
import ViewTransaction from './views/Transactions/ViewTransaction'

import CreditNotes from './views/CreditNotes/CreditNotes'
import ViewCreditNote from './views/CreditNotes/ViewCreditNote'

import Customers from './views/Customers/Customers'
import NewCustomer from './views/Customers/NewCustomer';
import EditCustomer from './views/Customers/EditCustomer';
import ViewCustomer from './views/Customers/ViewCustomer';
import CustomerTransactions from './views/Customers/CustomerTransactions';

import Products from './views/Products/Products'
import NewProductAndService from './views/Products/NewProductAndService';
import EditProductAndService from './views/Products/EditProductAndService';
import ViewProductAndService from './views/Products/ViewProductAndService';

import DefaultLayout from './containers/DefaultLayout';

import AppKeys from './views/keys/AppKeys'

import PaymentSettings from './views/PaymentSettings/PaymentSettings'
import PaymentGateway from './views/PaymentSettings/PaymentGateway'

import CompanyUsers from './views/Company/CompanyUsers'
import InviteUser from './views/Company/InviteUser'
import UpdateUser from './views/Company/UpdateUser'

import Sales from './views/Reports/Sales'
import PurchaseRegister from './views/Reports/Purchase'
import MaterializedView from './views/Reports/MaterializedView'

import Vendors from './views/Vendors/Vendors'
import NewVendor from './views/Vendors/NewVendor'
import ViewVendor from './views/Vendors/ViewVendor'
import EditVendor from './views/Vendors/EditVendor'

import Billings from './views/Billings/Billings'
import NewBilling from './views/Billings/NewBilling'
import ViewBilling from './views/Billings/ViewBilling'

import PurchaseReturns from './views/PurchaseReturns/PurchaseReturns'
import NewPurchaseReturn from './views/PurchaseReturns/NewPurchaseReturn'
import ViewPurchaseReturn from './views/PurchaseReturns/ViewPurchaseReturn'

import CreditNoteList from './views/Invoice/CreditNoteList'
import NotFoundPage from './views/NotFoundPage'
import ServerError from './views/ServerError'

import NoInternet from './views/NoInternet'

import ProformaInvoices from './views/ProformaInvoice/ProformaInvoices'
import ViewProformaInvoice from './views/ProformaInvoice/ViewProformaInvoice'
import EditProformaInvoice from './views/ProformaInvoice/EditProformaInvoice'

import Database from './views/Backup/Database'
import Recovery from './views/Backup/Recovery'
import QueryLog from './views/Backup/QueryLog'
import BinaryLog from './views/Backup/BinaryLog'
import ActivityLog from './views/Backup/ActivityLog'

import UserManual from './views/UserManual'

import Roles from './views/Roles/Roles'
import Permissions from './views/Permissions/Permissions'

import POS from './views/POS/POS'
import NewPOS from './views/POS/NewPOS'
import ViewPOS from './views/POS/ViewPOS'
import EditPOS from './views/POS/EditPOS'
import InvoiceTemplates from "./views/InvoiceTemplate/InvoiceTemplates";
import NewInvoiceTemplate from "./views/InvoiceTemplate/NewInvoiceTemplate";
import Inventories from "./views/Inventory/Inventories";
import NewInventory from "./views/Inventory/NewInventory";
import InventoryHistory from "./views/Inventory/InventoryHistory";
import Menu from "./views/Menu/Menu";
import NewMenu from "./views/Menu/NewMenu";
import NewPaymentGateway from "./views/PaymentSettings/NewPaymentGateway";

// import Calendar from './views/Calendar'

const routes = [
  {path: '/', exact: true, name: 'Home', component: DefaultLayout},
  {path: '/dashboard', name: 'Dashboard', component: Dashboard},
  {path: '/sales/invoice', exact: true, name: 'Invoice', component: Invoice},
  {path: '/sales/invoice/new', exact: true, name: 'New', component: NewInvoice},
  {path: '/sales/invoice/:id/cancel', exact: true, name: 'Credit Note', component: NewCreditNote},
  {path: '/sales/invoice/:id', exact: true, name: 'Details', component: ViewInvoice},
  {path: '/sales/invoice/:id/print', exact: true, name: 'Print', component: PrintInvoice},
  {path: '/sales/invoice/:id/credit-notes', exact: true, name: 'Credit Notes', component: CreditNoteList},
  {path: '/sales/proforma-invoice', exact: true, name: 'Proforma Invoices', component: ProformaInvoices},
  {path: '/sales/proforma-invoice/new', exact: true, name: 'New', component: NewInvoice},
  {path: '/sales/proforma-invoice/:id', exact: true, name: 'Details', component: ViewProformaInvoice},
  {path: '/sales/proforma-invoice/:id/edit', exact: true, name: 'Edit', component: EditProformaInvoice},
  {path: '/sales/credit_notes', exact: true, name: 'Credit Notes', component: CreditNotes},
  {path: '/sales/credit_notes/:id', exact: true, name: 'Details', component: ViewCreditNote},
  {path: '/sales/customers', exact: true, name: 'Customers', component: Customers},
  {path: '/sales/customers/new', exact: true, name: 'New', component: NewCustomer},
  {path: '/sales/customers/:id', exact: true, name: 'View', component: ViewCustomer},
  {path: '/sales/customers/:id/transactions', exact: true, name: 'Transactions', component: CustomerTransactions},
  {path: '/sales/customers/:name/transactions/:id', exact: true, name: 'View', component: ViewTransaction},
  {path: '/sales/customers/:id/edit', exact: true, name: 'Edit', component: EditCustomer},
  {path: '/transactions', exact: true, name: 'Transactions', component: Transactions},
  {path: '/transactions/:id', exact: true, name: 'View Transaction', component: ViewTransaction},
  {path: '/company-settings', exact: true, name: 'Company Settings', component: CompanySettings},
  {path: '/user-settings', exact: true, name: 'User Settings', component: UserSettings},
  {path: '/payment-settings', exact: true, name: 'Payment Settings', component: PaymentSettings},
  {path: '/payment-gateway', exact: true, name: 'Payment Gateway', component: PaymentGateway},
  {path: '/payment-gateway/new', exact: true, name: 'New Payment Gateway', component: NewPaymentGateway},
  {path: '/payment-gateway/:id/edit', exact: true, name: 'Edit', component: NewPaymentGateway},
  {path: '/products_and_services', exact: true, name: 'Products and Services', component: Products},
  {path: '/products_and_services/new', exact: true, name: 'New', component: NewProductAndService},
  {path: '/products_and_services/:id', exact: true, name: 'View', component: ViewProductAndService},
  {path: '/products_and_services/:id/edit', exact: true, name: 'Edit', component: EditProductAndService},
  {path: '/keys/app', exact: true, name: 'App Key', component: AppKeys},
  {path: '/company-users', exact: true, name: 'Company Users', component: CompanyUsers},
  {path: '/company-users/invite', exact: true, name: 'Invite', component: InviteUser},
  {path: '/company-users/:id/edit', exact: true, name: 'Edit', component: UpdateUser},
  {path: '/purchases/vendors', exact: true, name: 'Vendors', component: Vendors},
  {path: '/purchases/vendors/new', exact: true, name: 'New', component: NewVendor},
  {path: '/purchases/vendors/:id', exact: true, name: 'View', component: ViewVendor},
  {path: '/purchases/vendors/:id/edit', exact: true, name: 'Edit', component: EditVendor},
  {path: '/purchases/billings', exact: true, name: 'Billings', component: Billings},
  {path: '/purchases/billings/new', exact: true, name: 'Create', component: NewBilling},
  {path: '/purchases/billings/:id', exact: true, name: 'View', component: ViewBilling},
  {path: '/reports/sales', exact: true, name: 'Sales Register', component: Sales},
  {path: '/reports/purchases', exact: true, name: 'Purchase Register', component: PurchaseRegister},
  {path: '/reports/materialized-view', exact: true, name: 'Materialized View', component: MaterializedView},
  {path: '/purchases/returns', exact: true, name: 'Debit Notes', component: PurchaseReturns},
  {path: '/purchases/returns/:id/cancel', exact: true, name: 'Debit Note', component: NewPurchaseReturn},
  {path: '/purchases/returns/:id', exact: true, name: 'View', component: ViewPurchaseReturn},
  {path: '/backup/database', exact: true, name: 'Database', component: Database},
  {path: '/backup/recovery', exact: true, name: 'Recovery', component: Recovery},
  {path: '/backup/query-log', exact: true, name: 'Query Log', component: QueryLog},
  {path: '/backup/binary-log', exact: true, name: 'Binary Log', component: BinaryLog},
  {path: '/backup/activity-log', exact: true, name: 'Activity Log', component: ActivityLog},
  {path: '/templates/invoice', exact: true, name: 'Invoice Template', component: InvoiceTemplates},
  {path: '/templates/invoice/new', exact: true, name: 'New Invoice Template', component: NewInvoiceTemplate},
  {path: '/templates/invoice/:id/edit', exact: true, name: 'Edit Invoice Template', component: NewInvoiceTemplate},
  {path: '/inventory', exact: true, name: 'Inventory', component: Inventories},
  {path: '/inventory-history', exact: true, name: 'Inventory History', component: InventoryHistory},
  {path: '/inventory/new', exact: true, name: 'New Inventory', component: NewInventory},
  {path: '/inventory/:id/edit', exact: true, name: 'Edit Inventory', component: NewInventory},
  {path: '/menus', exact: true, name: 'Menu', component: Menu},
  {path: '/menus/new', exact: true, name: 'New Menu', component: NewMenu},
  {path: '/menus/:id/edit', exact: true, name: 'Edit Menu', component: NewMenu},
  {path: '/server-error', exact: true, name: 'Server Error', component: ServerError},
  {path: '/not-found', name: 'Not Found', component: NotFoundPage},
  {path: '/no-internet', name: 'No Internet', component: NoInternet},
  {path: '/user-manual', name: 'User Manual', component: UserManual},
  {path: '/roles', exact: true, name: 'Roles', component: Roles},
  {path: '/permissions', exact: true, name: 'Permissions', component: Permissions},
  {path: '/pos', exact: true, name: 'POS', component: POS},
  {path: '/pos/new', exact: true, name: 'New', component: NewPOS},
  {path: '/pos/:id', exact: true, name: 'View', component: ViewPOS},
  {path: '/pos/:id/edit', exact: true, name: 'Edit', component: EditPOS},
  // { path: '/calendar',  exact:true, name:'Calendar', component:Calendar },
];

export default routes;
