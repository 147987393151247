import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "proforma-invoice"

export const getProformaInvoices = (limit, page = 1, filters = [],dateFilter = {}) => {
  let reducers = {
    success: 'FETCH_PROFORMA_INVOICES_SUCCESS',
    error: 'FETCH_PROFORMA_INVOICES_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}

export const downloadProformaInvoice = (limit, page = 1, filters = [],dateFilter = {}) => {
  let reducers = {
    success: 'DOWNLOAD_PROFORMA_INVOICES_SUCCESS',
    error: 'DOWNLOAD_PROFORMA_INVOICES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}

export const getProformaInvoice = (id) => {
  let reducers = {
    success: 'FETCH_PROFORMA_INVOICE_SUCCESS',
    error: 'FETCH_PROFORMA_INVOICE_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createProformaInvoice = (data) => {
  let reducers = {
    success: 'CREATE_PROFORMA_INVOICE_SUCCESS',
    error: 'CREATE_PROFORMA_INVOICE_ERROR',
    validation_error: 'CREATE_PROFORMA_INVOICE_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteProformaInvoice = (id) => {
  let reducers = {
    success: 'DELETE_PROFORMA_INVOICE_SUCCESS',
    error: 'DELETE_PROFORMA_INVOICE_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const updateProformaInvoice = (id, data) => {
  let reducers = {
    success: 'UPDATE_PROFORMA_INVOICE_SUCCESS',
    error: 'UPDATE_PROFORMA_INVOICE_ERROR',
    validation_error: 'UPDATE_PROFORMA_INVOICE_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const convertToInvoice = (id) => {
  let reducers = {
    success: 'CONVERT_TO_INVOICE_SUCCESS',
    error: 'CONVERT_TO_INVOICE_ERROR'
  }
  let url = prefix + `/${id}/invoice`
  let get = new Get(url, reducers)
  get.get()
}

export const createResetProformaInvoice = () => {
  store.dispatch(resetData('CREATE_RESET_PROFORMA_INVOICE'))
}

export const getResetProformaInvoice = () => {
  store.dispatch(resetData('FETCH_RESET_PROFORMA_INVOICE'))
}

export const deleteResetProformaInvoice = () => {
  store.dispatch(resetData('DELETE_RESET_PROFORMA_INVOICE'))
}

export const updateResetProformaInvoice = () => {
  store.dispatch(resetData('UPDATE_RESET_PROFORMA_INVOICE'))
}
