import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {getInvoices} from "../../apis/invoices";
import PrintInvoiceModal from "./PrintInvoiceModal";
import {Config} from "../../Config";
import {Tabs} from "antd";
import {Card, CardBody} from "reactstrap";
import {getStatistics} from "../../apis/statistics";
import getLoggedInUser from "../../reducers/login/get-logged-in-user";


const {TabPane} = Tabs;

class Invoice extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    data: [],
    showPrintModal: false,
    cus_code: null,
    statistics: {
      active: {},
      all: {},
      inactive: {}
    },
    statisticsFilter: [],
    userRole: {},
    action_links: [
      {
        type: 'navlink',
        icon: 'snippets',
        twoToneColor: "#4dbd74",
        title: 'View',
        url: ['/sales/invoice/']
      },
      {
        type: 'button',
        icon: 'printer',
        twoToneColor: "#4dbd74",
        title: 'Print',
        onClick: this.printIcon.bind(this)
      },
      // {
      //   type: 'button',
      //   icon: 'printer',
      //   twoToneColor: "#0fbd00",
      //   title: 'Print Original Invoice',
      //   onClick: this.printIcon.bind(this)
      // },
      {
        type: 'navlink',
        icon: 'delete',
        twoToneColor: "#f86c6b",
        title: 'Issue Credit Note',
        url: ['/sales/invoice/', '/cancel']
      }

    ]
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter, searchIndex} = filters
    let statisticsFilter = [...searchArray]
    let statusCheck = searchIndex.indexOf('status')
    if (statusCheck >= 0) {
      statisticsFilter.splice(statusCheck, 1)
    }
    getInvoices(limit, page, searchArray, dateFilter)
    getStatistics("invoices", limit, page, statisticsFilter, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getInvoices, getStatistics, getLoggedInUser} = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      statistics,
      userRole,
      action_links
    } = prevState;
    if (getLoggedInUser) {
      if (getInvoices) {
        let {success, error} = getInvoices;
        if (success) {
          data = success.data.data || [];
          prevPageUrl = success.data.prev_page_url;
          nextPageUrl = success.data.next_page_url;
          currentPage = success.data.current_page;
          from = success.data.from;
          to = success.data.to;
          total = success.data.total;
          lastPage = success.data.last_page;
          firstPage = 1;
        } else if (error) {
        }
      }

      if (getStatistics) {
        let {success} = getStatistics
        if (success) {
          statistics = success.data
        }
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      statistics,
      userRole,
      action_links
    };
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, statistics) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  printIcon(id, title) {
    this.setState({
      url: Config.BillingUrl + 'invoices/' + id + `?q=${title === "Print Original Invoice" ? "original" : "preview"}`,
      showPrintModal: true,
      cus_code: id
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  togglePrintModal() {
    this.setState({
      showPrintModal: !this.state.showPrintModal
    })
  }

  newAction() {
    this.props.history.push('/sales/invoice/new')
  }

  onTabChange(tab) {
    let filters = {...this.state.filters}
    let searchArray = [...filters.searchArray]
    let a = {
      key: 'status',
      value: tab === "1" ? '' : tab === "2" ? 1 : 0
    }

    let searchIndex = filters.searchIndex
    let position = searchIndex.indexOf('status')
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push('status')
    }

    if (tab === "1")
      searchArray.splice(position, 1)

    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex

    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      statistics,
    } = this.state


    const columns = [
      {
        title: 'Invoice',
        dataIndex: 'invoice_no',
        searchText: 'invoice_no'
      },
      {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        searchText: 'customer_name'
      },
      {
        title: 'Reseller',
        dataIndex: 'reseller',
        searchText: 'reseller'
      },
      {
        title: 'Entered By',
        dataIndex: 'created_by',
        searchText: 'created_by'
      },
      {
        title: 'Amount',
        dataIndex: 'total'
      },
      {
        title: 'Transaction Date',
        dataIndex: 'transaction_date'
      },
      {
        title: 'Invoice Issue Date',
        dataIndex: 'created_at',
        searchDate: 'dateFilter',
      },
      {
        title: 'POS',
        dataIndex: 'pos',
        searchText: 'pos'
      },
      {
        title: 'IRD Sync',
        dataIndex: 'ird_sync',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Synced'
          },
          {
            key: 0,
            value: 'red',
            label: 'Not Synced'
          }
        ],
        filters: [
          {
            text: 'Synced',
            value: 1
          },
          {
            text: 'Not Synced',
            value: 0
          }
        ]
      },
      {
        title: 'Actions',
        action_links: this.state.action_links
      }
    ]

    let tableData = []

    data.map((single) => {
      let {
        customer_name,
        total,
        invoice_no,
        fiscal_year,
        transaction_date,
        company_payment_id,
        created_by,
        ird_sync,
        created_at,
        reseller,
        pos
      } = single

      tableData.push({
        customer_name,
        invoice_no: invoice_no + '-' + fiscal_year,
        created_by,
        total,
        transaction_date,
        created_at,
        ird_sync,
        id: company_payment_id,
        reseller: reseller || "-",
        pos: pos || "-"
      })
      return null
    })

    let allTab = `All (${statistics.all.count})`
    let activeTab = `Active (${statistics.active.count})`
    let inActiveTab = `Credit Notes (${statistics.inactive.count})`

    return (
      <div className="animated fadeIn">
        <PrintInvoiceModal
          isOpen={this.state.showPrintModal}
          toggle={this.togglePrintModal.bind(this)}
          url={this.state.url}
          customer_code={this.state.cus_code}
          email={false}
        />
        <TableHelpers
          newTitle="Create Invoice"
          newAction={this.newAction.bind(this)}
          url="invoices"
          hideTable={true}
          filters={this.state.filters}
        />
        <Card>
          <CardBody>
            <Tabs onChange={this.onTabChange.bind(this)}>
              <TabPane tab={allTab} key="1">
                <TableHelpers
                  columns={columns}
                  tableData={tableData}
                  to={to}
                  from={from}
                  total={total}
                  prevPageUrl={prevPageUrl}
                  nextPageUrl={nextPageUrl}
                  currentPage={currentPage}
                  paginationButton={this.paginationButton.bind(this)}
                  lastPage={lastPage}
                  temp={this.temp}
                  filters={this.state.filters}
                  onDateConfirm={this.onDateConfirm.bind(this)}
                  confirmSearch={this.confirmSearch.bind(this)}
                  hideNewCard={true}
                  statistics={statistics.all}
                />
              </TabPane>
              <TabPane tab={activeTab} key="2">
                <TableHelpers
                  columns={columns}
                  tableData={tableData}
                  to={to}
                  from={from}
                  total={total}
                  prevPageUrl={prevPageUrl}
                  nextPageUrl={nextPageUrl}
                  currentPage={currentPage}
                  paginationButton={this.paginationButton.bind(this)}
                  lastPage={lastPage}
                  temp={this.temp}
                  filters={this.state.filters}
                  onDateConfirm={this.onDateConfirm.bind(this)}
                  confirmSearch={this.confirmSearch.bind(this)}
                  hideNewCard={true}
                  statistics={statistics.active}
                />
              </TabPane>
              <TabPane tab={inActiveTab} key="3">
                <TableHelpers
                  columns={columns}
                  tableData={tableData}
                  to={to}
                  from={from}
                  total={total}
                  prevPageUrl={prevPageUrl}
                  nextPageUrl={nextPageUrl}
                  currentPage={currentPage}
                  paginationButton={this.paginationButton.bind(this)}
                  lastPage={lastPage}
                  temp={this.temp}
                  filters={this.state.filters}
                  onDateConfirm={this.onDateConfirm.bind(this)}
                  confirmSearch={this.confirmSearch.bind(this)}
                  hideNewCard={true}
                  statistics={statistics.inactive}
                />
              </TabPane>
            </Tabs>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getInvoices,
    getStatistics,
    getLoggedInUser
  } = state;
  return {
    getInvoices,
    getStatistics,
    getLoggedInUser
  };
}

export default connect(mapStateToProps)(Invoice)
