import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";
import {_success} from '../connect_reducer'
import {noInternet, serverError} from "../error";

export class Remove {
  constructor(url, reducers) {
    this.url = url
    this.reducers = reducers
  }

  remove() {
    store.dispatch(enableInnerLoader())
    let config = {
      url: Config.BillingUrl + this.url,
      method: "delete",
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    };

    axios(config)
      .then(res => {
        store.dispatch(_success(res, this.reducers.success))
        store.dispatch(disableInnerLoader())
      })
      .catch(error => {
        store.dispatch(disableInnerLoader())
        if (error.response){
          if (error.response.status >= 500)
            store.dispatch(serverError())
        }
        else if (error.request){
          store.dispatch(noInternet())
        }
        else {
          store.dispatch(serverError())
        }
      });
  }
}
