export default function downloadPurchaseReturns
  (state = {error: null, success: null, processing: ''}, action) {

  switch (action.type) {

    case 'DOWNLOAD_PURCHASE_RETURNS_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DOWNLOAD_PURCHASE_RETURNS_ERROR':
      return {...state, ...{error: action.error}};
    case 'DOWNLOAD_PURCHASE_RETURNS_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
