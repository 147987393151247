import React from 'react';
import {
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { stringUpperCase } from '../helpers/GeneralHelpers';

const HorizontalTextView = (props) => {
  let {value,name,isNumber} = props;
  let label = stringUpperCase(name);
  return(
    <FormGroup row>
      <Col md="3">
        <Label>{label}</Label>
      </Col>
      <Col xs="12" md="9" className={isNumber && "text-right"}>
        <span>{value}</span>
      </Col>
    </FormGroup>
  );
};

export default HorizontalTextView;
