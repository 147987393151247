import {GetGroup} from "../actions/crud/get_group";

let prefix = "purchases-registers"
let reducers = {
  success: '',
  error: ''
}

export const getPurchaseRegisters = (limit, page = 1, filters = [],dateFilter={}) => {
  reducers['success'] = 'FETCH_PURCHASE_REGISTERS_SUCCESS'
  reducers['error'] = 'FETCH_PURCHASE_REGISTERS_ERROR'
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  getGroup.getGroup()
}
