export default function createPaymentTerm(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {

    case 'CREATE_PAYMENT_TERM_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_PAYMENT_TERM_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null}};
      case 'CREATE_RESET_PAYMENT_TERM':
      return {...state, ...{validation: null,success: null}};
    default:
      return state;
  }
}
