import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import {connect} from "react-redux";
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import HorizontalToggle from "../../shared/form-helpers/HorizontalToggle";
import {createInventory, getInventory, updateInventory} from "../../apis/inventory";
import {useDidMountEffect} from "../../shared/helpers/GeneralHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";
import {getActiveCustomers} from "../../apis/customers";
import {getProducts} from "../../apis/products";
import HorizontalTextView from "../../shared/form-helpers/HorizontalTextView";

const resetData = {
  product_id: "",
  stock_available: "",
  date_added: 1
}

function NewInventory(props) {
  let id = props.match.params.id
  const [data, setData] = useState({status: 1})
  const [products, setProducts] = useState([])
  const [error, setError] = useState({})

  useEffect(() => {
    fetchData()
  }, [])

  useDidMountEffect(() => {
    let {success, validation} = props.createInventory
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Inventory Created"))
        props.history.goBack()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.createInventory])

  useDidMountEffect(() => {
    let {success, validation} = props.updateInventory
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Invoice Template Updated"))
        props.history.goBack()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.updateInventory])

  useDidMountEffect(() => {
    let {success, validation} = props.getInventory
    if (success) {
      if (success.data) {
        setData(success.data)
        setProducts([{id: success.data.product_id, name: success.data.product_name}])
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.getInventory])

  useDidMountEffect(() => {
    let {success, validation} = props.getProducts
    if (success) {
      if (success.data)
        setProducts(success.data.data)
    } else if (validation) {
      setError(validation)
    }
  }, [props.getProducts])

  function fetchData() {
    if (id) {
      getInventory(id)
    }
  }

  const onCancel = () => {
    props.history.goBack()
  }
  const onReset = () => {
    if (id)
      fetchData()
    else
      setData({...resetData})
  }
  const onSubmit = () => {
    if (id) {
      updateInventory(id, data)
    } else
      createInventory(data)
  }

  function onChange(e) {
    let {name, value} = e.target
    setData({...data, [name]: value})
    setError({...error, [name]: ""})
  }

  function onProductChange(e) {
    if (e.name) {
      setData({...data, product_id: e.value})
      setError({...error, product_id: ""})
    }
  }

  function onProductFetch(e) {
    let filters = [
      {
        key: 'name',
        value: e
      },
      {
        key: 'status',
        value: 1
      }
    ]
    getProducts(20, 1, filters)
  }

  let productOption = [];

  productOption.push({
    name: "",
    value: "",
    address: "",
    pan: "",
    phone: ""
  })

  products && products.forEach((product) => {
    productOption.push({
      name: product.name,
      value: product.id,
    })
  })


  return (
    <div className="animated fadeIn">
      <Card className="top-page-content">
        <CardBody>
          <Row>
            <Col sm="2"></Col>
            <Col sm="7">
              <Row>
                <Col xs="12">
                  {!id ?
                    <HorizontalSelectSearch
                      options={productOption}
                      value={data.product_id}
                      name="product_id"
                      onChange={(e) => onProductChange(e)}
                      placeholder="Search product"
                      error={error.product_id}
                      isRequired={true}
                      onFetch={onProductFetch.bind(this)}
                    /> :
                    <HorizontalTextView name="product_id" value={data.product_name}/>
                  }
                </Col>
                <Col xs="12">
                  <HorizontalTextField
                    name="stock_available"
                    value={data.stock_available}
                    type="number"
                    onChange={(e) => onChange(e)}
                    error={error.stock_available}
                    isRequired={true}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalTextField
                    name="date_added"
                    value={data.date_added}
                    type="date"
                    onChange={(e) => onChange(e)}
                    error={error.date_added}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="3"></Col>
          </Row>

        </CardBody>
        <CardFooter>
          <ButtonGroup className="float-right">
            <Button outline color="danger" onClick={onCancel}>Cancel</Button>
            <Button outline color="primary" onClick={onReset}>Reset</Button>
            <Button outline color="success" onClick={onSubmit}>Save</Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  );
}

function mapStateToProps(state) {
  let {
    createInventory,
    getInventory,
    updateInventory,
    getProducts
  } = state

  return {
    createInventory,
    getInventory,
    updateInventory,
    getProducts
  }
}

export default connect(mapStateToProps)(NewInventory);

