import React from 'react';
import {Button, Col, Modal, ModalBody, Row} from "reactstrap";

const AlertModal = (props) => {
  let {open,toggle,status,statusTitle,statusMessage} = props

  return (
    <Modal isOpen={open} size="lg" className={'modal-lg ' + props.className}>
      <ModalBody>
        <div className="text-center">
          <span className="status-code server-error">
            {`${status} !!`}
          </span>
        </div>
        <div className="text-center"><span className="page-title">{statusTitle}</span></div>
        <div className="text-center"><span className="page-info">{statusMessage}</span></div>
        <hr/>
        <Row>
          <Col className="text-center">
            <Button color='success' onClick={toggle}>Okay</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default AlertModal;
