export default function updateRootCustomer
  (state = {error: null, success: null, processing: ''}, action) {

  switch (action.type) {

    case 'UPDATE_ROOT_CUSTOMER_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'UPDATE_ROOT_CUSTOMER_ERROR':
      return {...state, ...{error: action.error.response}};
    case 'UPDATE_ROOT_CUSTOMER_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
