import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";
import {_success} from '../connect_reducer'
import {noInternet, serverError, unauthorizedError} from "../error";

export class Get {
  constructor(url, reducers = {}) {
    this.url = url
    this.reducers = reducers
  }

  get() {
    store.dispatch(enableInnerLoader())
    let config = {
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    };

    axios.get(Config.BillingUrl + this.url, config)

      .then(res => {
        store.dispatch(disableInnerLoader())
        store.dispatch(_success(res, this.reducers.success))

      })
      .catch(error => {
        store.dispatch(disableInnerLoader())
        if (error.response){
          if (error.response.status >= 500)
            store.dispatch(serverError())
          else if (error.response.status === 403)
            store.dispatch(unauthorizedError(error.response.data.message))
        }
        else if (error.request){
          store.dispatch(noInternet())
        }
        else {
          store.dispatch(serverError())
        }
      });
  }
}
