import React, { Component } from 'react';
import './datepicker.css';
import { clone } from 'lodash';
import Day from './Day';
import { convertDate, formatDate } from './CalendarHelper/__calendarHelper';
import { Config } from './Config';
import WeekHeader from './WeekHeader';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const refDate = props.value || new Date();
    const date = convertDate(refDate, props.base);
    const selectedDate = convertDate(refDate, props.base);
    const monthList = props.base === 'BS'? Config.monthNameBS : Config.monthName;
    this.state = {
      dateType: props.base,
      date,
      monthList,
      currentMonth: monthList[date.getMonth()],
      currentYear: date.getYear(),
      selectedDate,
      yearList: date.getYearList()
    };
  }

  getWeekDays(currentDate) {
    const { date, selectedDate, dateType } = this.state;
    const days = [];
    const calendarDate = currentDate;
    for (let c = 0; c < 7; c += 1) {
      const calendarYear = dateType === 'AD' ? calendarDate.getFullYear() : calendarDate.getYear();
      days.push(
        <Day
          key={calendarDate}
          date={clone(calendarDate)}
          value={calendarDate.getDate()}
          base={this.props.base}
          isCurrentMonth={date.getMonth() === calendarDate.getMonth()}
          isSelectedDate={date.getMonth() === selectedDate.getMonth()
            && calendarDate.getDate() === selectedDate.getDate()
            && calendarYear === selectedDate.getYear()
          }
          onChange={d => this.changeSelectedDate(d)}
        />,
      );
      calendarDate.setDate(calendarDate.getDate() + 1);
    }
    return days;
  }

  changeMonth(currentMonth) {
    const { date, monthList } = this.state;
    const nextMonth = monthList.indexOf(currentMonth);
    date.setMonth(nextMonth);
    this.setState({ currentMonth });
  }

  changeYear(currentYear) {
    currentYear = parseInt(currentYear)
    const { date } = this.state;
    date.setYear(currentYear);
    this.setState({ currentYear });
  }

  changeSelectedDate(date) {
    const { dateType } = this.state;
    const { onSelect } = this.props;
    const selectedDate = convertDate(date, dateType)
    this.setState({
      selectedDate,
    })

    if (onSelect) {
      let d = formatDate(selectedDate.date, dateType)
      onSelect(d);
    }
  }

  changeData(date){
    const startingDate = date.getStartingDate()
    const calendarData = [];
    for (let r = 0; r < Config.NUM_OF_WEEKS; r += 1) {
      const calendarRow = (
        <div
          key={startingDate}
          style={{ margin: '2px 0px'}}
        >
          {this.getWeekDays(startingDate)}
        </div>

      )
      calendarData.push(calendarRow)
    }
    if (startingDate.getMonth() === date.getMonth()) {
      calendarData.push(
        <div
          key={startingDate}
          style={{ margin: '2px 0px' }}
        >
          {this.getWeekDays(startingDate)}
        </div>,
      )
    }
    return calendarData
  }

  goToPrev(){
    const {date, monthList}= this.state
    let newDate = date.getPrevMonth()
    this.setState({
      date: newDate,
      currentMonth: monthList[newDate.getMonth()],
      currentYear: newDate.getYear(),
    })
  }

  goToNext(){
    const {date, monthList}= this.state
    let newDate = date.getNextMonth()
    this.setState({
      date: newDate,
      currentMonth: monthList[newDate.getMonth()],
      currentYear: newDate.getYear(),
    })
  }

  render() {
    const {
      date,
      monthList,
      currentYear,
      currentMonth,
      yearList,
    } = this.state;
    return (
      <div>
        <div className="main-datepicker-container">
          <div className="datepicker-container">
            <div className="datepicker-header">
                <span className='datepicker-button' 
                  onClick={()=> this.goToPrev()}>&lt;</span>
                <select className='select-container'
                  value={currentMonth}
                  onChange={event => this.changeMonth(event.target.value)}>
                  {
                    monthList.map(m => (
                      <option key={m} value={m}> {m} </option>
                    ))
                  }
                </select>
                <select className='select-container'
                  value={currentYear}
                  onChange={event => this.changeYear(event.target.value)}>
                  {
                    yearList.map(m => (
                      <option key={m} value={m}> {m} </option>
                    ))
                  }
                </select>  
                <span className='datepicker-button' 
                  onClick={()=> this.goToNext()}>&gt;</span>          
            </div>
            <div className="datepicker-content-container">
              <div className="weekdays-container">
                <WeekHeader />
              </div>
              <div className="datepicker-contents">
                { this.changeData(date) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default DatePicker;
