import React from 'react'
import {Col, Row} from "reactstrap";
import csv from "../../assets/img/csv.svg";
import pdf from "../../assets/img/pdf.svg";
import xls from "../../assets/img/xls.svg";
import xml from "../../assets/img/xml.svg";
import txt from "../../assets/img/txt.svg";
import xls_Duplicate from '../../assets/img/xls_2.svg'

const DownloadGroup = (props) => {
  let {
    onCSV,
    onPDF,
    onXLS,
    onXML,
    onTXT,
    onXLSX
  } = props

  return (
    <Row>
      <Col className="text-center">
        <img src={csv} alt="CSV" className="report-download-icon" onClick={onCSV}
             title={"Sales Register on CSV Format"}/>
        <img src={pdf} alt="PDF" className="report-download-icon" onClick={onPDF}
             title={"Sales Register on PDF Format"}/>
        <img src={xls} alt="XLS" className="report-download-icon" onClick={onXLS}
             title={"Sales Register on XLS Format"}/>
        <img src={xml} alt="XML" className="report-download-icon" onClick={onXML}
             title={'Sales Register on XML Format'}/>
        <img src={txt} alt="TXT" className="report-download-icon" onClick={onTXT}
             title={'Sales Register on TXT Format'}/>
        <img src={xls_Duplicate} alt="XLSX" className="report-download-icon" onClick={onXLSX}
             title={'Sales Register Copy on XLS Format'}/>
      </Col>
    </Row>
  );
}

export default DownloadGroup
