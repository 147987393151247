import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {CSVLink} from "react-csv";
import moment from "moment";

const DownloadModal = (props) => {
  let {open, toggle, downloadPdf, data, name} = props
  return (
    <Modal isOpen={open} size="md" className={'modal-md ' + props.className}>
      <ModalHeader>Select the file type you want to download.</ModalHeader>
      <ModalBody className="download-body-height">
        <div className="download-buttongroup">
            <span className="download-button button-pdf" onClick={downloadPdf} title="Download as PDF file">
            <i
              className="fa fa-file-pdf-o"></i>
            </span>
          <CSVLink
            data={data}
            filename={name + '-' + moment().format() + ".csv"}
          >
              <span className="download-button button-csv" title="Download as CSV file">
            <i
              className="fa fa-file-excel-o"></i>
            </span>
          </CSVLink>
          <CSVLink
            data={data}
            filename={name + '-' + moment().format() + ".txt"}
          >
              <span className="download-button button-txt" title="Download as text file">
            <i
              className="fa fa-file-text"></i>
            </span>
          </CSVLink>
        </div>
        }
      </ModalBody>

      <ModalFooter>
        <Row>
          <Col>
            <Button outline color="danger" className="float-right" onClick={toggle}>Cancel
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

export default DownloadModal;
