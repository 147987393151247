import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addSuccessMessage} from "../../actions/messages/success_message";
import ViewData from "../../shared/form-helpers/ViewData";
import {ArrayValue} from "../../shared/helpers/ArrayKey";
import {getCustomer} from "../../apis/customers";
import {getCountries} from "../../apis/countries";

class ViewCustomer extends Component {

  data = {
    type: "C",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    website: "",
    address: "",
    mobile_no: "",
    zip_code: "",
    state: "",
    country_id: ""
  }

  error = {}
  state = {
    company: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    restData: null,
    countries: [],
    provinces: [],
    isSuccess: null,
    processing: false
  }

  fetchData() {
    getCountries(1000)
    getCustomer(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData();
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onEdit() {
    let id = this.props.match.params.id
    this.props.history.push("/sales/customers/" + id + "/edit")
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {updateCustomer, getCountries, getCustomer} = nextProps
    let {restError, countries, provinces, restData, isSuccess, processing} = prevState
    if (updateCustomer) {
      let {success, error} = updateCustomer
      if (error) {
        if (error.status === 422) {
          restError = error.data
        }
      } else if (success) {
        isSuccess = success.data.message
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      } else if (error) {
        console.log(error)
      }
    }

    if (getCustomer) {
      let {success, error} = getCustomer
      if (success) {
        restData = success.data
        console.log(restData)
      } else if (error) {
        console.log(error)
      }
      processing = getCustomer.processing
    }
    return {
      restError, countries, provinces, restData, isSuccess, processing
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== prevState.restError) {
      this.setState({
        error: this.state.restError
      })
    }

    if (this.state.restData !== prevState.restData) {
      this.data = this.state.restData
      this.setState({
        data: this.data
      })
    }

    if (this.state.isSuccess !== prevState.isSuccess) {
      store.dispatch(addSuccessMessage(this.state.isSuccess))
      this.props.history.push('/sales/customers');
    }
  }

  render() {
    let {data, countries, provinces} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardHeader>
            <ButtonGroup className="float-right">
              <Button outline color="primary" onClick={this.onEdit.bind(this)}>Edit</Button>
            </ButtonGroup>
          </CardHeader>
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Basic Information</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  <Col>
                    <Badge color={data.type === "I" ? "primary" : "success"} pill className="status-badge">
                      {data.type === "I" ? "Individual" : "Company"}
                    </Badge>
                  </Col>
                </Row>
                <br/>
                {data.type === "I" ? <Row>
                    {data.first_name &&
                    <Col sm="12" md="6">
                      <ViewData
                        label="First Name"
                        data={data.first_name}
                      />
                    </Col>
                    }
                    {data.last_name &&
                    <Col sm="12" md="6">
                      <ViewData
                        label="Last Name"
                        data={data.last_name}
                      />
                    </Col>
                    }
                  </Row> :
                  <Row>
                    {data.last_name &&
                    <Col sm="12" md="6">
                      <ViewData
                        label="Company Name"
                        data={data.last_name}
                      />
                    </Col>}
                  </Row>}
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Contact Information</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  {data.phone_number &&
                  <Col sm="12" md="6">
                    <span className="form-span-label">Phone Number</span>
                    <FormGroup>
                      <span className="form-span-value">{data.phone_number}</span>
                    </FormGroup>
                  </Col>}
                  {data.mobile_no &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Mobile Number"
                      data={data.mobile_no}
                    />
                  </Col>}
                </Row>
                <Row>
                  {data.email &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Email"
                      data={data.email}
                    />
                  </Col>}
                  {data.website &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Website"
                      data={data.website}
                    />
                  </Col>
                  }
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Address</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  {data.address &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Billing Address"
                      data={data.address}
                    />
                  </Col>
                  }
                  {data.city &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="City"
                      data={data.city}
                    />
                  </Col>
                  }
                </Row>
                <Row>
                  {data.country_id &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Country"
                      data={ArrayValue(countries, data.country_id, 'id', 'name')}
                    />
                  </Col>
                  }
                  {data.state &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="State"
                      data={ArrayValue(provinces[data.country_id], data.state, 'id', 'name')}
                    />
                  </Col>
                  }
                </Row>
                {data.zip_code ?
                  <Row>
                    <Col sm="12" md="6">
                      <ViewData
                        label="Postal/Zip Code"
                        data={data.zip_code}
                      />
                    </Col>
                  </Row>
                  : ""
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {updateCustomer, getCountries, getCustomer} = state
  return {
    updateCustomer, getCountries, getCustomer
  }
}

export default connect(mapStateToProps)(ViewCustomer);
