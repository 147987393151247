import {Get} from "../actions/crud/get";

export const getResellers =() =>{
  let reducers = {
    success: 'GET_RESELLERS_SUCCESS',
    error: 'GET_RESELLERS_ERROR'
  }

  let url = "resellers"
  let get = new Get(url, reducers)
  get.get()
}
