import React, {Component} from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import 'owl.carousel';
import 'bootstrap'
import '../../assets/css/style.default.css'
import '../../assets/front'
import main from '../../assets/main.png'
import stat from '../../assets/stat.png'
import logo from '../../assets/img/brand/smallLogo.png'
import {Button} from "reactstrap";
import SignIn from "../Auth/SignIn";
import store from "../../Store";
import {disableLoader} from "../../actions/loaders/loader";
import queryString from "query-string";
import {isEmptyObject} from "../../shared/helpers/ArrayKey";

class LandingPage extends Component {

  state = {
    isSignIn: false,
    isSignUp: false,
    isMessage: false,
    message: ''
  }

  componentDidMount() {
    const response = queryString.parse(this.props.location.search);
    if (!isEmptyObject(response)){
      this.setState({
        isSignIn: response.popup
      })
    }

    if (this.props.location.state)
      if (this.props.location.state.isLogin)
        store.dispatch(disableLoader())
  }

  toggleSignIn(){
    this.setState({
      isSignIn: !this.state.isSignIn
    })
  }

  toggleSignUp(message){
    this.setState({
      isSignUp: !this.state.isSignUp
    },
      ()=>{
      this.props.history.push()
      })
  }

  loginClick(){
    this.setState({
      isSignUp: false,
      isSignIn: true
    })
  }

  signUpClick(){
    this.setState({
      isSignUp: true,
      isSignIn: false
    })
  }

  toggleMessage(){
    this.setState({
      isMessage: !this.state.isMessage
    })
  }

  setMessage(message){
    this.setState({
      message
    },()=>{
      this.props.history.push("/signup-success",{message:message})
    })
  }

  forgotClick(){
    this.props.history.push('/forgot-password')
  }


  render() {
    let {isSignIn,isSignUp,isMessage,message} = this.state
    return (
      <div>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top"><img src={logo} className="dashboard-image-brand" alt="GERP"/>
            <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                    className="navbar-toggler navbar-toggler-right"><span></span><span></span><span></span></button>
            <div id="navbarSupportedContent" className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto align-items-start align-items-lg-center">
                {/*<li className="nav-item"><a href="#about-us" className="nav-link link-scroll">About Us</a></li>*/}
                {/*<li className="nav-item"><a href="#features" className="nav-link link-scroll">Features</a></li>*/}
                {/*<li className="nav-item"><a href="#testimonials" className="nav-link link-scroll">Testimonials</a></li>*/}
              </ul>
              <div className="navbar-text">
                <Button outline color="success" className="download-button dashboard-button" onClick={this.toggleSignIn.bind(this)}>SignIn</Button>
              </div>
            </div>
          </nav>
        </header>
        <section id="hero" className="hero hero-home">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-6 text order-2 order-lg-1">
                <h1>GERP &mdash; Erp&nbsp;System</h1>
                <p className="hero-text">
                  Enterprise resource planning (ERP) is business process management software that allows an organization to use a system of integrated applications to manage the business and automate many back office functions related to technology, services and human resources.

                  ERP software typically integrates all facets of an operation — including product planning, development, manufacturing, sales and marketing — in a single database, application and user interface.
                </p>
                <div className="CTA">
                  <Button outline color="success">Discover More</Button>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2"><img src={main} alt="..." className="img-fluid"/>
              </div>
            </div>
          </div>
        </section>
        <section id="browser" className="browser">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="h3 mb-5">How it works</h2>
                <div className="browser-mockup">
                  <div id="nav-tabContent" className="tab-content">
                    <div id="nav-first" role="tabpanel" aria-labelledby="nav-first-tab"
                         className="tab-pane fade show active"><img src={stat} alt="..."
                                                                    className="img-fluid"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="myTab" role="tablist" className="nav nav-tabs">
              <div className="row">
                <div className="col-md-4"><a id="nav-first-tab" data-toggle="tab" href="#nav-first" role="tab"
                                             aria-controls="nav-first" aria-expanded="true"
                                             className="nav-item nav-link active"> <span className="number">1</span>Choose
                  any website to turn into an interactive pinboard for feedback</a></div>
                <div className="col-md-4"><a id="nav-second-tab" data-toggle="tab" href="#nav-second" role="tab"
                                             aria-controls="nav-second" className="nav-item nav-link"> <span
                  className="number">2</span>Choose any website to turn into an interactive pinboard for feedback</a>
                </div>
                <div className="col-md-4"><a id="nav-third-tab" data-toggle="tab" href="#nav-third" role="tab"
                                             aria-controls="nav-third" className="nav-item nav-link"> <span
                  className="number">3</span>Choose any website to turn into an interactive pinboard for feedback</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SignIn
        isOpen={isSignIn}
        toggle={this.toggleSignIn.bind(this)}
        signUpClick={this.signUpClick.bind(this)}
        forgotClick={this.forgotClick.bind(this)}
        />
      </div>
    );
  }
}

export default LandingPage
