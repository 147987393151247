export default function updatePaymentMethod(
  state = { validation: null, success: null},
  action
) {
  switch (action.type) {

    case 'UPDATE_PAYMENT_METHOD_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'UPDATE_PAYMENT_METHOD_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null}};
    case 'UPDATE_RESET_PAYMENT_METHOD':
      return {...state, ...{success: null,validation: null}};
    default:
      return state;
  }
}
