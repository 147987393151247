import React from 'react';
import FiscalYear from "./FiscalYear";
import Tax from "./Tax";
import Units from "./Units";
import Currency from './Currency'
import Settings from './Settings'
import ProductsFamilies from './ProductsFamilies'
import {Card, Tabs} from "antd";
import InvoiceTemplate from "./InvoiceTemplate";


const {TabPane} = Tabs;

class CompanySettings extends React.Component {

  render() {
    return (
      <div className="animated fadeIn">
        <div className="top-page-content">
          <Card>
            <Tabs>
              <TabPane tab="Fiscal Year" key="1">
                <FiscalYear/>
              </TabPane>
              <TabPane tab="Tax" key="2">
                <Tax/>
              </TabPane>
              <TabPane tab="Products Families" key="3">
                <ProductsFamilies/>
              </TabPane>
              <TabPane tab="Units" key="4">
                <Units/>
              </TabPane>
              <TabPane tab="Currency" key="5">
                <Currency/>
              </TabPane>
              <TabPane tab="Invoice Template" key="6">
                <InvoiceTemplate/>
              </TabPane>
              <TabPane tab="Settings" key="7">
                <Settings/>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }

}

export default CompanySettings
