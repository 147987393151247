export default function changePassword(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'CHANGE_PASSWORD_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'CHANGE_PASSWORD_ERROR':
      return {...state, ...{error: action.error.response}};
    case 'CHANGE_PASSWORD_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'CHANGE_RESET_PASSWORD':
      return {...state, ...{error:null,success:null,processing: ''}};
    default:
      return state;
  }
}
