import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody, CardFooter,
  Col, CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {Select, Switch} from "antd";
import {getProductFamilies} from "../../apis/product-families";
import {createPaymentGateway, createResetPaymentGateway, getAdminPaymentGateway} from "../../apis/payment-gateway";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {localValidation} from "../../shared/helpers/ValidationHelper";
import {getActiveUnits} from "../../apis/units";
import {ekUpload} from "./fileUploader"
import img from "./../../assets/img/logo.png"
import UploadFile from "./UploadFile";
import {Config} from "../../Config";
import {checkURL, ucFirst, ucFirstWords} from "../../shared/helpers/GeneralHelpers";
import {getCountries} from "../../apis/countries";
import HorizontalCheckbox from "../../shared/form-helpers/HorizontalCheckbox";
import {getPlatforms} from "../../apis/platforms";

class NewPaymentGateway extends Component {
  id = this.props.match.params.id
  data = {
    name: "",
    status: 1,
    enable_tv: 1,
    platforms: [],
    countries: [],
    config: [{
      key: "",
      value: ""
    }]
  }

  error = {
    name: "",
    sales_price: "",
    purchase_price: "",
    unit_id: "",
    description: "",
    family: "",
    status: 1,
    product_family_id: ""
  }

  state = {
    units: [],
    service: true,
    status: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    countries: [],
    platforms: [],
    createSuccess: null,
    username: null,
    families: [],
    fileUpload: {},
    img: img,
  }

  fetchData() {
    getCountries()
    getPlatforms()
    if (this.id) {
      getAdminPaymentGateway(this.id)
    }
  }

  componentDidMount() {
    this.fetchData();
    // ekUpload();
  }

  changeService() {
    let data = {...this.data}
    let error = {...this.error}
    data['type'] = "service"
    this.data = data
    this.setState({
      service: true,
      data,
      error
    })
  }

  changeProduct() {
    let data = {...this.data}
    let error = {...this.error}
    data['type'] = "product"
    this.data = data
    this.setState({
      service: false,
      data,
      error
    })
  }

  changeOn() {
    let {data} = this.state
    data['status'] = 1
    this.setState({
      status: true,
      data
    })
  }

  changeOff() {
    let {data} = this.state
    data['status'] = 0
    this.setState({
      status: false,
      data
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/payment-gateway")
  }

  onReset() {
    let {service} = this.state
    this.setState({
      data: {...this.data},
      error: {...this.error},
      service
    })
  }

  onSubmit() {
    let {data, fileUpload} = this.state

    let validationRule = {
      // name: ['required'],
    }
    if (fileUpload.id) {
      data['image'] = fileUpload.id
    }
    let localValidationStatus = false
    let check = localValidation(data, validationRule, this.state.error, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      let formData = new FormData()
      Object.keys(data).forEach((i) => {
        if (i === "platforms") {
          data["platforms"].forEach((oo, ii) => {
            formData.append(`platforms[${ii}]`, oo)
          })
        } else if (i === "countries") {
          let key = 0
          data[i].forEach((oo) => {
            let index = this.state.countries.findIndex(x => x.name === oo)
            if (index > -1) {
              formData.append(`countries[key]`, this.state.countries[index].id)
              key++;
            }
          })
        } else if (i === "config") {
          let _config = {}
          data[i] && data[i].length && data[i].forEach(o => {
            if (o.key && o.value)
              _config[o.key] = o.value
          })
          formData.append(i, JSON.stringify(_config))
        } else if (i === "enable_tv") {
          if (data[i]) formData.append(i, "1")
          else formData.append(i, "0")
        } else if (i === "status") {
          if (data[i]) formData.append(i, 1)
          else formData.append(i, 0)
        } else
          formData.append(i, data[i])
      })
      if (this.id) {
        formData.append("_method", "PATCH")
        createPaymentGateway(formData, this.id)
      } else {
        createPaymentGateway(formData)
      }
    }
  }

  onUploadImage(image) {
    // uploadImageProduct(file)
    let data = {...this.state.data}
    let error = {...this.state.error}
    data["logo"] = image
    error["logo"] = ""
    this.setState({
      data, error
    })

  }

  componentDidUpdate(prevProps, prevState) {
    let {createPaymentGateway, getCountries, getPlatforms, getAdminPaymentGateway} = this.props
    if (createPaymentGateway && createPaymentGateway !== prevProps.createPaymentGateway) {
      let {success, validation} = createPaymentGateway
      if (success) {
        this.triggerSuccessMessage(success.data.message)
      } else if (validation) {
        this.triggerErrorMessage(validation)
      }
    }

    if (getAdminPaymentGateway && getAdminPaymentGateway !== prevProps.getAdminPaymentGateway) {
      let {success} = getAdminPaymentGateway
      if (success) {
        let _data = success.data
        _data["description"] = _data["description"] ? JSON.stringify(_data["description"]) : ""
        if (_data["countries"]) {
          _data["countries"] = _data["countries"].map(x => x.name)
        }
        if (_data["platforms"]) {
          _data["platforms"] = _data["platforms"].map(x => x.bit_value)
        }
        if (_data["required_field"]) {
          _data["required_field"] = _data["required_field"] ? JSON.stringify(_data["required_field"]) : _data["required_field"]
        }
        if (_data["config"]) {
          _data["config"] = _data["config"] ? _data["config"] : {}
          let _config = []
          try {
            Object.keys(_data["config"]).forEach(i => {
              let o = _data["config"][i]
              _config.push({
                key: i,
                value: o
              })
            })
          } catch (e) {

          }
          _config.push({
            key: "",
            value: ""
          })
          _data["config"] = _config
        } else {
          let _config = {
            key: "",
            value: ""
          }
          _data["config"] = [_config]
        }
        this.setState({
          data: _data
        })
      }
    }

    if (getCountries && getCountries !== prevProps.getCountries) {
      let {success} = getCountries
      if (success && success.data) {
        this.setState({
          countries: success.data
        })
      }
    }

    if (getPlatforms && getPlatforms !== prevProps.getPlatforms) {
      let {success} = getPlatforms
      if (success && success.data) {
        this.setState({
          platforms: success.data
        })
      }
    }
  }

  triggerSuccessMessage(message) {
    store.dispatch(addMessage(message, "success", "Create Payment Gateway"))
    // createResetPaymentGateway()
    setFetch(true)
    this.props.history.push('/payment-gateway')
  }

  triggerErrorMessage(error) {
    this.setState({
      error: error
    }, () => {
      createResetPaymentGateway()
    })
  }

  toggleSwitch(name) {
    let {data} = this.state
    data[name] = !data[name]
    this.setState({data})
  }

  handleCountrySelect(value) {
    console.log(value)
    if (value) {
      let {data, error} = this.state
      data["countries"] = value
      error["countries"] = ""
      this.setState({data, error})
    }
  }

  onChangePlatform(e) {
    let value = e.target.value
    let {data, error} = {...this.state}
    if (value) {
      let platforms = data.platforms ? [...data.platforms] : []
      let index = platforms.indexOf(value * 1)
      if (index > -1) {
        platforms.splice(index, 1)
      } else {
        platforms.push(value * 1)
      }
      data['platforms'] = platforms
      error['platforms'] = ""
      this.setState({
        data, error
      }, () => console.log(this.state.data))
    }
  }

  onChangeUrlsEdit(index, key, e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    let option = data[index] && data[index].length ? [...data[index]] : [{}]
    option[key][name] = value;
    if (name === "key" && value && key * 1 === option.length - 1) {
      option.push({
        key: "",
        value: ""
      })
    }
    if (option[key]["key"] === "" && option[key]["value"] === "")
      option.splice(key, 1)
    data[index] = option
    if (error[`${index}.` + key + "." + name] !== '') {
      error[`${index}.` + key + "." + name] = '';
    }
    error[index] = ""
    this.setState({data, error});

  }

  render() {
    let {data, error, units, countries, platforms} = this.state

    let children = countries.map((o, i) => (
      <Select.Option key={o.id} value={o.name}>{o.name}</Select.Option>
    ))

    let urlsList = []
    this.state.data.config && Object.keys(this.state.data.config).forEach((key,i) => {
      let url = this.state.data.config[key]
      urlsList.push(
        <div className={`row form-group ${error.option ? "has-error" : ""}`} key={key}>
          <Col>
            <input className="form-control"
                   name="key"
                   placeholder="Key"
                   value={url.key}
                   onChange={e => this.onChangeUrlsEdit("config", i, e)}
              // disabled={!(Math.pow(2, key) & this.state.urlEditRow)}
            />
          </Col>
          <Col>
            <input className="form-control"
                   name="value"
                   placeholder="Value"
                   value={url.value}
                   onChange={e => this.onChangeUrlsEdit("config", i, e)}
              // disabled={!(Math.pow(2, key) & this.state.urlEditRow)}
            />
          </Col>

        </div>
      )
    })

    let platformOption = platforms.map((o) => {
        return (
          {
            name: o.name,
            id: o.bit_value
          }
        )
      }
    )
    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.name} className="form-control"
                             name="name" value={data.name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Slug</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.slug} className="form-control"
                             name="slug" value={data.slug}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.slug}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Status</span>
                    <FormGroup>
                      <Switch
                        checked={data.status}
                        onChange={this.toggleSwitch.bind(this, "status")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Details</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Enable TV</span>
                    <FormGroup>
                      <Switch
                        checked={data.enable_tv}
                        onChange={this.toggleSwitch.bind(this, "enable_tv")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Description</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="textarea" invalid={!!error.description} className="form-control"
                             name="description" value={data.description}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Countries</span>&nbsp;<span className="text-danger">*</span>
                    <div className={error.countries ? "form-group error": "form-group"}>
                      <Select
                        mode="multiple"
                        // allowClear
                        style={{width: '100%'}}
                        dropdownStyle={{
                          borderColor: "red"
                        }}
                        placeholder="Please select"
                        // defaultValue={["Afganistan"]}
                        value={data["countries"] ? data["countries"] : []}
                        onChange={(e) => this.handleCountrySelect(e)}
                      >
                        {children}
                      </Select>
                      {
                        error.countries ? <div className="invalid-feedback d-block">{error.countries}</div>: null
                      }

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <span className="form-span-label">Platforms</span>&nbsp;<span className="text-danger">*</span>
                    <div>
                      {
                        platformOption && platformOption.map((o, i) => {
                          return (
                            <CustomInput type="checkbox" name="platforms" value={o.id}
                                         onClick={(e) => this.onChangePlatform(e)}
                                         checked={data.platforms.includes(o.id * 1)}
                                         id={`exampleCustomCheckbox-platforms-${o.id}-${o.name}`} label={o.name}
                                         invalid={!!error.platforms}/>
                          )
                        })
                      }
                    </div>
                    <FormFeedback>{error.platforms}}</FormFeedback>
                    <br/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Config</span>
                    {urlsList}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Required Field</span>
                    <FormGroup>
                      <Input type="textarea" invalid={!!error.required_field} className="form-control"
                             name="required_field" value={data.required_field}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.required_field}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Image *</span>
                    <div className={error.logo ? "form-group error": "form-group"}>
                      <UploadFile
                        img={data.logo ? (checkURL(data.logo) ? `${data.logo}?t=${Math.random(100)}` : URL.createObjectURL(data.logo)) : null}
                        onChange={(e) => this.onUploadImage(e)}/>
                      {error.logo ? <FormFeedback style={{display: "block"}}>{error.logo}</FormFeedback>: null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
          </CardBody>

          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {createPaymentGateway, getCountries, getPlatforms, getAdminPaymentGateway} = state
  return {
    createPaymentGateway, getCountries, getPlatforms, getAdminPaymentGateway
  }
}

export default connect(mapStateToProps)(NewPaymentGateway);
