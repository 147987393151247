import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {getCompanyUsers} from "../../actions/companies/get_company_users";
import store from '../../Store'
import {getUser} from "../../apis/users";

class CompanyUsers extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showPrintModal: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    store.dispatch(getCompanyUsers(limit, page, searchArray, dateFilter))
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getCompanyUsers
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    } = prevState;

    if (getCompanyUsers) {
      let {success, error} = getCompanyUsers;
      if (success) {
        console.log(success)
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    };
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  newAction() {
    this.props.history.push('/company-users/invite')
  }

  onUpdateClick(id){
    this.props.history.push(`/company-users/${id}/edit`)
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        image: true
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Created By',
        dataIndex: 'created_by'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Inactive'
          }
        ]
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        id, email, image, status, name,created_by
      } = single

      tableData.push({
        id, email, image, status, name,created_by
      })

    })

    return (
      <div className="animated fadeIn">
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          onDateConfirm={this.onDateConfirm.bind(this)}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Invite User"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getCompanyUsers
  } = state;
  return {
    getCompanyUsers
  };
}

export default connect(mapStateToProps)(CompanyUsers)
