import React, {Component} from 'react'
import {connect} from "react-redux";
import store from '../Store'
import {getLoggedInCompany} from "../actions/companies/get_company";
import {NumberFormatter} from "../shared/helpers/NumberFormatter";

class CurrencyAdder extends Component {
  state = {
    currency: ""
  }

  componentDidMount() {
    if (!this.props.getLoggedInCompany.success) {
      console.log("here")
      store.dispatch(getLoggedInCompany())
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {currency} = prevState
    let {getLoggedInCompany} = nextProps

    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        currency = success.data.currency ? success.data.currency.symbol : ""
      }
    }

    return {
      currency
    }
  }

  render() {
    let {currency} = this.state
    let {amount} = this.props
    return (
      <>
        {`${currency}${NumberFormatter(amount)}/-`}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    getLoggedInCompany
  } = state
  return {
    getLoggedInCompany
  }
}

export default connect(mapStateToProps)(CurrencyAdder)
