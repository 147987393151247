export default function updateUser(
  state = { error: null, success: null, validation: null },
  action
) {
  switch (action.type) {

    case 'UPDATE_USER_SUCCESS':
      return {...state, ...{success: action.success,error: null}};
    case 'UPDATE_USER_ERROR':
      return {...state, ...{error: action.error.response,success: null}};
    case 'UPDATE_USER_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null,error: null}};
    case 'UPDATE_RESET_USER':
      return {...state, ...{success:null,error:null,validation: null}};
    default:
      return state;
  }
}
