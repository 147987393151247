import React, {Component} from 'react'
import {Col, Row} from "reactstrap";
import DownloadGroup from "../../shared/helpers/DownloadGroup";
import {downloadFile} from "../../actions/download-info/download_file";
import store from '../../Store'

class ReportHeader extends Component {

  onCSV() {
    store.dispatch(downloadFile(this.props.dateFilter, 'csv', 'CSV', this.props.page, this.props.reportType, null, this.props.filters))
  }

  onPDF() {
    store.dispatch(downloadFile(this.props.dateFilter, 'pdf', 'PDF', this.props.page, this.props.reportType, null, this.props.filters))
  }

  onXLS() {
    store.dispatch(downloadFile(this.props.dateFilter, 'xls', 'XLS', this.props.page, this.props.reportType, null, this.props.filters))
  }

  onXML() {
    store.dispatch(downloadFile(this.props.dateFilter, 'xml', 'XML', this.props.page, this.props.reportType, null, this.props.filters))
  }

  onTXT() {
    store.dispatch(downloadFile(this.props.dateFilter, 'txt', 'TXT', this.props.page, this.props.reportType, null, this.props.filters))
  }

  onXLSX(){
    store.dispatch(downloadFile(this.props.dateFilter, 'xls', 'XLSX', this.props.page, this.props.reportType, null, this.props.filters))

  }

  render() {
    let {
      userDetail
    } = this.props
    userDetail = userDetail || {}

    return (
      <Row>
        <Col sm="4">
          <span className="text-bold">{`PAN No: ${userDetail && userDetail.pan ? userDetail.pan : ""}`}</span>
        </Col>
        <Col sm="4" className="text-center">
          <Row><Col>
                  <span className="report-company-title">
                    {userDetail.name ? userDetail.name : ""}
                  </span>
          </Col></Row>
          <Row><Col>
                  <span>
                    {`${userDetail.street ? userDetail.street : ""},${userDetail.city ? userDetail.city : ""}`}
                  </span>
          </Col></Row>
          <Row><Col>
                  <span>
                    {`${(userDetail.province && userDetail.province.name) ? userDetail.province.name : ""},${(userDetail.country_info && userDetail.country_info.name) ? userDetail.country_info.name : ""}`}
                  </span>
          </Col></Row>
          <Row><Col>
                  <span>
                    <i className="fa fa-phone"></i>
                    {` ${userDetail.phone ? userDetail.phone : ""}`}
                  </span>
          </Col></Row>
          <Row><Col>
                  <span>
                    <i className="fa fa-envelope"></i>
                    {` ${userDetail.email ? userDetail.email : ""}`}
                  </span>
          </Col></Row>
        </Col>
        <Col sm="4">
          <Row> <Col className="text-center">
            Generate Report
          </Col></Row>
          <hr/>
          <DownloadGroup
            onCSV={this.onCSV.bind(this)}
            onXML={this.onXML.bind(this)}
            onPDF={this.onPDF.bind(this)}
            onTXT={this.onTXT.bind(this)}
            onXLS={this.onXLS.bind(this)}
            onXLSX={this.onXLSX.bind(this)}
          />
        </Col>
      </Row>
    );
  }
}


export default ReportHeader
