import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap"
import ViewData from "../../shared/form-helpers/ViewData";
import {getPOS} from "../../apis/pos";

class ViewPOS extends Component {

  state = {
    data: {},
  }

  fetchData() {
    getPOS(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getPOS} = nextProps
    let {data} = prevState

    if (getPOS) {
      let {success} = getPOS
      if (success) {
        data = success.data
      }
    }
    return {
      data
    }
  }

  onEdit() {
    let id = this.props.match.params.id
    this.props.history.push("/pos/" + id + "/edit")
  }

  render() {
    let {data} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardHeader>
            <ButtonGroup className="float-right">
              <Button outline color="primary" onClick={this.onEdit.bind(this)}>Edit</Button>
            </ButtonGroup>
          </CardHeader>
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Basic</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  {data.name &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Name"
                      data={data.name}
                    />
                  </Col>}
                </Row>
                <Row>
                  {data.description &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Description"
                      data={data.description}
                    />
                  </Col>}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getPOS} = state
  return {
    getPOS
  }
}


export default connect(mapStateToProps)(ViewPOS);
