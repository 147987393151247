
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Provider from "react-redux/es/components/Provider";
import store from "./Store";
import Routes from "./routes/Routes";
import "@coreui/icons/css/coreui-icons.css"
import "flag-icon-css/css/flag-icon.min.css"
import "font-awesome/css/font-awesome.min.css"
import "simple-line-icons/css/simple-line-icons.css"
import "./assets/css/scss/coreui.css"
import "./assets/css/fileuploader.css"
import './assets/required.css';
import './assets/datatables.css';
import './assets/css/custom.css'
import {BrowserRouter} from "react-router-dom";

const provider = (
  <Provider store={store}>
    <BrowserRouter>
      <Routes/>
    </BrowserRouter>
  </Provider>
);

export default provider;

ReactDOM.render(provider, document.getElementById('root'));
