export function setDownloadMessage(message) {
  return dispatch => {
    dispatch({
      type: "SET_DOWNLOAD_MESSAGE",
      message
    });
  };
}

export function deleteDownloadMessage() {
  return dispatch => {
    dispatch({
      type: "DELETE_DOWNLOAD_MESSAGE"
    });
  };
}
