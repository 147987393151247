import React, {Component} from 'react';
import {connect} from "react-redux";
import CurrencyModal from "./CurrencyModal";
import {
  createCurrency,
  createResetCurrency, deleteCurrency,
  deleteResetCurrency,
  getCurrencies, getCurrency, updateCurrency,
  updateResetCurrency
} from "../../apis/currencies";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class Currency extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    createSuccess: null,
    createError: null,
    updateSuccess: null,
    updateError: null,
    deleteSuccess: null,
    restData: [],
    statistics: {},
    status: {
      all: true,
      active: false,
      inactive: false
    },
    message: {
      type: '',
      message: ''
    },
    formData: {},
    formError: {},
    openModal: false,
    restView: null,
    newModal: false,
    viewModal: false,
    isNew: true,
    isSave: false,
    isView: null,
    isUpdate: false,
    id: '',
    showDeleteModal: false,
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
  }

  tempData = {}

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getCurrencies(limit, page, searchArray)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getCurrencies,
      createCurrency,
      getCurrency,
      updateCurrency,
      deleteCurrency
    } = nextProps

    let {
      createSuccess,
      createError,
      updateSuccess,
      deleteSuccess,
      updateError,
      restData,
      restView,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState

    if (getCurrencies) {
      let {success} = getCurrencies
      if (success) {
        restData = success.data.data || []
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      }
    }

    if (getCurrency) {
      let {success, error} = getCurrency
      if (success) {
        restView = success.data
      } else if (error) {
        console.log(error)
      }
    }

    if (createCurrency) {
      let {success, validation} = createCurrency
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (updateCurrency) {
      let {success, validation} = updateCurrency
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        updateError = validation
      } else {
        updateSuccess = null
        updateError = null
      }
    }

    if (deleteCurrency) {
      let {success, error} = deleteCurrency
      if (success) {
        deleteSuccess = success.data.message
      } else if (error) {
        console.log(error)
      }
    }

    return {
      createSuccess,
      createError,
      updateSuccess,
      deleteSuccess,
      updateError,
      restData,
      restView,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      createSuccess,
      createError,
      updateSuccess,
      deleteSuccess,
      updateError,
      restView,
    } = this.state

    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Currency"));
      this.setState({
        formData: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        createResetCurrency()
      })
    }

    if (updateSuccess !== null) {
      store.dispatch(addMessage(updateSuccess, "success", "Update Currency"));
      this.setState({
        formData: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        updateResetCurrency()
      })
    }

    if (deleteSuccess !== prevState.deleteSuccess) {
      store.dispatch(addMessage(deleteSuccess, "success", "Delete Success"));
      this.setState({
        showDeleteModal: false,
        id: ''
      }, () => {
        this.fetchData()
        deleteResetCurrency()
      })
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetCurrency()
      })
    }

    if (updateError !== null) {
      this.setState({
        formError: updateError
      }, () => {
        updateResetCurrency()
      })
    }

    if (restView !== prevState.restView) {
      this.tempData = restView
      this.setState({
        isUpdate: true,
        formData: restView
      })
    }
  }

  onNewClick() {
    this.tempData = {
      code: '',
      symbol: '',
      description: ''
    }

    this.setState({
      openModal: true,
      newModal: true,
      id: null,
      formData: {...this.tempData},
      formError: {},
      isView: null,
      isUpdate: null,
      isSave: true,
      viewModal: false
    })
  }

  toggleModal() {
    this.setState({
      openModal: false
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    let tempData = {...this.tempData}
    formData[name] = value;
    formError[name] = "";
    if (name === "name") {
      tempData[name] = value
      this.tempData = tempData
      this.setState({isNew: false, isSave: false})
    }
    this.setState({formData, formError});
  }

  onCreate() {
    createCurrency(this.state.formData)
  }

  onUpdate() {
    let {id, formData} = this.state
    updateCurrency(id, formData)
  }

  onReset() {
    this.setState({
      formData: {...this.tempData}
    })
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  onUpdateClick(id) {
    getCurrency(id)
    this.setState({
      openModal: true,
      newModal: false,
      viewModal: false,
      id: id
    })
  }

  onViewClick(id) {
    getCurrency(id)
    this.setState({
      openModal: true,
      newModal: false,
      viewModal: true,
      id: id
    })
  }

  onDelete(id) {
    this.state.showDeleteModal ?
      this.setState({showDeleteModal: false, id: ""}) :
      this.setState({showDeleteModal: true, id: id})
  }

  onDeleteConfirm() {
    deleteCurrency(this.state.id)
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }


  render() {
    let {
      openModal,
      restView,
      newModal,
      formData,
      formError,
      isView,
      isNew,
      isSave,
      isUpdate,
      restData,
      viewModal,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
    } = this.state

    const columns = [
      {
        title: 'Code',
        dataIndex: 'code'
      },
      {
        title: 'Symbol',
        dataIndex: 'symbol'
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            onClick: this.onViewClick.bind(this)
          },
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        id, code, description, symbol
      } = single

      tableData.push({
        id, code, description, symbol
      })
    })

    if (openModal)
      return (
        <CurrencyModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={restView ? "View Currency" : newModal ? "Add New Currency" : "Edit Currency"}
          data={formData}
          onChange={e => this.onChange(e)}
          error={formError}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
          viewModal={viewModal}
          isView={isView}
          isNew={isNew}
          isSave={isSave}
          isUpdate={isUpdate}
        />
      )
    else
      return (
        <>
          <TableHelpers
            columns={columns}
            tableData={tableData}
            to={to}
            from={from}
            total={total}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            currentPage={currentPage}
            paginationButton={this.paginationButton.bind(this)}
            lastPage={lastPage}
            filters={this.filters}
            newTitle="Create Currency"
            newAction={this.onNewClick.bind(this)}
            tabTable={true}
          />
          {/*<DeleteModal*/}
          {/*  open={showDeleteModal}*/}
          {/*  toggle={this.onDelete.bind(this)}*/}
          {/*  deleteItem={this.onDeleteConfirm.bind(this)}*/}
          {/*  title="Delete Currency"*/}
          {/*/>*/}
        </>
      );
  }
}

function mapStateToProps(state) {
  let {
    getCurrencies,
    createCurrency,
    getCurrency,
    updateCurrency,
    deleteCurrency
  } = state

  return {
    getCurrencies,
    createCurrency,
    getCurrency,
    updateCurrency,
    deleteCurrency
  }
}

export default connect(mapStateToProps)(Currency)
