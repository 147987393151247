import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import ViewData from "../../shared/form-helpers/ViewData";
import pdf from "../../assets/img/pdf.svg";
import {getTransaction} from "../../apis/transactions";

class ViewTransaction extends Component {
  componentDidMount() {
    getTransaction(this.props.match.params.id)
  }

  state = {
    data: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getTransaction
    } = nextProps

    let {
      data
    } = prevState

    if (getTransaction) {
      let {success} = getTransaction
      if (success)
        data = success.data
    }

    return {
      data
    }
  }

  render() {
    let {data} = this.state

    console.log(data)
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col className="text-center" sm="11">
                <span className="text-bold">
                  {`Transaction - ${data.transaction_id}`}
                </span>
              </Col>
              <Col className="float-right" sm="1">
                <img src={pdf} alt="PDF" className="report-download-icon"/>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col md="8">
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Customer Code"
                      data={data.customer_code}
                    />
                  </Col>
                  {data.customer &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Customer Name"
                      data={`${data.customer.last_name} ${data.customer.first_name}`}
                    />
                  </Col>
                  }
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Amount"
                      data={data.amount}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Channel"
                      data={data.recharged_type}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Product Type"
                      data={data.type}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Product Name"
                      data={data.product_name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Accepted"
                      data={data.accepted === 0 ? "Not Confirmed" : "Confirmed"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Transaction Date"
                      data={data.created_at}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getTransaction
  } = state

  return {
    getTransaction
  }
}

export default connect(mapStateToProps)(ViewTransaction)
