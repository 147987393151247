import store from '../Store'
import "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "billings"

export const createBilling = (data) => {
  let reducers = {
    success: 'CREATE_BILLING_SUCCESS',
    validation_error: 'CREATE_BILLING_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create();
}

export const downloadBilings = (limit, page = 1, filters = [],dateFilter={}) => {
  let reducers = {
    success: 'DOWNLOAD_BILLINGS_SUCCESS',
    error: 'DOWNLOAD_BILLINGS_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}

export const uploadImageBilling = (data) => {
  let reducers = {
    success: 'UPLOAD_IMAGE_BILLING_SUCCESS',
    validation_error: 'UPLOAD_IMAGE_BILLING_VALIDATION_ERROR'
  }
  let  url = 'file-upload'
  let create = new Create(url, reducers,data)
  create.create();
}

export const getBilling = (id) => {
  let reducers = {
    success: 'FETCH_BILLING_SUCCESS',
    error: 'FETCH_BILLING_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getBillings = (limit, page = 1, filters = [],dateFilter={}) => {
  let reducers = {
    success: 'FETCH_BILLINGS_SUCCESS',
    error: 'FETCH_BILLINGS_ERROR'
  }
  let group =new GetGroup(limit, page, filters,prefix,reducers,dateFilter)
  group.getGroup()
}

export const createResetBilling = () => {
  store.dispatch(resetData('CREATE_RESET_BILLING'))
}
