export default function deletePaymentMethod(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'DELETE_PAYMENT_METHOD_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DELETE_PAYMENT_METHOD_ERROR':
      return {...state, ...{error: action.error}};
    case 'DELETE_PAYMENT_METHOD_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'DELETE_RESET_PAYMENT_METHOD':
      return {...state, ...{success: null,error: null}};
    default:
      return state;
  }
}
