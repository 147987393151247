import axios from "axios";
import { Config } from "../../Config";
import store from "../../Store";
import {disableLoader, enableLoader} from "../loaders/loader";

function _success(success) {
  return { type: "SET_PASSWORD_SUCCESS", success };
}
function _error(error) {
  return { type: "SET_PASSWORD_ERROR", error };
}

export function setResetPassword() {
  return dispatch => {
    dispatch({
      type: "SET_RESET_PASSWORD"
    });
  };
}


export function setPassword(data) {
  return dispatch => {
    store.dispatch(enableLoader())
    let base_url = Config.BillingUrl;

    axios({
      url: base_url + `change-password`,
      method: "post",
      dataType: "json",
      data: data,
      headers: {
      }
    })
      .then(res => {
        store.dispatch(disableLoader())
        dispatch(_success(res));
      })
      .catch(error => {
        store.dispatch(disableLoader())
        dispatch(_error(error));
      });
  };
}

export default setPassword;
