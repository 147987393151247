import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {createPOS, createResetPOS} from "../../apis/pos";
import {localValidation} from "../../shared/helpers/ValidationHelper";

class NewPOS extends Component {

  data = {
    name: "",
    description: ""
  }

  error = {
    name: "",
    description: ""
  }

  state = {
    data: {...this.data},
    error: {...this.error},
    restError: null,
    createSuccess: null
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/pos")
  }

  onReset() {
    this.setState({
      data: {...this.data},
      error: {...this.error}
    })
  }

  onSubmit() {
    let validationRule = {
      name: ['required', 'text']
    }
    let localValidationStatus = false
    let check = localValidation(this.state.data, validationRule, this.state.error, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      createPOS(this.state.data)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {createPOS} = nextProps
    let {restError, createSuccess} = prevState
    if (createPOS) {
      let {success, validation} = createPOS
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        restError = null
        createSuccess = null
      }
    }

    return {
      restError, createSuccess
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
          error: this.state.restError
        },
        () => {
          createResetPOS()
        })
    }

    if (this.state.createSuccess !== null) {
      store.dispatch(addMessage(this.state.createSuccess, "success", "Create POS"));
      createResetPOS()
      setFetch(true)
      this.props.history.push('/pos')
    }
  }

  render() {
    let {data, error} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col md="2" sm="12"><span className="form-left-span"><strong>Basic</strong></span></Col>
              <Col md="10" sm="12">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.name} className="form-control"
                             name="name" value={data.name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Description</span>
                    <FormGroup>
                      <Input invalid={!!error.description} className="form-control" name="description"
                             onChange={e => this.onChange(e)} value={data.description}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {createPOS} = state
  return {
    createPOS
  }
}

export default connect(mapStateToProps)(NewPOS);
