import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";

function _success(success) {
  return {type: 'CHANGE_PASSWORD_SUCCESS', success}
}

function _error(error) {
  return {type: 'CHANGE_PASSWORD_ERROR', error}
}

export function changeResetPassword() {
  return dispatch => {
    dispatch({
      type: "CHANGE_RESET_PASSWORD"
    });
  };
}


export function changePassword(id,data) {
  return dispatch => {

    let config = {
      url: Config.BillingUrl + `users/${id}/change-password`,
      method: "post",
      dataType: 'json',
      data: data,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    };

    let request = axios(config)

      .then(res => {
        // dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
        dispatch(_error(error));
        // dispatch(_processing(false));
      });

    return {type: 'CHANGE_PASSWORD_SUCCESS', payload: request}
  }
}

export default changePassword;
