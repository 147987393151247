import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";
import {_error, _success, _validation_error} from '../connect_reducer'
import {noInternet, serverError, unauthorizedError} from "../error";
import {addMessage} from "../messages/success_message";

export class Update {
  constructor(url, reducers, data) {
    this.url = url
    this.reducers = reducers
    this.data = data
  }

  update() {
    store.dispatch(enableInnerLoader())
    let config = {
      url: Config.BillingUrl + this.url,
      method: "patch",
      dataType: 'json',
      data: this.data,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    };

    axios(config)
      .then(res => {
        store.dispatch(_success(res, this.reducers.success))
        store.dispatch(disableInnerLoader())

      })
      .catch(error => {
        store.dispatch(disableInnerLoader())
        if (error.response) {
          if (error.response.status === 422)
            if (error.response.data.message)
              store.dispatch(_validation_error(error.response.data.message, this.reducers.validation_error))
            else
              store.dispatch(_validation_error(error.response.data, this.reducers.validation_error))
          else if (error.response.status === 403)
            store.dispatch(unauthorizedError(error.response.data.message))
          else if (error.response.status >= 500)
            store.dispatch(serverError())
          else if (error.response.data && error.response.data.message) {
            store.dispatch(addMessage(error.response.data.message, "error"))
          }
        } else if (error.request) {
          store.dispatch(noInternet())
        } else {
          store.dispatch(serverError())
        }
      });
  }
}
