import axios from 'axios'
import store from '../../Store'
import {disableLoader, enableLoader} from "../../actions/loaders/loader";
import {loginToken} from "./GeneralHelpers";

export const downloadFile = (url, name="", issql = null,isbinary = null) => {
  name = name + ""
  store.dispatch(enableLoader())
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + loginToken()
    }
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    if (!isbinary) {
      if (issql)
        link.setAttribute('download', `${name} ${name.includes('.sql')?'':'.sql'}`);
      else
        link.setAttribute('download', name + `${name} ${name.includes('.pdf')?'':'.pdf'}`);
    }
    else
      link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    store.dispatch(disableLoader())
  })
    .catch(err => {
      console.log(err)
      store.dispatch(disableLoader())
    });
}
