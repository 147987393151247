import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";
import {_success} from '../connect_reducer'
import {noInternet, serverError, unauthorizedError} from "../error";
import {addMessage} from "../messages/success_message";

export class GetGroup {
  constructor(limit = null, page = 1, filters = [], url, reducers = {}, dateFilter = {}, skipCheck, extra_params={}) {
    this.limit = limit
    this.page = page
    this.filters = filters
    this.url = url
    this.reducers = reducers
    this.dateFilter = dateFilter
    this.skipCheck = skipCheck
    this.extra_params = extra_params
  }

  getGroup() {
    store.dispatch(enableInnerLoader())
    let config = {
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      },
      params: {
        limit: this.limit || 10,
        page: this.page,
        start_date: this.dateFilter.start_date,
        end_date: this.dateFilter.end_date
      }
    };

    for (let i = 0; i < this.filters.length; i++) {
      config.params["filter[" + this.filters[i].key + "]"] = this.filters[i].value;
    }
    Object.keys(this.extra_params).forEach((i) => {
      config.params[i] = this.extra_params[i]
    })

    axios.get(Config.BillingUrl + this.url, config)
      .then(res => {
        if (!this.skipCheck)
          if (res.status === 204)
            store.dispatch(addMessage("No records found.", "error", "204"))
        store.dispatch(_success(res, this.reducers.success))
        store.dispatch(disableInnerLoader())

      })
      .catch(error => {
        store.dispatch(disableInnerLoader())
        if (error.response) {
          if (error.response.status >= 500)
            store.dispatch(serverError())
          else if (error.response.status === 403)
            store.dispatch(unauthorizedError(error.response.data.message))
        } else if (error.request) {
          store.dispatch(noInternet())
        } else {
          store.dispatch(serverError())
        }
      });
  }
}
