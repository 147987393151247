
let pathname = window.location.pathname

export default function filters(state = { fetch: null }, action) {
  switch (action.type) {
    case "SET_FILTER":
      return { ...state, ...{ fetch: pathname } }
    case "REMOVE_FILTER":
      return { ...state, ...{ fetch: false } }
    default:
      return state;
  }
}
