import React, {Component} from 'react';
import {connect} from "react-redux";
import store from '../../Store'
import {Button, Col, Row} from "reactstrap";
import {updateRootCustomer} from "../../actions/login/update_customer";
import blank from '../../assets/img/blank.png'
import TextField from "../../shared/form-helpers/TextField";
import SettingSelect from "../../shared/form-helpers/SettingSelect";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {getCountries} from "../../apis/countries";
import {getCurrencies} from "../../apis/currencies";
import {addMessage} from "../../actions/messages/success_message";

class Settings extends Component {
  state = {
    restData: {},
    data: {},
    new: true,
    countries: [],
    restError: {},
    restSuccess: null,
    error: {},
    message: '',
    provinces: [],
    currencies: [],
  }

  componentDidMount() {
    store.dispatch(getLoggedInCompany())
    getCountries()
    getCurrencies()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoggedInCompany, getCountries, updateRootCustomer, getCurrencies} = nextProps
    let {restData, countries, restError, restSuccess, provinces, currencies} = prevState
    if (getLoggedInCompany) {
      let {success} = getLoggedInCompany
      if (success) {
        restData = success.data
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      } else if (error) {
        console.log(error)
      }
    }

    if (updateRootCustomer) {
      let {success, error} = updateRootCustomer
      if (success) {
        restSuccess = success.data
      } else if (error) {
        if (error.status === 422) {
          restError = error.data
        }
      }
    }

    if (getCurrencies) {
      let {success, error} = getCurrencies
      if (success) {
        currencies = success.data.data
      } else if (error) {
        console.log(error)
      }
    }

    return {
      restData, countries, restError, restSuccess, provinces, currencies
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restData !== prevState.restData) {
      this.setState({
        data: this.state.restData
      })
    }

    if (this.state.restError !== prevState.restError) {
      if (this.state.restError.logo) {
        store.dispatch(addMessage(this.state.restError.logo[0], "error", "Image Upload"));
      }
      this.setState({
        error: this.state.restError
      })
    }

    if (this.state.restSuccess !== prevState.restSuccess) {
      if (this.state.restSuccess) {
        store.dispatch(addMessage(this.state.restSuccess.message, "success", "Update Company"));
        store.dispatch(getLoggedInCompany())
        this.setState({
          restSuccess: null,
          new: true,
        })
      }
    }
  }

  imageChange(e) {
    let data = {...this.state.data}
    if (e.target.files[0]) {
      data['logo'] = e.target.files[0]
      this.setState({
        new: false,
        data
      })
    }
  }

  onUpdate() {
    let data = {...this.state.data}
    const formData = new FormData();
    formData.append('_method', 'patch');
    if (data.logo) {
      formData.append("logo", data.logo)
    }
    let keys = Object.keys(data);
    keys.forEach((key) => {
      formData.append(key, data[key] ? data[key] : "")
    })
    if (this.state.new)
      formData.delete('logo')
    store.dispatch(updateRootCustomer(formData));
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCountryChange(e) {
    let data = {...this.state.data}
    if (e.name) {
      data['country'] = e.value
      this.setState({data})
    }
  }

  onDismiss() {
    this.setState({
      message: ''
    });
  }

  render() {

    let {data, countries, error, provinces, currencies} = this.state

    let provincesList = provinces[data.country] && provinces[data.country].map((province) => {
      if (province.name)
        return (
          <option value={province.id}>{province.name}</option>
        )
      else
        return null
    })

    let countriesOption = countries ? countries.map((country) => {
      if (country.name)
        return (
          <option value={country.id}>{country.name}</option>
        )
      else
        return null
    }) : []

    let currenciesList = currencies && currencies.map((currency) => {
      let {id, code, symbol} = currency
      return (
        <option value={id}>{`${symbol}   ${code}`}</option>
      )
    })


    return (
      <>
        <Row>
          <Col>
            <Row>
              <Col sm="3">
                <span className="float-right span-label">
                  Profile Image
                </span>
              </Col>
              <Col sm="2">
                <div className="custom-image-container">
                  <div className="custom-image-inner-container">
                    {this.state.new ? data.logo ? <img
                        className="custom-image"
                        src={data.logo + '?date=' + Date()} alt="logo"/> : <img
                        className="custom-image"
                        src={blank}
                        alt="logo"
                      /> :
                      data.logo &&
                      <img
                        className="custom-image"
                        src={window.URL.createObjectURL(data.logo)} alt="logo"/>}
                  </div>
                  <div className="topLayerBtnContainer">
                    <div className="upload-btn-wrapper">
                      <button className="custom-img-up-btn">Change</button>
                      <input type="file" accept="image/x-png,image/gif,image/jpeg"
                             onChange={(e) => this.imageChange(e)}/>
                    </div>
                  </div>
                </div>
                {/*<input className="file-upload" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.imageChange(e)}/>*/}
              </Col>
              <Col sm="7">
                <div className="image-info">
                  <p className="image-text">You can upload/change company logo here</p>
                  <p className="image-text">Required file types: .GIF, .PNG, or .JPG</p>
                </div>
              </Col>

            </Row>
            <Row className="custom-row">
              <Col sm="3">
                <span className="float-right span-label">
                  Contact
                </span>
              </Col>
              <Col sm="9">
                <Row>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="phone" value={data.phone} onChange={(e) => this.onChange(e)}
                                 error={error.phone} isRequired={true}/>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="website" value={data.website} onChange={(e) => this.onChange(e)}
                                 error={error.website}/>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="fax" value={data.fax} onChange={(e) => this.onChange(e)}
                                 error={error.fax}/>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="custom-row">
              <Col sm="3">
                <span className="float-right span-label">
                  Address
                </span>
              </Col>
              <Col sm="9">
                <Row>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <SettingSelect
                        name="country"
                        options={countriesOption}
                        value={data.country}
                        placeholder="Select Country"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <SettingSelect
                        name="state"
                        options={provincesList}
                        value={data.state}
                        placeholder="Select Province"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="region" value={data.region} onChange={(e) => this.onChange(e)}
                                 error={error.region}/>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="city" value={data.city} onChange={(e) => this.onChange(e)}
                                 error={error.city}/>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <TextField name="street" value={data.street} onChange={(e) => this.onChange(e)}
                                 error={error.street}/>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="custom-row">
              <Col sm="3">
                <span className="float-right span-label">
                  Currency
                </span>
              </Col>
              <Col sm="9">
                <Row>
                  <Col sm="12" md="6">
                    <div className="custom-profile">
                      <SettingSelect
                        name="currency"
                        options={currenciesList}
                        value={data.currency}
                        placeholder="Select Currency"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="float-right" color="success" outline
                        onClick={this.onUpdate.bind(this)}>Update</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {getLoggedInCompany, getCountries, updateRootCustomer, getCurrencies} = state
  return {
    getLoggedInCompany, getCountries, updateRootCustomer, getCurrencies
  }
}

export default connect(mapStateToProps)(Settings);
