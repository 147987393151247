import React, {Component} from 'react';
import {connect} from "react-redux";
import HorizontalTextArea from "../../shared/form-helpers/HorizontalTextArea";
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, Input, Row, Table} from "reactstrap";
import PrintInvoiceModal from "../Invoice/PrintInvoiceModal";
import {Config} from "../../Config";
import ViewData from "../../shared/form-helpers/ViewData";
import {createCreditNote, createResetCreditNote} from "../../apis/creditnotes";
import {getInvoice} from "../../apis/invoices";
import {addMessage} from "../../actions/messages/success_message";
import store from '../../Store'
import {fixIfNecessary, isDecimal, isNumber} from "../../shared/helpers/GeneralHelpers";
import {setFetch} from "../../shared/helpers/FilterHelper";
import HorizontalTextView from "../../shared/form-helpers/HorizontalTextView";
import {getProductFamilies} from "../../apis/product-families";

class NewCreditNote extends Component {

  state = {
    data: {},
    error: {},
    rest_bad_request: null,
    datas: {},
    creditNoteRestSuccess: null,
    creditNoteRestError: null,
    createCreditNoteSuccess: {},
    createCreditNoteSuccessStatus: false,
    restErrorMessage: "",
    errorMessage: "",
    url: null,
    customer_code: null,
    details: [],
    creditItem: {},
    index: -1,
    creditDatas: [],
    creditDetails: [],
    username: '',
    pos: '',
    selected_array: [],
    current_index: null,
    showTscData: false,
    productFamilies: [],
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    getProductFamilies(1000)
    getInvoice(this.props.match.params.id)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getInvoice, createCreditNote, getLoggedInUser, getProductsFamilies} = nextProps;
    let {
      datas,
      creditNoteRestError,
      createCreditNoteSuccess,
      createCreditNoteSuccessStatus,
      url,
      customer_code,
      restErrorMessage,
      username,
      pos,
      rest_bad_request,
      productFamilies
    } = prevState
    if (getInvoice) {
      let {success} = getInvoice
      if (success) {
        datas = success.data
      }
    }
    if (createCreditNote) {
      let {success, error, bad_request, validation} = createCreditNote
      if (success) {
        if (success.status === 200) {
          createCreditNoteSuccess = success.data
          customer_code = success.data.company_payment_id
          url = Config.BillingUrl + 'credit-notes/' + customer_code + '?q=preview'
        }
      } else if (error) {
        console.log(error)
        if (error.status === 422) {
          creditNoteRestError = error.data
        } else if (error.status === 401) {
          restErrorMessage = error.data.message
        }
      } else if (validation) {
        creditNoteRestError = validation
      } else if (bad_request) {
        rest_bad_request = bad_request
      } else {
        createCreditNoteSuccess = null
        creditNoteRestError = null
        restErrorMessage = ""
        rest_bad_request = null
      }
    }

    if (getLoggedInUser) {
      let {success} = getLoggedInUser
      if (success) {
        username = success.data.name
        pos = success.data.pos
      }
    }
    if (getProductsFamilies) {
      let {success} = getProductsFamilies
      if (success) {
        if (success.status !== 204)
          productFamilies = success.data.data
      }
    }

    return {
      datas,
      creditNoteRestError,
      createCreditNoteSuccessStatus,
      createCreditNoteSuccess,
      url,
      customer_code,
      restErrorMessage,
      username,
      pos,
      rest_bad_request,
      productFamilies
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.datas !== prevState.datas) {
      let data = {...this.state.datas}
      data.amount = 0
      data.vat_amount = 0
      data.total = 0
      data.discount_amount = 0
      data.taxable_amount = 0
      this.setState({
        data,
        details: this.state.datas.details
      }, () => {
        if (this.state.data['remaining-amount'] === 0) {
          store.dispatch(addMessage("Credit Note cannot be issued of this invoice", "error", "Issue Credit Note", true));
        }
      })
    }

    if (this.state.creditNoteRestError !== null) {
      let error = this.state.creditNoteRestError
      this.setState({
        error
      }, () => {
        createResetCreditNote()
      })
    }

    if (this.state.createCreditNoteSuccess !== null) {
      setFetch(true)
      this.setState({
        createCreditNoteSuccessStatus: true
      }, () => {
        setFetch(true)
        createResetCreditNote()
      })
    }

    if (this.state.restErrorMessage !== "") {
      this.setState({
        errorMessage: this.state.restErrorMessage
      }, () => {
        createResetCreditNote()
      })
    }

    if (this.state.rest_bad_request !== prevState.rest_bad_request) {
      store.dispatch(addMessage(this.state.rest_bad_request.error, "error", this.state.rest_bad_request.title, false));
    }
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  getTotal() {
    let total = 0;
    this.state.creditDetails.forEach((single) => {
      total += parseFloat(single.total)
    })
    return total
  }

  onSubmit() {
    if (this.state.data['remaining-amount'] === 0) {
      store.dispatch(addMessage("Credit Note cannot be issued of this invoice", "error", "Issue Credit Note", true));
    } else {
      let data = {...this.state.data}
      if (this.state.showTscData) {
        if (data.total > 0) {
          let temp = {
            company_payment_id: data.company_payment_id,
            payment_gateway: data.payment_gateway,
            created_by: this.state.username,
            cancel_note: data.cancel_note,
            amount: data.amount ? data.amount.toFixed(2) * 1 : data.amount,
            taxable_amount: data.taxable_amount,
            vat_amount: data.vat_amount ? data.vat_amount.toFixed(2) * 1 : data.vat_amount,
            vat_rate: data.vat_rate,
            total: data.total ? data.total.toFixed(2) * 1 : data.total,
            details: this.state.creditDetails,
            invoice_id: data.company_payment_id,
            discount_amount: data.discount_amount,
            discount_rate: data.discount_rate,
            reseller: data.reseller,
            tsc_amount: data.tsc_amount,
            tsc_rate: data.tsc_rate
          }
          createCreditNote(temp)
        } else
          store.dispatch(addMessage("Credit Note cannot be issued of this invoice", "error", "Issue Credit Note",));
      } else {

        if (data.total > 0) {
          let temp = {
            company_payment_id: data.company_payment_id,
            payment_gateway: data.payment_gateway,
            created_by: this.state.username,
            cancel_note: data.cancel_note,
            amount: data.amount ? data.amount.toFixed(2) * 1 : data.amount,
            taxable_amount: data.taxable_amount,
            vat_amount: data.vat_amount ? data.vat_amount.toFixed(2) * 1 : data.vat_amount,
            vat_rate: data.vat_rate,
            total: data.total ? data.total.toFixed(2) * 1 : data.total,
            details: this.state.creditDetails,
            invoice_id: data.company_payment_id,
            discount_amount: data.discount_amount,
            discount_rate: data.discount_rate,
            reseller: data.reseller
          }
          createCreditNote(temp)
        } else
          store.dispatch(addMessage("Credit Note cannot be issued of this invoice", "error", "Issue Credit Note",));
      }

    }
  }

  togglePrintModal() {
    this.setState({
      createCreditNoteSuccessStatus: !this.state.createCreditNoteSuccessStatus
    }, () => {
      this.props.history.push('/sales/credit_notes')
    })
  }

  changeQuantity(e, maxQty) {
    if (isNumber(e.target.value)) {
      if (e.target.value <= maxQty && e.target.value > 0) {
        let creditItem = {...this.state.creditItem}
        creditItem['quantity'] = e.target.value
        creditItem['total'] = parseFloat(creditItem['quantity']) * parseFloat(creditItem['return_rate']) + (parseFloat(creditItem['vat_rate']) * (parseFloat(creditItem['quantity'] * parseFloat(creditItem['return_rate'])) / 100))
        this.setState({
          creditItem
        })
      } else {
        store.dispatch(addMessage("Maximum " + maxQty + " quantity can be returned.", "error"))
      }
    }
  }


  changeAmount(e, maxAmount) {
    console.log(e.target.value)
    console.log(maxAmount)
    if (e.target.value <= maxAmount) {
      let creditItem = {...this.state.creditItem}
      creditItem['return_rate'] = fixIfNecessary(e.target.value.replace(/^0+/, '') || 0)
      creditItem['total'] = fixIfNecessary(parseFloat(creditItem['quantity']) * parseFloat(creditItem['return_rate']))
      this.setState({
        creditItem
      })
    } else {
      store.dispatch(addMessage("Maximum return rate is " + maxAmount, "error"))
    }
  }

  productChange(e) {
    let creditItem = {...this.state.creditItem}
    let value = e.target.value
    let position = value.split("_")
    let current_index = null
    if (position[1])
      current_index = position[1]
    let index = 0;
    if (value === "0") {
      index = -1
      creditItem['product_name'] = value
      creditItem['quantity'] = 0
      creditItem['return_rate'] = 0
      creditItem['vat_rate'] = 13
      creditItem['total'] = 0
    } else {
      let details = this.state.details
      console.log(details)
      let splits = value.split('_')
      index = splits[splits.length - 1]
      creditItem['product_name'] = value
      creditItem['quantity'] = details[index].qty
      creditItem['return_rate'] = 0
      creditItem['vat_rate'] = 13
      creditItem['total'] = 0
      creditItem['hs_code'] = details[index].hs_code
    }
    this.setState({
      creditItem,
      index,
      current_index
    })
  }

  onAddCredit(order) {
    let creditDetails = [...this.state.creditDetails]
    let selected_array = [...this.state.selected_array]
    let creditItem = {...this.state.creditItem}
    let {details, index} = this.state
    let {productFamilies} = this.state
    console.log('@creditItem0', selected_array)
    console.log('@creditItem1', creditDetails)
    console.log('@creditItem2', productFamilies)
    if (creditItem.return_rate <= 0) {
      store.dispatch(addMessage("Return rate cannot be 0.", "error", "Credit Note"))
    } else {
      if (index !== -1) {
        if (creditItem.return_rate > 0) {
          let temp = {...details[index]}
          let checkProductFamilyForInternetIndex = productFamilies.findIndex(x => x.name.toLowerCase() === temp.product_name.toLowerCase())
          if (checkProductFamilyForInternetIndex > -1) {
            this.setState({
              showTscData: true
            })
          }
          temp['qty'] = creditItem.quantity
          temp['amount'] = creditItem.return_rate
          temp['vat_rate'] = creditItem.vat_rate
          temp['total'] = creditItem.total
          temp['taxable_amount'] = creditItem.return_rate
          temp['vat_amount'] = parseFloat(temp['vat_rate']) * parseFloat(temp['taxable_amount']) / 100

          creditDetails.push(temp)

          creditItem['product_name'] = 0
          creditItem['quantity'] = 0
          creditItem['return_rate'] = 0
          creditItem['vat_rate'] = 13
          creditItem['total'] = 0

          selected_array.push("" + this.state.current_index)

          this.setState({
            creditDetails,
            creditItem,
            index: -1,
            selected_array,
          }, () => {
            this.changeSubTotal()
          })
        } else {
          store.dispatch(addMessage("Return rate must be greater than 0", "error", "New Credit Note"));
        }
      }
    }
  }

  onRemarkChange(e) {
    let data = {...this.state.data}
    let error = {...this.state.error}
    data['cancel_note'] = e.target.value
    error['cancel_note'] = ""
    this.setState({
      data,
      error
    })
  }

  onDismiss() {
    this.setState({
      errorMessage: "",
    });
  }

  changeSubTotal() {
    let subTotal = this.getTotal()
    let data = {...this.state.data}
    if (this.state.showTscData && data.tsc_amount > 0) {
      let discount_amount = parseFloat(subTotal) * parseFloat(data.discount_rate) / 100
      let taxable_amount = (subTotal - discount_amount) + data.tsc_amount
      let vatAmount = taxable_amount * parseFloat(data.vat_rate) / 100
      let total = taxable_amount + vatAmount
      data.amount = subTotal
      data.vat_amount = vatAmount
      data.total = total
      data.discount_amount = discount_amount
      data.taxable_amount = taxable_amount
      this.setState({
        data
      })
    } else {
      let discount_amount = parseFloat(subTotal) * parseFloat(data.discount_rate) / 100
      let taxable_amount = subTotal - discount_amount
      let vatAmount = taxable_amount * parseFloat(data.vat_rate) / 100
      let total = taxable_amount + vatAmount
      data.amount = subTotal
      data.vat_amount = vatAmount
      data.total = total
      data.discount_amount = discount_amount
      data.taxable_amount = taxable_amount
      this.setState({
        data
      })
    }

  }

  onRemoveCredit(id) {
    id--
    let selected_array = [...this.state.selected_array]
    selected_array.splice(id, 1)
    let creditDetails = [...this.state.creditDetails]
    creditDetails.splice(id, 1)
    this.setState({
      creditDetails,
      selected_array
    }, () => {
      this.changeSubTotal()
    })
  }


  render() {
    let {
      data,
      error,
      createCreditNoteSuccessStatus,
      details,
      creditItem,
      creditDetails
    } = this.state
    let creditItemOption = details.map((detail, o) => {
      let {product_name} = detail
      let {selected_array} = this.state
      if (selected_array.includes("" + o)) {
        return null
      }
      return (
        <option value={product_name + '_' + o}>{product_name}</option>
      );
    })

    let temp = 0

    let allowedRate = []
    let allowedQuantity = []

    let creditDataRows = creditDetails.map((credit) => {
      let {id, product_name, qty, amount, vat_rate, total, hs_code} = credit
      temp++
      return (
        <tr key={id}>
          <td>{product_name}</td>
          <td>{hs_code ? hs_code : "-"}</td>
          <td>{qty}</td>
          <td>{amount}</td>
          <td className="text-right">{total} &nbsp;
            <Button className="btn-sm" color="danger" title="Add Credit Note"
                    onClick={() => this.onRemoveCredit(temp)} outline>
              <i className="fa fa-times"></i>
            </Button>
          </td>
        </tr>
      )
    })

    let count = 0;

    let tableData = data.details && data.details.map((table) => {
      let {id, product_name, qty, discount_amount, vat_rate, description, taxable_amount} = table
      allowedRate[count] = taxable_amount
      console.log(allowedRate)
      allowedQuantity[count] = qty
      count++;
      return (
        <tr key={id}>
          <td>{product_name}</td>
          <td>{description}</td>
          <td>{qty}</td>
          <td>{taxable_amount}</td>
          <td>{discount_amount}</td>
          <td>{vat_rate}</td>
        </tr>
      );
    })

    return (
      <div className="animated fadeIn">
        <PrintInvoiceModal
          isOpen={createCreditNoteSuccessStatus}
          toggle={this.togglePrintModal.bind(this)}
          url={this.state.url}
          customer_code={this.state.customer_code}
          email={false}
        />
        <Card className="top-page-content">
          <CardBody>
            <Row>
              <Col className="text-center">
                <span className="report-page-title">Invoice Details</span>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Reference Bill Number"
                  data={data.invoice_no}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Customer Name"
                  data={data.customer_name}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Company Payment Id"
                  data={data.company_payment_id}
                />
              </Col>
              <Col sm="12" md="6" lg="4" className="text-center">
                <ViewData
                  label="Payment Counter"
                  data={data.payment_gateway}
                />
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm="12">
                <Table responsive>
                  <thead>
                  <tr>
                    <th width="120">Items</th>
                    <th width="120">Description</th>
                    <th width="50">Quantity</th>
                    <th width="50">Rate</th>
                    <th width="50">Discount</th>
                    <th width="50">VAT(%)</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tableData}
                  </tbody>
                </Table>
                <hr/>
              </Col>
            </Row>
            <Row><Col>
              <span className="notes-data-return">
                {`Note: Sales total return amount should not exceed the above limit. Total Allowed Amount Rs. ${data['remaining-amount']}`}
              </span>
            </Col></Row>
            <Row>
              <Col className="text-center">
                <span className="report-page-title">Credit Note</span>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm="12">
                <Table responsive>
                  <thead>
                  <tr>
                    <th width="120">Items&nbsp;<span className="text-danger">*</span></th>
                    <th width="120">HS Code</th>
                    <th width="80">Quantity&nbsp;<span className="text-danger">*</span></th>
                    <th width="80">Return Rate&nbsp;<span className="text-danger">*</span></th>
                    <th width="80">Total&nbsp;<span className="text-danger">*</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <Input type="select" name="product_name" onChange={(e) => this.productChange(e)}
                             value={creditItem.product_name} invalid={!!error.details}>
                        <option value="0"> -- Select Items --</option>
                        {creditItemOption}
                      </Input>
                    </td>
                    <td>
                        {creditItem.hs_code ? creditItem.hs_code : "-"}
                    </td>
                    <td>
                      <Input value={creditItem.quantity}
                             onChange={(e) => this.changeQuantity(e, creditItem.product_name ? allowedQuantity[creditItem.product_name.split("_")[creditItem.product_name.split("_").length - 1]] : null)}
                             invalid={!!error.details} className="text-right"/>
                    </td>
                    <td>
                      <Input value={creditItem.return_rate}
                             onChange={(e) => this.changeAmount(e, creditItem.product_name ? allowedRate[creditItem.product_name.split("_")[creditItem.product_name.split("_").length - 1]] : null)}
                             invalid={!!error.details} className="text-right"/>
                    </td>
                    <td className="text-right">{creditItem.total || 0} &nbsp;
                      <Button className="btn-sm" color="success" title="Add Credit Note"
                              onClick={this.onAddCredit.bind(this)}>
                        Add
                      </Button>
                    </td>
                  </tr>
                  {creditDataRows}
                  </tbody>
                </Table>
                <Row>
                  <Col sm="12" md="6"/>
                  <Col sm="12" md="6">
                    <HorizontalTextView
                      name="subtotal"
                      isNumber={true}
                      value={data.amount ? data.amount : '-'}
                    />
                    {(this.state.showTscData && data.tsc_amount > 0) &&
                    <HorizontalTextView
                      name="tsc_amount"
                      isNumber={true}
                      value={data.tsc_amount ? data.tsc_amount : '-'}
                    />
                    }
                    <HorizontalTextView
                      name="discount_amount"
                      isNumber={true}
                      value={data.discount_amount ? data.discount_amount : '-'}
                    />
                    <HorizontalTextView
                      name="taxable_amount"
                      isNumber={true}
                      value={data.taxable_amount ? data.taxable_amount : '-'}
                    />
                    <HorizontalTextView
                      name="vat_amount"
                      isNumber={true}
                      value={data.vat_amount ? data.vat_amount : '-'}
                    />
                    <hr/>
                    <HorizontalTextView
                      name="total"
                      isNumber={true}
                      value={data.total ? data.total : '-'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <HorizontalTextArea
                      name="cancel_note"
                      onChange={(e) => this.onRemarkChange(e)}
                      value={data.cancel_note}
                      error={error.cancel_note}
                      isRequired={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              {/*<Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>*/}
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }


}

function mapStateToProps(state) {
  let {successMessage, getInvoice, createCreditNote, getLoggedInUser, getProductsFamilies} = state
  return {
    successMessage, getInvoice, createCreditNote, getLoggedInUser, getProductsFamilies
  }
}

export default connect(mapStateToProps)(NewCreditNote);
