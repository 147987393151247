import {combineReducers} from "redux";

import getLoginRequest from "../reducers/login/login-request";
import refreshToken from './login/refresh-token'

import successMessage from "../reducers/messages/success-message"

import getLoggedInUser from './login/get-logged-in-user'
import updateRootCustomer from './login/update-customer'

import getCreditNotes from './credit-notes/get-credit-notes'
import getCreditNote from './credit-notes/get-credit-note'
import createCreditNote from './credit-notes/create-credit-note'
import downloadCreditNotes from './credit-notes/download-credit-notes'

import getInvoice from './invoices/get-invoice'
import getInvoices from './invoices/get-invoices'
import createInvoice from './invoices/create-invoice'
import downloadInvoice from './invoices/download-invoice'

import getProducts from './products/get-products'
import getProduct from './products/get-product'
import createProduct from './products/create-product'
import updateProduct from './products/update-product'
import deleteProduct from './products/delete-product'
import downloadProducts from './products/download-products'
import uploadImageProduct from './products/upload-image-product'

import getWallets from './wallets/get-wallets'

import currentFiscalYear from './fiscal-year/current-fiscal-year'
import createFiscalYear from './fiscal-year/create-fiscal-year'
import getFiscalYears from './fiscal-year/get-fiscal-years'

import createCustomer from './customers/create-customer'
import getCustomers from './customers/get-customers'
import getActiveCustomers from './customers/get-active-customers'
import getCustomer from './customers/get-customer'
import updateCustomer from './customers/update-customer'
import downloadCustomers from './customers/download-customers'
import deleteCustomer from './customers/delete-customer'

import createPaymentMethod from './payment-methods/create-payment-method'
import getPaymentMethod from './payment-methods/get-payment-method'
import getPaymentMethods from './payment-methods/get-payment-methods'
import updatePaymentMethod from './payment-methods/update-payment-method'
import deletePaymentMethod from './payment-methods/delete-payment-method'

import createInvoiceTemplate from './invoice-templates/create-invoice-template'
import getInvoiceTemplate from './invoice-templates/get-invoice-template'
import getInvoiceTemplates from './invoice-templates/get-invoice-templates'
import updateInvoiceTemplate from './invoice-templates/update-invoice-template'
import deleteInvoiceTemplate from './invoice-templates/delete-invoice-template'
import updateCompanyInvoiceTemplate from './invoice-templates/update-company-invoice-template'
import updateCompanyCreditNoteTemplate from './invoice-templates/update-company-credit-note-template'

import createInventory from './inventory/create-inventory'
import getInventory from './inventory/get-inventory'
import getInventories from './inventory/get-inventorys'
import updateInventory from './inventory/update-inventory'
import deleteInventory from './inventory/delete-inventory'
import getInventoryHistory from './inventory/get-inventory-history'

import createMenu from './menus/create-menu'
import getMenu from './menus/get-menu'
import getMenus from './menus/get-menus'
import getAllMenus from './menus/get-all-menus'
import updateMenu from './menus/update-menu'
import deleteMenu from './menus/delete-menu'
import getUserMenu from './menus/get-user-menu'

import createTax from './taxes/create-tax'
import getTax from './taxes/get-tax'
import getTaxes from './taxes/get-taxes'
import updateTax from './taxes/update-tax'
import deleteTax from './taxes/delete-tax'

import getCountries from './countries/get-countries'

import getUnits from './units/get-units'
import getUnit from './units/get-unit'
import createUnit from './units/create-unit'
import getActiveUnits from './units/get-active-units'
import updateUnit from './units/update-unit'
import deleteUnit from './units/delete-unit'

import getPaymentTerms from './payment-terms/get-payment-terms'
import getPaymentTerm from './payment-terms/get-payment-term'
import createPaymentTerm from './payment-terms/create-payment-term'
import updatePaymentTerm from './payment-terms/update-payment-term'
import deletePaymentTerm from './payment-terms/delete-payment-term'
import resetPaymentTerm from './payment-terms/reset-payment-term'

import getPaymentGateways from './payment-gateways/get-payment-gateways'
import getAdminPaymentGateways from './payment-gateways/get-admin-payment-gateways'
import getAdminPaymentGateway from './payment-gateways/get-admin-payment-gateway'
import deleteAdminPaymentGateway from './payment-gateways/delete-admin-payment-gateway'
import getInactivePaymentGateways from './payment-gateways/get-inactive-payment-gateways'
import getPaymentGateway from './payment-gateways/get-payment-gateway'
import createPaymentGateway from './payment-gateways/create-payment-gateway'
import getKeys from './payment-gateways/get-keys'

import getStatistics from './statistics/get-statistics'

import getAuthKeys from './keys/get-auth-keys'
import updateAuthKey from './keys/update-auth-key'
import generateAuthKey from './keys/generate-auth-key'

import getEsewaConfig from './esewa/get-esewa-config'

import loader from './loaders/loader'

import getCompanyUsers from './companies/get-company-users'

import getCurrencies from './currencies/get-currencies'
import createCurrency from './currencies/create-currency'
import deleteCurrency from './currencies/delete-currency'
import updateCurrency from './currencies/update-currency'
import getCurrency from './currencies/get-currency'

import changePassword from './password/change-password'
import forgotPassword from './password/forgot-password'
import setPassword from './password/set-password'

import uploadImage from './users/upload-image'
import inviteUser from './users/invite-user'
import getUser from './users/get-user'
import updateUser from './users/update-user'

import getTransactions from './transactions/get-transactions'
import getTransaction from './transactions/get-transaction'

import getSalesRegisters from './sales-registers/get-sales-registers'

import downloadMessage from './download-info/download-message'

import getVendors from './vendors/get-vendors'
import getVendor from './vendors/get-vendor'
import updateVendor from './vendors/update-vendor'
import deleteVendor from './vendors/delete-vendor'
import createVendor from './vendors/create-vendor'
import downloadVendors from './vendors/download-vendors'

import createBilling from './billings/create-billing'
import downloadBilling from './billings/download-billing'
import getBilling from './billings/get-billing'
import getBillings from './billings/get-billings'
import uploadImageBilling from './billings/upload-image-billing'

import createPurchaseReturn from './purchase-return/create-purchase-return'
import downloadPurchaseReturns from './purchase-return/download-purchase-returns'
import getPurchaseReturn from './purchase-return/get-purchase-return'
import getPurchaseReturns from './purchase-return/get-purchase-returns'

import getLoggedInCompany from './companies/get-company'

import getPurchaseRegisters from './purchase-register/get-purchase-registers'

import getProductsFamilies from './product-families/get-product-families'
import getProductFamily from './product-families/get-product-family'
import createProductFamily from './product-families/create-product-family'
import updateProductFamily from './product-families/update-product-family'
import deleteProductFamily from './product-families/delete-product-family'

import convertToInvoice from './proforma-invoice/convert-to-invoice'
import createProformaInvoice from './proforma-invoice/create-proforma-invoice'
import deleteProformaInvoice from './proforma-invoice/delete-proforma-invoice'
import getProformaInvoice from './proforma-invoice/get-proforma-invoice'
import getProformaInvoices from './proforma-invoice/get-proforma-invoices'
import updateProformaInvoice from './proforma-invoice/update-proforma-invoice'
import downloadProformaInvoice from './proforma-invoice/download-proforma-invoice'

import listDb from './backup/list-db'
import getQueryLog from './query-log/get-query-log'
import getActivityLog from './activity-log/get-activity-log'
import recoveryDb from './backup/recovery-db'

import errorHandling from './error'

import filters from './filters'

import getMaterialView from './material-view/get-material-view'

import createRole from './roles/create-role'
import deleteRole from './roles/delete-role'
import getRole from './roles/get-role'
import getRoles from './roles/get-roles'
import updateRole from './roles/update-role'

import createPermission from './permissions/create-permission'
import deletePermission from './permissions/delete-permission'
import getPermission from './permissions/get-permission'
import getPermissions from './permissions/get-permissions'
import updatePermission from './permissions/update-permission'

import createPOS from './pos/create-pos'
import getPOS from './pos/get-pos'
import getPOSes from './pos/get-poses'
import deletePOS from './pos/delete-pos'
import updatePOS from './pos/update-pos'
import getWorldlinkPos from './pos/get-worldlink-pos'

import getBinaryLog from './binary-log/get-binary-log'

import getResellers from './resellers/get-resellers'

import getPlatforms from './platforms/get-platforms'
import getTscRateConfig from "./tscRate/get-tsc-rate-config";



const allReducers = combineReducers({
  getLoginRequest, getLoggedInUser, updateRootCustomer, refreshToken,
  successMessage,
  getCreditNotes, createCreditNote, downloadCreditNotes,getCreditNote,
  getInvoice, getInvoices, createInvoice, downloadInvoice,
  getProducts, createProduct, updateProduct, getProduct, deleteProduct, downloadProducts, uploadImageProduct,
  getWallets,
  currentFiscalYear, createFiscalYear, getFiscalYears,
  createCustomer, getCustomers, updateCustomer, getCustomer,getActiveCustomers, downloadCustomers, deleteCustomer,
  createPaymentMethod, getPaymentMethod, getPaymentMethods, updatePaymentMethod,deletePaymentMethod,
  createInvoiceTemplate, getInvoiceTemplate, getInvoiceTemplates, updateInvoiceTemplate,deleteInvoiceTemplate,
  updateCompanyInvoiceTemplate, updateCompanyCreditNoteTemplate,
  createInventory, getInventory, getInventories, updateInventory,deleteInventory, getInventoryHistory,
  createMenu, getMenu, getMenus, updateMenu,deleteMenu, getAllMenus, getUserMenu,
  createTax, getTax, getTaxes, updateTax, deleteTax,
  getCountries,
  getUnits,createUnit,getActiveUnits,updateUnit, deleteUnit, getUnit,
  getPaymentTerms, getPaymentTerm, createPaymentTerm, updatePaymentTerm, deletePaymentTerm,resetPaymentTerm,
  getStatistics,
  getAuthKeys,updateAuthKey,generateAuthKey,
  getPaymentGateways,getPaymentGateway,createPaymentGateway,getInactivePaymentGateways,getKeys, getAdminPaymentGateways, getAdminPaymentGateway, deleteAdminPaymentGateway,
  getEsewaConfig,
  loader,
  getLoggedInCompany,
  getCompanyUsers,
  getCurrencies,createCurrency,deleteCurrency,updateCurrency,getCurrency,
  changePassword, forgotPassword, setPassword,
  uploadImage, inviteUser, getUser, updateUser,
  getTransactions,getTransaction,
  getSalesRegisters,
  downloadMessage,
  getVendor,getVendors,updateVendor,deleteVendor,createVendor,downloadVendors,
  createBilling, getBilling, getBillings, uploadImageBilling,downloadBilling,
  createPurchaseReturn, downloadPurchaseReturns, getPurchaseReturn, getPurchaseReturns,
  getPurchaseRegisters,
  getProductsFamilies, getProductFamily, createProductFamily, updateProductFamily, deleteProductFamily,
  errorHandling,
  convertToInvoice, createProformaInvoice, deleteProformaInvoice, getProformaInvoice, getProformaInvoices, updateProformaInvoice, downloadProformaInvoice,
  listDb,getQueryLog,getActivityLog,recoveryDb,
  filters,
  getMaterialView,
  createRole,getRole,getRoles,updateRole,deleteRole,
  createPermission,getPermission,getPermissions,deletePermission,updatePermission,
  createPOS,getPOS, getPOSes, deletePOS,updatePOS, getWorldlinkPos,
  getBinaryLog,
  getResellers,
  getPlatforms,
  getTscRateConfig
});

export default allReducers;
