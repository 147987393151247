export default function updateAuthKey
  (state = {error: null, success: null, processing: ''}, action) {

  switch (action.type) {

    case 'UPDATE_AUTH_KEY_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'UPDATE_AUTH_KEY_ERROR':
      return {...state, ...{error: action.error}};
    case 'UPDATE_AUTH_KEY_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'RESET_UPDATE_AUTH_KEY':
      return {...state,...{success: null,error: null, processing: null}}
    default:
      return state;
  }
}
