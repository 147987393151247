import React from 'react'
import {Button, ButtonGroup, Col, Row} from "reactstrap";

export const FormFooter = (props) => {
  let {
    onSubmit,
    onCancel,
    onReset
  } = props

  return (
    <Row>
      <Col className="text-right">
        <ButtonGroup className="float-right">
          <Button outline color="danger" onClick={onCancel}>Cancel</Button>
          <Button outline color="primary" onClick={onReset}>Reset</Button>
          <Button outline color="success" onClick={onSubmit}>Save</Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
}
