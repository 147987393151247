import axios from 'axios';
import {loggedIn} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import jwt from "jsonwebtoken";
import store from '../../Store'
import {disableInnerLoader, enableInnerLoader} from "../loaders/loader";


function _success(success) {
  return {type: 'FETCH_LOGGED_IN_COMPANY_SUCCESS', success}
}

function _error(error) {
  return {type: 'FETCH_LOGGED_IN_COMPANY_ERROR', error}
}

export function getLoggedInCompany() {
  store.dispatch(enableInnerLoader())
  if (loggedIn) {
    return dispatch => {
      let company_id = jwt.decode(localStorage.getItem('ERP_Bearer')) ? jwt.decode(localStorage.getItem('ERP_Bearer')).params.company_id : null;

      let base_url = Config.BillingUrl;
      let config = {headers: {'Authorization': 'Bearer ' + localStorage.getItem('ERP_Bearer')}};
      axios.get(base_url + (`companies/` + company_id), config)


        .then(function (res) {
          dispatch(_success(res));
          store.dispatch(disableInnerLoader())

        }).catch(function (error) {
        dispatch(_error(error));
        store.dispatch(disableInnerLoader())
      });
    }
  }
  store.dispatch(disableInnerLoader())
}

export default getLoggedInCompany;
