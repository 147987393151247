import React from 'react'
import HorizontalTextField from "./HorizontalTextField";
import HorizontalToggle from "./HorizontalToggle";
import HorizontalSelect from "./HorizontalSelect";
import {Col, Row} from "reactstrap";

const FormBuilder = (props) => {
  let {
    keys,
    onChange,
    data,
    error
  } = props

  return keys.length > 0 ? keys.map((key) => {
    let {
      name,
      type,
      is_required,
      placeholder,
      options,
      title
    } = key
    if (title) {
      return <Row>
        <Col className="text-center">
          <h3 className="form-title">{title}</h3>
        </Col>
      </Row>
    }
    if (type === "text") {
      return <HorizontalTextField
        name={name}
        isRequired={is_required}
        onChange={onChange}
        value={data[name]}
        error={error[name]}
      />
    } else if (type === "radio") {
      return <HorizontalToggle
        name={name}
        isRequired={is_required}
        value={data[name]}
        error={error[name]}
        onChange={onChange}
      />
    } else if (type === "select") {
      return <HorizontalSelect
        name={name}
        value={data[name]}
        onChange={onChange}
        options={options}
        error={error[name]}
        placeholder={placeholder}
        isRequired={is_required}
      />
    }
    return null
  }) : null
}

export default FormBuilder
