import {GetGroup} from "../actions/crud/get_group";

let prefix = "statistics"
let reducers = {
  success: '',
  error: ''
}

export const getStatistics = (type,limit, page = 1, filters = []) => {
  reducers['success'] = 'FETCH_STATISTICS_SUCCESS'
  reducers['error'] = 'FETCH_STATISTICS_ERROR'
  let url = prefix + `/${type}`
  let getGroup = new GetGroup(limit, page, filters, url, reducers)
  getGroup.getGroup()
}
