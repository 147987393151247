export default function getBinaryLog
  (state = {error: null, success: null}, action) {

  switch (action.type) {

    case 'FETCH_BINARY_LOG_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'FETCH_BINARY_QUERY_LOG_ERROR':
      return {...state, ...{error: action.error}};
    default:
      return state;
  }
}
