import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";

let prefix = "transactions"

export const getTransactions = (limit, page = 1, filters = [], dateFilter = {}) => {
  let reducers = {
    success: 'FETCH_TRANSACTIONS_SUCCESS',
    error: 'FETCH_TRANSACTIONS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers, dateFilter)
  getGroup.getGroup()
}

export const getTransaction = (id) => {
  let reducers = {
    success: 'FETCH_TRANSACTION_SUCCESS',
    error: 'FETCH_TRANSACTION_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}
