import React, {Component} from 'react'
import {connect} from "react-redux";
import {Button, Card, CardBody, Table} from "reactstrap";
import {getBinaryLog} from "../../apis/binary-log";
import {Config} from "../../Config";
import {downloadFile} from "../../shared/helpers/DownloadFile";

class BinaryLog extends Component {

  state = {
    data: []
  }

  componentDidMount() {
    getBinaryLog()
  }

  static getDerivedStateFromProps(nextProps,prevState){
    let { getBinaryLog } = nextProps
    let {data} = prevState
    if (getBinaryLog){
      let {success} = getBinaryLog
      if (success){
        data = success.data
      }
    }
    return {data}
  }

  downloadFile(name){
    let url = Config.BillingUrl+'bin-log/'+name
    downloadFile(url,name,true,true)
  }

  render() {
    let {data} = this.state

    let sn = 0
    let tableBody = data.map((single) => {
      let {id, name, created_at, size} = single
      sn++;
      return (
        <tr key={id}>
          <td>{sn}</td>
          <td>{name}</td>
          <td>{created_at}</td>
          <td>{size}</td>
          <td>
            <Button outline color="success" title="Download" onClick={()=>this.downloadFile(name)}>
              <i className="fa fa-download"></i>
            </Button>
          </td>
        </tr>
      );
    })

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Table>
              <thead>
              <tr>
                <th width="50">S.N</th>
                <th width="250">Binary Log Name</th>
                <th width="100">Created Date</th>
                <th width="80">Size</th>
                <th width="80"></th>
              </tr>
              </thead>
              <tbody>
              {tableBody}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getBinaryLog} = state
  return { getBinaryLog }
}

export default connect(mapStateToProps)(BinaryLog)
