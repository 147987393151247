import {Update} from "../actions/crud/update";

export const updateCompanyInvoiceTemplate = (id, data) => {
  let reducers = {
    success: 'UPDATE_COMPANY_INVOICE_TEMPLATE_SUCCESS',
    validation_error: 'UPDATE_COMPANY_INVOICE_TEMPLATE_VALIDATION_ERROR'
  }
  let url = `companies/${id}/invoice-template`
  let update = new Update(url, reducers, data)
  update.update()
}

export const updateCompanyCreditNoteTemplate = (id, data) => {
  let reducers = {
    success: 'UPDATE_COMPANY_CREDIT_NOTE_TEMPLATE_SUCCESS',
    validation_error: 'UPDATE_COMPANY_CREDIT_NOTE_TEMPLATE_VALIDATION_ERROR'
  }
  let url = `/companies/${id}/credit-note-template`
  let update = new Update(url, reducers, data)
  update.update()
}
