export default function getFiscalYears(
  state = { error: null, success: null},
  action
) {
  switch (action.type) {
    case 'FETCH_FISCAL_YEARS_SUCCESS':
      return {...state, ...{success: action.success, error: null}};
    case 'FETCH_FISCAL_YEARS_ERROR':
      return {...state, ...{error: action.error, success: null}};
    default:
      return state;
  }
}
