export function addSuccessMessage(message) {
  return dispatch => {
    dispatch({
      type: "ADD_SUCCESS_MESSAGE",
      message
    });
  };
}

export function deleteSuccessMessage() {
  return dispatch => {
    dispatch({
      type: "DELETE_SUCCESS_MESSAGE"
    });
  };
}

export function addMessage(message,type,title,persistent=false) {
  let temp = {
    type,
    message,
    title,
    persistent
  }
  return dispatch => {
    dispatch({
      type: "ADD_MESSAGE",
      message: temp
    });
  };
}

export function deleteMessage(type) {
  let temp = {
    type,
    message: ''
  }
  return dispatch => {
    dispatch({
      type: "DELETE_MESSAGE",
      message: temp
    });
  };
}

export function addOuterMessage(message,type,title,persistent=false) {
  let temp = {
    type,
    message,
    title,
    persistent
  }
  return dispatch => {
    dispatch({
      type: "ADD_OUTER_MESSAGE",
      message: temp
    });
  };
}

export function deleteOuterMessage(type) {
  let temp = {
    type,
    message: ''
  }
  return dispatch => {
    dispatch({
      type: "DELETE_OUTER_MESSAGE",
      message: temp
    });
  };
}
