export default function uploadImageProduct(
  state = { validation: null, success: null},
  action
) {
  switch (action.type) {

    case 'UPLOAD_IMAGE_PRODUCT_SUCCESS':
      return {...state, ...{success: action.success, validation: null}};
    case 'UPLOAD_IMAGE_PRODUCT_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null}};
    default:
      return state;
  }
}
