import React, {Component} from 'react';
import img from "../../assets/img/logo.png";
import {withRouter} from "react-router";

function fileDragHover(e){
  var fileDrag = document.getElementById('file-drag');

  e.stopPropagation();
  e.preventDefault();

  fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
}

function parseFile(file) {

  console.log(file.name);
  output(
    '<strong>' + encodeURI(file.name) + '</strong>'
  );

  // var fileType = file.type;
  // console.log(fileType);
  var imageName = file.name;

  var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
  if (isGood) {
    // document.getElementById('start').classList.add("hidden");
    // document.getElementById('response').classList.remove("hidden");
    // document.getElementById('notimage').classList.add("hidden");
    // // Thumbnail Preview
    // document.getElementById('file-image').classList.remove("hidden");
    // document.getElementById('file-image').src = URL.createObjectURL(file);
  }
  else {
    // document.getElementById('file-image').classList.add("hidden");
    // document.getElementById('notimage').classList.remove("hidden");
    // document.getElementById('start').classList.remove("hidden");
    // document.getElementById('response').classList.add("hidden");
    // document.getElementById("file-upload-form").reset();
  }
}

function output(msg) {
  // Response
  var m = document.getElementById('messages');
  m.innerHTML = msg;
}

class UploadFile extends Component {

  Init() {

    console.log("Upload Initialised");

    var fileSelect    = document.getElementById('file-upload'),
      fileDrag      = document.getElementById('file-drag'),
      submitButton  = document.getElementById('submit-button');

    fileSelect.addEventListener('change', this.fileSelectHandler, false);

    // Is XHR2 available?
    var xhr = new XMLHttpRequest();
    if (xhr.upload && fileDrag) {
      // File Drop
      fileDrag.addEventListener('dragover', fileDragHover, false);
      fileDrag.addEventListener('dragleave', fileDragHover, false);
      fileDrag.addEventListener('drop', this.fileSelectHandler, false);
    }
  }



  fileSelectHandler = (e) => {
    // Fetch FileList object
    var files = e.target.files || e.dataTransfer.files;

    // Cancel event and hover styling
    fileDragHover(e);

    // Process all File objects
    for (var i = 0, file; file = files[i]; i++) {
      parseFile(file);

      var xhr = new XMLHttpRequest(),
        fileInput = document.getElementById('class-roster-file'),
        pBar = document.getElementById('file-progress'),
        fileSizeLimit = 1024; // In MB
      if (xhr.upload) {
        // Check if file is less than x MB
        if (file.size <= fileSizeLimit * 1024 * 1024) {
          // Progress bar
          pBar.style.display = 'inline';
          xhr.upload.addEventListener('loadstart', this.setProgressMaxValue, false);
          xhr.upload.addEventListener('progress', this.updateFileProgress, false);

          this.props.onChange(file)
        } else {
          output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
        }
      }
    }
  }



  setProgressMaxValue(e) {
    var pBar = document.getElementById('file-progress');

    if (e.lengthComputable) {
      pBar.max = e.total;
    }
  }

  updateFileProgress(e) {
    var pBar = document.getElementById('file-progress');

    if (e.lengthComputable) {
      pBar.value = e.loaded;
    }
  }


  componentDidMount() {
    if (window.File && window.FileList && window.FileReader) {
      this.Init();
    } else {
      document.getElementById('file-drag').style.display = 'none';
    }
  }

  render() {
    let {img} = this.props
    return (
      <form id="file-upload-form" className="uploader">
        <input id="file-upload" type="file" name="fileUpload" accept="image/*" o/>

        <label htmlFor="file-upload" id="file-drag">
          {img ?
            <img id="file-image" src={img} alt="Preview"/>
            : <div id="start">
              <i className="fa fa-download" aria-hidden="true"></i>
              <div>Select a file or drag here</div>
              <div id="notimage" className="hidden">Please select an image</div>
              <span id="file-upload-btn" className="btn btn-primary">Select a file</span>
            </div>
          }
          <div id="response" className="hidden">
            <div id="messages"></div>
            <progress className="progress" id="file-progress" value="0">
              <span>0</span>%
            </progress>
          </div>
        </label>
      </form>
    );
  }
}

export default withRouter(UploadFile);
