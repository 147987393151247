import React, {useEffect, useState, useRef} from "react";

import DatePicker from '../../shared/components/Datepicker'
import {ucFirst} from "../../shared/helpers/GeneralHelpers";

function DateInput(props) {
  let [show, setShow] = useState(false)
  let {name, value, onChange} = props
  let pickerContainer = useRef();


  useEffect(
    function () {
      document.addEventListener("mousedown", closeDropdowns);
    }
    , [])

  function closeDropdowns(e) {
    if(pickerContainer && pickerContainer.current){
      if(!pickerContainer.current.contains(e.target)){
        setShow(false)
      }}
  }

  return (
    <div ref={pickerContainer}>
      <button type="button" className={`form-control dropdown-toggle text-left px-2`} style={{
        marginRight: "20px",
        width:"160px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
              onClick={() => {setShow(!show); pickerContainer.current.focus()}}>
        <div className="dropdown-selected-item">
          {value ? value : ucFirst(name)}
        </div>
      </button>
      {show && <div x-placement="bottom-start" className="dropmenu-position"
                    style={{zIndex: '10', position:"absolute"}}>
        <DatePicker base="BS" value={value} onSelect={(e) => {onChange({target: {name, value: e}});setShow(false)}}/>
      </div>}
    </div>
  )
}

export default DateInput
