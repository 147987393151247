import React, {Component} from 'react';
import PaymentGatewayModal from "./PaymentGatewayModal";
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import {connect} from "react-redux";
import {ArrayValue} from "../../shared/helpers/ArrayKey";
import {
  createPaymentGateway,
  createResetPaymentGateway,
  getInactivePaymentGateways,
  getPaymentGateway,
  getPaymentGateways,
  getKeys, getAdminPaymentGateways, deleteAdminPaymentGateway
} from "../../apis/payment-gateway";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class PaymentGateway extends Component {



  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: []
  }

  state = {
    filters: {...this.filters},
    restData: [],
    restView: null,
    inactiveData: null,
    createSuccess: null,
    createError: null,
    openModal: false,
    newModal: true,
    formData: {},
    formError: {},
    deleteModal: false,
    message: {
      type: '',
      message: ''
    },
    slug: '',
    keys: {},
    isNew: true,
    isSave: false,
    isView: null,
    statistics: {},
    status: {
      all: true,
      active: false,
      inactive: false
    },
    edit: false
  }

  tempData = {}

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getAdminPaymentGateways(limit, page, searchArray)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getAdminPaymentGateways, getInactivePaymentGateways, createPaymentGateway, getPaymentGateway, getKeys} = nextProps
    let {
      restData,
      inactiveData,
      createSuccess,
      createError,
      restView,
      keys,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    } = prevState
    if (getAdminPaymentGateways) {
      let {success} = getAdminPaymentGateways
      if (success) {
        restData = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      }
    }

    if (getInactivePaymentGateways) {
      let {success, error} = getInactivePaymentGateways
      if (success) {
        inactiveData = success.data.data
      } else if (error) {

      }
    }

    if (createPaymentGateway) {
      let {success, validation} = createPaymentGateway
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        createError = validation
      } else {
        createSuccess = null
        createError = null
      }
    }

    if (getPaymentGateway) {
      let {success, error} = getPaymentGateway
      if (success) {
        restView = success.data.data[0]
      } else if (error) {
        console.log(error)
      }
    }

    if (getKeys) {
      let {success} = getKeys
      if (success) {
        keys = success.data
      }
    }

    return {
      restData,
      inactiveData,
      createSuccess,
      createError,
      restView,
      keys,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {createSuccess, createError, keys, restView} = this.state
    if (createSuccess !== null) {
      store.dispatch(addMessage(createSuccess, "success", "Create Payment Gateway"));
      this.setState({
        formData: {},
        keys: {},
        openModal: false,
        isSave: true
      }, () => {
        this.fetchData()
        createResetPaymentGateway()
      })
    }

    if (restView !== prevState.restView) {
      this.setState({
        isView: restView,
        formData: restView
      })
    }

    if (createError !== null) {
      this.setState({
        formError: createError
      }, () => {
        createResetPaymentGateway()
      })
    }

    if (keys !== prevState.keys) {
      if (keys !== null) {
        let tempData = this.tempData
        keys.required_field && keys.required_field.forEach((field) => {
          let {name} = field
          tempData[name] = ''
        })
        this.tempData = tempData
      }
    }

    if (this.props.deleteAdminPaymentGateway && this.props.deleteAdminPaymentGateway !== prevProps.deleteAdminPaymentGateway) {
      let {success, error} = this.props.deleteAdminPaymentGateway
      if(success && success.data) {
        this.fetchData()
        store.dispatch(addMessage(success.data.message, "success", "Delete Payment Gateway"))
      }
    }
  }

  onViewClick(slug) {
    let {formData} = this.state
    getPaymentGateway(slug)

    this.setState({
      formData,
      openModal: true,
      newModal: false,
      slug: slug
    })
  }

  onNewClick() {
    this.props.history.push('/payment-gateway/new')
  }

  onUpdateClick(id){
    this.props.history.push(`/payment-gateway/${id}/edit`)
  }

  toggleModal() {
    this.setState({
      openModal: false,
      edit: false
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let formData = {...this.state.formData};
    let formError = {...this.state.formError};
    let tempData = {...this.tempData}
    formData[name] = value;
    formError[name] = "";
    if (name === "name") {
      tempData[name] = value
      this.tempData = tempData
      this.setState({isNew: false, isSave: false})
      getKeys(value)
    }
    this.setState({formData, formError});
  }

  toggleStatus() {
    let formData = {...this.state.formData}
    formData['status'] = !formData.status
    this.setState({
      formData
    })
  }

  onCreate() {
    let {inactiveData, formData} = this.state
    let slug = ArrayValue(inactiveData, formData.name, 'id', 'slug')
    createPaymentGateway(this.state.formData, slug)
  }

  onUpdate() {
    let {formData,slug} = this.state
    createPaymentGateway(formData, slug)
  }

  onReset() {
    this.setState({formData: this.tempData})
  }

  onDelete(id) {
    this.state.deleteModal ?
      this.setState({
        deleteModal: false,
        itemToDelete: ''
      }) :
      this.setState({
        deleteModal: true,
        itemToDelete: id
      })
  }

  onDeleteConfirm() {
    deleteAdminPaymentGateway(this.state.itemToDelete)
    this.onDelete()
  }

  onDismiss() {
    this.setState({
      message: {type: `success`, message: ``},
    });
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  render() {
    let {
      restData,
      inactiveData,
      openModal,
      newModal,
      formData,
      formError,
      deleteModal,
      restView,
      keys,
      isNew,
      isSave,
      isView,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      edit
    } = this.state


    const columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '30%',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Disabled'
          }
        ]
      },
      {
        title: 'Actions',
        width: '10%',
        action_links: [
          {
            type: 'button',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            onClick: this.onUpdateClick.bind(this)
          },
          {
            type: 'button',
            icon: 'delete',
            twoToneColor: "#f86c6b",
            title: 'Delete Inventory',
            onClick: this.onDelete.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    restData.forEach((single) => {
      let {
        name, status, id
      } = single

      tableData.push({
        name, status, id:id
      })
    })

    let gatewayOptions = inactiveData ? inactiveData.map((data) => {
      return {
        label: data.name,
        value: data.id
      }
    }) : null


    if (openModal) {
      return (
        <PaymentGatewayModal
          isOpen={openModal}
          toggle={this.toggleModal.bind(this)}
          modalHeader={restView ? "View Config" : newModal ? "Add New Payment Gateway" : "Edit Payment Gateway"}
          data={formData}
          error={formError}
          onChange={e => this.onChange(e)}
          toggleStatus={this.toggleStatus.bind(this)}
          onSave={newModal ? this.onCreate.bind(this) : this.onUpdate.bind(this)}
          onReset={this.onReset.bind(this)}
          gatewayOptions={gatewayOptions}
          isView={isView}
          keys={keys}
          isNew={isNew}
          isSave={isSave}
          isEdit={edit}
        />
      );
    } else {
      return (
        <div className="animated fadeIn">
          <DeleteModal
            open={deleteModal}
            toggle={this.onDelete.bind(this)}
            deleteItem={this.onDeleteConfirm.bind(this)}
            title="Delete Payment Gateway"
          />

          <TableHelpers
            columns={columns}
            tableData={tableData}
            to={to}
            from={from}
            total={total}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            currentPage={currentPage}
            paginationButton={this.paginationButton.bind(this)}
            lastPage={lastPage}
            filters={this.filters}
            newTitle="Create Gateway"
            newAction={this.onNewClick.bind(this)}
            hideDownload={true}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let {getAdminPaymentGateways, getInactivePaymentGateways, createPaymentGateway, getPaymentGateway, getKeys, deleteAdminPaymentGateway} = state
  return {getAdminPaymentGateways, getInactivePaymentGateways, createPaymentGateway, getPaymentGateway, getKeys, deleteAdminPaymentGateway}
}

export default connect(mapStateToProps)(PaymentGateway)
