export default function deletePermission(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'DELETE_PERMISSION_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DELETE_PERMISSION_ERROR':
      return {...state, ...{error: action.error.response}};
    case 'DELETE_PERMISSION_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'DELETE_RESET_PERMISSION':
      return {...state, ...{error:null,success:null,processing: ''}};
    default:
      return state;
  }
}
