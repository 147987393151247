import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "customers"

export const getCustomers = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_CUSTOMERS_SUCCESS',
    error: 'FETCH_CUSTOMERS_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const getActiveCustomers = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_ACTIVE_CUSTOMERS_SUCCESS',
    error: 'FETCH_ACTIVE_CUSTOMERS_ERROR'
  }
  // let url = "api/" + prefix
  let group = new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const downloadCustomers = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_CUSTOMERS_SUCCESS',
    error: 'DOWNLOAD_CUSTOMERS_ERROR'
  }
   let group =new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const getCustomer = (id) => {
  let reducers = {
    success: 'FETCH_CUSTOMER_SUCCESS',
    error: 'FETCH_CUSTOMER_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createCustomer = (data) => {
  let reducers = {
    success: 'CREATE_CUSTOMER_SUCCESS',
    error: 'CREATE_CUSTOMER_ERROR',
    validation_error: 'CREATE_CUSTOMER_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteCustomer = (id) => {
  let reducers = {
    success: 'DELETE_CUSTOMER_SUCCESS',
    error: 'DELETE_CUSTOMER_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const updateCustomer = (id, data) => {
  let reducers = {
    success: 'UPDATE_CUSTOMER_SUCCESS',
    error: 'UPDATE_CUSTOMER_ERROR',
    validation_error: 'UPDATE_CUSTOMER_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const createResetCustomer = () => {
  store.dispatch(resetData('CREATE_RESET_CUSTOMER'))
}

export const deleteResetCustomer = () => {
  store.dispatch(resetData('DELETE_RESET_CUSTOMER'))
}

export const updateResetCustomer = () => {
  store.dispatch(resetData('UPDATE_RESET_CUSTOMER'))
}
