import React from 'react';
import {
  FormGroup,
  FormFeedback,
  Label, Input
} from 'reactstrap';
import {stringUpperCase} from '../helpers/GeneralHelpers';

const SettingSelect = (props) => {
  let {error, onChange, value, name, options, placeholder, label, nolabel} = props;
  label = label ? label : stringUpperCase(name);
  return (
    <FormGroup>
      {nolabel ? "" : <Label>{label}</Label>}
      <Input type="select" invalid={!!error} className="form-control" name={name}
             onChange={onChange} value={value}>
        <option value="" disabled selected>{placeholder}</option>
        {options}
      </Input>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default SettingSelect;
