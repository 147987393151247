import React, {Component} from 'react'
import {Card, CardBody} from "reactstrap";

class NotFoundPage extends Component {
  render() {
    return (
      <Card>
        <CardBody className="empty-div">
          <div className="text-center">
            <h1 className="status-code not-found">404</h1>
            <h2>Oops! Nothing was found</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily
              unavailable.</p>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default NotFoundPage;
