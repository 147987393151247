import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {deleteMenu, getMenus} from "../../apis/menus";
import DeleteModal from "../../shared/form-helpers/DeleteModal";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

class Menu extends Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showPrintModal: false,
    deleteSuccess: null
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getMenus(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getMenus,
      deleteMenu
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      deleteSuccess
    } = prevState;

    if (getMenus) {
      let {success, error} = getMenus;
      if (success) {
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    if (deleteMenu) {
      let {success, error} = deleteMenu;
      if (success) {
        deleteSuccess = success.data || [];
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
      deleteSuccess
    };
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.deleteMenu && this.props.deleteMenu !== prevProps.deleteMenu) {
      let {success, error} = this.props.deleteMenu
      if(success && success.data) {
        this.fetchData()
        store.dispatch(addMessage(success.data.message, "success", "Delete Menu"))
      }
    }
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  printIcon(id) {
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  togglePrintModal() {
    this.setState({
      showPrintModal: !this.state.showPrintModal
    })
  }

  newAction() {
    this.props.history.push('/menus/new')
  }

  onDelete(id) {
    this.state.showDeleteModal ?
      this.setState({showDeleteModal: false, itemToDelete: ""}) :
      this.setState({showDeleteModal: true, itemToDelete: id})
  }

  onDeleteConfirm() {
    deleteMenu(this.state.itemToDelete)
    this.onDelete()
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: 'Name',
        dataIndex: 'name',
        searchText: 'name'
      },
      {
        title: 'Parent Id',
        dataIndex: 'parent_id',
      },
      {
        title: 'Url',
        dataIndex: 'url',
      },
      {
        title: 'Icon',
        dataIndex: 'icon',
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        searchText: 'created_at'
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            url: ['/menus/', '/edit']
          },
          {
            type: 'button',
            icon: 'delete',
            twoToneColor: "#f86c6b",
            title: 'Delete Menu',
            onClick: this.onDelete.bind(this)
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        id,
        name,
        parent_id,
        icon,
        url,
        created_at
      } = single

      tableData.push({
        id,
        name,
        parent_id,
        icon,
        url,
        created_at
      })

    })

    return (
      <div className="animated fadeIn">
        <DeleteModal
          open={this.state.showDeleteModal}
          toggle={this.onDelete.bind(this)}
          deleteItem={this.onDeleteConfirm.bind(this)}
          title="Delete Menu"
        />
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={{}}
          filters={this.filters}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Create Menu"
          newAction={this.newAction.bind(this)}
          url=""
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getMenus,
    deleteMenu,
  } = state;
  return {
    getMenus,
    deleteMenu,
  };
}

export default connect(mapStateToProps)(Menu)
