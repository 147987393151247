import React from 'react';
import {
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { stringUpperCase } from '../helpers/GeneralHelpers';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';


const HorizontalDateTimePicker = (props) => {
  let {error,onChange,value,name,isRequired} = props;
  let label = stringUpperCase(name);
  let errorClass = error ? "custom-invalid-border": null
  return(
    <FormGroup row>
      <Col md="3">
        <Label>{label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <div className={errorClass}>
        <DatePicker showTime placeholder="Select Time" onChange={onChange} value={value}/>
        </div>
        <span className="custom-invalid-feedback">{error}</span>
      </Col>
    </FormGroup>
  );
};

export default HorizontalDateTimePicker;
