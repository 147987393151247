import React, {Component} from 'react'
import {Card, CardBody} from "reactstrap";
import no_internet from '../assets/img/no-internet.png'

class NoInternet extends Component {

  render() {
    return (
      <Card>
        <CardBody className="empty-div">
          <div className="text-center">
            <img src={no_internet} alt="No Internet" className="no-internet"/>
            <p className="page-title">Request Failed</p>
            <p className="page-info">
              Either you have no internet connection or request failed due to server issues.
            </p>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default NoInternet;
