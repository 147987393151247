import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "app-keys"

export const generateAuthKey = (data) => {
  let reducers = {
    success: 'GENERATE_AUTH_KEYS_SUCCESS',
    error: 'GENERATE_AUTH_KEYS_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const getAuthKeys = () => {
  let reducers = {
    success: 'FETCH_AUTH_KEYS_SUCCESS',
    error: 'FETCH_AUTH_KEYS_ERROR'
  }
  let get = new Get(prefix, reducers)
  get.get()
}

export const updateAuthKey = (id) => {
  let reducers = {
    success: 'UPDATE_AUTH_KEY_SUCCESS',
    error: 'UPDATE_AUTH_KEY_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, null)
  update.update()
}

export const resetUpdateAuthKey = () => {
  store.dispatch(resetData('RESET_UPDATE_AUTH_KEY'))
}
