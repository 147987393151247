import React from 'react';
import {Button, ButtonGroup, Col, Form, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalToggle from "../../shared/form-helpers/HorizontalToggle";
import HorizontalTextArea from "../../shared/form-helpers/HorizontalTextArea";

const TaxModal = (props) => {

  let {
    toggle,
    data,
    error,
    onChange,
    toggleStatus,
    onSave,
    onReset
  } = props

  return (
    <>
      <Row>
        <Col>
          <Form>
            <HorizontalTextField
              name="days"
              error={error.days}
              onChange={onChange}
              value={data.days}
            />
            <HorizontalToggle
              status={data.status}
              name="status"
              error={null}
              onChange={toggleStatus}
            />
            <HorizontalTextArea
              name="description"
              error={error.description}
              onChange={onChange}
              value={data.description}
            />
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup className="float-right">
            <Button outline color="danger" onClick={toggle}>Cancel</Button>
            <Button outline color="info" onClick={onReset}>Reset</Button>
            <Button outline color="success" onClick={onSave}>Save</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
}

export default TaxModal;
