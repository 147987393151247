import React from 'react';
import {Button, ButtonGroup, Card, CardBody} from "reactstrap";
import DownloadModal from "./DownloadModal";
import * as jsPDF from "jspdf";
import moment from "moment";
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

class SearchCard extends React.Component {

  onDownloadPdf(downloadColumns, columnName, name) {
    let columns = downloadColumns
    let rows = []
    this.props.downloadData.forEach(item => {
      let row = []
      let temp = {}

      for (let i = 0; i < columnName.length; i++) {
        temp[columnName[i]] = item[columnName[i]]
      }

      Object.keys(temp).forEach((i) => {
        const subItem = temp[i] ? temp[i].length > 20 ? temp[i].substr(0, 25) : temp[i] : "-"
        row.push(subItem)
      })
      rows.push(row)
    })
    let doc = new jsPDF('p', 'pt');
    doc.autoTable(columns, rows);
    doc.save(name + '-' + moment().format() + '.pdf');
  }

  render() {
    let {
      downloadData,
      toggleDownload,
      searchPlaceholder,
      searchValue,
      searchChange,
      startDate,
      endDate,
      onDateChange,
      isDate,
      showDownload,
      downloadColumns,
      columnName,
      name,
      searchName,
      isDownload
    } = this.props

    return (
      <Card className="top-page-content">
        <DownloadModal
          open={showDownload}
          toggle={toggleDownload}
          downloadPdf={() => this.onDownloadPdf(downloadColumns, columnName, name)}
          data={downloadData}
          name={name}
        />
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <span className="text-muted"><i className="fa fa-search"></i></span>
              <input
                name={searchName}
                className="header-input"
                placeholder={searchPlaceholder}
                value={searchValue}
                onChange={searchChange}
              />
            </div>
            <div className="col-sm-6 col-md-4">
              {isDate &&
              <RangePicker
                format={dateFormat}
                onChange={(date,dateString)=>{this.onDateChange(date, dateString)}}
                value={[startDate,endDate]}
              />
              }
            </div>
            <div className="col-sm-6 col-md-2">
              {isDownload &&
              <ButtonGroup className="float-right">
                <Button color="success" onClick={toggleDownload} outline><i
                  className="fa fa-download"></i></Button>
              </ButtonGroup>
              }
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default SearchCard
