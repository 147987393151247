export default function deleteTax(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'DELETE_TAX_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DELETE_TAXS_ERROR':
      return {...state, ...{error: action.error}};
    case 'DELETE_TAXS_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'DELETE_RESET_TAX':
      return {...state, ...{success:null,error:null}};
    default:
      return state;
  }
}
