export function noInternet() {
  return dispatch => {
    dispatch({
      type: "NO_INTERNET"
    });
  };
}

export function serverError() {
  return dispatch => {
    dispatch({
      type: "SERVER_ERROR"
    });
  };
}

export function resetError() {
  return dispatch => {
    dispatch({
      type: "RESET_ERROR"
    });
  };
}

export function unauthorizedError(message){
  return dispatch => {
    dispatch({
      type: "UNAUTHORIZED_ERROR",
      message
    });
  };
}

