import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "roles"

export const getRoles = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_ROLES_SUCCESS',
    error: 'FETCH_ROLES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const getRole = (id) => {
  let reducers = {
    success: 'FETCH_ROLE_SUCCESS',
    error: 'FETCH_ROLE_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createRole = (data) => {
  console.log("create")
  let reducers = {
    success: 'CREATE_ROLE_SUCCESS',
    validation_error: 'CREATE_ROLE_VALIDATION_ERROR',
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteRole = (id) => {
  let reducers = {
    success: 'DELETE_ROLE_SUCCESS',
    error: 'DELETE_ROLE_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const updateRole = (id, data) => {
  let reducers = {
    success: 'UPDATE_ROLE_SUCCESS',
    validation_error: 'UPDATE_ROLE_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const createResetRole = () => {
  store.dispatch(resetData('CREATE_RESET_ROLE'))
}

export const deleteResetRole = () => {
  store.dispatch(resetData('DELETE_RESET_ROLE'))
}

export const updateResetRole = () => {
  store.dispatch(resetData('UPDATE_RESET_ROLE'))
}
