export default function getProductsFamilies(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {
    case 'FETCH_PRODUCTS_FAMILIES_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'FETCH_PRODUCTS_FAMILIES_ERROR':
      return {...state, ...{error: action.error}};
    case 'FETCH_PRODUCTS_FAMILIES_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
