import React from 'react';
import PaymentMethods from "./PaymentMethods";
import PaymentTerms from './PaymentTerms'
import {Card, Tabs} from "antd";

const {TabPane} = Tabs;


class PaymentSettings extends React.Component {
  render() {
    return (
      <div className="animated fadeIn">
        <div className="top-page-content">
          <Card>
            <Tabs>
              <TabPane tab="Payment Methods" key="1">
                <PaymentMethods/>
              </TabPane>
              <TabPane tab="Payment Terms" key="2">
                <PaymentTerms/>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}

export default PaymentSettings
