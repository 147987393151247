import { clone } from 'lodash';
import { Calendar } from './Calendar';
// import { getStartingDate } from './__calendarHelper';
import { Config } from './../Config';


class EnglishCalendar implements Calendar {
  constructor(date) {
    this.date = new Date(date);
  }

  getStartingDate() {
    const date = clone(this.date);
    date.setDate(1);
    const day = -Math.abs(date.getDay()) + 1;
    date.setDate(day);
    return date;
  }

  getCurrentMonth() {
    return Config.monthName[this.date.getMonth()];
  }

  getNextMonth() {
    const dateObj = clone(this.date);
    const date = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 1);
    return new EnglishCalendar(date);
  }

  getPrevMonth() {
    const dateObj = clone(this.date);
    const date = new Date(dateObj.getFullYear(), dateObj.getMonth() - 1, 1);
    return new EnglishCalendar(date);
  }

  getMonth() {
    return this.date.getMonth();
  }

  getYear() {
    return this.date.getFullYear();
  }

  getDate() {
    return this.date.getDate();
  }

  getYearList() {
    const yearList = [];
    let currentDate = new EnglishCalendar(new Date());
    let yearStart = currentDate.getYear() - 150;
    for( let i = 0; i < 500; i += 1) {
      yearList.push(yearStart);
      yearStart += 1;
    }
    return yearList;
  }

  setMonth(month) {
    this.date.setMonth(month);
  }

  setYear(year) {
    this.date.setYear(year);
  }


  format(format) {
    const year = this.getYear();
    const month = this.getMonth() + 1;
    let m = month < 10 ? '0'+month : month
    const day = this.getDate();
    let d = day < 10 ? '0'+day : day
    const res = format.replace('YYYY', year).replace('MM', m).replace('DD', d);
    return res;
  }
}

export default EnglishCalendar;
