import React from 'react';

const Day = ({
  date, value, isCurrentMonth, isSelectedDate, onChange
}) => (
  <div className='datepicker-day'
    style={{
      width: `${100 / 7}%`,

      opacity: isCurrentMonth ? 1 : 0.4,
      color: isSelectedDate && isCurrentMonth ? 'white' : 'black',
      display: 'inline-block',
      borderRadius: '15px',
      backgroundColor: isSelectedDate && isCurrentMonth ? '#4dbd74' : null,
    }}
  >
    <center>
      <button
        type="button"
        style={{
          border: 'none',
          width: '80%',
          background: 'none',
          color: 'inherit',
          padding: 0,
          font: 'inherit',
          cursor: 'pointer',
          outline: 'inherit',
        }}
        onClick={() => onChange(date)}
      >
        {value}
      </button>
    </center>
  </div>
);


export default Day;
