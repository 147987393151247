export default function deleteAdminPaymentGateway(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'DELETE_ADMIN_PAYMENT_GATEWAY_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'DELETE_ADMIN_PAYMENT_GATEWAY_ERROR':
      return {...state, ...{error: action.error}};
    case 'DELETE_ADMIN_PAYMENT_GATEWAY_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'DELETE_RESET_ADMIN_PAYMENT_GATEWAY':
      return {...state, ...{success: null,error: null}};
    default:
      return state;
  }
}
