import axios from 'axios';
import {loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";

function _success(success){
  return { type: 'FETCH_COMPANY_USERS_SUCCESS', success }
}
function _error(error){
  return { type:'FETCH_COMPANY_USERS_ERROR', error }
}
function _processing(processing){
  return { type: 'FETCH_COMPANY_USERS_PROCESSING', processing }
}

export function getCompanyUsers(limit, page = 1, q, sort, field, filter_field, filter_value)
{
  return dispatch => {
    dispatch(_processing(true));
    let config = {
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      },
      params: {
        limit: limit || 9,
        page:  page,
        q:  q  || ``,
        sort_field: field || `id`,
        sort_by:  sort || 'desc',
        filter_field: filter_field,
        filter_value: filter_value
      }
    };
    let request  = axios.get(Config.BillingUrl + (`/users`), config)

      .then(res => {
        dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
        dispatch(_error(error));
        dispatch(_processing(false));
      });

    return { type: 'FETCH_COMPANY_USERS_SUCCESS', payload: request }
  }
}

export default getCompanyUsers;
