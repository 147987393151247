import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {getProducts} from "../../apis/products";

class Products extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getProducts(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getProducts,
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState;

    if (getProducts) {
      let {success, error} = getProducts;
      if (success) {
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    };
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  newAction() {
    this.props.history.push('/products_and_services/new')
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'name',
        searchText: 'name'
      },
      {
        title: 'Product Code',
        dataIndex: 'code'
      },
      {
        title: 'Purchase Price',
        dataIndex: 'purchase_price'
      },
      {
        title: 'Sales Price',
        dataIndex: 'sales_price'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Inactive'
          }
        ]
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            url: ['/products_and_services/']
          },
          {
            type: 'navlink',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            url: ['/products_and_services/','/edit']
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        id, name, code, sales_price, status,purchase_price
      } = single

      tableData.push({
        id,
        name,
        code,
        sales_price,
        purchase_price,
        status
      })

    })

    return (
      <div className="animated fadeIn">
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          onDateConfirm={this.onDateConfirm.bind(this)}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Create Product"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getProducts,
  } = state;
  return {
    getProducts,
  };
}

export default connect(mapStateToProps)(Products)
