export default function createCreditNote(
  state = {validation: null, success: null, bad_gateway: null, bad_request: null},
  action
) {
  switch (action.type) {

    case 'CREATE_CREDIT_NOTE_SUCCESS':
      return {...state, ...{success: action.success, validation: null, bad_gateway: null}};
    case 'CREATE_CREDIT_NOTE_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null, bad_gateway: null}};
    case 'CREATE_RESET_CREDIT_NOTE':
      return {...state, ...{success: null, validation: null, bad_gateway: null}};
    case 'CREATE_CREDIT_NOTE_BAD_GATEWAY':
      return {...state, ...{success: null, validation: null, bad_gateway: action.bad_gateway}};
    case 'CREATE_CREDIT_NOTE_BAD_REQUEST':
      return {...state, ...{success: null, validation: null, bad_request: action.bad_request}};
    default:
      return state;
  }
}
