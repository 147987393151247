import axios from 'axios';
import {loggedIn, loginToken} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import jwt from "jsonwebtoken";


function _success(success){
  return { type: 'UPDATE_ROOT_CUSTOMER_SUCCESS', success }
}
function _error(error){
  return { type:'UPDATE_ROOT_CUSTOMER_ERROR', error }
}
function _processing(processing){
  return { type: 'UPDATE_ROOT_CUSTOMER_PROCESSING', processing }
}

export function updateRootCustomer(data)
{
  if (loggedIn) {
    return dispatch => {

      let company_id = jwt.decode(localStorage.getItem('ERP_Bearer')).params.company_id;
      console.log(company_id)

      let config = {
        url: Config.BillingUrl + `companies/`+company_id,
        method: "post",
        dataType: 'json',
        data: data,
        headers: {
          'Authorization': 'Bearer ' + loginToken()
        }
      };

      let request  = axios(config)

        .then(res => {
          console.log(res)
          dispatch(_processing(false));
          dispatch(_success(res));

        }).catch(error => {
          console.log(error)
          dispatch(_error(error));
          dispatch(_processing(false));
        });
      return { type: 'UPDATE_ROOT_CUSTOMER_SUCCESS', payload: request }
    }
  }
}

export default updateRootCustomer;
