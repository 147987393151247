import axios from 'axios';
import {loggedIn} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import jwt from "jsonwebtoken";
import {refreshToken} from "./refresh_token";
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";


function _success(success){
  return { type: 'FETCH_LOGGED_IN_USER_SUCCESS', success }
}
function _error(error){
  return { type:'FETCH_LOGGED_IN_USER_ERROR', error }
}
function _processing(processing){
  return { type: 'FETCH_LOGGED_IN_USER_PROCESSING', processing }
}

export function getLoggedInUser()
{
  store.dispatch(enableLoader())
  if (loggedIn) {
    return dispatch => {
      let id = jwt.decode(localStorage.getItem('ERP_Bearer')) ? jwt.decode(localStorage.getItem('ERP_Bearer')).params.id : null;

      dispatch(_processing(true));
      let config = { headers: {'Authorization': 'Bearer ' + localStorage.getItem('ERP_Bearer') }};
      // let request = axios.get(base_url + (`companies/`+company_id), config)
      let request  = axios.get(Config.BillingUrl + (`users/`+id), config)


        .then(function(res){
          store.dispatch(disableLoader())
          dispatch(_success(res));
          dispatch(_processing(false));
        }).catch(function(error){
          if (error && error.response && error.response.status === 401) store.dispatch(refreshToken());
          store.dispatch(disableLoader())
          dispatch(_error(error));
          dispatch(_processing(false));
        });



      return { type: 'FETCH_LOGGED_IN_USER_SUCCESS', payload: request }
    }
  }
  store.dispatch(disableLoader())
}

export default getLoggedInUser;
