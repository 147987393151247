import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody, CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {Switch} from "antd";
import {getProductFamilies} from "../../apis/product-families";
import {createProduct, createResetProduct, uploadImageProduct} from "../../apis/products";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {localValidation} from "../../shared/helpers/ValidationHelper";
import {getActiveUnits} from "../../apis/units";
import {ekUpload} from "./fileUploader"
import img from "./../../assets/img/logo.png"
import UploadFile from "./UploadFile";
import {Config} from "../../Config";
import {ucFirst, ucFirstWords} from "../../shared/helpers/GeneralHelpers";

class NewCustomer extends Component {

  data = {
    type: "service",
    name: "",
    sales_price: "",
    purchase_price: "",
    unit_id: "",
    description: "",
    family: "",
    status: 1,
    product_family_id: "",
    hs_code: ""
  }

  error = {
    name: "",
    sales_price: "",
    purchase_price: "",
    unit_id: "",
    description: "",
    family: "",
    status: 1,
    product_family_id: "",
    hs_code: ""
  }

  state = {
    units: [],
    service: true,
    status: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    countries: [],
    provinces: [],
    createSuccess: null,
    username: null,
    families: [],
    fileUpload:{},
    img: img
  }

  fetchData() {
    getActiveUnits()
    getProductFamilies(1000)
  }

  componentDidMount() {
    this.fetchData();
    // ekUpload();
  }

  changeService() {
    let data = {...this.data}
    let error = {...this.error}
    data['type'] = "service"
    this.data = data
    this.setState({
      service: true,
      data,
      error
    })
  }

  changeProduct() {
    let data = {...this.data}
    let error = {...this.error}
    data['type'] = "product"
    this.data = data
    this.setState({
      service: false,
      data,
      error
    })
  }

  changeOn() {
    let {data} = this.state
    data['status'] = 1
    this.setState({
      status: true,
      data
    })
  }

  changeOff() {
    let {data} = this.state
    data['status'] = 0
    this.setState({
      status: false,
      data
    })
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/products_and_services")
  }

  onReset() {
    let {service} = this.state
    this.setState({
      data: {...this.data},
      error: {...this.error},
      service
    })
  }

  onSubmit() {
    let {data, fileUpload} = this.state

    let validationRule = {
      name: ['required'],
      unit_id: ['required'],
      sales_price: ['required', "decimal"],
      product_family_id: ['required'],
    }
    if (data['type'] !== "service")
      validationRule['purchase_price'] = ['required', "decimal"]
    else
      data['purchase_price'] = data['sales_price']
    if (fileUpload.id){
      data['image'] = fileUpload.id
    }
    data['created_by'] = this.state.username
    let localValidationStatus = false
    let check = localValidation(data, validationRule, this.state.error, localValidationStatus)
    if (check.localvalidationerror) {
      this.setState({
        error: check.error
      })
    } else {
      createProduct(data)
    }
  }

  onUploadImage(file){
    uploadImageProduct(file)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getActiveUnits, createProduct, getLoggedInUser, getProductsFamilies, uploadImageProduct} = nextProps
    let {units, restError, username, createSuccess, families, fileUpload} = prevState
    if (getActiveUnits) {
      let {success} = getActiveUnits
      if (success) {
        units = success.data || []
      }
    }

    if (createProduct) {
      let {success, validation} = createProduct
      if (success) {
        createSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        createSuccess = null
        restError = null
      }
    }

    if (getLoggedInUser) {
      let {success, error} = getLoggedInUser
      if (success) {
        username = success.data.name
      } else if (error) {
        console.log(error)
      }
    }

    if (getProductsFamilies) {
      let {success} = getProductsFamilies
      if (success) {
        if (success.status !== 204)
          families = success.data.data
      }
    }

    if (uploadImageProduct){
        let {success, validation} = uploadImageProduct
        if (success) {
          fileUpload = {
            id: success.data.id,
            path: Config.BillingUrl + success.data.name
          }
        } else if (validation) {
          console.log(validation)
        }
    }

    return {
      units, restError, username, createSuccess, families, fileUpload
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
        error: this.state.restError
      }, () => {
        createResetProduct()
      })
    }

    if (this.state.createSuccess !== null) {
      store.dispatch(addMessage(this.state.createSuccess, "success", "Create Product"))
      createResetProduct()
      setFetch(true)
      this.props.history.push('/products_and_services')
    }
  }

  toggleSwitch() {
    let {data} = this.state
    data['status'] = !data.status
    this.setState({data})
  }

  render() {
    let {data, error, units, families} = this.state

    let familyList = families.map((family) => (
      <option value={family.id}>{family.name}</option>
    ))
    let unitList = units.map((unit) => (
      <option value={unit.id}>{unit.name}</option>
    ))

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Overview</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="6">
                    <ButtonGroup>
                      <Button color="primary" outline={!this.state.service}
                              onClick={this.changeService.bind(this)}>Service</Button>
                      <Button color="primary" outline={this.state.service}
                              onClick={this.changeProduct.bind(this)}>Product</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.name} className="form-control"
                             name="name" value={data.name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Unit</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.unit_id} className="form-control" name="unit_id"
                             onChange={e => this.onChange(e)} value={data.unit_id}>
                        <option value=""></option>
                        {unitList}
                      </Input>
                      <FormFeedback>{error.unit_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>

                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Sales Price</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.sales_price} className="form-control"
                             name="sales_price" value={data.sales_price}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.sales_price}</FormFeedback>
                    </FormGroup>
                  </Col>
                  {data.type === "product" &&
                  <Col sm="12" md="6">
                    <span className="form-span-label">Purchase Price</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.purchase_price} className="form-control"
                             name="purchase_price" value={data.purchase_price}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.purchase_price}</FormFeedback>
                    </FormGroup>
                  </Col>}
                  <Col sm="12" md="6">
                    <span className="form-span-label">Standard Cost</span>
                    <FormGroup>
                      <Input invalid={!!error.standard_cost} className="form-control"
                             name="standard_cost" value={data.standard_cost}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.standard_cost}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">HS Code</span>
                    <FormGroup>
                      <Input invalid={!!error.hs_code} className="form-control"
                             name="hs_code" value={data.hs_code}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.hs_code}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Status</span>
                    <FormGroup>
                      <Switch
                        checked={data.status}
                        onChange={this.toggleSwitch.bind(this)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Details</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Family</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input type="select" invalid={!!error.product_family_id} className="form-control"
                             name="product_family_id"
                             onChange={e => this.onChange(e)} value={data.product_family_id}>
                        <option value=""></option>
                        {familyList}
                      </Input>
                      <FormFeedback>{error.product_family_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Description</span>
                    <FormGroup>
                      <Input type="textarea" invalid={!!error.description} className="form-control"
                             name="description" value={data.description}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form-span-label">Image</span>
                    <FormGroup>
                      <UploadFile img={this.state.fileUpload.path} onChange={(e) => this.onUploadImage(e)}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>

            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Dimension</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  {["lenght", "height", "width"].map((o, i) =>
                    (<Col key={i}>
                      <span className="form-span-label">{ucFirstWords(o)}</span>
                      <FormGroup>
                        <Input type="number" invalid={!!error[o]} className="form-control"
                               min={0}
                               name={o} value={data[o]}
                               onChange={e => this.onChange(e)}/>
                        <FormFeedback>{error[o]}</FormFeedback>
                      </FormGroup>
                    </Col>)
                  )
                  }
                </Row>
                <Row>
                  {/*let attributes = ["name", "description", "status", "is_downloadable", "standard_cost", "authorize_negative_stock",*/}
                  {/*"minimum_stock_qty", "minimum_stock_qty", "private_note", "width", "height", "dimension_unit"]*/}
                  <Col sm="12" md="6">
                    <span className="form-span-label">Dimension Unit</span>
                    <FormGroup>
                      <Input type="text" invalid={!!error["dimension_unit"]} className="form-control"
                             name={"dimension_unit"} value={data["dimension_unit"]}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error["dimension_unit"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <span className="form-span-label">New Weight</span>
                    <FormGroup>
                      <Input type="text" invalid={!!error["net_weight"]} className="form-control"
                             name={"net_weight"} value={data["net_weight"]}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error["net_weight"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>

            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="2"><span className="form-left-span"><strong>Stock</strong></span></Col>
              <Col sm="12" md="10">
                <Row>
                  {["minimum_stock_qty","minimum_stock_qty"].map((o,i) =>
                    (<Col key={i}>
                      <span className="form-span-label">{ucFirstWords(o)}</span>
                      <FormGroup>
                        <Input type="number" invalid={!!error[o]} className="form-control"
                               min={0}
                               name={o} value={data[o]}
                               onChange={e => this.onChange(e)}/>
                        <FormFeedback>{error[o]}</FormFeedback>
                      </FormGroup>
                    </Col>)
                  )
                  }
                </Row>
                <Row>
                  <Col sm="12" md="6">
                      <span className="form-span-label">Authorize Negative Stock</span>
                      <FormGroup>
                        <Input type="text" invalid={!!error["authorize_negative_stock"]} className="form-control"
                               name={"authorize_negative_stock"} value={data["authorize_negative_stock"]}
                               onChange={e => this.onChange(e)}/>
                        <FormFeedback>{error["authorize_negative_stock"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>

          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getActiveUnits, createProduct, getLoggedInUser, getProductsFamilies, uploadImageProduct} = state
  return {
    getActiveUnits, createProduct, getLoggedInUser, getProductsFamilies, uploadImageProduct
  }
}

export default connect(mapStateToProps)(NewCustomer);
