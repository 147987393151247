import React from 'react';
import {connect} from "react-redux";
import store from '../../Store'
import {Card, CardBody} from "reactstrap";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {getBilling} from "../../apis/billings";

class ViewBilling extends React.Component {

  state = {
    data: {},
    error: {},
    processing: false,
    width: null,
    logo: ''
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    getBilling(this.props.match.params.id)
    store.dispatch(getLoggedInCompany())
  }

  componentDidUpdate() {
    this.resizeWindow();
  }

  resizeWindow() {
    let width = document.getElementById('pdf') && document.getElementById('pdf').clientWidth;
    if (this.state.width !== width) {
      this.setState({
        width
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getBilling, getLoggedInCompany} = nextProps;
    let {data, processing,logo} = prevState
    if (getBilling) {
      let {success} = getBilling
      if (success) {
        data = success.data
      }
      processing = getBilling.processing
    }

    if (getLoggedInCompany){
      let {success }= getLoggedInCompany
      if (success){
        logo = success.data.logo
      }
    }


    return {data, processing,logo}
  }

  render() {
    let {logo, data} = this.state

    const tableData = data.details && data.details.map((detail,i)=>(
      <tr key={i}>
        <td className="text-center">{i + 1}</td>
        <td className="text-center">{detail.product_name}</td>
        <td className="text-center">{detail.description}</td>
        <td className="text-center">{detail.qty}</td>
        <td className="text-center">{detail.amount}</td>
        <td className="text-center">{detail.discount_rate}</td>
        <td className="text-center">{detail.taxable_amount}</td>
        <td className="text-center">{detail.taxable_amount}</td>
      </tr>
    ))


    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <table style={{color: "#7270c5"}}>
                      <tr>
                        <td>Net Tv</td>
                      </tr>
                      <tr>
                        <td>Jawalakhel ,Kathmandu, Nepal</td>
                      </tr>
                      <tr>
                        <td>Tel: 01-2838373</td>
                      </tr>
                      <tr>
                        <td>Email: info@nettv.com.np</td>
                      </tr>
                      <tr>
                        <td>Pan no: {data.pan}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-4"><h4 className="">Purchase</h4></div>
                  <div className="col-4"><img
                    style={{width: "110px", height: "40px"}} src={logo} alt="logo"/></div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-4">
                    <div>Bill By</div>
                    <div>{data.vendor_name}</div>
                  </div>
                  <div className="col-4">
                    <div>Billing Date</div>
                    <div>Vendor Id</div>
                    <div>Billing No</div>
                    <div>Billing Id</div>
                    <div>Vendor PAN</div>
                  </div>
                  <div className="col-4">
                    <div>{data.created_at && data.created_at.substr(0, 10)}</div>
                    <div>{data.vendor_id}</div>
                    <div>{data.billing_no}</div>
                    <div>{data.payment_id}</div>
                    <div>{data.pan}</div>
                  </div>
                </div>
                <hr style={{height: "3px", border: "none", backgroundColor: "black"}}/>
                <div className="row">
                  <div className="col-12">
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th scope="col" width="50" className="text-center">S.N</th>
                        <th scope="col" width="150" className="text-center">Particulars</th>
                        <th scope="col" width="200" className="text-center">Description</th>
                        <th scope="col" width="50" className="text-center">Qty.</th>
                        <th scope="col" width="50" className="text-center">Rate</th>
                        <th scope="col" width="100" className="text-center">Discount(%)</th>
                        <th scope="col" width="120" className="text-center">Amount</th>
                        <th scope="col" width="200" className="text-center">Total Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                      {tableData}
                      <tr>
                        <th scope="row" colSpan="5" className="text-right">SubTotal</th>
                        <td className="text-center">{data.discount_amount}</td>
                        <td className="text-center">{data.amount}</td>
                        <td className="text-center">{data.taxable_amount}</td>
                      </tr>
                      <tr>
                        <td rowSpan="3" colSpan="3"></td>
                        <th scope="row" colSpan="4" className="text-center">Total Taxable Amount</th>
                        <td className="text-center">{data.taxable_amount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-center">{`VAT(${data.vat_rate} %)`}</th>
                        <td className="text-center">{data.vat_amount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="4" className="text-center">Grand Total</th>
                        <td className="text-center">{data.total}</td>
                      </tr>
                      <tr>
                        <td colSpan="8" className="text-center"> {data.in_words}</td>
                      </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {successMessage, getBilling, getLoggedInCompany} = state
  return {
    successMessage, getBilling, getLoggedInCompany
  }
}

export default connect(mapStateToProps)(ViewBilling);
