import React, {Component} from 'react';
import { Route, Switch} from 'react-router-dom';
import { Container} from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';

// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';
import {connect} from "react-redux";
import store from '../../Store'
import jwt from "jsonwebtoken";
import {refreshToken} from "../../actions/login/refresh_token";
import {createTax} from "../../apis/taxes";
import NotFoundPage from '../../views/NotFoundPage'
import AlertModal from "../../shared/form-helpers/AlertModal";
import {resetError} from "../../actions/error";
import {deleteMessage} from "../../actions/messages/success_message";
import {showNotification} from "../../shared/helpers/Notification";
import 'antd/dist/antd.css';
import {getUserMenu} from "../../apis/menus";

class DefaultLayout extends Component {

  fiscalYear = {
    name: "",
    start_date: "",
    end_date: ""
  }

  fiscalYearError = {
    name: "",
    start_date: "",
    end_date: ""
  }

  vat = {
    name: "standard",
    description: "",
    rate: 13,
    status: 1
  }

  vatError = {
    name: "",
    status: 1
  }


  state = {
    isNew: false,
    showModal: false,
    toggleForm: true,
    fiscalYear: {...this.fiscalYear},
    vat: {...this.vat},
    fiscalYearError: {...this.fiscalYearError},
    vatError: {...this.vatError},
    vatSuccess: null,
    isLoader: false,
    downloadInfo: "",
    refreshToken: {},
    serverError: null,
    noInternet: null,
    message: {},
    restMessage: {message: '', type: ''},
    type: null,
    messageType: '',
    unauthorizedError: null,
    userMenu: []
  }

  componentDidMount() {

    let element = document.getElementsByClassName("main");
    if (element[0]) {
      element[0].childNodes[0].classList.add("breadcrumb-main");
    }
    if (window.location.pathname === "/")
      this.props.history.push('/dashboard')
    getUserMenu()
    // store.dispatch(currentFiscalYear())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {currentFiscalYear, createFiscalYear, createTax, loader, downloadMessage, errorHandling, successMessage, getUserMenu} = nextProps;
    let {isNew, toggleForm, isLoader, downloadInfo, refreshToken, noInternet, serverError, restMessage, messageType,unauthorizedError, userMenu} = prevState;
    if (currentFiscalYear) {
      let {success, error} = currentFiscalYear
      if (success) {
        if (success.status === 204) {
          isNew = true
        }
      } else if (error) {
        console.log(error)
      }
    }

    if (createFiscalYear) {
      let {success, error} = createFiscalYear
      if (success) {
        if (success.status === 200) {
          toggleForm = false
        }
      } else if (error) {
        console.log(error)
      }
    }

    if (createTax) {
      let {success, error} = createTax
      if (success) {
        if (success.status === 200) {
          return {
            vatSuccess: 1
          }
        }
      } else if (error) {
        console.log(error)
      }
    }

    if (getUserMenu) {
      let {success, error} = getUserMenu
      if (success) {
        if (success.data) {
          let _data = success.data
          _data.map((o,i) => {
            if(o.children && o.children.length > 0) {
              return o.children.map((oo, ii) => {
                delete oo["children"]
                return oo
              })
            }else{
              delete o["children"]
            }
          })
          return {
            userMenu: _data
          }
        }
      } else if (error) {
        console.log(error)
      }
    }

    isLoader = loader.isInnerLoader

    refreshToken = nextProps.refreshToken

    downloadInfo = downloadMessage.message

    noInternet = errorHandling.no_internet

    serverError = errorHandling.server_error

    unauthorizedError = errorHandling.unauthorized

    restMessage = successMessage.message

    messageType = successMessage.message.type

    return {isNew, toggleForm, isLoader, downloadInfo, refreshToken, noInternet, serverError, restMessage, messageType,unauthorizedError};
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isNew !== prevState.isNew) {
      this.setState({
        showModal: this.state.isNew
      })
    }

    if (this.state.restMessage.message !== '') {
      let message = this.state.restMessage
      showNotification(message.message,message.type,message.title,message.persistent)
      store.dispatch(deleteMessage(this.state.messageType))
    }

    let expiry = jwt.decode(localStorage.getItem('ERP_Bearer')).exp
    if (Date.now() / 1000 > expiry)
      if (!this.state.refreshToken.initiate)
        store.dispatch(refreshToken())
    if (window.location.pathname === "/")
      this.props.history.push('/dashboard')
  }

  onFiscalYearChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let fiscalYear = {...this.state.fiscalYear};
    let fiscalYearError = {...this.state.fiscalYearError};
    fiscalYear[name] = value;
    fiscalYearError[name] = "";
    this.setState({fiscalYear, fiscalYearError});
  }

  onVatChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let vat = {...this.state.vat};
    let vatError = {...this.state.vatError};
    vat[name] = value;
    vatError[name] = "";
    this.setState({vat, vatError});
  }

  onCreateVat() {
    createTax(this.state.vat)
  }

  toggleModal() {
    this.setState({
      vatSuccess: 2
    })
  }

  closeModal() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  toggleAlert() {
    store.dispatch(resetError())
  }

  onDismiss() {
    this.setState({
      message: {type: this.state.messageType, message: ``},
    });
  }

  render() {
    let {downloadInfo, noInternet, serverError,unauthorizedError} = this.state
    return (
      <>
        {this.state.isLoader && <div className="loading"></div>}
        <AlertModal
          open={!!noInternet}
          status={503}
          statusTitle="Request Failed"
          statusMessage="Either you have no internet connection or request failed due to server issues."
          toggle={this.toggleAlert.bind(this)}
        />
        <AlertModal
          open={!!serverError}
          status={500}
          statusTitle="Server Error"
          statusMessage="Oops !! Something went wrong with the server"
          toggle={this.toggleAlert.bind(this)}
        />
        <AlertModal
          open={!!unauthorizedError}
          status={403}
          statusTitle="Unauthorized"
          statusMessage={unauthorizedError}
          toggle={this.toggleAlert.bind(this)}
        />

        <div className="app">
          <AppHeader fixed>
            <DefaultHeader/>
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg">
              <AppSidebarNav navConfig={{items:this.state.userMenu}} {...this.props} />
              <AppSidebarFooter/>
              <AppSidebarMinimizer/>
            </AppSidebar>
            <main className="main">
              {downloadInfo.length > 0 &&
              <div className="download-info"><i className="fa fa-spinner fa-spin"></i>{`  ${downloadInfo}`}</div>}
              <AppBreadcrumb appRoutes={routes}/>
              <Container fluid>
                <Switch>
                  {routes.map((route, idx) => {
                      return route.component ? (
                          <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                            <route.component {...props} />
                          )}/>)
                        : (null);
                    },
                  )}
                  {/*<Redirect from="/" to="/dashboard"/>*/}
                  <Route path="*" component={NotFoundPage}/>
                </Switch>
              </Container>
            </main>
            <AppAside fixed>
              <DefaultAside/>
            </AppAside>
          </div>
          {/*<AppFooter>*/}
          {/*<DefaultFooter/>*/}
          {/*</AppFooter>*/}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {currentFiscalYear, getvat, createFiscalYear, createTax, loader, downloadMessage, refreshToken, errorHandling, successMessage, getUserMenu} = state
  return {
    currentFiscalYear,
    getvat,
    createFiscalYear,
    createTax,
    loader,
    downloadMessage,
    refreshToken,
    errorHandling,
    successMessage,
    getUserMenu
  }
}

export default connect(mapStateToProps)(DefaultLayout);
