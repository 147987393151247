import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "products"

export const createProduct = (data) => {
  let reducers = {
    success: 'CREATE_PRODUCT_SUCCESS',
    validation_error: 'CREATE_PRODUCT_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const uploadImageProduct = (data) => {
  let reducers = {
    success: 'UPLOAD_IMAGE_PRODUCT_SUCCESS',
    validation_error: 'UPLOAD_IMAGE_PRODUCT_VALIDATION_ERROR'
  }
  let  url = 'file-upload'
  let create = new Create(url, reducers,data)
  create.create();
}

export const deleteProduct = (id) => {
  let reducers = {
    success: 'DELETE_PRODUCT_SUCCESS',
    error: 'DELETE_PRODUCT_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getProduct = (id) => {
  let reducers = {
    success: 'FETCH_PRODUCT_SUCCESS',
    error: 'FETCH_PRODUCT_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getProducts = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_PRODUCTS_SUCCESS',
    error: 'FETCH_PRODUCTS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const downloadProducts = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'DOWNLOAD_PRODUCTS_SUCCESS',
    error: 'DOWNLOAD_PRODUCTS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const updateProduct = (id, data) => {
  let reducers = {
    success: 'UPDATE_PRODUCT_SUCCESS',
    validation_error: 'UPDATE_PRODUCT_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const updateResetProduct = () => {
  store.dispatch(resetData('UPDATE_RESET_PRODUCT'))
}

export const createResetProduct = () => {
  store.dispatch(resetData('CREATE_RESET_PRODUCT'))
}

export const deleteResetProduct = () => {
  store.dispatch(resetData('DELETE_RESET_PRODUCT'))
}
