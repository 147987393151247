export default function recoveryDb(
  state = { error: null, success: null },
  action
) {
  switch (action.type) {

    case 'RECOVER_DB_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'LIST_DB_ERROR':
      return {...state, ...{error: action.error.response}};
    default:
      return state;
  }
}
