export default function getUnits(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {

    case 'CREATE_UNIT_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'CREATE_UNIT_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null}};
      case 'RESET_CREATE_UNIT':
      return {...state, ...{success:null,validation:null}};
    default:
      return state;
  }
}
