import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {getPOSes} from ".././../apis/pos";

class POS extends Component {
  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showPrintModal: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray} = filters
    getPOSes(limit, page, searchArray)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getPOSes
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    } = prevState;

    if (getPOSes) {
      let {success, error} = getPOSes;
      if (success) {
        console.log(success)
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    };
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  newAction() {
    this.props.history.push('/pos/new')
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        searchText: 'name'
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            url: ['/pos/']
          },
          {
            type: 'navlink',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            url: ['/pos/', '/edit']
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        id, name, description
      } = single

      tableData.push({
        id, name, description
      })

    })

    return (
      <div className="animated fadeIn">
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          filters={this.filters}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Create POS"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getPOSes
  } = state;
  return {
    getPOSes
  };
}

export default connect(mapStateToProps)(POS)
