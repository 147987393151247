import React from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  InputGroup,
  InputGroupAddon, InputGroupText, ButtonGroup
} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import TablePagination from "../../shared/table-helpers/TablePagination";

const AddOrSelectProduct = (props) => {
  let {
    isOpen,
    toggle,
    products,
    checkedProducts,
    toggleForm,
    onToggleForm,
    serviceList,
    unitList,
    onCreateProduct,
    productError,
    onProductChange,
    product,
    to,
    from,
    total,
    prevPageUrl,
    nextPageUrl,
    currentPage,
    paginationButton,
    lastPage,
    searchChange,
    rowPressed,
    productFamilies
  } = props;

  let familyList = productFamilies && productFamilies.map((family) => {
    return (
      {
        label: family.name,
        value: family.id
      }
    )
  })

  if (toggleForm) {
    return (
      <Modal isOpen={isOpen} size="lg" toggle={toggle} className={'modal-lg ' + props.className}>
        <ModalHeader toggle={toggle}>Add Product or Service</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Form>
                <HorizontalTextField
                  name="name"
                  error={productError.name}
                  onChange={(e) => onProductChange(e)}
                  value={product.name}
                  isRequired={true}
                />
                <HorizontalSelect
                  name="type"
                  options={serviceList}
                  error={productError.type}
                  onChange={(e) => onProductChange(e)}
                  value={product.type}
                />
                <HorizontalSelect
                  name="unit_id"
                  options={unitList}
                  onChange={(e) => onProductChange(e)}
                  error={productError.unit_id}
                  value={product.unit_id}
                  isRequired={true}
                />
                <HorizontalTextField
                  name="sales_price"
                  onChange={(e) => onProductChange(e)}
                  error={productError.sales_price}
                  value={product.sales_price}
                  isRequired={true}
                />
                <HorizontalTextField
                  name="purchase_price"
                  onChange={(e) => onProductChange(e)}
                  error={productError.purchase_price}
                  value={product.purchase_price}
                  isRequired={true}
                />
                <HorizontalSelect
                  isRequired={true}
                  name="product_family_id"
                  options={familyList}
                  onChange={(e) => onProductChange(e)}
                  error={productError.product_family_id}
                  value={product.product_family_id}
                />
                <br/>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm="12">
              <ButtonGroup className="float-right">
                <Button outline color="danger" onClick={onToggleForm}>Cancel
                </Button>&nbsp;
                <Button outline color="success" onClick={onCreateProduct}>Save
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  } else {
    let tableBody = products && products.length > 0 ? products.map((product) => {
        let {id, name, code, type, sales_price, purchase_price, hs_code} = product;

        return (
          <tr key={id} onClick={() => rowPressed(name,product)}
              className={`table-row-checkbox ${checkedProducts.get("" + name) && 'table-row-checkbox-selected'}`}>
            <td>
              {code}
            </td>
            <td>
              {hs_code}
            </td>
            <td>
              {name}
            </td>
            <td>{sales_price}</td>
            <td>
              {purchase_price}
            </td>
            <td><span className={`badge ${type === "s" ? "badge-light" : "badge-secondary"}`}>
              {type === "s" ? "Service" : "Product"}
            </span></td>
          </tr>
        )
      }
    ) : (<tr>
      <td colSpan="10" align="center"><b>Empty Records</b></td>
    </tr>);

    return (
      <Modal isOpen={isOpen} size="lg" toggle={toggle} className={'modal-lg ' + props.className}>
        <ModalHeader>Add Product or Service</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12" md="6">
              <Button color="success" className="float-left" onClick={onToggleForm}>New
              </Button>
            </Col>
            <Col sm="12" md="6">
              <InputGroup>
                <Input
                  placeholder="Search by Product Name"
                  onChange={(e) => searchChange(e)}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <Table responsive>
                <thead>
                <tr>
                  <th width="140">Code</th>
                  <th width="100">HS Code</th>
                  <th width="100">Name</th>
                  <th width="120">Sales Price</th>
                  <th width="120">Purchase Price</th>
                  <th width="60">Type</th>
                </tr>
                </thead>
                <tbody>
                {tableBody}
                </tbody>
              </Table>
              <TablePagination
                to={to}
                from={from}
                total={total}
                prevPageUrl={prevPageUrl}
                nextPageUrl={nextPageUrl}
                currentPage={currentPage}
                paginationButton={paginationButton}
                lastPage={lastPage}
                hideInfo={true}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button color="success" onClick={toggle}>Done</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddOrSelectProduct;
