export default function inviteUser(
  state = {success: null, processing: "",validation: null },
  action
) {
  switch (action.type) {

    case 'INVITE_USER_SUCCESS':
      return {...state, ...{success: action.success,validation: null}};
    case 'RESET_INVITE_USER':
      return {...state, ...{success:null,validation: null}};
    case  'INVITE_USER_VALIDATION_ERROR':
      return {...state, ...{success:null,validation: action.validation}};
    default:
      return state;
  }
}
