import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap';
import {stringUpperCase} from '../helpers/GeneralHelpers';

const HorizontalSelect = (props) => {
  let {error, onChange, value, name, options, disabled, placeholder,displayName,isRequired, keyval} = props;
  let label = stringUpperCase(name);
  let option_label = keyval && keyval[0] ? keyval[0] : "label"
  let option_value = keyval && keyval[1] ? keyval[1] : "value"
  return (
    <FormGroup row>
      <Col md="3">
        <Label>{displayName ? displayName : label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <Input type="select" invalid={!!error} name={name} value={value} onChange={onChange} disabled={disabled} placeholder={placeholder}>
          <option value=""/>
          {options
            ? options.map((o, i) => {
              return (
                <option value={o[option_value]}>
                  {o[option_label]}
                </option>
              );
            })
            : null}
        </Input>
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};

export default HorizontalSelect;
