import React, {Component} from 'react'
import {Button, DatePicker, Icon, Input, Table} from "antd";
import {stringUpperCase} from "../helpers/GeneralHelpers";
import {Tag, Divider} from 'antd'
import {NavLink} from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap";
import csv from "../../assets/img/csv.svg";
import pdf from "../../assets/img/pdf.svg";
import xls from "../../assets/img/xls.svg";
import xml from "../../assets/img/xml.svg";
import txt from "../../assets/img/txt.svg";
import PaginationHelper from "./PaginationHelper";
import {Config} from "../../Config";
import store from "../../Store";
import {downloadFile} from "../../actions/download-info/download_file";


const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class TableHelpers extends Component {
  temp = {...this.props.temp}

  url = Config.BillingUrl + this.props.url + "/export"

  state = {
    data: [],
    searchIndex: []
  }

  componentDidMount() {
    if (this.props.filters)
      this.setState({
        data: [...this.props.filters.searchArray],
        searchIndex: [...this.props.filters.searchIndex]
      })
  }

  onDateChange = (date, dateString) => {
    let temp = {...this.props.temp}
    temp['startDate'] = dateString[0]
    temp['endDate'] = dateString[1]
    temp['tempFilter'][0] = dateString[0]
    temp['tempFilter'][1] = dateString[1]
    this.props.onDateConfirm(temp);
  }

  onDateReset = () => {
    let temp = {...this.props.temp}
    temp['startDate'] = null
    temp['endDate'] = null
    temp['tempFilter'][0] = null
    temp['tempFilter'][1] = null
    this.props.onDateConfirm(temp);
  }

  handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination)
    console.log(filters)
    console.log(sorter)
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.filters) {
      if (prevProps.filters !== this.props.filters) {
        this.setState({
          data: [...this.props.filters.searchArray],
          searchIndex: [...this.props.filters.searchIndex]
        })
      }
    }
  }

  onChange = (e) => {
    let {name, value} = e.target;
    let data = [...this.state.data];
    let searchIndex = this.state.searchIndex
    let position = searchIndex.indexOf(name)

    let a = {
      key: name,
      value: value
    }

    if (position !== -1) {
      data[position] = a
    } else {
      data.push(a)
      searchIndex.push(name)
    }
    this.setState({data, searchIndex});
  }

  searchText = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      let {data, searchIndex} = this.state
      let position = searchIndex.indexOf(dataIndex)
      let value = data[position] ? data[position]['value'] : ""
      return (
        <div style={{padding: 8}}>
          <Input
            name={dataIndex}
            placeholder={`Search by ${stringUpperCase(dataIndex)}`}
            value={value}
            onChange={e => this.onChange(e)}
            onPressEnter={() => this.handleSearch(value, confirm, dataIndex)}
            style={{width: 220, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(value, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </div>
      )
    },
    filterIcon: filtered => (
      <Icon type="search" style={{color: filtered ? '#4dbd74' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });

  searchDate = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <RangePicker
          format={dateFormat}
          onChange={(date, dateString) => {
            this.onDateChange(date, dateString)
          }}
          value={[this.temp.startDate, this.temp.endDate]}
          style={{width: 220, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, 'dateFilter')}
          icon="search"
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button onClick={() => this.onDateReset()} size="small" style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{color: filtered ? '#4dbd74' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filters = {...this.props.filters}
    let searchArray = [...filters.searchArray]
    let a = {
      key: dataIndex,
      value: selectedKeys
    }

    let searchIndex = filters.searchIndex
    let position = searchIndex.indexOf(dataIndex)
    if (position >= 0) {
      searchArray[position] = a
    } else {
      searchArray.push(a)
      searchIndex.push(dataIndex)
    }

    filters['searchArray'] = searchArray
    filters['searchIndex'] = searchIndex
    this.props.confirmSearch(filters, selectedKeys)
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = {...this.props.filters}
    let searchArray = [...filters.searchArray]
    let searchIndex = filters.searchIndex
    let position = searchIndex.indexOf(dataIndex)
    if (position !== -1) {
      searchArray.splice(position, 1)
      searchIndex.splice(position, 1)
      filters['searchArray'] = searchArray
      filters['searchIndex'] = searchIndex
      this.props.confirmSearch(filters, '')
    }
  };

  onCsv() {
    store.dispatch(downloadFile(this.props.filters.dateFilter, 'csv', this.props.url, this.props.url, this.props.reportType, this.url, this.props.filters.searchArray))
  }

  onPdf() {
    store.dispatch(downloadFile(this.props.filters.dateFilter, 'pdf', this.props.url, this.props.url, this.props.reportType, this.url, this.props.filters.searchArray))
  }

  onXls() {
    store.dispatch(downloadFile(this.props.filters.dateFilter, 'xls', this.props.url, this.props.url, this.props.reportType, this.url, this.props.filters.searchArray))
  }

  onXml() {
    store.dispatch(downloadFile(this.props.filters.dateFilter, 'xml', this.props.url, this.props.url, this.props.reportType, this.url, this.props.filters.searchArray))
  }

  onTxt() {
    store.dispatch(downloadFile(this.props.filters.dateFilter, 'txt', this.props.url, this.props.url, this.props.reportType, this.url, this.props.filters.searchArray))
  }

  render() {
    let {
      paginationButton,
      to,
      from,
      total,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      lastPage,
      columns,
      tableData,
      newTitle,
      newAction,
      hideDownload,
      hideNewCard,
      hideTable,
      tabTable,
      statistics,
    } = this.props

    let tableColumns = []

    columns && columns.forEach((single) => {
      let {title, dataIndex, searchText, searchDate, variant_text, action_links, contact, image} = single

      let temp = {
        title,
        dataIndex
      }

      if (searchText) {
        temp = {
          title,
          dataIndex,
          ...this.searchText(searchText)
        }
      } else if (searchDate) {
        temp = {
          title,
          dataIndex,
          ...this.searchDate(searchDate)
        }
      }

      if (variant_text) {
        temp = {
          title,
          dataIndex,
          render: one => {
            let variant_length = variant_text.length
            let color = ""
            let label = ""
            while (variant_length > 0) {
              if (variant_text[variant_length - 1]['key'] || variant_text[variant_length - 1]['key'] === 0) {
                if (one === variant_text[variant_length - 1]['key']) {
                  color = variant_text[variant_length - 1]['value']
                  label = variant_text[variant_length - 1]['label']
                  break;
                }
              } else if (variant_text[variant_length - 1]['check']) {
                if (one) {
                  if (variant_text[variant_length - 1]['value'] !== "red") {
                    color = variant_text[variant_length - 1]['value']
                    label = variant_text[variant_length - 1]['label']
                  }
                } else {
                  if (variant_text[variant_length - 1]['value'] === "red") {
                    color = variant_text[variant_length - 1]['value']
                    label = variant_text[variant_length - 1]['label']
                  }
                }
              }
              variant_length--
            }
            return (
              <span>
                <Tag color={color}>
                  {label}
                </Tag>
              </span>
            );
          }
        }
      }

      if (action_links) {
        temp = {
          title: 'Actions',
          render: (text, record) => {
            let buttonsActions = action_links.map((one) => {
              let {
                type,
                icon,
                twoToneColor,
                title,
                url,
                onClick,
                check,
                simple,
                ifShow,
                key
              } = one
              if (ifShow) {
                if (!record[key]) {
                  if (type === "navlink") {
                    let tempUrl = url[0] + record.id
                    if (url.length > 1) {
                      tempUrl += url[1]
                    }
                    return (
                      <>
                        <NavLink to={tempUrl}>
                          <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}/>
                        </NavLink>
                        <Divider type="vertical"/>
                      </>
                    )
                  } else if (type === "button") {
                    if (simple) {
                      return (
                        <>
                          <Icon type={icon} title={title}
                                onClick={() => onClick(record.id)}/>
                          <Divider type="vertical"/>
                        </>
                      );
                    } else if (check) {
                      if (!record[check]) {
                        return (
                          <>
                            <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}
                                  onClick={() => onClick(record.id)}/>
                            <Divider type="vertical"/>
                          </>
                        );
                      }
                    } else {
                      return (
                        <>
                          <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}
                                onClick={() => onClick(record.id, title)}/>
                          <Divider type="vertical"/>
                        </>
                      );
                    }
                  }
                }
              } else {
                if (type === "navlink") {
                  let tempUrl = url[0] + record.id
                  if (url.length > 1) {
                    tempUrl += url[1]
                  }
                  return (
                    <>
                      <NavLink to={tempUrl}>
                        <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}/>
                      </NavLink>
                      <Divider type="vertical"/>
                    </>
                  )
                } else if (type === "button") {
                  if (simple) {
                    return (
                      <>
                        <Icon type={icon} title={title}
                              onClick={() => onClick(record.id)}/>
                        <Divider type="vertical"/>
                      </>
                    );
                  } else if (check) {
                    if (!record[check]) {
                      return (
                        <>
                          <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}
                                onClick={() => onClick(record.id)}/>
                          <Divider type="vertical"/>
                        </>
                      );
                    }
                  } else {
                    return (
                      <>
                        <Icon type={icon} theme="twoTone" twoToneColor={twoToneColor} title={title}
                              onClick={() => onClick(record.id, title)}/>
                        <Divider type="vertical"/>
                      </>
                    );
                  }
                }
              }
              return null
            })
            return buttonsActions
          }
        }
      }

      if (contact) {
        temp = {
          title,
          dataIndex,
          render: (text, record) => {
            let {phone, email, address} = record.contact
            return (
              <>
                {phone.length > 0 && <>
                  <Icon type="phone"/>
                  <Divider type="vertical"/>
                  <span>{phone}</span>
                  <br/>
                </>}
                {email.length > 0 && <>
                  <Icon type="mail"/>
                  <Divider type="vertical"/>
                  <span>{email}</span>
                  <br/>
                </>}
                {address.length > 0 && <>
                  <Icon type="heat-map"/>
                  <Divider type="vertical"/>
                  <span>{address}</span>
                </>}
              </>
            )
          }
        }
      }

      if (image) {
        temp = {
          title,
          dataIndex,
          render: (text, record) => (
            <img src={record.image} alt={record.name} className="img img-company"/>
          )
        }
      }

      tableColumns.push(temp)
    })

    if (tabTable) {
      return (
        <>
          <Row>
            <Col className="text-right">
              {newTitle &&
              <Button className="new-button" onClick={newAction}>{newTitle}</Button>}
            </Col>
          </Row>
          {newTitle && <br/>}
          <div style={{'overflow': 'auto'}}>
            <Table
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
              onChange={this.handleTableChange.bind(this)}
              scroll={{x: true}}
            />
          </div>
          <PaginationHelper
            to={to}
            from={from}
            total={total}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            currentPage={currentPage}
            paginationButton={paginationButton}
            lastPage={lastPage}
          />
        </>
      )
    } else {
      return (
        <>
          {!hideNewCard &&
          <Card>
            <CardBody>
              <Row>
                <Col sm="12" md="9">
                  {!hideDownload && <>
                    <label className="text-bold">Export Report: </label>
                    <img src={csv} alt="CSV" className="report-download-icon" onClick={this.onCsv.bind(this)}/>
                    <img src={pdf} alt="PDF" className="report-download-icon" onClick={this.onPdf.bind(this)}/>
                    <img src={xls} alt="XLS" className="report-download-icon" onClick={this.onXls.bind(this)}/>
                    <img src={xml} alt="XML" className="report-download-icon" onClick={this.onXml.bind(this)}/>
                    <img src={txt} alt="TXT" className="report-download-icon" onClick={this.onTxt.bind(this)}/>
                  </>}
                </Col>
                <Col sm="12" md="3" className="text-right">
                  {newTitle &&
                  <Button className="new-button" onClick={newAction}>{newTitle}</Button>}
                </Col>
              </Row>
            </CardBody>
          </Card>
          }
          {!hideTable &&
          <Card>
            <CardBody>
              {statistics && <Row><Col sm="6">
                <span className="form-span-label">Total Data :</span> <span
                className="form-span-value text-success">{statistics.count}</span>
              </Col>
                <Col sm="6" className="text-right">
                  <span className="form-span-label">Total Amount :</span> <span
                  className="form-span-value text-success">{statistics.total}</span>
                </Col>
              </Row>}
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                onChange={this.handleTableChange.bind(this)}
              />
              <PaginationHelper
                to={to}
                from={from}
                total={total}
                prevPageUrl={prevPageUrl}
                nextPageUrl={nextPageUrl}
                currentPage={currentPage}
                paginationButton={paginationButton}
                lastPage={lastPage}
              />
            </CardBody>
          </Card>
          }
        </>
      );
    }
  }
}

export default TableHelpers
