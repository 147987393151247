import React, {Component} from 'react'
import logo from "../../assets/img/brand/89_25.png";
import {Col} from "reactstrap";

class SignUpSuccess extends Component {
  state = {
    message: ""
  }

  componentDidMount() {

    if (this.props.location.state)
      if (this.props.location.state.message)
        this.setState({message: this.props.location.state.message})
      else
        this.props.history.push('/')
    else
      this.props.history.push('/')
  }

  goLogin(){
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="row justify-content-center" style={{background: '#ffffff', minHeight: '100vh'}}>
        <Col md="9" sm="12">
          <div className="right-side-div">
            <div>
              <img src={logo} className="right-side-payment-logo" alt="logo"/>
            </div>
            <div>
              <p className="right-side-detail text-center">
                {this.state.message}
              </p>
            </div>
            <hr/>
            <div>
              <p className="form-span-label text-center confirmation-texttext-center">
                Go back to <span className="span-link" onClick={this.goLogin.bind(this)}>login</span> screen.
              </p>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default SignUpSuccess
