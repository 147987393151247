import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardBody, CardFooter, Col, FormFeedback, FormGroup, Input, Row} from "reactstrap";
import HorizontalTextField from "../../shared/form-helpers/HorizontalTextField";
import HorizontalSelect from "../../shared/form-helpers/HorizontalSelect";
import {connect} from "react-redux";
import HorizontalSelectSearch from "../../shared/form-helpers/HorizontalSelectSearch";
import HorizontalToggle from "../../shared/form-helpers/HorizontalToggle";
import {createInvoiceTemplate, getInvoiceTemplate, updateInvoiceTemplate} from "../../apis/invoice-templates";
import {useDidMountEffect} from "../../shared/helpers/GeneralHelpers";
import {Config} from "../../Config";
import store from "../../Store";
import {addMessage} from "../../actions/messages/success_message";

const resetData = {
  name: "",
  filename: "",
  status: 1,
  type: ""
}

function NewInvoiceTemplate(props) {
  let id = props.match.params.id
  const [data, setData] = useState({status: 1})
  const [error, setError] = useState({})

  useEffect(() => {
    fetchData()
  }, [])

  useDidMountEffect(() => {
    let {success, validation} = props.createInvoiceTemplate
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Invoice Template Created"))
        props.history.goBack()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.createInvoiceTemplate])

  useDidMountEffect(() => {
    let {success, validation} = props.updateInvoiceTemplate
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Invoice Template Updated"))
        props.history.goBack()
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.updateInvoiceTemplate])

  useDidMountEffect(() => {
    let {success, validation} = props.getInvoiceTemplate
    if (success) {
      if (success.data)
        setData(success.data)
    } else if (validation) {
      setError(validation)
    }
  }, [props.getInvoiceTemplate])

  function fetchData() {
    if (id) {
      getInvoiceTemplate(id)
    }
  }

  const onCancel = () => {
    props.history.goBack()
  }
  const onReset = () => {
    if (id)
      fetchData()
    else
      setData({...resetData})
  }
  const onSubmit = () => {
    if (id) {
      updateInvoiceTemplate(id, data)
    } else
      createInvoiceTemplate(data)
  }

  function onChange(e) {
    let {name, value} = e.target
    setData({...data, [name]: value})
    setError({...error, [name]: ""})
  }

  const toggleSwitch = () => {
    setData({
      ...data, status: !data["status"]
    })
  }


  return (
    <div className="animated fadeIn">
      <Card className="top-page-content">
        <CardBody>
          <Row>
            <Col sm="2"></Col>
            <Col sm="7">
              <Row>
                <Col xs="12">
                  <HorizontalTextField
                    name="name"
                    value={data.name}
                    onChange={(e) => onChange(e)}
                    error={error.name}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalTextField
                    name="filename"
                    value={data.filename}
                    onChange={(e) => onChange(e)}
                    error={error.filename}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalSelect
                    name="type"
                    value={data.type}
                    onChange={(e) => onChange(e)}
                    error={error.type}
                    options={[{label: "Invoice", value: "I"}, {label: "Credit Note", value: "C"}]}
                  />
                </Col>
                <Col xs="12">
                  <HorizontalToggle
                    name="status"
                    status={data["status"]}
                    onChange={toggleSwitch}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="3"></Col>
          </Row>

        </CardBody>
        <CardFooter>
          <ButtonGroup className="float-right">
            <Button outline color="danger" onClick={onCancel}>Cancel</Button>
            <Button outline color="primary" onClick={onReset}>Reset</Button>
            <Button outline color="success" onClick={onSubmit}>Save</Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  );
}

function mapStateToProps(state) {
  let {
    createInvoiceTemplate,
    getInvoiceTemplate,
    updateInvoiceTemplate
  } = state

  return {
    createInvoiceTemplate,
    getInvoiceTemplate,
    updateInvoiceTemplate
  }
}

export default connect(mapStateToProps)(NewInvoiceTemplate);

