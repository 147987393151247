import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addMessage} from "../../actions/messages/success_message";
import {setFetch} from "../../shared/helpers/FilterHelper";
import {getPOS, updatePOS, updateResetPOS} from "../../apis/pos";

class EditPOS extends Component {

  data = {
    name: "",
    description: ""
  }

  error = {}
  state = {
    data: {...this.data},
    error: {...this.error},
    restError: null,
    updateSuccess: null,
    restData: null
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onCancel() {
    this.props.history.push("/pos")
  }

  onReset() {
    this.setState({
      data: {...this.data},
      error: {...this.error}
    })
  }

  onSubmit() {
    updatePOS(this.props.match.params.id,this.state.data)
  }

  componentDidMount() {
    getPOS(this.props.match.params.id)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {updatePOS, getPOS} = nextProps
    let {restError, updateSuccess, restData} = prevState
    if (updatePOS) {
      let {success, validation} = updatePOS
      if (success) {
        updateSuccess = success.data.message
      } else if (validation) {
        restError = validation
      } else {
        restError = null
        updateSuccess = null
      }
    }

    if (getPOS) {
      let {success} = getPOS
      if (success) {
        restData = success.data
      }
    }

    return {
      restError, updateSuccess, restData
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      this.setState({
          error: this.state.restError
        },
        () => {
          updateResetPOS()
        })
    }

    if (this.state.updateSuccess !== null) {
      store.dispatch(addMessage(this.state.updateSuccess, "success", "Update POS"));
      updateResetPOS()
      setFetch(true)
      this.props.history.push('/pos')
    }

    if (this.state.restData !== prevState.restData) {
      this.data = this.state.restData
      this.setState({
        data: this.data,
      })
    }
  }

  render() {
    let {data, error} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col md="2" sm="12"><span className="form-left-span"><strong>Basic</strong></span></Col>
              <Col md="10" sm="12">
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Name</span>&nbsp;<span className="text-danger">*</span>
                    <FormGroup>
                      <Input invalid={!!error.name} className="form-control"
                             name="name" value={data.name}
                             onChange={e => this.onChange(e)}/>
                      <FormFeedback>{error.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <span className="form-span-label">Description</span>
                    <FormGroup>
                      <Input invalid={!!error.description} className="form-control" name="description"
                             onChange={e => this.onChange(e)} value={data.description}/>
                      <FormFeedback>{error.description}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button outline color="danger" onClick={this.onCancel.bind(this)}>Cancel</Button>
              <Button outline color="primary" onClick={this.onReset.bind(this)}>Reset</Button>
              <Button outline color="success" onClick={this.onSubmit.bind(this)}>Save</Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {updatePOS, getPOS} = state
  return {
    updatePOS, getPOS
  }
}

export default connect(mapStateToProps)(EditPOS);
