export default function convertToInvoice(
  state = { error: null, success: null},
  action
) {
  switch (action.type) {

    case 'CONVERT_TO_INVOICE_SUCCESS':
      return {...state, ...{success: action.success,error: null}};
    case 'CONVERT_TO_INVOICE_ERROR':
      return {...state, ...{error: action.error,success: null}};
    default:
      return state;
  }
}
