export default function createInvoice(
  state = { validation: null, success: null},
  action
) {
  switch (action.type) {
    case 'CREATE_INVOICES_SUCCESS':
      return {...state, ...{success: action.success, validation: null}};
    case 'CREATE_INVOICES_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation, success: null}};
    case 'CREATE_RESET_INVOICES':
      return {...state, ...{success:null,validation:null}};
    default:
      return state;
  }
}
