import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import {connect} from "react-redux";
import store from "../../Store";
import {addMessage, addOuterMessage, deleteSuccessMessage} from "../../actions/messages/success_message";
import {getLoginRequest, resetLoginRequest} from "../../actions/login/login_request";
import IconTextField from "../../shared/form-helpers/IconTextField";

class Login extends Component {

  state = {
    data: {
      email: "",
      password: ""
    },
    message: {type: ``, message: ``, title: ``},
    error: {
      email: "",
      password: ""
    },
    localValidationError: false,
    restError: null,
    validationError: null,
    visible: true,
  };

  validationRule = {
    email: ["required"],
    password: ["required", "int"]
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getLoginRequest} = nextProps;
    let {restError, validationError} = prevState;
    if (getLoginRequest) {
      let {success,error} = getLoginRequest;
      if (error) {
        if (error.status === 422) {
          restError = error.data;
        } else {
          validationError = error.data.message;
        }
      } else if (success) {
        localStorage.setItem("ERP_Bearer", success.data.access_token);
        localStorage.setItem("ERP_rt", success.data.refresh_token);
        window.location.href = (`/dashboard`);
      }
      else {
        restError = null
        validationError = null
      }
    }
    return {
      restError,
      validationError
    };
  }

  componentDidMount() {
    if (this.props.successMessage.message !== "") {
      this.setState(
        {
          message: {
            type: `success`,
            message: this.props.successMessage.message,
            title: `success`
          }
        },
        store.dispatch(deleteSuccessMessage())
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== null) {
      let error = this.state.restError;
      this.setState({
        error
      },()=>{
        store.dispatch(resetLoginRequest());
      });
    }
    if (this.state.validationError !== null) {
      store.dispatch(addOuterMessage(this.state.validationError, "error", "Unauthorised"));
      this.setState({
        message: {
          type: `error`,
          message: this.state.validationError
        }
      },()=>{
        store.dispatch(resetLoginRequest());
      });
    }
  }

  keyPress(e){
    if(e.keyCode === 13){
      this.onSubmitHandler(e)
    }
  }

  onSubmitHandler() {
    store.dispatch(getLoginRequest(this.state.data));
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onDismiss() {
    this.setState({
      message: {type: `error`, message: ``},
    });
  }


  render() {
    const { data, error, message } = this.state;

    let { isOpen,toggle,signUpClick,forgotClick } = this.props

    return (
      <Modal isOpen={isOpen} size="md" toggle={toggle} className={'modal-md ' + this.props.className}>
        <ModalBody>
          <Card className="p-3 login-card" body>
            <CardHeader style={{background: 'transparent'}} className="text-center">
              SIGN IN
            </CardHeader>
            <CardBody>
              <Form>
                <IconTextField
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={e => this.onChange(e)}
                  error={error.email}
                  icon="fa fa-envelope"
                />
                <IconTextField
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={e => this.onChange(e)}
                  error={error.password}
                  icon=" fa fa-asterisk"
                  onKeyDown={this.keyPress.bind(this)}
                />
              </Form>
              <Row>
                <Col>
                  <Button color="success" size="lg" block
                          onClick={this.onSubmitHandler.bind(this)}>Sign In</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="justify-content-center">
            <span className="span-link" onClick={forgotClick}>Forgot Password?</span>
          </Row>
          <Row className="justify-content-center">
            <span className="text-muted">Don't have an account?</span> &nbsp;
            <span className="span-link" onClick={signUpClick}>Signup Now.</span>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  let {getLoginRequest, successMessage} = state;
  return {
    getLoginRequest,
    successMessage
  };
}

export default connect(mapStateToProps)(Login);

