import store from '../Store'
import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";
import {resetData} from "../actions/reset";

let prefix = "currencies"

export const getCurrencies = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_CURRENCIES_SUCCESS',
    error: 'FETCH_CURRENCIES_ERROR'
  }
  let group = new GetGroup(limit, page, filters, prefix, reducers)
  group.getGroup()
}

export const getCurrency = (id) => {
  let reducers = {
    success: 'FETCH_CURRENCY_SUCCESS',
    error: 'FETCH_CURRENCY_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const createCurrency = (data) => {
  let reducers = {
    success: 'CREATE_CURRENCY_SUCCESS',
    validation_error: 'CREATE_CURRENCY_VALIDATION_ERROR',
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteCurrency = (id) => {
  let reducers = {
    success: 'DELETE_CURRENCY_SUCCESS',
    error: 'DELETE_CURRENCY_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const updateCurrency = (id, data) => {
  let reducers = {
    success: 'UPDATE_CURRENCY_SUCCESS',
    validation_error: 'UPDATE_CURRENCY_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}

export const createResetCurrency = () => {
  store.dispatch(resetData('CREATE_RESET_CURRENCY'))
}

export const deleteResetCurrency = () => {
  store.dispatch(resetData('DELETE_RESET_CURRENCY'))
}

export const updateResetCurrency = () => {
  store.dispatch(resetData('UPDATE_RESET_CURRENCY'))
}
