export const NumberFormatter = (number) =>{
  number = number + ""
  let formattedNumber = ""
  let reversedString = reverseString(number)
  if (number.includes('.')){
    let num = reversedString.split('.')[1]
    for (let i = 0 ; i < num.length; i++){
      formattedNumber += num.charAt(i)
      if (i % 2 === 0 && i !== 0 && num.charAt(i+1))
        formattedNumber += ","
    }
  }
  else {
    let num = reversedString
    for (let i = 0 ; i < num.length; i++){
      formattedNumber += num.charAt(i)
      if (i % 2 === 0 && i !== 0 && num.charAt(i+1))
        formattedNumber += ","
    }
  }

  let returnString = reverseString(formattedNumber)
  if (number.split('.')[1]) {
    returnString += "."
    returnString += number.split('.')[1]
  }
  return returnString
}


function reverseString(str) {
  return str.split("").reverse().join("");
}
