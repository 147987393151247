import React from 'react';
import {connect} from "react-redux";
import { Document, Page, pdfjs } from 'react-pdf';
import {Config} from "../../Config";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.js`


class PrintInvoice extends React.Component {

  state = {
    data: {},
    error: {},
    numPages: null,
    pageNumber: 1,
    url: ""
  }

  componentDidMount(){
    // this.fetchData();
    this.setState({
      url: Config.BillingUrl +this.props.match.params.id+ "/print"
    })
  }

  fetchData(){
    // store.dispatch(getInvoice(this.props.match.params.id))
  }

  static getDerivedStateFromProps(nextProps,prevState){
    let {getInvoice} = nextProps;
    let { data } = prevState
    if (getInvoice){
      let {success} = getInvoice
      if (success){
        data = success.data
      }
    }
    return {data}
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    // this.setState({ numPages });
  }

  render() {

    const { pageNumber } = this.state;

    return (
      <div>
        <Document
          file={{url:this.state.url}}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    );
  }
}


function mapStateToProps(state) {
  let {successMessage, getInvoice} = state
  return {
    successMessage, getInvoice
  }
}

export default connect(mapStateToProps)(PrintInvoice);
