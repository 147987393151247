import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

const DeleteModal = (props) => {
  let {open,toggle, deleteItem, title,isUpdate,disable} = props
  return (
    <Modal isOpen={open} size="md" toggle={toggle} className={'modal-lg ' + props.className}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>Please confirm to {disable ? 'disable' : isUpdate ? 'update' : 'delete'}.</ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button outline color={isUpdate?'success':'danger'} className="float-right" onClick={deleteItem}>{disable ? 'Disable' : isUpdate ? 'Update' : 'Delete'}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteModal;
