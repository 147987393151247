import React, {Component} from 'react'
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {connect} from "react-redux";
import {Config} from "../../Config";
import PrintInvoiceModal from "../Invoice/PrintInvoiceModal";
import {getVendors} from "../../apis/vendors";

class Vendors extends Component {
  temp = {
    startDate: null,
    endDate: null,
    tempFilter: [null, null]
  }

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null,
    data: [],
    showPrintModal: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let {filters} = this.state
    let {limit, page, searchArray, dateFilter} = filters
    getVendors(limit, page, searchArray, dateFilter)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getVendors,
    } = nextProps;
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage,
    } = prevState;

    if (getVendors) {
      let {success, error} = getVendors;
      if (success) {
        data = success.data.data || [];
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1;
      } else if (error) {
      }
    }

    return {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage,
      firstPage
    };
  }

  onDateConfirm(temp) {
    this.temp = temp
    let filters = {...this.state.filters}
    filters['startDate'] = this.temp.startDate
    filters['endDate'] = this.temp.endDate
    filters['dateFilter'] = {
      start_date: this.temp.tempFilter[0],
      end_date: this.temp.tempFilter[1]
    }
    this.setState({
      filters
    }, () => {
      this.fetchData()
    })
  }

  confirmSearch(filters, key) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  printIcon(id) {
    this.setState({
      url: Config.BillingUrl + 'purchase-returns/' + id + "?q=print",
      showPrintModal: true,
      cus_code: id
    })
  }

  paginationButton(page) {
    let filters = {...this.state.filters}
    filters['page'] = page
    this.setState({filters}, () => {
      this.fetchData()
    })
  }

  togglePrintModal() {
    this.setState({
      showPrintModal: !this.state.showPrintModal
    })
  }

  newAction() {
    this.props.history.push('/purchases/vendors/new')
  }

  render() {
    let {
      data,
      prevPageUrl,
      nextPageUrl,
      currentPage,
      from,
      to,
      total,
      lastPage
    } = this.state

    const columns = [
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
        searchText: 'vendor_name'
      },
      {
        title: 'Vendor Code',
        dataIndex: 'vendor_code'
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        contact: true
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        searchDate: 'dateFilter'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        variant_text: [
          {
            key: 1,
            value: 'green',
            label: 'Active'
          },
          {
            key: 0,
            value: 'red',
            label: 'Inactive'
          }
        ]
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            url: ['/purchases/vendors/']
          },
          {
            type: 'navlink',
            icon: 'edit',
            twoToneColor: "#4dbd74",
            title: 'Edit',
            url: ['/purchases/vendors/','/edit']
          }
        ]
      }
    ]

    let tableData = []

    data.forEach((single) => {
      let {
        id, first_name, last_name, phone_no, mobile_no, email, address, city, country, status, vendor_code,pan,created_at
      } = single

      tableData.push({
        id,
        vendor_name: `${first_name} ${last_name}`,
        vendor_code,
        pan: pan || '-',
        created_at,
        contact: {
          phone: `${phone_no}${phone_no && ','}${mobile_no}`,
          email: email || '',
          address: `${address}${address && ','}${city}${city && ','}${country.name}`
        },
        status
      })

    })

    return (
      <div className="animated fadeIn">
        <PrintInvoiceModal
          isOpen={this.state.showPrintModal}
          toggle={this.togglePrintModal.bind(this)}
          url={this.state.url}
          customer_code={this.state.cus_code}
          email={false}
        />
        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.filters}
          onDateConfirm={this.onDateConfirm.bind(this)}
          confirmSearch={this.confirmSearch.bind(this)}
          newTitle="Create Vendor"
          newAction={this.newAction.bind(this)}
          hideDownload={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    getVendors,
  } = state;
  return {
    getVendors,
  };
}

export default connect(mapStateToProps)(Vendors)
