import store from '../Store'
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {resetData} from "../actions/reset";
import {GetGroup} from "../actions/crud/get_group";

let prefix = "fiscal-year"

export const createFiscalYear = (data) => {
  let reducers = {
    success: 'CREATE_FISCAL_YEAR_SUCCESS',
    validation_error: 'CREATE_FISCAL_YEAR_VALIDATION_ERROR',
    taken_error: 'CREATE_FISCAL_YEAR_TAKEN_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const currentFiscalYear = () => {
  let reducers = {
    success: 'FETCH_CURRENT_FISCAL_YEAR_SUCCESS',
    error: 'FETCH_CURRENT_FISCAL_YEAR_ERROR'
  }
  let get = new Get(prefix, reducers)
  get.get()
}

export const getFiscalYears = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_FISCAL_YEARS_SUCCESS',
    error: 'FETCH_FISCAL_YEARS_ERROR'
  }
  let url = prefix + `/list`
  let group = new GetGroup(limit, page, filters, url, reducers)
  group.getGroup()
}

export const resetCreateFiscalYear = () => {
  store.dispatch(resetData('RESET_CREATE_FISCAL_YEAR'))
}
