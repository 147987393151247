export default function updateCustomer(
  state = { error: null, success: null, validation: null },
  action
) {
  switch (action.type) {

    case 'UPDATE_CUSTOMER_SUCCESS':
      return {...state, ...{success: action.success,error: null}};
    case 'UPDATE_CUSTOMER_ERROR':
      return {...state, ...{error: action.error.response,success: null}};
      case 'UPDATE_CUSTOMER_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null,error: null}};
    case 'UPDATE_RESET_CUSTOMER':
      return {...state, ...{success:null,error:null,validation: null}};
    default:
      return state;
  }
}
