export default function deleteProformaInvoice(
  state = { error: null, success: null},
  action
) {
  switch (action.type) {

    case 'DELETE_PROFORMA_INVOICE_SUCCESS':
      return {...state, ...{success: action.success,error: null}};
    case 'DELETE_PROFORMA_INVOICE_ERROR':
      return {...state, ...{error: action.error,success: null}};
    case 'DELETE_RESET_PROFORMA_INVOICE':
      return {...state, ...{success:null,error:null}};
    default:
      return state;
  }
}
