import React from 'react'
import {Col, Row} from "reactstrap";

const ReportColor = (props) =>{
  let {
    isPurchase
  } = props
  return (
    <Row>
      <Col className="text-center">
        <span className="active-report report-active"></span>
        <span className="active-report-color-label">Active {isPurchase ? "Purchase" : "Sales"}</span>
        <span className="active-report report-return"></span>
        <span className="active-report-color-label">{isPurchase ? "Purchase" : "Sales"} Return</span>
        <span className="active-report report-return-indicator"></span>
        <span className="active-report-color-label">{isPurchase ? "Purchase" : "Sales"} Return Indicator</span>
      </Col>
    </Row>
  );
}

export default ReportColor
