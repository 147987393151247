export default function getLoggedInCompany(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'FETCH_LOGGED_IN_COMPANY_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'FETCH_LOGGED_IN_COMPANY_ERROR':
      console.log(action.error)
      return {...state, ...{error: action.error.response}};
    case 'FETCH_LOGGED_IN_COMPANY_PROCESSING':
      return {...state, ...{processing: action.processing}};
    default:
      return state;
  }
}
