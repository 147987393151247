export default function refreshToken(
  state = { expired: null ,initial: true, loggedIn: null,initiate: false},
  action
) {
  switch (action.type) {
    case "TOKEN_EXPIRED":
      return { ...state, ...{ expired: true } };
    case "NOT_LOGGED_IN":
      return { ...state, ...{ loggedIn: false } };
    case "RESET_REFRESH":
      return { ...state, ...{ expired: null ,initial: null} };
    case "START_REFRESH":
      return { ...state, ...{ initiate: true } };
    case "END_REFRESH":
      return { ...state, ...{ initiate: false } };
    default:
      return state;
  }
}
