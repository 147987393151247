import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap"
import store from '../../Store'
import {addSuccessMessage} from "../../actions/messages/success_message";
import ViewData from "../../shared/form-helpers/ViewData";
import {ArrayValue} from "../../shared/helpers/ArrayKey";
import {getCountries} from "../../apis/countries";
import {getVendor} from "../../apis/vendors";

class ViewVendor extends Component {

  data = {
    type: "C",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    website: "",
    address: "",
    mobile_no: "",
    zip_code: "",
    state: "",
    country_id: ""
  }

  error = {}
  state = {
    company: true,
    data: {...this.data},
    error: {...this.error},
    restError: null,
    restData: null,
    countries: [],
    provinces: [],
    isSuccess: null,
    processing: false
  }

  fetchData() {
    getCountries(1000)
    getVendor(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData();
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  onEdit() {
    let id = this.props.match.params.id
    this.props.history.push("/purchases/vendors/" + id + "/edit")
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {updateVendor, getCountries, getVendor} = nextProps
    let {restError, countries, provinces, restData, isSuccess, processing} = prevState
    if (updateVendor) {
      let {success, error} = updateVendor
      if (error) {
        if (error.status === 422) {
          restError = error.data
        }
      } else if (success) {
        isSuccess = success.data.message
      }
    }

    if (getCountries) {
      let {success, error} = getCountries
      if (success) {
        let temp = [];
        countries = success.data
        countries.forEach((country) => {
          temp[country.id] = country.provinces
        })
        provinces = temp
      } else if (error) {
        console.log(error)
      }
    }

    if (getVendor) {
      let {success, error} = getVendor
      if (success) {
        restData = success.data
      } else if (error) {
        console.log(error)
      }
      processing = getVendor.processing
    }
    return {
      restError, countries, provinces, restData, isSuccess, processing
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.restError !== prevState.restError) {
      this.setState({
        error: this.state.restError
      })
    }

    if (this.state.restData !== prevState.restData) {
      this.data = this.state.restData
      this.setState({
        data: this.data
      })
    }

    if (this.state.isSuccess !== prevState.isSuccess) {
      store.dispatch(addSuccessMessage(this.state.isSuccess))
      this.props.history.push('/sales/customers');
    }
  }

  render() {
    let {data, countries, provinces} = this.state

    return (
      <div className="animated fadeIn">
        <Card className="top-page-content">
          <CardHeader>
            <ButtonGroup className="float-right">
              <Button color="success" onClick={this.onEdit.bind(this)}>Edit</Button>
            </ButtonGroup>
          </CardHeader>
          <CardBody>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Basic Information</strong></span></Col>
              <Col sm="12" md="9">

                <Row>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Vendor Name"
                      data={data.last_name}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ViewData
                      label="Vendor Code"
                      data={data.vendor_code}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Contact Information</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  {data.phone_no &&
                  <Col sm="12" md="6">
                    <span className="form-span-label">Phone Number</span>
                    <FormGroup>
                      <span className="form-span-value">{data.phone_no}</span>
                    </FormGroup>
                  </Col>}
                  {data.mobile_no &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Mobile Number"
                      data={data.mobile_no}
                    />
                  </Col>}
                </Row>
                <Row>
                  {data.email &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Email"
                      data={data.email}
                    />
                  </Col>}
                  {data.website &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Website"
                      data={data.website}
                    />
                  </Col>
                  }
                </Row>
              </Col>
            </Row>
            <hr/>
            <Row style={{paddingRight: '10%'}}>
              <Col sm="12" md="3"><span className="form-left-span"><strong>Address</strong></span></Col>
              <Col sm="12" md="9">
                <Row>
                  {data.address &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Billing Address"
                      data={data.address}
                    />
                  </Col>
                  }
                  {data.city &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="City"
                      data={data.city}
                    />
                  </Col>
                  }
                </Row>
                <Row>
                  {data.country_id &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Country"
                      data={ArrayValue(countries, data.country_id, 'id', 'name')}
                    />
                  </Col>
                  }
                  {data.state &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="State"
                      data={ArrayValue(provinces[data.country_id], data.state, 'id', 'name')}
                    />
                  </Col>
                  }
                </Row>
                <Row>
                  {data.zip_code &&
                  <Col sm="12" md="6">
                    <ViewData
                      label="Postal / Zip Code"
                      data={data.zip_code}
                    />
                  </Col>
                  }
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {updateVendor, getCountries, getVendor} = state
  return {
    updateVendor, getCountries, getVendor
  }
}

export default connect(mapStateToProps)(ViewVendor);
