import React from 'react';
import {
  FormGroup,
  Input, Label,
} from 'reactstrap';
import {stringUpperCase} from "../helpers/GeneralHelpers";

const SelectBox = (props) => {
  let {error, onChange, value, name, options, placeholder,noLabel} = props;
  let label = stringUpperCase(name);
  return (
    <FormGroup>
      {noLabel ? "" :<Label className="custom-label">{label}</Label>}
      <Input type="select" invalid={!!error} name={name} value={value} onChange={onChange}
             placeholder={placeholder} className="custom-form-input">
        {options
          ? options.map((o, i) => {
            return (
              <option value={o["value"]}>
                {o["label"]}
              </option>
            );
          })
          : null}
      </Input>
    </FormGroup>
  );
};

export default SelectBox;
