import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label, CustomInput
} from 'reactstrap';
import {stringUpperCase} from '../helpers/GeneralHelpers';

const HorizontalCheckbox = (props) => {
  let {error, onChange, value = [], name, options, disabled, placeholder, displayName, isRequired} = props;
  let label = stringUpperCase(name);
  return (
    <FormGroup row>
      <Col md="3">
        <Label>{displayName ? displayName : label}{isRequired && <>&nbsp;<span
          className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <div>
        {
          options && options.map((o, i) => {
            return (
                <CustomInput type="checkbox"  name={name} value={o.id} onClick={onChange} disabled={disabled} checked={value.includes(o.id * 1)} id={`exampleCustomCheckbox-${name}-${o.id}-${o.name}`} label={o.name} invalid={!!error}/>
            )
          })
        }
        </div>
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};

export default HorizontalCheckbox;
