import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Label
} from 'reactstrap';
import {Switch} from 'antd';
import {stringUpperCase} from '../helpers/GeneralHelpers';

const HorizontalToggle = (props) => {
  let {error, name, onChange, status} = props;
  let label = stringUpperCase(name);
  return (
    <FormGroup row>
      <Col md="3">
        <Label>{label}</Label>
      </Col>
      <Col xs="12" md="9">
        <Switch
          checked={status}
          onChange={onChange}
        />
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};

export default HorizontalToggle;
