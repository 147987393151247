import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import SettingSelect from "../../shared/form-helpers/SettingSelect";
import store from "../../Store";
import {getLoggedInCompany} from "../../actions/companies/get_company";
import {
  getInvoiceTemplates
} from "../../apis/invoice-templates";
import {connect} from "react-redux";
import {useDidMountEffect} from "../../shared/helpers/GeneralHelpers";
import {addMessage} from "../../actions/messages/success_message";
import {updateCompanyCreditNoteTemplate, updateCompanyInvoiceTemplate} from "../../apis/company-invoice-template";

function InvoiceTemplate(props) {
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [invoiceTemplates, setInvoiceTemplates] = useState([])
  const [creditNoteTemplates, setCreditNoteTemplates] = useState([])

  useEffect(() => {
    store.dispatch(getLoggedInCompany())
    getInvoiceTemplates()
  }, [])

  useEffect(() => {
    let {success} = props.getLoggedInCompany
    if (success && success.data) {
      setData(success.data)
    }
  }, [props.getLoggedInCompany])

  useEffect(() => {
    let {success, error} = props.getInvoiceTemplates
    if (success && success.data) {
      let _invoiceTemplates = []
      let _creditNoteTemplates = []
      success.data.data.forEach((o) => {
        if (o.type && o.type.toUpperCase() === "I") {
          _invoiceTemplates.push(o)
        } else if (o.type && o.type.toUpperCase() === "C") {
          _creditNoteTemplates.push(o)
        }
      })
      setInvoiceTemplates(_invoiceTemplates)
      setCreditNoteTemplates(_creditNoteTemplates)
    } else if (error) {
      console.log(error)
    }
  }, [props.getInvoiceTemplates])

  useDidMountEffect(() => {
    let {success, validation} = props.updateCompanyInvoiceTemplate
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Invoice Template Updated"))
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.updateCompanyInvoiceTemplate])

  useDidMountEffect(() => {
    let {success, validation} = props.updateCompanyCreditNoteTemplate
    if (success) {
      if (success.data) {
        store.dispatch(addMessage(success.data.message, "success", "Invoice Template Updated"))
      }
    } else if (validation) {
      setError(validation)
    }
  }, [props.updateCompanyCreditNoteTemplate])

  function onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    setData({...data, [name]: value})
    setError({...error, [name]: ""})
  }

  const onUpdateInvoiceTemplate = () => {
    if (data["id"])
      updateCompanyInvoiceTemplate(data["id"], {invoice_template_id: data["invoice_template_id"]})
  }

  const onUpdateCreditNoteTemplate = () => {
    if (data["id"])
      updateCompanyCreditNoteTemplate(data["id"], {credit_note_template_id: data["credit_note_template_id"]})
  }


  let invoiceTemplateOptions = invoiceTemplates ? invoiceTemplates.map((o, i) => {
    if (o.name)
      return (
        <option value={o.id} key={i}>{o.name}</option>
      )
    else
      return null
  }) : []

  let creditNoteTemplateOptions = creditNoteTemplates ? creditNoteTemplates.map((o, i) => {
    if (o.name)
      return (
        <option value={o.id} key={i}>{o.name}</option>
      )
    else
      return null
  }) : []

  return (
    <>
      <Row>
        <Col>
          <Row className="custom-row">
            <Col sm="3">
                <span className="float-right span-label">
                  Invoice Template
                </span>
            </Col>
            <Col sm="9">
              <Row>
                <Col sm="12" md="6">
                  <div className="custom-profile">
                    <SettingSelect
                      label="Select Invoice Template"
                      name="invoice_template_id"
                      options={invoiceTemplateOptions}
                      value={data.invoice_template_id}
                      placeholder="Select Invoice Template"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Col>

                <Col sm="12" md="12">
                  <Button className="float-left" color="success" outline
                          onClick={onUpdateInvoiceTemplate}>Save</Button>
                </Col>
              </Row>
              <hr className="mb-0"/>
            </Col>
          </Row>
          <Row className="custom-row">
            <Col sm="3">
                <span className="float-right span-label">
                  Credit Note Template
                </span>
            </Col>
            <Col sm="9">
              <Row>
                <Col sm="12" md="6">
                  <div className="custom-profile">
                    <SettingSelect
                      label="Select Credit Note Template"
                      name="credit_note_template_id"
                      options={creditNoteTemplateOptions}
                      value={data.credit_note_template_id}
                      placeholder="Select Credit Note Template"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Col>

                <Col sm="12" md="12">
                  <Button className="float-left" color="success" outline
                          onClick={onUpdateCreditNoteTemplate}>Save</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  let {getLoggedInCompany, getInvoiceTemplates, updateCompanyInvoiceTemplate, updateCompanyCreditNoteTemplate} = state
  return {
    getLoggedInCompany, getInvoiceTemplates, updateCompanyInvoiceTemplate, updateCompanyCreditNoteTemplate
  }
}

export default connect(mapStateToProps)(InvoiceTemplate);
