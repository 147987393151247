import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";
import {Create} from "../actions/crud/create";
import {Remove} from "../actions/crud/remove";
import {Update} from "../actions/crud/update";

let prefix = "menus"

export const createMenu = (data) => {
  let reducers = {
    success: 'CREATE_MENU_SUCCESS',
    validation_error: 'CREATE_MENU_VALIDATION_ERROR'
  }
  let create = new Create(prefix, reducers,data)
  create.create()
}

export const deleteMenu = (id) => {
  let reducers = {
    success: 'DELETE_MENU_SUCCESS',
    error: 'DELETE_MENU_ERROR'
  }
  let url = prefix + `/${id}`
  let remove = new Remove(url, reducers)
  remove.remove()
}

export const getMenu = (id) => {
  let reducers = {
    success: 'FETCH_MENU_SUCCESS',
    error: 'FETCH_MENU_ERROR'
  }
  let url = prefix + `/${id}`
  let get = new Get(url, reducers)
  get.get()
}

export const getUserMenu = () => {
  let reducers = {
    success: 'FETCH_USER_MENU_SUCCESS',
    error: 'FETCH_USER_MENU_ERROR'
  }
  let url = prefix + `/-/role`
  let get = new Get(url, reducers)
  get.get()
}

export const getMenus = (limit, page = 1, filters = []) => {
  let reducers = {
    success: 'FETCH_MENUS_SUCCESS',
    error: 'FETCH_MENUS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const getAllMenus = (limit, page = 1, filters = []) => {
  limit = 1000
  page = 1
  let reducers = {
    success: 'FETCH_ALL_MENUS_SUCCESS',
    error: 'FETCH_ALL_MENUS_ERROR'
  }
  let getGroup = new GetGroup(limit, page, filters, prefix, reducers)
  getGroup.getGroup()
}

export const updateMenu = (id, data) => {
  let reducers = {
    success: 'UPDATE_MENU_SUCCESS',
    validation_error: 'UPDATE_MENU_VALIDATION_ERROR'
  }
  let url = prefix + `/${id}`
  let update = new Update(url, reducers, data)
  update.update()
}
