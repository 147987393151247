import {GetGroup} from "../actions/crud/get_group"

let prefix = "material-view"

export const getMaterialView = (limit, page = 1, filters = [],dateFilter={}) => {
  let reducers = {
    success: 'FETCH_MATERIAL_VIEW_SUCCESS',
    error: 'FETCH_MATERIAL_VIEW_ERROR'
  }
  let group =new GetGroup(limit, page, filters, prefix, reducers,dateFilter)
  group.getGroup()
}
