import React from 'react';
import {
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap';
import { stringUpperCase } from '../helpers/GeneralHelpers';

const HorizontalTextField = (props) => {
  let {type,error,onChange,value,name,disabled,min,isRequired, label} = props;
  label = label ? label :stringUpperCase(name);
  return(
    <FormGroup row>
      <Col md="3">
        <Label>{label}{isRequired && <>&nbsp;<span className="text-danger">*</span></>}</Label>
      </Col>
      <Col xs="12" md="9">
        <Input type={type} invalid={!!error} name={name} value={value} onChange={onChange} disabled={disabled} min={min}  className={type === "number" && "text-right"}/>
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};

export default HorizontalTextField;
