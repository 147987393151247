export default function updateProductFamily(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {

    case 'UPDATE_PRODUCT_FAMILY_SUCCESS':
      return {...state, ...{success: action.success, validation: null}};
    case 'UPDATE_PRODUCT_FAMILY_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null}};
    case 'UPDATE_RESET_PRODUCT_FAMILY':
      return {...state, ...{success:null,validation:null}};
    default:
      return state;
  }
}
