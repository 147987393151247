export default function createFiscalYear(
  state = { validation: null, success: null, gateway: null, taken: null },
  action
) {
  switch (action.type) {

    case 'CREATE_FISCAL_YEAR_SUCCESS':
      return {...state, ...{success: action.success,validation:null,gateway:null, taken: null}};
    case 'CREATE_FISCAL_YEAR_VALIDATION_ERROR':
      return {...state, ...{validation: action.validation,success: null,gateway:null, taken: null}};
    case 'CREATE_FISCAL_YEAR_TAKEN_ERROR':
      return {...state, ...{validation: null,success: null,gateway:null, taken: action.taken}};
    case 'CREATE_FISCAL_YEAR_GATEWAY_ERROR':
      return {...state, ...{gateway: action.gateway,validation: null,success: null, taken: null}};
    case 'RESET_CREATE_FISCAL_YEAR':
      return {...state, ...{success:null,validation:null, taken: null, gateway: null}}
    default:
      return state;
  }
}
