import React from 'react'
import {connect} from "react-redux";
import TablePagination from "../../shared/table-helpers/TablePagination";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import {getTransactions} from "../../apis/transactions";
import TableHelpers from "../../shared/dashboard-helpers/TableHelpers";
import {getInvoices} from "../../apis/invoices";

class Transactions extends React.Component {

  filters = {
    limit: "10",
    page: null,
    searchArray: [],
    searchIndex: [],
    dateFilter: {},
    customer_name: '',
    startDate: null,
    endDate: null,
    status: {
      all: true,
      active: false,
      inactive: false
    },
  }

  state = {
    filters: {...this.filters},
    limit: "10",
    data: [],
    prevPageUrl: "",
    nextPageUrl: "",
    currentPage: "",
    from: "",
    to: "",
    total: "",
    lastPage: "",
    firstPage: "",
    page: null
  }


  componentDidMount() {
    if (!this.props.getTransactions.success)
      this.fetchData()
  }

  fetchData() {
    let {filters, limit, page} = this.state
    let {searchArray, dateFilter, searchIndex} = filters
    let statisticsFilter = [...searchArray]
    let statusCheck = searchIndex.indexOf('status')
    if (statusCheck >= 0) {
      statisticsFilter.splice(statusCheck,1)
    }
    getTransactions(limit, page, searchArray, dateFilter)


  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {getTransactions} = nextProps
    let {data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage, firstPage} = prevState;
    if (getTransactions) {
      let {success, error} = getTransactions
      if (success) {
        data = success.data.data;
        prevPageUrl = success.data.prev_page_url;
        nextPageUrl = success.data.next_page_url;
        currentPage = success.data.current_page;
        from = success.data.from;
        to = success.data.to;
        total = success.data.total;
        lastPage = success.data.last_page;
        firstPage = 1
      } else if (error) {
        console.log(error)
      }
    }
    return {
      data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage, firstPage
    }
  }

  confirmSearch(filters,statistics) {
    this.setState({
      filters,
    }, () => {
      this.fetchData()
    })
  }

  paginationButton(page) {
    this.setState({page}, () => {
      this.fetchData()
    });
  }

  limitChange(e) {
    this.setState({
      limit: e.target.value,
      page: 1
    }, () => {
      this.fetchData();
    });
  }

  onView(id) {
    this.props.history.push(`/transactions/${id}`)
  }

  render() {
    let {data, prevPageUrl, nextPageUrl, currentPage, from, to, total, lastPage} = this.state;


    const columns = [
      {
        title: 'Customer',
        dataIndex: 'customer_code',
        searchText: 'customer_code'
      },
      {
        title: 'Transaction Id',
        dataIndex: 'transaction_id',
        searchText: 'transaction_id'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        searchText: 'type'
      },
      {
        title: 'Channel',
        dataIndex: 'recharged_type',
        searchText: 'recharged_type'
      },
      {
        title: 'Product Name',
        dataIndex: 'product',
        searchText: 'product'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        searchText: 'amount'
      },
      {
        title: 'Accepted',
        dataIndex: 'accepted',
        searchText: 'accepted'
      },
      {
        title: 'Actions',
        action_links: [
          {
            type: 'navlink',
            icon: 'snippets',
            twoToneColor: "#4dbd74",
            title: 'View',
            url: ['/transactions/']
          },
        ]
      }
    ]

    let tableData = []

    data && data.map((single) => {
      let {id, customer, customer_code, transaction_id, type, recharged_type, product, amount, accepted} = single

      tableData.push({
        customer_code: customer ? `${customer.last_name} ${customer.first_name} (${customer_code})` : '-',
        type,
        recharged_type,
        transaction_id,
        id,
        product,
        amount,
        accepted
      })
      return null
    })

    return (
      <div className="animated fadeIn">

        <TableHelpers
          columns={columns}
          tableData={tableData}
          to={to}
          from={from}
          total={total}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          currentPage={currentPage}
          paginationButton={this.paginationButton.bind(this)}
          lastPage={lastPage}
          temp={this.temp}
          filters={this.state.filters}
          confirmSearch={this.confirmSearch.bind(this)}
          hideNewCard={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {getTransactions} = state;
  return {
    getTransactions
  }
}

export default connect(mapStateToProps)(Transactions)
